import React from 'react';
import moment from 'moment'

import { Range } from 'rc-slider'
import DJWall from 'app/shared/dj-wall'

import { markGenerator } from 'lib/string'

export default class DJInfo extends React.Component {
  constructor(props) {
    super(props);

    this.toggleVisible = this.toggleVisible.bind(this)
    this.handleRelease = this.handleRelease.bind(this)
    this.handleSliding = this.handleSliding.bind(this)
    this.handleRangeValues = this.handleRangeValues.bind(this)
    this.renderSimilarDJs = this.renderSimilarDJs.bind(this)

    this.state = {
      isVisible: true,
      currentYear: new Date().getFullYear(),
      from: 2012,
      to: new Date().getFullYear()
    }
  }

  toggleVisible(e) {
    e.preventDefault();

    this.setState({
      isVisible: !this.state.isVisible
    })
  }

  handleSliding(range) {
    const params = this.handleRangeValues(range)

    this.props.setSongFields(params);
  }

  handleRelease(range) {
    const params = this.handleRangeValues(range)

    this.props.setSongFields(params)
    this.props.updateUrlParams(params)
  }

  handleRangeValues (range) {
    let from = range[0]
    let to = range[1]

    this.setState({from, to})

    from = moment().year(from).month('January').date(1)
    to = moment().year(to)

    if (to !== this.state.currentYear) {
      to = to.month('December').date(1)
    }

    return { from, to }
  }

  renderSimilarDJs() {
    if(this.props.isPublic) return;

    return (
      <section>
        <h4>Similar DJs</h4>
        <DJWall 
          djs={this.props.similarDJs} width={3} limit={9}
          from={this.props.from} to={this.props.to}
        />
      </section>
    )
  }

  render() {
    const { dj } = this.props;

    const genres = dj && dj.genres ? dj.genres.slice(0, 5).map((g, gIndex) => {
      return (
        <label className='genre-label' key={`dj-${dj.id}-genre-${gIndex}`}>{ g }</label>
      )
    }) : []

    const iconClass = this.state.isVisible ? 'glyphicon-menu-down' : 'glyphicon-menu-right'
    
    const from = parseInt(this.props.from.format("YYYY"));
    const to = parseInt(this.props.to.format("YYYY"))

    return (
      <div className='m6k-panel dj-info'>
        {
          genres.length > 0 ?
            <section>
              <h4>Genres</h4>
              <div className='genres'>
                { genres }
              </div>
            </section> : null
        }

        <section>
          <h4 className='filter-title' onClick={this.toggleVisible}>
            Years Played
            <span className={'glyphicon ' + iconClass}></span>
          </h4>

          {
            this.state.isVisible ?
              <div className='date-slider'>
                <Range
                  allowCross={false}
                  defaultValue={[from, to]}
                  value={[from, to]}
                  step={1}
                  pushable
                  onChange={this.handleSliding}
                  onAfterChange={this.handleRelease}
                  min={1982}
                  max={this.state.currentYear}
                  tipFormatter={value => `${value}`}
                  marks={markGenerator()}
                />
              </div> : 
              null
          }
        </section>

        {
          this.renderSimilarDJs()
        }
      </div>
    )
  }
}

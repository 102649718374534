import React from 'react';

import playlistEmpty from 'assets/img/playlist-empty.jpg'

import { 
  formatYoutubeUrl,
  secondsToDurationString,
} from 'lib/video'

import { buildResourceUrl } from  'lib/string'

import ProfileNavItem from 'app/shared/profile/profile-nav-item'

export default class PlaylistHeader extends React.Component {
  constructor(props) {
    super(props);
    
    this.renderArt = this.renderArt.bind(this)
    this.renderNav = this.renderNav.bind(this)
  }

  renderArt() {
    const youtubeId = this.props.playlist.youtube_id
    if (youtubeId === null || youtubeId === undefined  || youtubeId === '') {
      return <img src={playlistEmpty} />
    } else {
      return <img src={ formatYoutubeUrl(youtubeId) } /> 
    }
  }

  renderNav() {
    const { playlist } = this.props;
    const url = buildResourceUrl('playlists', playlist.name, playlist.id);

    return (
      <ul className='playlist-nav'>
        <ProfileNavItem
          name='Tracks'
          count={playlist.total_songs}
          url={`${url}/tracks`}
        />
        {
          this.props.playlistViewer() ?
            <ProfileNavItem
              name='Collaborators'
              url={`${url}/collaborators`}
            /> : null
        }
      </ul>
    )
  }

  render() {
    const { playlist } = this.props;

    return (
      <div className='playlist-header m6k-panel'>
        <div className='playlist-album-art'>
          { this.renderArt() } 
        </div>

        <div className='playlist-detail-panel'>
          <div className='title'>
            <h1>{playlist.name}</h1>
          </div>

          { this.props.isMobile ? null : this.renderNav()}
        </div>

      </div>
    );
  }
}

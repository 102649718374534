export default [
  {
    name: 'Amin',
    isFilter: false,
    id: 'Amin',
  }, {
    name: 'Emin',
    isFilter: false,
    id: 'Emin'
  }, {
    name: 'Gmin',
    isFilter: false,
    id: 'Gmin',
  }, {
    name: 'Fmin',
    isFilter: false,
    id: 'Fmin'
  }, {
    name: 'Bmin',
    isFilter: false,
    id: 'Bmin'
  }, {
    name: 'Amaj',
    isFilter: false,
    id: 'Amaj',
  }, {
    name: 'E♭min',
    isFilter: false,
    id: 'E♭min'
  }, {
    name: 'B♭min',
    isFilter: false,
    id: 'B♭min'
  }, {
    name: 'Emaj',
    isFilter: false,
    id: 'Emaj'
  }, {
    name: 'E♭maj',
    isFilter: false,
    id: 'E♭maj'
  }, {
    name: 'B♭maj',
    isFilter: false,
    id: 'B♭maj'
  }, {
    name: 'Bmaj',
    isFilter: false,
    id: 'Bmaj'
  }, {
    name: 'A♯maj',
    isFilter: false,
    id: 'A♯maj'
  }, {
    name: 'A♯min',
    isFilter: false,
    id: 'A♯min',
  }, {
    name: 'C♯min',
    isFilter: false,
    id: 'C♯min',
  }, {
    name: 'G♯min',
    isFilter: false,
    id: 'G♯min',
  }, {
    name: 'G♯maj',
    isFilter: false,
    id: 'G♯maj',
  }, {
    name: 'Cmin',
    isFilter:  false,
    id: 'Cmin'
  }, {
    name: 'Dmin',
    isFilter: false,
    id: 'Dmin'
  }, {
    name: 'Cmaj',
    isFilter: false,
    id: 'Cmaj'
  }, {
    name: 'Gmaj',
    isFilter: false,
    id: 'Gmaj'
  }, {
    name: 'Fmaj',
    isFilter: false,
    id: 'Fmaj'
  }, {
    name: 'Dmaj',
    isFilter: false,
    id: 'Dmaj'
  }, {
    name: 'G♭min',
    isFilter: false,
    id: 'G♭min'
  }, {
    name: 'A♭min',
    isFilter: false,
    id: 'A♭min'
  }, {
    name: 'D♭min',
    isFilter: false,
    id: 'D♭min'
  }, {
    name: 'A♭maj',
    isFilter: false,
    id: 'A♭maj'
  }, {
    name: 'G♭maj',
    isFilter: false,
    id: 'G♭maj'
  }, {
    name: 'D♭maj',
    isFilter: false,
    id: 'D♭maj'
  }, {
    name: 'D♯min',
    isFilter: false,
    id: 'D♯min',
  }, {
    name: 'F♯min',
    isFilter: false,
    id: 'F♯min'
  }, {
    name: 'D♯maj',
    isFilter: false,
    id: 'D♯maj'
  }, {
    name: 'F♯maj',
    isFilter: false,
    id: 'F♯maj'
  }, {
    name: 'C♯maj',
    isFilter: false,
    id:  'C♯maj'
  }
]
import defaultAvatar from 'assets/img/default_avatar'
import reduce from 'lodash/reduce'

export const concatName = (firstName, lastName, username) => {
  if(firstName && lastName) {
    return `${firstName} ${lastName}`
  } else if(username) {
    return `${username}`
  } else {
    return ''
  }
}

export const getUrlFromPhoto = (photo, size) => {
  if (!photo || !photo.images || !photo.images.length) return defaultAvatar;

  const tuples = photo.images.map((i) => 
    [i, Math.abs(i.width - size)]
  )

  const image =  reduce(tuples, (memo, v) => {
    return memo[1] < v[1] ? memo : v;
  }, [-1, 9999])[0];

  return image.url
}
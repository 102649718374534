import React from 'react';

import { buildURL } from 'lib/artist-url'

import SquareImage from 'app/shared/dj-wall/square-image';
import FollowButton from 'app/shared/follow-button'
import ClickHandler from 'components/click-handler'

class SearchDJsList extends React.Component { 
  constructor(props) {
    super(props);

    this.renderItem = this.renderItem.bind(this)
    this.renderSkeleton = this.renderSkeleton.bind(this)
  }

  renderSkeleton(index) {
    return (
      <tr className='skeleton-row' key={`result-${index}`}>
        <td className='square'><div/></td>
        <td className='long-rectangle'>
          <div className='r1'/>
          <div className='r2'/>
        </td>
        <td></td>
      </tr>
    )  
  }

  renderItem(dj, index) {
    const urlObj = buildURL({
      urls: dj.discogs, 
      slug: dj.slug,
      from: this.props.from,
      to: this.props.to,
      id: dj.id,
    })

    const genres = dj.genres.slice(0, 3).map((g, gIndex) => {
      return (
        <label className='genre-label' key={`artist-${index}-genre-${gIndex}`}>{ g }</label>
      )
    })

    return (
      <tr className='result-row' key={`result-${index}`} >
        <td className='preview-image'>
          <ClickHandler url={urlObj.artistURL} >
            <SquareImage urlPath={urlObj.imgURL} />
          </ClickHandler>
        </td>
        <td className='info'>
          <ClickHandler url={urlObj.artistURL} >
            <h3>{ dj.name }</h3>
          </ClickHandler>
          <h5>{ dj.country.name || "" }</h5>
          <div className='genres'>
            {genres}
          </div>
        </td>
        <td className='action-row'>
          <FollowButton isFollowed={dj.is_followed} onClick={() => this.props.toggleFollow(dj) } />
        </td>
      </tr>
    )
  }

  render() {
    let items = []

    if(this.props.isFetching) {
      const count = 18
      for(var i = 0; i < count; ++i) {
        items.push(this.renderSkeleton(i))
      }
    } else {
      items = this.props.djs.map((dj, index) =>
        this.renderItem(dj, index)
      )
    }
   

    return (
      <tbody>
        { items }
        { this.props.endListItem }
      </tbody>
    )
  }
}

export default SearchDJsList;
import axios from 'lib/axios-config'
import { normalize } from 'normalizr'
import { songs } from 'app/schemas/main'

import uniq from 'lodash/uniq'
import filter from 'lodash/filter';

import {
  filterEmptyVideos
} from 'lib/video'

export const fetchSongFeed = (params) => {
  return (dispatch, getState) => {
    const url = `/api/song_feed`
    const state = getState();

    if (state.songFeed.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: 'FETCH_SONG_FEED_REQUEST'
    })

    return axios.get(url, {
      params,
    })
    .then((response) => {
      let results = filterEmptyVideos(response.data.songs);
      const normalized = normalize(results, songs)

      dispatch({
        type: 'FETCH_SONG_FEED_SUCCESS',
        payload: {
          entities: normalized.entities,
          ids: normalized.result,
          cursor: response.data.cursor,
          isEnd: response.data.is_end,
          sfSession: response.data.sf_session,
        }
      })

      return Promise.resolve()
    })
    .catch((error) => {
      dispatch({
        type: 'FETCH_SONG_FEED_FAILURE',
        payload: {
          error: error.response.data.error
        }
      })
    })
  }
}

export const cleanupSession = (sfSession) => {
  return (dispatch, getState) => {
    const url   = `/api/song_feed/user_session`
    const state = getState();

    if(state.songFeed.isDeleting) {
      return Promise.reject()
    }

    dispatch({
      type: 'DELETE_SONG_FEED_SF_SESSION_REQUEST'
    })

    return axios.delete(url, {
      sf_session: sfSession 
    })
      .then((response) => {
        dispatch({
          type: 'DELETE_SONG_FEED_SF_SESSION_SUCCESS'
        })

        return Promise.resolve()
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_SONG_FEED_SF_SESSION_FAILURE',
          payload: {
            error: error.response.data.error
          }
        })

        return Promise.reject()
      })
  }
}

export const resetIsEnd = () => {
  return {
    type: 'RESET_IS_END'
  }
}

export const clearSongFeed = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'CLEAR_SONG_FEED'
    })

    return Promise.resolve()
  }
}

export const clearAllFilterOptions = (key) => {
  return {
    type: 'CLEAR_ALL_FILTER_OPTIONS',
    payload: {
      key,
    }
  }
}

export const toggleFilterOption = (mode, key, id, isFilter = false) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'TOGGLE_FILTER_OPTION',
      payload: {
        mode,
        id,
        isFilter,
        key,
      }
    })
  }
}

export const selectSongFeed = (state) => {
  const entities = state.entities;
  const ids = state.songFeed.ids;

  return ids.map((id) => {
    return entities.songs[id]
  })
}

export const selectFilters = (state, type) => {
  return state.songFeed.filters[type].map((id) => {
    return state.entities[type][id]
  })
}

const defaultState = {
  isFetching: false,
  isDeleting: false,
  didInvalidate: false,
  error: null,
  isEnd: false,
  cursor: null,
  sfSession: null,
  ids: [],
  filters: {
    genres: [],
    artists: [],
    keys: [],
  }
}

const reducer = (state = defaultState, action) => {
  switch(action.type) {
    case 'FETCH_SONG_FEED_REQUEST':
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        error: null
      }
    case 'CLEAR_ALL_FILTER_OPTIONS':
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: []
        }
      }
    case 'FETCH_SONG_FEED_SUCCESS':
      return {
        ...state,
        isFetching: false,
        cursor: action.payload.cursor,
        isEnd: action.payload.isEnd,
        sfSession: action.payload.sfSession,
        ids: [
          ...state.ids,
          ...action.payload.ids,
        ]
      }
    case 'FETCH_SONG_FEED_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.payload.error,
      }
    case 'RESET_IS_END':
      return {
        ...state,
        isEnd: false,
      }
    case 'CLEAR_SONG_FEED':
      return {
        ...state,
        ids: [],
        isEnd: false,
        cursor: null,
        sfSession: null,
      }
    case 'DELETE_SONG_FEED_SF_SESSION_REQUEST':
      return {
        ...state,
        isDeleting: true
      }
    case 'DELETE_SONG_FEED_SF_SESSION_SUCCESS':
      return {
        ...state,
        isDeleting: false,
        sfSession: null
      }
    case 'DELETE_SONG_FEED_SF_SESSION_FAILURE':
      return {
        ...state,
        isDeleting: false,
        didInvalidate: true,
        error:  action.payload.error
      }
    case 'TOGGLE_FILTER_OPTION':
      var mode = action.payload.mode
      if(mode !== 'song-feed') return state;

      const key = action.payload.key

      if (action.payload.isFilter) {
        return {
          ...state,
          filters: {
            ...state.filters,
            [key]: uniq([
              ...state.filters[key],
              action.payload.id
            ])
          }
        } 
      } else {
        const items = filter(state.filters[key], id => id !== action.payload.id)

        return {
          ...state,
          filters: {
            ...state.filters,
            [key]: items,
          }
        }
      }
    default:
      return state;
  }
}

export default reducer;
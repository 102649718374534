import axios from 'lib/axios-config'
import { user, users } from 'app/schemas/main'
import { normalize } from 'normalizr'

export function getUserAccount (uuid) {
  return (dispatch, getState) => {
    const account = getState().currentUser.account
    const url = `/api/users/${uuid}`

    if (account.isFetching || uuid === null) {
      return Promise.reject()
    }

    dispatch({
      type: 'FETCH_USER_DATA'
    })

    return axios.get(url)
      .then((response) => {
        const normalized = normalize(response.data, user) 
        dispatch({
          type: 'FETCH_USER_DATA_SUCCESS',
          payload: {
            entities: normalized.entities,
            uuid: normalized.result,
          } 
        })
      }).catch((error) => {
        const status = error.response.status
        dispatch({
          type: 'FETCH_USER_DATA_FAILURE',
          error: error.response.data.error
        })
      })
  }
}

export function updateUserAccount (current_password, password = null, password_confirmation = null) {
  return (dispatch, getState) => {
    const account = getState().currentUser.account
    const form = account.form
    const url = `/api/users`

    if (account.isFetching || account.uuid === null) {
      return Promise.reject()
    }

    dispatch({
      type: 'UPDATE_USER_DATA'
    })

    let params = {
      ...form,
      current_password
    }

    if (password !== null) {
      params['password'] = password
    }

    if (password_confirmation !== null) {
      params['password_confirmation'] = password_confirmation
    }

    return axios.put(url, params)
      .then((response) => {
        const normalized = normalize({
          ...form,
          uuid: account.uuid
        }, user)
        dispatch({
          type: 'UPDATE_USER_DATA_SUCCESS',
          payload: {
            message: response.data.msg,
            entities: normalized.entities
          }
        })

        return Promise.resolve()
      }).catch((error) => {
        dispatch({
          type: 'UPDATE_USER_DATA_FAILURE',
          payload: {
            error: error.response.data
          }
        })

        return Promise.reject()
      })
  }
}


export function selectCurrentUser (state) {
  const uuid = state.currentUser.account.uuid

  if (state.entities.users.hasOwnProperty(uuid)) {
    return state.entities.users[uuid]
  } else {
    return {}
  }
}

export function initializeUserForm () {
  return (dispatch, getState) => {
    const state = getState()
    const user = selectCurrentUser(state)

    dispatch({
      type: 'INITIALIZE_USER_FORM',
      payload: {
        form: {
          username: user.username,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        }
      }
    })
  }
}

export function resetUserForm () {
  return (dispatch, getState) => {
    dispatch({type: "RESET_USER_FORM"})  
  }
}

export function setUserField(field, value) {
  return {
    type: "SET_USER_FIELD",
    field,
    value
  }
}

const defaultState = {
  isFetching: false,
  isUpdating: false,
  didInvalidate: false,
  error: null,
  message: null,
  uuid: null,
  form: {},
}

const account = (state = defaultState, action) => {
  switch (action.type) {
    case "SET_USER_FIELD":
      return {
        ...state,
        form: {
          ...state.form,
          [action.field]: action.value
        }
      }
    case 'INITIALIZE_USER_FORM':
      return {
        ...state,
        form: action.payload.form
      }
    case "RESET_USER_FORM":
      return {
        ...state,
        form: {}
      }
    case 'FETCH_USER_DATA':
      return {
        ...state,
        isFetching: true
      }
    case 'FETCH_USER_DATA_SUCCESS':
      return {
        ...state,
        uuid: action.payload.uuid,
        isFetching: false
      }
    case 'FETCH_USER_DATA_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    case 'UPDATE_USER_DATA':
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
        didInvalidate: false
      }
    case 'UPDATE_USER_DATA_SUCCESS':
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        message: action.payload.message
      }
    case 'UPDATE_USER_DATA_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'CREATE_USER_REQUEST':
      return {
        ...state,
        isFetching: true
      }
    case 'CREATE_USER_SUCCESS':
      return {
        ...state,
        isFetching: false,
        didInvalidate: false
      }
    case 'CREATE_USER_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default account;
import axios from 'lib/axios-config'
import { push, replace } from 'connected-react-router'

export function getInviteCampaign (name) {
  return (dispatch, getState) => {
    const url = `/api/invite_campaigns/${name}`
    const campaignState = getState().inviteCampaign

    if (campaignState.isFetching) {
      return Promise.reject()
    }

    dispatch({ type: 'GET_INVITE_CAMPAIGN' })

    return axios.get(url)
			.then((response) => {
  dispatch({
    type: 'GET_INVITE_CAMPAIGN_SUCCESS',
    payload: response.data
  })
})
			.catch((error) => {
  dispatch({
    type: 'GET_INVITE_CAMPAIGN_FAILURE',
    payload: {
      message: error.response.data.message
    }
  })

	      dispatch(replace('/login'))
  return Promise.reject()
})
  }
}

const defaultState = {
  isFetching: false,
  name: null,
  active: false,
  identifier: null,
  limit: null,
  sign_up_total: 0,
  expires: null,
  error: null
}

const inviteCampaign = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_INVITE_CAMPAIGN':
      return {
        ...state,
        isFetching: true
      }
    case 'GET_INVITE_CAMPAIGN_SUCCESS':
      return {
        ...state,
        ...action.payload,
        isFetching: false
      }
    case 'GET_INVITE_CAMPAIGN_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.payload.message
      }
    default:
      return state
  }
}

export default inviteCampaign

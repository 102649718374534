import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { userIsAuthenticated, userIsNotAuthenticated } from 'lib/authentication'

import Login from './login'
import Waitlist from './waitlist'
import ResetPassword from './reset-password'
import Invitation from './invitation'
import Unsubscribe from './unsubscribe'
import Signup from './signup'
import Confirmation from './confirmation'
import Legal from './legal'
import Home from './home'
import Help from './help'

import App from './app'
import Public from './app/public'

import RenderedRoute from 'components/rendered-route'

export class CreateRoutes extends React.Component {
  // Need to create HOC components that will remain for the remainder
  // lifecycle of the CreateRoutes component
  componentWillMount () {
    const { store } = this.props

    this.Confirmation = userIsNotAuthenticated(Confirmation(store))
    this.ResetPassword = userIsNotAuthenticated(ResetPassword(store))
    this.Signup = userIsNotAuthenticated(Signup(store))
    this.Waitlist = userIsNotAuthenticated(Waitlist(store))
    this.Invitation = userIsNotAuthenticated(Invitation(store))
    this.Unsubscribe = userIsNotAuthenticated(Unsubscribe(store))

    this.Help = Help(store); 

    this.AppOrPublic = userIsAuthenticated(App(store), Public(store))
  }

  render () {
    const authenticated = this.props.store.getState().auth.authenticated
    
    return (
      <Switch>
        <RenderedRoute exact path='/login' component={userIsNotAuthenticated(Login)} />

        <RenderedRoute path='/reset_password' component={this.ResetPassword} />
        <RenderedRoute exact path='/confirm' component={this.Confirmation} />
        <RenderedRoute exact path='/signup/:name' component={this.Signup} />
        <RenderedRoute exact path='/unsubscribe' component={this.Unsubscribe} />
        <RenderedRoute exact path='/invitation' component={this.Invitation} />
        <RenderedRoute exact path='/waitlist' component={this.Waitlist} />

        <RenderedRoute path='/help' component={this.Help} />

        { !authenticated ? <RenderedRoute path='/terms' component={Legal} /> : null }
        { !authenticated ? <RenderedRoute path='/privacy-policy' component={Legal} /> : null }

        <RenderedRoute path='/' component={this.AppOrPublic} />
      </Switch>
    )
  }
}

export default CreateRoutes

import React from 'react'
import defaultAvatar from 'assets/img/default_avatar.jpg'

class SquareImage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loaded: false,
      error: false
    }
  }

  componentDidMount () {
    this.image = new Image()

    this.image.onerror = function () {
      this.setState({
        loaded: true,
        error: true
      })
    }.bind(this)

    this.image.onload = function () {
      this.setState({
        loaded: true
      })
    }.bind(this)

    this.image.src = this.props.urlPath
  }

  componentWillUnmount () {
    this.image.onload = null
    this.image.onerror = null
  }

  render () {
    if (!this.state.loaded) {
      return (
        <img
          src={defaultAvatar}
        />
      )
    } else if (this.state.loaded && this.state.error) {
      return (
        <img
          src={defaultAvatar}
        />
      )
    } else {
      return (
        <img
          src={this.props.urlPath}
        />
      )
    }
  }
}

export default SquareImage

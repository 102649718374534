import axios from 'lib/axios-config'

const defaultState = {
  article: null,
  isFetching: false,
  didInvalidate: false,
}

export const fetchArticle = (uri) => {
  return (dispatch, getState) => {
    const url = `/api/articles/${uri}`
    const state = getState();

    if(state.articles.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: 'FETCH_ARTICLE_REQUEST'
    })

    return axios.get(url)
      .then((response) => {
        dispatch({
          type: 'FETCH_ARTICLE_SUCCESS',
          payload: {
            article: response.data,
          }
        }) 

        return Promise.resolve(response.data)
      })
      .catch((error) => {
        console.error(error);

        dispatch({
          type: 'FETCH_ARTICLE_FAILURE'
        })

        return Promise.reject()
      })
  }
}

const reducer = (state = defaultState, action) => {
  switch(action.type) {
    case 'FETCH_ARTICLE_REQUEST':
      return {
        ...state,
        isFetching: true,
      }
    case 'FETCH_ARTICLE_SUCCESS':
      return {
        ...state,
        isFetching: false,
        article: action.payload.article,
      }
    case 'FETCH_ARTICLE_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true
      }
    default:
      return state;
  }
}

export default reducer;
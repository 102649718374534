import React from 'react';

import { connect } from 'react-redux';
import { isBrowser } from "react-device-detect";

import ReactGA from 'react-ga';
import defaults from 'lodash/defaults';

import MusicPlayerBrowser from './music-player-browser'
import MusicPlayerMobile from './music-player-mobile'

import {
  selectPlayerSong,
  selectPlayerArtist,
  selectDJs,
  nextSong,
  prevSong,
  repeatSong,
  shuffleSong,
  queueNextSong,
  toggleRepeat,
  trackPlay,
  toggleShuffle,
} from '../modules/player';

import { 
  removeVideo,
  fetchSong,
} from 'app/modules/song';

import {
  favoriteSong,
  unfavoriteSong,
} from 'app/modules/favorites';

import {
  getItem,
  setItem
} from 'lib/local-storage';

import {
  trimTitle
} from 'lib/string';

import {
  YOUTUBE_PLAYER_STATE_PLAYING
} from 'lib/constants'; 

import VideoPlayer from './video-player'

const mapStateToProps = (state) => {
  return {
    song: selectPlayerSong(state),
    player: state.player,
    search: state.song.form,
    currentUser: state.currentUser,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchField: (name) => {
      dispatch({
        type: "SET_FIELD",
        field: "name",
        value: name
      })
    },
    fetchSong(id, isDetailed) {
      return dispatch(fetchSong(id, isDetailed));
    },
    favoriteSong(song) {
      dispatch(favoriteSong(song.id))
        .then(() => {})
    },
    unfavoriteSong(songId) {
      dispatch(unfavoriteSong(songId));
    },
    trackPlay(params) {
      dispatch(trackPlay(params))
    },
    nextSong() {
      dispatch(queueNextSong(nextSong));
    },
    prevSong() {
      dispatch(queueNextSong(prevSong));
    },
    repeatSong() {
      dispatch(queueNextSong(repeatSong));
    },
    shuffleSong() {
      dispatch(queueNextSong(shuffleSong));
    },
    toggleRepeat() {
      dispatch(toggleRepeat());
    }, 
    toggleShuffle() {
      dispatch(toggleShuffle());
    },
    removeVideo: () => {
      dispatch(removeVideo());
    },
  } 
}

class MusicPlayerWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.initializePlayerState = this.initializePlayerState.bind(this);
    this.handleResize = this.handleResize.bind(this);

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.nextSong = this.nextSong.bind(this);
    this.prevSong = this.prevSong.bind(this);
    this.togglePlay = this.togglePlay.bind(this);
    this.seekTo = this.seekTo.bind(this);
    this.seekDir = this.seekDir.bind(this);
    this.adjustVolume = this.adjustVolume.bind(this);
    this.toggleMute = this.toggleMute.bind(this);

    this.setPlayerValues = this.setPlayerValues.bind(this)
    this.queueNextSong = this.queueNextSong.bind(this)
    this.toggleRepeat = this.toggleRepeat.bind(this)
    this.toggleShuffle = this.toggleShuffle.bind(this)
    this.toggleFavorite = this.toggleFavorite.bind(this)
    this.toggleFullScreen = this.toggleFullScreen.bind(this)

    this.handleFocus = this.handleFocus.bind(this)

    this.counter = 0;

    this.state = {
      buffered: 0,
      currentTime: 0,
      duration: 0,
      songId: null,
      videoId: null,
      isMuted: false,
      volume: 100,
      isRepeat: false,
      isShuffle: false,
      isMobile: false,
      isFullScreen: false,
      width: 0,
      isFocused: false,
    }
  }

  stashPlayerState(playerStateValues = {}) {
    const currentPlayerState = defaults(
      playerStateValues,
      this.state
    );

    delete currentPlayerState['buffered'];
    delete currentPlayerState['forceStash'];
    delete currentPlayerState['isMobile'];
    delete currentPlayerState['isFullScreen']

    setItem("playerState", currentPlayerState);
    this.setState(currentPlayerState);
  }

  nextSong() {
    if(this.props.player.isShuffle) {
      this.props.shuffleSong();
    } else {
      this.props.nextSong();
    }
  }

  prevSong() {
    if(this.props.player.isShuffle) {
      this.props.shuffleSong();
    } else {
      this.props.prevSong();
    }
  }

  togglePlay() {
    const playerState = this.props.player.playerState;

    const isPlaying = playerState === YOUTUBE_PLAYER_STATE_PLAYING;
    const isActive = playerState !== null && 
      playerState !== -1;

    if(isActive) {
      if(isPlaying) {
        this.props.player.pauseVideo();
      } else {
        this.props.player.playVideo();
      }
    }
  }

  seekTo(progressPosition) {
    const duration = this.props.song.video.duration;
    const seconds = 60 * progressPosition * duration;

    this.props.player.seekTo(seconds, true);
  }

  seekDir(dir, increment) {
    const currentTime = this.props.player.getCurrentTime();

    const nextTime = currentTime + (dir * increment);
    const seconds = Math.max(0, Math.min(this.props.song.video.duration * 60, nextTime));

    this.props.player.seekTo(seconds, true);
  }

  adjustVolume(adjustment) {
    const volume = Math.max(0, Math.min(100, this.state.volume + adjustment));
    this.props.player.setVolume(volume);
  }

  toggleMute() {
    if(this.state.isMuted) {
      this.props.player.unMute();
    } else {
      this.props.player.mute();
    }
  }

  // Handles keyboard shortcuts to control music player.
  handleKeyPress(e) {
    if(!this.state.isFocused) return;

    switch(e.keyCode) {
      // Play / Pause Control
      case 32:
      case 75:
        e.preventDefault();
        this.togglePlay();
        break;

      // Restart Video
      case 48:
        e.preventDefault();
        this.seekTo(0);
        break;
      
      // Seek to position: 1-9
      case 49:
      case 50:
      case 51:
      case 52:
      case 53:
      case 54:
      case 55:
      case 56:
      case 57:
        e.preventDefault();
        const v = parseInt(e.key) * 0.1;

        this.seekTo(v);
        break;

      // Seek back / forth 5 seconds
      case 37:
      case 39:
        e.preventDefault();
        this.seekDir(e.keyCode === 37 ? -1 : 1, 5);
        break;

      // Seek back / forth 10 seconds
      case 74:
      case 76:
        e.preventDefault();
        this.seekDir(e.keyCode === 74 ? -1 : 1, 10);
        break;

      // Full screen mode
      case 70:
        e.preventDefault();
        this.props.player.fullScreen();
        break;

      // Volume up / down by 5%
      case 38:
      case 40:
        e.preventDefault();
        this.adjustVolume(e.keyCode === 38 ? 5 : -5);
        break;

      // Mute / un-mute volume
      case 77:
        e.preventDefault();
        this.toggleMute();
        break;

      // Next / previous song
      case 78:
      case 80:
        if(e.shiftKey) {
          if(e.keyCode === 78) {
            e.preventDefault();
            this.nextSong();
          } else {
            e.preventDefault();
            this.prevSong();
          }
        }
        break;

      // Toggle Shuffle
      case 83:
        e.preventDefault();
        this.props.toggleShuffle();
        break;

      // Toggle Repeat
      case 82:
        e.preventDefault();
        this.props.toggleRepeat();
        break;
    }
  }

  initializePlayerState() {
    const playerStateValues = getItem("playerState") || {};

    if(playerStateValues.currentTime) {
      playerStateValues.currentTime = parseFloat(playerStateValues.currentTime);
    }

    if(playerStateValues.volume) {
      playerStateValues.volume = parseFloat(playerStateValues.volume);
    }

    const currentPlayerState = defaults(
      playerStateValues,
      this.state
    );

    this.setState(currentPlayerState);
  }

  componentDidMount() {
    this.initializePlayerState();
    window.addEventListener("resize", this.handleResize);

    this.handleResize();

    if(this.props.player.songId) {
      this.props.fetchSong(this.props.player.songId, true)
        .then(() => {})
        .catch(() => {});
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize() {
    const w = window.innerWidth;

    if(w > 767 && this.state.isMobile === true) {
      this.setState({isMobile: false});
    } else if(w <= 767 && this.state.isMobile === false) {
      this.setState({isMobile: true})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.song.id !== this.props.song.id && prevProps.song.id !== undefined) { 
      this.props.fetchSong(this.props.song.id, true)
        .then((song) => {
          if(!this.props.isPublic) {
            this.props.trackPlay({ song_id: this.props.song.id })
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  setPlayerValues(options) {
    this.counter = (this.counter + 1) % 7;

    if(this.counter === 0 || options.forceStash) {
      this.stashPlayerState(options)
    }

    this.setState(options)
  }

  queueNextSong() {
    if(this.props.player.isRepeat) {
      this.props.repeatSong();
    } else if (this.props.player.isShuffle) {
      this.props.shuffleSong();
    } else {
      this.props.nextSong();
    }
  }

  toggleRepeat() {
    this.stashPlayerState({isRepeat: !this.state.isRepeat});
    this.props.toggleRepeat();
  }

  toggleShuffle () {
    this.stashPlayerState({isShuffle: !this.state.isShuffle});
    this.props.toggleShuffle()
  }

  toggleFullScreen() {
    if(this.state.isFullScreen) {
      document.body.classList.remove('no-scroll')
      document.documentElement.classList.remove('no-scroll');
    } else {
      document.body.classList.add('no-scroll')
      document.documentElement.classList.add('no-scroll');
    }

    this.setState({
      isFullScreen: !this.state.isFullScreen
    })
  }

  toggleFavorite () {
    const song = this.props.song;

    if(this.props.isPublic) {
      console.log('open modal')
      this.props.openLoginModal();
      return;
    }

    if(song.is_favorite) {
      this.props.unfavoriteSong(song.id);
    } else {
      this.props.favoriteSong(song, this.props.dj);
    }
  }

  handleFocus() {
    this.setState({isFocused: true})
  }

  render() {
    const styles = {}

    const { isPublic, currentUser } = this.props;

    if(this.state.isFullScreen) {
      styles['height'] = '100%'
    } else if (isBrowser) {
      styles['height'] = '100%'
    } else {
      styles['height'] = '100%'
    }

    if(isPublic) {
      styles["left"] = "0px"
    } else {
      if(currentUser && currentUser.ui.playerLocation === 'right') {
        styles['right'] = '0px'
      } else {
        styles['left'] = '0px'
      }
    }

    return (
      <div>
        <div 
          className={`m6k-player-wrapper${this.state.isFullScreen && !isBrowser ? '-fullscreen' : ''}`} 
          onKeyDown={this.handleKeyPress}
          tabIndex="1"
          onFocus={this.handleFocus}
          style={styles}
        >

          <VideoPlayer 
            setPlayerValues={this.setPlayerValues}
            isMobile={this.state.isMobile}

            queueNextSong={this.queueNextSong}
            currentBuffered={this.state.buffered}
            currentTime={this.state.currentTime}
            volume={this.state.volume}
            removeVideo={this.props.removeVideo}
          />
          {
            isBrowser ?
              <MusicPlayerBrowser
                isBrowser={isBrowser}
                setPlayerValues={this.setPlayerValues}
                isMobile={this.state.isMobile}

                isPublic={isPublic}
                openLoginModal={this.props.openLoginModal}

                queueNextSong={this.queueNextSong}
                currentBuffered={this.state.buffered}
                currentTime={this.state.currentTime}
                volume={this.state.volume}
                isMuted={this.state.isMuted}

                removeVideo={this.props.removeVideo}

                song={this.props.song}
                player={this.props.player}

                togglePlay={this.togglePlay}
                nextSong={this.nextSong}
                prevSong={this.prevSong}
                toggleRepeat={this.toggleRepeat}
                toggleShuffle={this.toggleShuffle}
                toggleFavorite={this.toggleFavorite}

                setSearchField={this.props.setSearchField}
                fetchDJsPlayedThisSong={this.fetchDJsPlayedThisSong}
                search={this.props.search}
              /> :
              <MusicPlayerMobile
                isBrowser={isBrowser}
                setPlayerValues={this.setPlayerValues}
                isMobile={this.state.isMobile}

                queueNextSong={this.queueNextSong}
                currentBuffered={this.state.buffered}
                currentTime={this.state.currentTime}
                volume={this.state.volume}
                isMuted={this.state.isMuted}

                removeVideo={this.props.removeVideo}

                isFullScreen={this.state.isFullScreen}
                toggleFullScreen={this.toggleFullScreen}

                song={this.props.song}
                player={this.props.player}

                togglePlay={this.togglePlay}
                nextSong={this.nextSong}
                prevSong={this.prevSong}
                toggleRepeat={this.toggleRepeat}
                toggleShuffle={this.toggleShuffle}
                toggleFavorite={this.toggleFavorite}

                setSearchField={this.props.setSearchField}
                fetchDJsPlayedThisSong={this.fetchDJsPlayedThisSong}
                search={this.props.search}
              />
          }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MusicPlayerWrapper);
import React from 'react';
import { NavLink } from 'react-router-dom'

export default class ProfileNavItem extends React.Component {
  render() {
    const { name, count, icon } = this.props


    const url = this.props.url ? this.props.url : this.props.pathname + this.props.search

    return (
      <li className='profile-nav-item'>
        <NavLink 
          to={url} 
          activeClassName='active' 
          isActive={(match, location) => {
            const compareUrl = this.props.url ? this.props.url : this.props.pathname
            return location.pathname === compareUrl
          }}
        >
          <h4>{name}</h4>
          { count ? <h5>{count}</h5> : null }
          { icon ? <span onClick={this.props.handleIconClick} className={`glyphicon ${icon}`} /> : null }
        </NavLink>
      </li>
    );
  }
}

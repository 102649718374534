import React from 'react'
import { connect } from 'react-redux'

import Notice from 'components/Notice/Notice'
import Subscription from './Subscription'

import {
  fetchEmailSubscriptions,
  updateEmailNotifications,
  toggleEmailSubscription
} from 'modules/email-notifications'

class EmailNotifications extends React.Component {
  constructor (props) {
    super(props)

    this.closeNotice = this.closeNotice.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      message: null,
      messageVisible: false,
      messageClasses: null,
      unsavedChanges: false
    }
  }

  closeNotice () {
    this.setState({
      message: null,
      messageVisible: false
    })
  }

  handleChange (index) {
    this.setState({
      unsavedChanges: true
    })
  }

  handleSubmit (e) {
    e.preventDefault()

    this.props.updateEmailNotifications()
      .then(() => {
        this.setState({
          unsavedChanges: false,
          message: 'Email settings successfully updated!',
          messageVisible: true,
          messageClasses: 'green'
        })
      })
      .catch(() => {
        this.setState({
          message: 'Something went wrong',
          messageVisible: true,
          messageClasses: 'red'
        })
      })
  }

  componentDidMount () {
    this.props.fetchEmailSubscriptions()
  }

  render () {
    const subscriptions = this.props.subscriptions.map((s, i) =>
      <Subscription
        key={i}
        subscription={s}
        index={i}
        onChange={this.handleChange}
        handleClick={this.props.toggleEmailSubscription}
      />
    )

    return (
      <div>
        <h2>Email Notifications</h2>
        <Notice
          classes={this.state.messageClasses}
          visible={this.state.messageVisible}
          handleClick={this.closeNotice}
        >
          {this.state.message}
        </Notice>
        <form className='form-horizontal' onSubmit={this.handleSubmit}>
          <div className='section'>
            <h3><span>Newsletter Subscriptions</span></h3>
            {subscriptions}
          </div>
          <button
            type='submit'
            className='btn btn-default'
            disabled={
              this.state.unsavedChanges
              ? '' : 'disabled'
            }
          >Save Changes</button>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    subscriptions: state.emailNotifications.subscriptions
  }
}

export default connect(mapStateToProps, {
  fetchEmailSubscriptions,
  updateEmailNotifications,
  toggleEmailSubscription
})(EmailNotifications)

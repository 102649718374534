import React from 'react';
import M6kThumbnail from './m6k-thumbnail'

import { buildResourceUrl } from 'lib/string'
import { formatYoutubeUrl, secondsToDurationString } from 'lib/video'
import { trimTitle } from 'lib/string';

export default class CompactSetlistItem extends React.Component {
  constructor(props) {
    super(props);

    this.isCoverPhoto = this.isCoverPhoto.bind(this)

    this.handleControlAction = this.handleControlAction.bind(this)
    this.renderControls = this.renderControls.bind(this)
  }

  isCoverPhoto(song) {
    return song && song.video && this.props.playlist &&
      song.video.youtube_id === this.props.playlist.youtube_id   
  }

  handleControlAction(e) {
    e.preventDefault();
    e.stopPropagation()

    const index = this.props.listIndex

    const playerState = this.props.playerState
    const playerIndex = this.props.playerIndex

    const isPlaying = (index === playerIndex) && (playerState === 1)
    const isPaused = (index === playerIndex) && (playerState === 2)
    const isBuffering = (index === playerIndex) && (playerState === 3)

    const isActive = (index === playerIndex) &&
      playerState !== null &&
      playerState !== -1 &&
      playerState !== 5

    if (isActive) {
      if (isPaused) {
        this.props.playVideo()
      } else {
        this.props.pauseVideo()
      }
    } else {
      this.props.playVideoAt(this.props.listIndex)
    }
  }

  renderControls() {
    const index = this.props.listIndex
    const playerState = this.props.playerState
    const playerIndex = this.props.playerIndex

    const isPlaying = (index === playerIndex) && (playerState === 1)
    const isPaused = (index === playerIndex) && (playerState === 2)
    const isBuffering = (index === playerIndex) && (playerState === 3)

    const isActive = (index === playerIndex) &&
      playerState !== null &&
      playerState !== -1 &&
      playerState !== 5

    let iconClass = 'glyphicon '

    if (isPlaying) {
      iconClass += 'glyphicon-volume-up'
    } else if (isBuffering) {
      iconClass += 'glyphicon-transfer'
    } else {
      iconClass += 'glyphicon-play'
    }

    return (
      <button className='icon-display' onClick={this.handleControlAction} >
        <span className={iconClass} onClick={this.handleControlAction} />
      </button>
    )
  }

  render() {
    const { playlist, song, listIndex } = this.props;

    const songUrl  = buildResourceUrl('song', song.video.name, song.id)
    const imageUrl = formatYoutubeUrl(song.video.youtube_id);

    return (
      <li className='compact-setlist-item no-select'>
        <div className='list-index'>
          { parseInt(listIndex) + 1}
        </div>

        <M6kThumbnail
          imageUrl={imageUrl}
          handleLoad={this.handleLoad}
          handleError={this.handleError}
        />

        <div className='title'>
          <span>{ song.video ? trimTitle(song.video.name) : '' }</span>
        </div>

        { this.renderControls() }
      </li>
    );
  }
}

import axios from 'lib/axios-config'
import queryString from 'query-string'
import moment from 'moment'
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import uniq from 'lodash/uniq'

import { normalize } from 'normalizr'
import { artist, artists } from 'app/schemas/main'
import { createSelector } from 'reselect'
import { replace, push } from 'connected-react-router'

const defaultState = {
  ids: [],
  total: 0,
  filters: {
    genres: [],
    from: moment('2012-01-01'),
    to: moment(),
  }
}

export const selectSongs = (state) => {
  const { songs } = state.entities;
  const ids = state.search.songs.ids;

  return ids.map((id) => {
    const song = songs[id];

    return {
      ...song,
    }
  })
}

const searchSongsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_SEARCH_RESULTS_SUCCESS':
      const ids = action.payload.loadMore ? 
        [ ...state.ids, ...action.payload.songs.ids ] : 
        action.payload.songs.ids

      return {
        ...state,
        ids,
        total: action.payload.songs.total,
      }
    case 'SET_SEARCH_FIELD':
      if(action.payload.mode !== 'songs') return state;

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.field]: action.payload.value
        }
      }
    case 'TOGGLE_FILTER_OPTION':
      if(action.payload.mode !== 'songs') return state;

      const key = action.payload.key

      if (action.payload.isFilter) {
        return {
          ...state,
          filters: {
            ...state.filters,
            [key]: uniq([
              ...state.filters[key],
              action.payload.id
            ])
          }
        }
      } else {
        const items = filter(state.filters[key], id => id !== action.payload.id)

        return {
          ...state,
          filters: {
            ...state.filters,
            [key]: items,
          }
        }
      }
    case 'CLEAR_ALL_FILTER_OPTIONS':
      if(action.payload.mode !== 'songs') return state;

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: []
        }
      }
    default:
      return state === null ? defaultState : state
  }
}

export default searchSongsReducer;
import React from 'react';
import { connect } from 'react-redux';
import { Range } from 'rc-slider'
import moment from 'moment'

import { isBrowser } from "react-device-detect";

import FilterSection from 'app/shared/filters/filter-section';
import FilterPanel from 'app/shared/filters/filter-panel/index'
import Filters from 'app/shared/filters/filters'

import withInfiniteScroll from 'app/shared/infinite-scroll'
import Setlist from 'app/shared/setlist'

import Loading from 'react-loading'

import {
  updateGenreSearch,
  selectGenres
} from 'app/modules/genres'

import { selectSongs } from 'app/modules/search/search-songs';

import { 
  submitSearch, 
  toggleFilterOption,
  setSearchField,
  selectFilterGenres,
  updateUrl,
  setSearchQuery,
  selectFilterCountries,
  clearAllFilterOptions,
} from 'app/modules/search'

import {
  markGenerator
} from 'lib/string'

const mapStateToProps = (state) => {
  return {
    q: state.search.q,
    from: state.search.songs.filters.from,
    to: state.search.songs.filters.to,
    songs: selectSongs(state) || [],
    page: state.search.pagination.page,
    genres: selectGenres(state),
    genreFilters: selectFilterGenres(state),
    genreFilterValue: state.genres.search,
    isFetching: state.search.isFetching,
  }
}

class SearchSongs extends React.Component {

  constructor(props) {
    super(props);

    this.renderBrowser = this.renderBrowser.bind(this)
    this.renderMobile = this.renderMobile.bind(this);
    this.getTitle = this.getTitle.bind(this)
    this.renderEndListItem = this.renderEndListItem.bind(this)
    this.renderSetlist = this.renderSetlist.bind(this)

    this.onPaginatedSearch = this.onPaginatedSearch.bind(this)

    this.handleSliding = this.handleSliding.bind(this)
    this.handleRelease = this.handleRelease.bind(this)
    this.handleRangeValues = this.handleRangeValues.bind(this)

    this.state = {
      mode: 'list',
      currentYear: new Date().getFullYear(),
      from: 2012,
      to: new Date().getFullYear(),
      isPaginatedFetching: false, 
    }
  }

  componentWillMount() {
    this.InfiniteSearchSongs = withInfiniteScroll(Setlist)
  }

  handleSliding (range) {
    this.handleRangeValues(range)
  }

  handleRelease (range) {
    this.handleRangeValues(range)
    this.props.updateUrl()
    this.props.submitSearch()
  }

  handleRangeValues (range) {
    let from = range[0]
    let to = range[1]

    this.setState({from, to})

    from = moment().year(from).month('January').date(1)
    to = moment().year(to)

    if (to !== this.state.currentYear) {
      to = to.month('December').date(1)
    }

    this.props.setSearchField('songs', 'from', from)
    this.props.setSearchField('songs', 'to', to)
  }

  onPaginatedSearch() {
    if(this.props.isFetching) return;

    this.setState({isPaginatedFetching: true})

    this.props.submitSearch(true)
      .then(() => {
        this.setState({isPaginatedFetching: false})
      })
      .catch(() => {})
  }

  getTitle() {
    const isFetching = this.props.isInitialFetching || 
      (this.props.isFetching && this.props.page === 1)

    const title = isFetching ?
      'Searching...' : 'Results'

    return title;
  }

  renderFilters() {
    const from = parseInt(this.props.from.format("YYYY"));
    const to = parseInt(this.props.to.format("YYYY"))

    return (
      <div className='m6k-panel filters'>
        <h3>Filters</h3>
        <FilterSection name='Years Played'>
          <div className='date-slider'>
            <Range
              allowCross={false}
              defaultValue={[from, to]}
              value={[from, to]}
              step={1}
              pushable
              onChange={this.handleSliding}
              onAfterChange={this.handleRelease}
              min={1982}
              max={this.state.currentYear}
              tipFormatter={value => `${value}`}
              marks={markGenerator()}
            />
          </div>
        </FilterSection>
        <FilterSection name='Genres'>
          <FilterPanel 
            placeholder='Find a genre'
            items={this.props.genres} 
            filterValue={this.props.genreFilterValue}
            filter={this.props.updateGenreSearch}
            toggle={(id, isFilter) => {
              this.props.toggleFilterOption('songs', 'genres', id, isFilter)
              this.props.updateUrl()
              this.props.submitSearch()
            }}
          />
        </FilterSection>
      </div>
    )
  }

  renderEndListItem () {
    if(this.state.isPaginatedFetching && !this.props.reachedLimit) {
      return (
        <li className='setlist-empty'>
          <div className='loading'>
            <Loading
              type='spin'
              color='#aaa'
              delay={0}
              height={45}
              width={45}
            />
          </div>
        </li>
      )
    } else {
      return (
        <li className='setlist-empty'>
          <h3 className='logo'>M</h3>
        </li>
      )
    }
  }

  renderSetlist() {
    const isFetching = this.props.isInitialFetching || 
      (this.props.isFetching && this.props.page === 1)

    return (
      React.createElement(this.InfiniteSearchSongs, {
        songs: this.props.songs,
        onPaginatedSearch: this.onPaginatedSearch,
        toggleFollow: this.toggleFollow,
        endListItem: this.renderEndListItem,
        isInitialFetching: isFetching,
        isPaginatedFetching: this.state.isPaginatedFetching,
        title: this.getTitle(),
      })
    )
  }
  
  renderBrowser() {
    return (
      <div>
        <div className='row'>
          <div className='col-md-12'>
            <Filters
              genres={this.props.genreFilters}
              name={this.props.q}
              from={this.props.from.format("YYYY")}
              to={this.props.to.format("YYYY")}
              clearName={() => {
                this.props.setSearchQuery("")
                this.props.updateUrl()
                this.props.submitSearch()
              }}
              clearGenre={(id) => {
                this.props.toggleFilterOption('songs', 'genres', id)
                this.props.updateUrl()
                this.props.submitSearch()
              }}
              clearAll={() => {
                this.props.clearAllFilterOptions('songs', 'genres')
                this.props.setSearchQuery("")
                this.props.updateUrl()
                this.props.submitSearch()
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3 m6k-fixed-col-3 col-md-4 col-sm-4 col-xs-4'>
            { this.renderFilters() }
          </div>
          <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>
            { this.renderSetlist() }
          </div>
        </div>
      </div>
    )
  }

  renderMobile() {
    return (
      <div className='results-container m6k-panel'>
        {
          React.createElement(this.InfiniteSearchSongs, {
            songs: this.props.songs,
            onPaginatedSearch: this.onPaginatedSearch,
            toggleFollow: this.toggleFollow,
            endListItem: this.renderEndListItem
          })
        }
      </div>
    )
  }

  render() {
    return this.renderBrowser()
  }
}

export default connect(mapStateToProps, {
  toggleFilterOption,
  setSearchQuery, 
  clearAllFilterOptions,
  updateUrl,
  submitSearch,
  setSearchField,
  updateGenreSearch,
})(SearchSongs)
import React from 'react';
import { connect } from 'react-redux'

import Loading from 'react-loading'
import Setlist from  'app/shared/setlist'

import {
  selectPlaylist,
} from 'app/modules/playlists'

class PlaylistTracks extends React.Component {
  constructor(props) {
    super(props);

    this.renderEndListItem = this.renderEndListItem.bind(this)
  }

  renderEndListItem() {
    if(this.props.isFetchingPlaylistSongs) {
      return (
        <li className='setlist-empty'>
          <div className='loading'>
            <Loading
              type='spin'
              color='#aaa'
              delay={0}
              height={45}
              width={45}
            />
          </div>
        </li>
      )
    } else {
      return (
        <li className='setlist-empty'>
          <h3 className='logo'>M</h3>
        </li>
      ) 
    }
  }

  render() {
    const songs = this.props.playlist.playlistSongs.map((ps) => {
      return ps.song
    })

    return (
      <div className='playlist-tracks'>
        <Setlist 
          songs={songs}
          playlist={this.props.playlist}
          title={this.props.playlist.name}
          endListItem={this.renderEndListItem}
          isInitialFetching={this.props.isFetchingPlaylistSongs}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    playlist: selectPlaylist(state),
    isFetchingPlaylistSongs: state.playlists.isFetchingPlaylistSongs,
  }
}

export default connect(mapStateToProps, {
})(PlaylistTracks)
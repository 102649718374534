import React from 'react';
import { connect } from 'react-redux'

import Loading from 'react-loading'
import Switch from 'react-toggle-switch'

import 'react-toggle-switch/dist/css/switch.min.css'

import { arrayMove } from 'react-sortable-hoc'

import GenreTag from './genre-tag';
import EditButton from './edit-button';
import GenreList from './genre-list';

import EditGenre from './edit-genres';
import EditGenreTag from './edit-genre-tag';

import CityList from './city-list';

import { 
	selectGenres, 
	fetchGenres,
	updateGenreSearch,
} from 'app/modules/genres'

import {
	getCities,
	searchCities,
	clearCitySearch,
} from 'app/modules/cities'

import { 
	removeUserGenres,
	addUserGenres,
	updateUserProfile
} from 'app/modules/profile'

import differenceBy from 'lodash/differenceBy';
import intersectionBy from 'lodash/intersectionBy';

class ProfileInfo extends React.Component {
	constructor(props) {
		super(props);

		this.renderVistor = this.renderVistor.bind(this)
		this.renderCurrentUser = this.renderCurrentUser.bind(this)
		this.renderUserEdit = this.renderUserEdit.bind(this)
		this.renderUserInfo = this.renderUserInfo.bind(this)
		this.renderGenreList = this.renderGenreList.bind(this)

		this.availableGenres = this.availableGenres.bind(this)
		this.renderGenres = this.renderGenres.bind(this)
		this.renderEditGenres = this.renderEditGenres.bind(this)

		this.removeGenre = this.removeGenre.bind(this)
		this.addGenre = this.addGenre.bind(this)

		this.searchCities = this.searchCities.bind(this)
		this.renderCityList = this.renderCityList.bind(this)

		this.setEdit = this.setEdit.bind(this)
		this.saveChanges = this.saveChanges.bind(this)

		this.handleOutsideClickGenre = this.handleOutsideClickGenre.bind(this);
		this.handleOutsideClickCity = this.handleOutsideClickCity.bind(this);

		this.aboutPlaceholderText = "Write something a little bit about yourself here to let others know what's going on."

		this.getUrl = this.getUrl.bind(this)

		this.state = {
			switched: false,
			isEditing: false,
			changed: false,
			genres: [],
			genresToRemove: [],
			location: '',
			citySearch: false,
		}
	}

	renderGenres() {
		const genres = this.props.genres || []
		return genres.map((genre, index) => 
			<GenreTag name={genre.name} key={index} />
		)
	}

	renderEditGenres() {
		const genres = this.state.genres

		return (
			<EditGenre 
				pressDelay={100}
				transitionDuration={150}
				axis='xy'
				helperClass='genre-dragger'
				lockToContainerEdges={true}
				useDragHandle={true}
				onSortEnd={({oldIndex, newIndex}) => {
					this.setState({
						changed: true,
	      		genres: arrayMove(this.state.genres, oldIndex, newIndex),
	    		});
				}}
			>
				{
					genres.map((genre, index) => 
						<EditGenreTag 
							index={index}
							key={index} 
							name={genre.name} 
							handleClick={(e) => {
								e.preventDefault()
								this.removeGenre(index)
							}}
						/>
					)
				}
			</EditGenre>
		)
	}

	renderGenreList() {
		const genres = this.availableGenres()

		if(this.props.search.length > 0 && genres.length > 0) {
			return (
				<GenreList 
					genres={genres} 
					handleClick={this.addGenre}
				/>
			)
		} else {
			return null;
		}
	}

	setEdit(e) {
		this.setState({
			visibility: this.props.visibility || 'private_profile',
			isEditing: true, 
			genres: this.props.genres || [],
			genresToRemove: [],
			changed: false,
			location: this.props.location || "",
			citySearch: false,
		})

		this.props.updateGenreSearch('')
	}

	saveChanges() {
		const resolvers = []

		if(this.bio.value !== this.props.bio || 
			 this.websiteUrl !== this.props.website_url ||
			 this.state.visibility !== this.props.visibility ||
			 (this.state.location !== this.props.location)) {
			resolvers.push(
				this.props.updateUserProfile({
					bio: this.bio.value,
					website_url: this.websiteUrl.value,
					city_id: this.state.city_id,
					visibility_type: this.state.visibility,
				})
			)
		}

		if(this.state.genresToRemove.length > 0) {
			// only remove genres belonging to original profile
			const genres = intersectionBy(this.props.genres, this.state.genresToRemove, 'id');
			resolvers.push(this.props.removeUserGenres(genres))
		}

		let genresChanged = false

    if(this.props.genres.length === this.state.genres.length) {
	    genresChanged = this.state.genres.find((target, i) => 
	    	this.props.genres[i].id !== target.id 
	    )
    }

    if(this.state.genres.length !== this.props.genres.length || genresChanged) {
			resolvers.push(this.props.addUserGenres(this.state.genres))
    }

		Promise.all(resolvers)
			.then(() => {
				this.setState({
					isEditing: false,
				})
			})
	}

	availableGenres() {
		return differenceBy(this.props.allGenres, this.state.genres, 'name')
	}

	addGenre(genre) {
		this.setState({
			changed: true,
			genres: [
				...this.state.genres,
				genre
			]
		})
	}

	removeGenre(index) {
		const genres = this.state.genres;

		this.setState({
			changed: true,
			genresToRemove: [
				...this.state.genresToRemove,
				genres[index],
			],
			genres: [
				...genres.slice(0, index),
				...genres.slice(index + 1)
			]
		})
	}

	searchCities(e) {
		const v = e.target.value;

		this.setState({
			location: v,
			citySearch: true,
			city_id: null
		})

		if(!this.props.cityIsFetching) {
			this.props.searchCities(e.target.value)
		}
	}

	renderCityList() {
		if(this.state.citySearch && this.state.location.length > 0 && 
			 this.props.cities && this.props.cities.length > 0) {
			return (
				<CityList
					cities={this.props.cities}
					handleClick={(city, name) => {
						this.setState({
							location: name, city_id: city.id, citySearch: false,
						})
					}}
				/>
			)			
		} else {
			return null;
		}
	}

	handleOutsideClickGenre(e) {
		if(!this.genreSearchArea || this.genreSearchArea.contains(e.target)) {
			return 
		}

		this.props.updateGenreSearch('')
	}

	handleOutsideClickCity(e) {
		if(!this.citySearchArea || this.citySearchArea.contains(e.target)) {
			return 
		}

		this.props.clearCitySearch()
	}

	componentDidMount() {
		document.addEventListener('click', this.handleOutsideClickGenre, false);
		document.addEventListener('click', this.handleOutsideClickCity, false);

		this.props.fetchGenres()
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleOutsideClickGenre, false);
		document.removeEventListener('click', this.handleOutsideClickCity, false);
	}

	renderUserEdit() {
		const genres = this.renderEditGenres() 

		return (
			<div className='m6k-panel profile-info-detailed'>
				<section>
					<h4>About</h4>
					<textarea 
						defaultValue={this.props.bio}
						placeholder={this.aboutPlaceholderText}
						ref={(textArea) => this.bio = textArea}
					/>
				</section>

				<section>
					<h4>Favorite Genres</h4>

					{
						this.state.genres.length > 0 ? 
							genres
							: <p>
								Search for genres that you love, click and move them to reorder anyway you'd like.
							</p> 
					}

					<div className='dropdown-wrapper' ref={(ref) => this.genreSearchArea = ref }>
			      <input 
			      	type="text" 
			      	className="form-control"
			      	placeholder='Trance, Progressive House, Techno'
			      	value={this.props.search || ''}
			      	onChange={(e) => this.props.updateGenreSearch(e.target.value) }
			      />

			      { this.renderGenreList() }
		      </div>

				</section>

				<section>
					<h4>Personal Website</h4>
					<input
						type="text"
						className="form-control"
						defaultValue={this.props.website_url || ""}
						ref={(ref) => this.websiteUrl = ref}
					/>
				</section>

				<section>
					<h4>Location</h4>
					<div className='dropdown-wrapper' ref={(ref) => this.citySearchArea = ref }>
						<input
							className='form-control'
							value={this.state.location}
							placeholder='New York, New York'
							onChange={this.searchCities}
							onKeyUp={(e) => {
								if(e.key === 'Escape') {
									this.props.clearCitySearch()
								}
							}}
						/>
						{ this.renderCityList() }
					</div>
				</section>

				<section>
					<h4>Profile</h4>

					<div className='switch-toggle-container'>
						<label>Private</label>
						<Switch 
							onClick={() => {
						    this.setState(prevState => {
						      return {
						        visibility: prevState.visibility === 'public_profile' ? 
						        	'private_profile' : 'public_profile'
						      };
						    });
							}} 
							on={this.state.visibility === 'public_profile'}
							className='switch-button'
						/>
						<label>Public</label>
					</div>

        	<p className='info-text'>When your profile is marked private, only friends can view your profile.</p>
				</section>

				<section>
					<div className='centered'>
						<button 
							className='btn btn-default'
							onClick={() => this.setState({isEditing: false})}	
						>
							Cancel
						</button>
						<button 
							className='btn btn-primary'
							onClick={this.saveChanges}
						>
							Save
						</button>
					</div>
				</section>
			</div>
		)
	}

	renderUserInfo() {
		const genres = this.renderGenres()

		return (
			<div className='m6k-panel profile-info-detailed'>
				<section>
					<h4 className='edit-title'>About</h4>
					<EditButton handleClick={this.setEdit} />
					<p>{this.props.bio}</p>
				</section>

				<section>
					<h4 className='edit-title'>Favorite Genres</h4>
					<EditButton handleClick={this.setEdit} />

					{
						genres.length > 0 ? 
							<div className='genres'>
								{genres}
							</div>
							: <p></p>
					}

				</section>

				<section>
					<h4 className='edit-title'>Personal Website</h4>
					<EditButton handleClick={this.setEdit} />
					<a className='profile-url' href={this.getUrl()} target="_blank">{
						this.props.website_url
					}</a>
				</section>

				<section>
					<h4 className='edit-title'>Location</h4>
					<EditButton handleClick={this.setEdit} />
					<p>{this.props.location}</p>
				</section>

				<section>
					<h4 className='edit-title'>Profile</h4>
					<EditButton handleClick={this.setEdit} />
					<p>{this.props.visibility === 'public_profile' ? 'Public' : 'Private'}</p>
				</section>
			</div>
		)
	}

	renderProfileUpdating() {
		return (
			<div className='m6k-panel profile-info-detailed' style={{lineHeight: '300px', height: '300px'}}>
				<div className='profile-loading'>
			    <Loading
		        type='spin'
		        color='#aaa'
		        delay={0}
		        height={60}
		        width={60}
		      />
	      </div>
			</div>
		)
	}

	renderCurrentUser() {
		if(this.state.isEditing) {
			return this.props.profileIsUpdating ?
				this.renderProfileUpdating() :
				this.renderUserEdit()
		} else {
			return this.renderUserInfo()
		}
	}

	getUrl() {
		const url = this.props.website_url;
		if(!url) return ''

		return url.includes("http") ? url : `https://${url}`
	}

	renderVistor() {
		const genres = this.renderGenres()
		return (
			<div className='m6k-panel profile-info-detailed'>
				<section>
					<h4>About</h4>
					{
						this.props.bio ?
							<p>{this.props.bio}</p>
							: null
					}
				</section>

				{
					genres.length > 0 ?
						(
							<section>
								<h4>Favorite Genres</h4>
								<div className='genres'>
									{genres}
								</div>
							</section>
						) : null
				}

				{
					this.props.website_url ?
						(<section>
							<h4>Connect</h4>
							<a className='profile-url' href={this.getUrl()} target="_blank">{
								this.props.website_url
							}</a>
						</section>) : null
				}
			</div>
		);
	}

	render() {
		return this.props.isCurrentUser ?
			this.renderCurrentUser() : 
			this.renderVistor()
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		allGenres: selectGenres(state),
		cities: getCities(state),
		search: state.genres.search,
		cityIsFetching: state.cities.isFetching,
		profileIsUpdating: state.profile.isUpdating,
	}
}

export default connect(mapStateToProps, {
	fetchGenres,
	updateGenreSearch,
	removeUserGenres,
	addUserGenres,
	updateUserProfile,
	searchCities,
	clearCitySearch
})(ProfileInfo)
import React from 'react';

import VideoPlayer from './video-player'
import PlayerControls from './player-controls/player-controls';
import ClickHandler from 'components/click-handler'
import DJWall from 'app/shared/dj-wall';

import { 
  buildResourceUrl
} from 'lib/string'

import {
  trimTitle,
  createMarkup
} from 'lib/string';

import unescape from 'lodash/unescape'

import '../styles/player.scss'

export default class MusicPlayerBrowser extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if(!this.props.isBrowser) return null;

    const { song, player } = this.props;
    const video = song.video || {} 
    const title = video ? trimTitle(video.name) : ""

    return (
      <div className='m6k-player'>
        {
          this.props.isPublic ?
            <a href="" className='video-title-wrapper' onClick={e => e.preventDefault()}>
              <h3 className='video-title'>{unescape(title)}</h3>
            </a> :
            <ClickHandler url={buildResourceUrl('song', video.name, song.id)} className='video-title-wrapper'>
              <h3 className='video-title'>{unescape(title)}</h3>
            </ClickHandler>
        }

        <PlayerControls
          currentTime={this.props.currentTime} 
          buffered={this.props.currentBuffered}
          duration={video.duration}
          isMuted={this.props.isMuted}
          volume={this.props.volume}

          seekTo={player.seekTo}
          playerState={player.playerState}
          isRepeat={player.isRepeat}
          isShuffle={player.isShuffle}
          mute={player.mute}
          unMute={player.unMute}
          setVolume={player.setVolume}

          isFavorite={song.is_favorite}
          marketplace={song.marketplace}

          togglePlay={this.props.togglePlay}
          nextSong={this.props.nextSong}
          prevSong={this.props.prevSong}
          
          toggleRepeat={this.props.toggleRepeat}
          toggleShuffle={this.props.toggleShuffle}
          toggleFavorite={this.props.toggleFavorite}
        />
        
        {
          this.props.song && this.props.song.djs ?
            <div className='dj-playing'>
              <h5>DJs Played this Song</h5>
                  <DJWall
                    width={6}
                    widthSmall={4}
                    widthXSmall={3}
                    djs={this.props.song.djs}
                    from={this.props.from}
                    to={this.props.to}
                    isPublic={this.props.isPublic}
                  /> 
            </div>
          : null
        }

      </div>
    );
  }
}

import { injectReducer } from 'store/reducers'
import asyncComponent from 'components/async-component'

export default (store) => asyncComponent(() => new Promise((resolve) => {
  require.ensure([], (require) => {
    const Help = require('./containers/help').default
    const articles = require('../../modules/articles').default
    const breadcrumbs = require('../../modules/breadcrumbs').default

    injectReducer(store, { key: 'articles', reducer: articles })
    injectReducer(store, { key: 'breadcrumbs', reducer: breadcrumbs })

    resolve(Help)
  }, 'help')
}))

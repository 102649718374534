import React from 'react'

import Progress from 'react-progressbar';
import Modal from 'react-modal';

const UploadProgressModal = (props) => {

	return (
		<div>
		<Modal
			isOpen={props.visible}
			contentLabel='UploadProgressModal'
			shouldCloseOnOverlayClick={false}
			overlayClassName='profile-img-modal-overlay'
			className='profile-edit-img-modal'
		>
			<div className='modal-header'>
				<h3>Uploading...</h3>
			</div>

			<div className='modal-body uploading'>
				<Progress 
					completed={props.uploadPercentage}
					className='uploading-progress-bar'
					height='7px'
					color='#E74C3C'
					animation={600}
				/>
			</div>
		</Modal>
		</div>
	)
}

export default UploadProgressModal;
import React from 'react';

export default class ListGallery extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='list-gallery m6k-panel'>
        <div className='get-prev'>
        </div>

        <div className='list-items'>
          { this.props.children }
        </div> 

        <div className='get-next'>
        </div>
      </div>
    );
  }
}

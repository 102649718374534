import React from 'react';

export default class Country extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='country'>
        <img src={this.props.uri128} alt={this.props.iso}/>       
        <h5>{this.props.name}</h5>
      </div>
    );
  }
}

import { injectReducer } from 'store/reducers'
import asyncComponent from 'components/async-component'

export default (store) => asyncComponent(() => new Promise((resolve) => {
  require.ensure([], (require) => {
    const Waitlist = require('./components/waitlist').default
    const reducer = require('./modules/waitlist').default

    injectReducer(store, { key: 'waitlist', reducer })

    resolve(Waitlist)
  }, 'waitlist')
}))

import React from 'react';
import { connect } from 'react-redux'
import Modal from 'react-modal'
import Loading from 'react-loading'

import ClickHandler from 'components/click-handler'

import {
  findFeedTemplate,
  createFeedTemplate,
  updateFeedTemplate,
  deleteFeedTemplate,
  selectFeedTemplates,
  selectFeedTemplate,
  setFeedTemplate,
} from 'app/modules/feed-templates'

const mapStateToProps = (state) => {
  return {
    result: selectFeedTemplate(state, 'result'),
    current: selectFeedTemplate(state, 'template'),
    isCreating: state.feedTemplates.isCreating,
    isUpdating: state.feedTemplates.isUpdating,
    isFetching: state.feedTemplates.isFetching,
  }
}

class FeedTemplateModal extends React.Component {
  constructor(props) {
    super(props);

    this.setTab = this.setTab.bind(this)
    this.renderHeader = this.renderHeader.bind(this)

    this.findTemplate = this.findTemplate.bind(this)
    this.createFeedTemplate = this.createFeedTemplate.bind(this)
    this.deleteFeedTemplate = this.deleteFeedTemplate.bind(this)
    this.loadTemplate = this.loadTemplate.bind(this)

    this.renderFilterDetails = this.renderFilterDetails.bind(this)
    this.createFeedTemplateFromImport = this.createFeedTemplateFromImport.bind(this)

    this.renderCurrent = this.renderCurrent.bind(this);
    this.renderManage = this.renderManage.bind(this);
    this.renderCreate = this.renderCreate.bind(this);
    this.renderImport = this.renderImport.bind(this)

    this.state = {
      importValue: '',
      templateName: '',
      tab: 'CURRENT',
      tabIndex: 0,
      tabs: [{
        tabName: 'CURRENT',
        label: 'Current Template',
        fn: this.renderCurrent,
      }, {
        tabName: 'MANAGE',
        label: 'Manage Templates',
        fn: this.renderManage,
      }, {
        tabName: 'CREATE',
        label: 'Create Template',
        fn: this.renderCreate,
      }, {
        tabName: 'IMPORT',
        label: 'Import Template',
        fn: this.renderImport,
      }]
    }
  }

  setTab(tab, tabIndex) {
    this.setState({
      tab,
      tabIndex
    })
  }

  renderFilterDetails(settings) {
    const {
      genres,
      artists,
      keys,
      from,
      to,
      bpmMin,
      bpmMax
    } = settings;

    const artistFilters = artists.map((artist, index) => {
      return (
        <li key={'key-' + index} className='list-group-item'>
          { artist.name }
        </li>
      )
    })

    const genreFilters = genres.map((genre, index) => {
      return (
        <li key={'key-' + index} className='list-group-item'>
          {genre.name}
        </li>
      )
    })

    const keyFilters = keys.map((key, index) => {
      return (
        <li key={'key-' + index} className='list-group-item'>
          {key.name}
        </li>
      )
    })

    return (
      <div className='row'>
        <div className='col-md-2'>
          <div className='input-group'>
            <label>Time Window</label>
            <div>{ from + " - " + to }</div>
          </div>

          <div className='input-group' style={{'marginTop': '15px'}}>
            <label>BPM Min</label>
            <div> 
              { bpmMin ? bpmMin : <span>No min BPM set</span> }
            </div>
          </div>

          <div className='input-group' style={{'marginTop': '15px'}}>
            <label>BPM Max</label>
            <div> 
              { bpmMax ? bpmMax : <span>No max BPM set</span> }
            </div>
          </div>
        </div>

        <div className='col-md-3'>
          <div className='input-group'>
            <label>DJs</label>
            <ul className='list-group'>
              { artistFilters.length > 0 ? 
                  artistFilters : <span>No DJs selected</span>
              }
            </ul>
          </div>
        </div>

        <div className='col-md-3'>
          <div className='input-group'>
            <label>Genres</label>
            <ul className='list-group'>
              { genreFilters.length > 0 ? 
                  genreFilters : <span>No genres selected</span>
              }
            </ul>
          </div>
        </div>

        <div className='col-md-3'>
          <div className='input-group'>
            <label>Keys</label>
            <ul className='list-group'>
              { keyFilters.length > 0 ? 
                  keyFilters : <span>No keys selected</span>
              }
            </ul>
          </div>
        </div>
      </div>
    )
  }

  loadTemplate(template) {
    this.props.setFeedTemplate(template.code)
    this.props.setFiltersFromTemplate(template);

    this.props.onCancel() 
  }

  renderCurrent() {
    const current = this.props.current;

    return (
      <div className='current tab-body'>
        { current && current.code ? 
            <div className='fixed-area'>
              <label>Name</label>
              <div>{ current.name }</div>
              <hr/>
              { this.renderFilterDetails(JSON.parse(current.settings))}
            </div> :
            <div>
              <p style={{'marginTop' : '25px', 'marginBottom' : '25px' }}>
                No template selected, please create a new template, import a code, or load a template alreay saved.
              </p>
            </div>
        }
      </div>
    )
  }

  deleteFeedTemplate(template, index) {
    this.props.deleteFeedTemplate(template, index)
      .then((clearAll) => {
        if(clearAll) {
          this.props.clearAllFilterOptions()
          this.props.cleanupAndFetchSongFeed()
        }
      })
  }

  renderManage() {
    const { templates } = this.props;

    if(!templates || templates.length === 0) {
      return (
        <div className='tab-body'>
          <p style={{'marginTop' : '25px', 'marginBottom' : '25px' }}>
            No templates created, please create a new template or import a code.
          </p>
        </div>
      )
    }

    return (
      <div className='current tab-body'>
        <div className='fixed-area'>
          <ul className='list-group'>
          { templates.map((t, index) => {
            return (
              <li className='list-group-item' key={index}>
                <span style={{'marginRight': "25px"}}>
                { t.name }
                </span>

                <span className='form-control' style={{'marginRight': "5px", width: '200px'}}>
                  { t.code }
                </span>

                <div className='btn-group' style={{'marginLeft': 'auto'}}>
                  <button 
                    className='btn btn-default'
                    onClick={(e) => {
                      e.preventDefault();
                      this.loadTemplate(t)
                    }}
                  >
                    Load <i className="fas fa-check-circle"></i>
                  </button>
                  <button 
                    className='btn btn-default'
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.toggleShowDetails(index)
                    }}
                  >
                    Details <i className="fas fa-window-maximize"></i>
                  </button>
                  <button 
                    className='btn btn-default'
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.toggleShowDelete(index)
                    }}
                  >
                    Delete <i className="fas fa-ellipsis-h"></i>
                  </button>
                </div>

                <div className='flex-break'></div>

                {
                  t.showDetails ?
                    <div style={{'width': '100%', 'marginTop': '15px'}}> 
                      { this.renderFilterDetails(JSON.parse(t.settings)) }
                    </div>
                    : null
                }

                { t.showDeleting ? 
                    <div style={{'width': '100%', 'marginTop': '15px'}}>
                      <span style={{'marginRight': '15px'}}>Are you sure you want to delete this template?</span>
                      <div className='btn-group'>
                        <button 
                          className='btn btn-danger btn-sm'
                          onClick={(e) => {
                            e.preventDefault();
                            this.deleteFeedTemplate(t, index)
                          }}
                        >
                          Yes, Delete
                        </button>

                        <button 
                          className='btn btn-default btn-sm'                     
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.toggleShowDelete(index)
                          }}
                        >
                          No, Cancel
                        </button>
                      </div>
                    </div>
                    : null
                }
              </li>
            )
          })}
          </ul>
        </div>
      </div>
    )
  }

  renderCreate() {
    return (
      <div className='current tab-body'>
        <form className='form' onSubmit={this.createFeedTemplate}>
          <div className='fixed-area'>
            <h4>Template Settings</h4>
            <hr/>
            
            <div className='input-group'>
              <label>Name</label>
              <input 
                type='text' 
                className='form-control' 
                onChange={e => this.setState({templateName: e.target.value})}
              />
            </div>

            <hr/>

            <h4>Current Filters</h4>
            <hr/>
            { this.renderFilterDetails(this.props) }
          </div>
          <hr/>

          <button type="submit" className='btn btn-primary' style={{'marginTop': '15px'}}>
            Save
          </button>
        </form>
      </div>
    )
  }

  findTemplate(e) {
    e.stopPropagation();
    e.preventDefault()

    if(!this.state.importValue || this.state.importValue === '') {
      return;
    }

    this.props.findFeedTemplate(this.state.importValue)
      .then((result) => {
        this.setState({
          templateName: result.name,
        })
      })
      .catch(() => {})
  }

  createFeedTemplate(e) {
    e.preventDefault()

    if(!this.state.templateName || this.state.templateName === '') return;

    const { 
      bpmMin,
      bpmMax,
      genres,
      artists,
      keys,
      from,
      to
    } = this.props;

    const params = {
      feed_template: {
        name: this.state.templateName,
        settings: JSON.stringify({
          from: from,
          to: to,
          bpmMin: bpmMin, 
          bpmMax: bpmMax, 
          genres: genres.map((g) => { return { id: g.id, name: g.name }}),
          artists: artists.map((a) => { return { id: a.id, name: a.name }}),
          keys: keys.map((k) => { return { id: k.id, name: k.name }})
        })
      }
    }

    this.props.createFeedTemplate(params)
      .then((response) => {
        this.setState({
          tab: 'CURRENT',
          tabIndex: 0
        })

        this.props.onCancel()
      })
  }

  createFeedTemplateFromImport(e) {
    e.preventDefault()

    if(!this.state.templateName || this.state.templateName === '') return;

    const { 
      bpmMin,
      bpmMax,
      genres,
      artists,
      keys,
      from,
      to
    } = this.props;

    const params = {
      feed_template: {
        name: this.state.templateName,
        settings: this.props.result.settings
      }
    }

    this.props.createFeedTemplate(params)
      .then((response) => {
        this.props.setFiltersFromTemplate(response)

        this.props.onCancel()
      })
  }

  renderImport() {
    const result = this.props.result;

    if(result && !result.code && !this.props.isFetching) {
      return (
        <div className='tab-body'>
          <h4>Step 1: Look up a template by pasting in template code</h4>
          <hr/>
          <form onSubmit={this.findTemplate} className='form'>
            <div className='input-group'>
              <label>Code</label>
              <input 
                type="text" 
                className='form-control' 
                placeholder="m6k-123abc456def7891" 
                onChange={(e) => {
                  this.setState({
                    importValue: e.target.value
                  })
                }}
              />
            </div>

            <button type='submit' className='btn btn-primary' style={{'marginTop': '15px'}}>
              Find Template
            </button>
          </form>
        </div>
      )
    } else if (result && !result.code && !this.props.isFetching) {
      return (
        <div className='tab-body'>
          <div className='loading'>
            <Loading
              type='spin'
              color='#aaa'
              delay={0}
              height={60}
              width={60}
            />
          </div>
        </div>
      )
    } else if (result && result.code) {
      return (
        <div className='tab-body'>
          <form onSubmit={this.createFeedTemplateFromImport} className='form'>
            <div className='fixed-area'>
              <h4>Step 1: Template Found</h4>
              <hr/>

              <div className='input-group'>
                <label>Code</label>
                <input type="text" value={result.code} disabled={true} className='form-control' />
              </div>

              <hr/>
              <h4>Step 2: Make changes to the template</h4>
              <hr/>

              <div className='input-group'>
                <label>Name</label>
                <input 
                  className='form-control'
                  type="text" 
                  value={this.state.templateName}
                  onChange={(e) => {
                    this.setState({
                      templateName: e.target.value
                    })
                  }}
                />
              </div>

              <hr/>
              { this.renderFilterDetails(JSON.parse(result.settings)) }
            </div>

            <hr/>
            <h4>Step 3: Save template</h4>
            <button type='submit' className='btn btn-primary'>
              Save Template
            </button>
          </form>
        </div>
      )
    }
  }

  renderHeader() {
    const tabs = this.state.tabs.map((t, index) => {
      return (
        <li
          className={this.state.tab === t.tabName ? 'active' : ''}
          key={index}
          onClick={(e) => {
            e.preventDefault();
            this.setTab(t.tabName, index)
          }}
        >
          { t.label }
        </li>
      )
    })
    return (
      <div className='modal-header'>
        <div className='row'>
          <div className='col-md-12'>
            <ul className='modal-nav'>
              { tabs }
            </ul>
          </div>
        </div>
      </div>
    )
  }

  renderBody() {
    const state = this.state;
    const view = state.tabs[state.tabIndex].fn()

    return (
      <div className='modal-body'>
        { view }
      </div>
    )
  }

  render() {
    return (
      <div>
         <Modal
          isOpen={this.props.show}
          onRequestClose={this.props.onCancel}
          contentLabel='Template Modal'
          className='template-modal m6k-panel'
          htmlOpenClassName="template-modal-html"
        >
          { this.renderHeader() }
          { this.renderBody() }
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  findFeedTemplate,
  createFeedTemplate,
  updateFeedTemplate,
  deleteFeedTemplate,
  setFeedTemplate,
})(FeedTemplateModal)
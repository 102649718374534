import { normalize } from 'normalizr'
import axios from 'lib/axios-config'
import moment from 'moment'

import { artist, artists } from '../schemas/main'

export function clearSimilarArtists () {
  return {
    type: 'CLEAR_SIMILAR_ARTISTS'
  }
}

export function clearArtistProfile () {
  return {
    type: 'CLEAR_ARTIST_PROFILE'
  }
}

export function toggleIsFollowed (artist_id, is_followed) {
  return {
    type: 'TOGGLE_IS_FOLLOWED',
    payload: {
      id: artist_id,
      is_followed
    }
  }
}

export function fetchArtist (id) {
  return (dispatch, getState) => {
    const url = `/api/artists/${id}`

    dispatch({
      type: 'FETCH_ARTIST'
    })

    return axios.get(url)
      .then((response) => {
        const normalized = normalize(response.data, artist)

        return dispatch({
          type: 'FETCH_ARTIST_SUCCESS',
          payload: {
            entities: normalized.entities
          }
        })
      }).catch((error) => {
        dispatch({
          type: 'FETCH_ARTIST_FAILURE',
          error: error.response.data || 'Something went wrong.'
        })
      })
  }
}

export function fetchDJProfile (id, from, to) {
  return (dispatch, getState) => {
    const url = `/api/artists/${id}/details`

    dispatch({
      type: 'FETCH_ARTIST_PROFILE'
    })

    return axios.get(url, {
      params: {
        from,
        to
      }
    })
      .then((response) => {
        const normalized = normalize(response.data, artist)

        dispatch({
          type: 'FETCH_ARTIST_PROFILE_SUCCESS',
          payload: {
            entities: normalized.entities,
            id: normalized.result
          }
        })

        return Promise.resolve(response.data)
      })
        .catch((error) => {
          dispatch({
            type: 'FETCH_ARTIST_PROFILE_FAILURE',
            payload: {
              error: error.response.data || 'Something went wrong.'
            }
          })

          return Promise.reject(error)
        })
  } 
}

export function searchArtist (name, from, to) {
  return (dispatch, getState) => {
    if (name === '' || name === null || name === undefined) {
      return Promise.reject()
    }

    const encodedName = encodeURIComponent(name)
    const url = `/api/artists/${encodedName}/search`

    if (getState().artist.isFetchingProfile) {
      return Promise.reject()
    }

    dispatch({
      type: 'REQUEST_SEARCH_ARTIST'
    })

    return axios.get(url, {
      params: {
        from,
        to
      }
    })
      .then((response) => {
        const normalized = normalize(response.data, artist)

        dispatch({
          type: 'REQUEST_SEARCH_ARTIST_SUCCESS',
          payload: {
            entities: normalized.entities,
            id: normalized.result
          }
        })

        return Promise.resolve(response.data)
      }).catch((error) => {
        dispatch({
          type: 'SEARCH_ARTIST_FAILURE',
          payload: {
            error: error.response.data || 'Something went wrong.'
          }
        })
      })
  }
}

export function fetchGenreProfile (id, from, to, limit = 8) {
  return (dispatch, getState) => {
    const url = `/api/artists/${id}/genre_profile`

    dispatch({
      type: 'FETCH_ARTIST_GENRE_PROFILE'
    })

    return axios.get(url, {
      params: {
        from,
        to,
        limit
      }
    }).then((response) => {
      dispatch({
        type: 'FETCH_ARTIST_GENRE_PROFILE_SUCCESS',
        payload: {
          id,
          genres: response.data
        }
      })
    }).catch((error) => {
      dispatch({
        type: 'FETCH_ARTIST_GENRE_PROFILE_FAILURE',
        payload: {
          error: error.response.data
        }
      })
    })
  }
}

export function clearOtherDJs (reducerName) {
  return {
    type: 'CLEAR_ARTISTS_PLAYED_THIS_SONG',
    reducerName
  }
}


export function fetchSimilarDJs (id, limit = 12) {
  return (dispatch, getState) => {
    const artist = getState().artist
    const url = `/api/artists/${id}/similar`

    if (artist.isFetchingSimilar) {
      return Promise.reject('Is Fetching...')
    }

    dispatch({
      type: 'SEARCH_SIMILAR_ARTISTS'
    })

    const form = getState().song.form
    const from = moment(form.from).format('YYYY-MM-DD')
    const to = moment(form.to).format('YYYY-MM-DD')

    return axios.get(url, {
      params: {
        from,
        to,
        limit
      }
    }).then((response) => {
      const normalized = normalize(response.data, artists)
      dispatch({
        type: 'SEARCH_SIMILAR_ARTISTS_SUCCESS',
        payload: {
          entities: normalized.entities,
          ids: normalized.result
        }
      })
    }).catch((error) => {
      dispatch({
        type: 'SEARCH_SIMILAR_ARTISTS_FAILURE',
        error: error.response.data || 'Something went wrong.'
      })
    })
  }
}

export const getArtistProfile = (state) => {
  const entities = state.entities
  const selectedId = state.artist.selected

  return {
    country: {},
    discogs: {},
    ...entities.artists[selectedId]
  }
}

export const selectDJ = (state) => {
  return getArtistProfile(state);
}

export const getSimilarDJs = (state) => {
  const entities = state.entities

  return state.artist.similarDJs.map((id) =>
    entities.artists[id]
  )
}

export const selectSimilarDJs = (state) => {
  return getSimilarDJs(state);
}

export const getOtherDJs = (state) => {
  const entities = state.entities

  return state.artist.otherDJs.map((id) =>
    entities.artists[id]
  )
}

const defaultRequestState = {
  isFetching: false,
  isFetchingProfile: false,
  isFetchingSimilar: false,
  didInvalidate: false,
  error: null,
  selected: null,
  otherDJs: [],
  similarDJs: []
}

const artistReducer = (state = defaultRequestState, action) => {
  switch (action.type) {
    case 'REQUEST_SEARCH_ARTIST':
      return {
        ...state,
        isFetchingProfile: true
      }
    case 'REQUEST_SEARCH_ARTIST_SUCCESS':
      return {
        ...state,
        isFetchingProfile: false,
        selected: action.payload.id
      }
    case 'REQUEST_SEARCH_ARTIST_FAILURE':
      return {
        ...state,
        isFetchingProfile: false,
        didInvalidate: true,
        error: action.error
      }

    case 'FETCH_ARTIST_PROFILE':
      return {
        ...state,
        isFetchingProfile: true
      }
    case 'FETCH_ARTIST_PROFILE_SUCCESS':
      return {
        ...state,
        isFetchingProfile: false,
        selected: action.payload.id
      }
    case 'FETCH_ARTIST_PROFILE_FAILURE':
      return {
        ...state,
        isFetchingProfile: false,
        didInvalidate: true,
        error: action.error
      }

    case 'FETCH_ARTIST':
      return {
        ...state
      }
    case 'FETCH_ARTIST_SUCCESS':
      return {
        ...state
      }
    case 'FETCH_ARTIST_FAILURE':
      return {
        ...state,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'FETCH_ARTIST_GENRE_PROFILE':
      return {
        ...state,
        isFetching: true
      }
    case 'FETCH_ARTIST_GENRE_PROFILE_SUCCESS':
      return {
        ...state,
        isFetching: false
      }
    case 'FETCH_ARTIST_GENRE_PROFILE_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'CLEAR_ARTIST_PROFILE':
      return {
        ...state,
        selected: null
      }
    case 'SEARCH_SIMILAR_ARTISTS':
      return {
        ...state,
        isFetchingSimilar: true
      }
    case 'SEARCH_SIMILAR_ARTISTS_SUCCESS':
      return {
        ...state,
        isFetchingSimilar: false,
        similarDJs: action.payload.ids
      }
    case 'SEARCH_SIMILAR_ARTISTS_FAILURE':
      return {
        ...state,
        isFetchingSimilar: false,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'CLEAR_SIMILAR_ARTISTS':
      return {
        ...state,
        similarDJs: []
      }
    default:
      return state
  }
}

export default artistReducer

import React, { Component } from 'react'
import DJTile from './dj-tile'
import './dj-wall.scss'

class DJWall extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    let djs = []

    if (this.props.djs && this.props.djs.length > 0) {
      djs = this.props.djs.map((dj, index) =>
        <DJTile
          name={dj.name}
          slug={dj.slug}
          isPublic={this.props.isPublic}
          id={dj.id}
          urls={dj.discogs}
          key={`dj-${dj.id}-${index}`}
          width={this.props.width || 6}
          widthSmall={this.props.widthSmall || 4}
          widthXSmall={this.props.widthXSmall || 3}
          from={this.props.from}
          to={this.props.to}
        />
      )

      if(this.props.limit) {
        djs = djs.slice(0, this.props.limit);
      }
    }

    return (
      <div className='dj-wall'>
        <div className='row'>
          {djs}
        </div>
      </div>
    )
  }
}

export default DJWall; 
import React from 'react';
import { Glyphicon, Dropdown, DropdownButton, MenuItem} from 'react-bootstrap'

import './index.scss'

class MobileFriendButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { user, currentUserId } = this.props;

    if(user.uuid === currentUserId) {
      return (
        null 
      )
    }

    if(user.did_receive_friend_request) {
      return (
        <div className='mobile-friend-button'>
          <i className="fas fa-user-check"></i>
        </div>
      ) 
    }

    if(user.did_send_friend_request) {
      return (
        <div className='mobile-friend-button'>
          <i className="fas fa-user-check"></i>
        </div>
      )
    }

    if(user.is_friend) {
      return (
        <div className='mobile-friend-button'>
          <i className="fas fa-user-friends"></i>
        </div>
      )
    }

    return (
      <div className='mobile-friend-button'
        onClick={(e) => {
          e.preventDefault()
          this.props.sendFriendRequest(user.uuid)
        }}
      >
        <i className="fas fa-user-plus"></i>
      </div>
    )
  }
}

export default MobileFriendButton;
import React from 'react';
import ReactDOM from 'react-dom'

export default class DropdownMenu extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  handleClickOutside (e) {
    const domNode = ReactDOM.findDOMNode(this)
    if (!domNode || !domNode.contains(e.target)) {
      this.props.closeDropdown();
    }
  }

  render() {
    return (
      <ul className='m6k-dropdown-menu m6k-panel' style={{top: this.props.positionTop}}>
        <li onClick={(e) => {
          e.preventDefault();
          this.props.handleClick('ADD')
          this.props.closeDropdown()
        }}>Add To Playlist</li>
        <li onClick={(e) => {
          e.preventDefault();
          this.props.handleClick('CREATE')
          this.props.closeDropdown()
        }}>Create New Playlist</li>
      </ul>
    );
  }
}

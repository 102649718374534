import React from 'react';
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Switch, Route, Redirect } from 'react-router-dom'

import moment from 'moment'
import queryString from 'query-string'
import { replace } from 'connected-react-router'

import PlaylistHeader from './playlist-header'
import PlaylistInfo from './playlist-info'

import {
  PlaylistTracks,
  PlaylistTracksEditable
} from '../routes/playlist-tracks';

import PlaylistCollaborators from '../routes/playlist-collaborators'

import {
  fetchPlaylist,
  selectPlaylist,
  selectUserPlaylistPermission,
  fetchPlaylistSongs,
  updatePlaylist,
  removeSongFromPlaylist,
  updatePlaylistSong,
  deletePlaylist,
} from 'app/modules/playlists'

import {
  updatePlaylistLocation 
} from 'app/shared/music-player/modules/player'

import { buildResourceUrl } from  'lib/string'

import '../styles/playlist.scss'

const mapStateToProps = (state) => {
  return {
    playlist: selectPlaylist(state),
    userPlaylistPermission: selectUserPlaylistPermission(state),
    isFetchingPlaylistSongs: state.playlists.isFetchingPlaylistSongs,
  }
}

class Playlist extends React.Component {
  constructor(props) {
    super(props);

    this.fetchPlaylist =  this.fetchPlaylist.bind(this)
    this.isPlaylistEditor = this.isPlaylistEditor.bind(this)
    this.playlistViewer = this.playlistViewer.bind(this)

    this.handleResize = this.handleResize.bind(this)
    this.state = {
      isMobile: false
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    this.props.updatePlaylistLocation('playlist')

    const { id } = this.props.match.params;
    this.fetchPlaylist(id)
  }

  handleResize(e) {
    const width = window.innerWidth
    const isMobile = this.state.isMobile

    if (width > 767 && isMobile) {
      this.setState({isMobile: false})
    } else if (width <= 767 && !isMobile) {
      this.setState({isMobile: true})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const prevId = prevProps.match.params.id;
    const id = this.props.match.params.id;

    if(prevId !== id) {
      this.fetchPlaylist(id)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }


  fetchPlaylist(id) {
    this.props.fetchPlaylist(id)
      .then((playlist) => {
        this.props.fetchPlaylistSongs(playlist)
      })
  }

  isPlaylistEditor() {
    const upp = this.props.userPlaylistPermission;

    if (!upp || !upp.hasOwnProperty('user')) return false;

    return upp.permission_level === 'owner' || 
      upp.permission_level === 'admin' || 
      upp.permission_level === 'editor'
  }

  playlistViewer() {
    const upp = this.props.userPlaylistPermission;

    if (!upp || !upp.hasOwnProperty('user')) return false;

    return upp.permission_level === 'owner' || 
      upp.permission_level === 'admin' || 
      upp.permission_level === 'viewer' ||
      upp.permission_level === 'editor'
  }

  render() {
    const playlist = this.props.playlist;
    const url = buildResourceUrl('playlists', playlist.name, playlist.id)

    return (
      <div className='playlist'>
        <Helmet>
          <title>
            { playlist ? playlist.name : 'Mu6ik / Listen' }
          </title>
        </Helmet>

        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12 mobile-no-gutter'>
              <PlaylistHeader 
                playlist={playlist}
                playlistEditor={this.playlistEditor}
                playlistViewer={this.playlistViewer}
                isMobile={this.state.isMobile}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-3'>
              <PlaylistInfo
                updatePlaylist={this.props.updatePlaylist}
                upp={this.props.userPlaylistPermission}
                playlist={playlist}
                deletePlaylist={this.props.deletePlaylist}
              />
            </div>

            <div className='col-md-9 col-sm-9 results'>
              <Switch>
                <Route 
                  path={`${url}/tracks`} 
                  render={(props) => {
                    if(this.isPlaylistEditor()) {
                      return <PlaylistTracksEditable />
                    } else {
                      return <PlaylistTracks /> 
                    }
                  }}
                />
                {
                  this.playlistViewer() ?
                    <Route path={`${url}/collaborators`} render={(props) => {
                      return (
                        <PlaylistCollaborators
                          playlist={playlist}
                        />
                      )
                    }} /> : null
                }
                <Redirect from={`${url}/`} to={`${url}/tracks`} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  fetchPlaylist,
  fetchPlaylistSongs,
  updatePlaylist,
  removeSongFromPlaylist,
  updatePlaylistSong,
  updatePlaylistLocation,
  deletePlaylist,
})(Playlist)
import axios from 'lib/axios-config'
import { normalize } from 'normalizr'
import { playlist, playlists, song, songs } from 'app/schemas/main'
import { artist, artists } from '../schemas/main'

import uniq from 'lodash/uniq'
import filter from 'lodash/filter';

import { getPlaylistDetails } from 'app/modules/playlists'


export const fetchCharts = () => {
  return (dispatch, getState) => {
    const url = `/api/home/lists`
    const state = getState();

    if (state.charts.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: 'FETCH_HOME_LISTS_REQUEST'
    })

    return axios.get(url)
      .then((response) => {
        const nta = normalize(response.data.top_artists_by_country, artists);
        const nfa = normalize(response.data.featured_female_djs, artists);
        const nmc = normalize(response.data.mu6ik_charts, playlists);
        const nol = normalize(response.data.overlooked, playlists);
        const nrc = normalize(response.data.recommended, playlists);
        const nnm = normalize(response.data.new_music_this_week, playlist)

        dispatch({
          type: 'FETCH_HOME_LISTS_SUCCESS',
          payload: {
            entities: {
              songs: {
                ...nnm.entities.songs
              },
              artists: {
                ...nta.entities.artists,
                ...nfa.entities.artists,
              },
              playlists: {
                ...nmc.entities.playlists,
                ...nol.entities.playlists,
                ...nrc.entities.playlists,
                ...nrc.entities.playlists,
                ...nnm.entities.playlists
              }
            },
            lists: {
              top_artists: nta.result,
              female_artists: nfa.result,
              mu6ik_charts: nmc.result,
              overlooked: nol.result,
              recommended: nrc.result,
              new_music: nnm.result,
            }
          }
        })

        return Promise.resolve()
      })
      .catch((error) => {
        console.error(error)

        dispatch({
          type: 'FETCH_HOME_LISTS_FAILURE'
        })

        return Promise.reject()
      })
  }
}

const mapItems = (ids, entities, key) => {
  return ids.map(id => entities[key][id])
}

export const selectCharts = (state) => {
  const entities = state.entities;
  const lists = state.charts.lists;

  const top_artists    = mapItems(lists.top_artists, entities, 'artists')
  const female_artists = mapItems(lists.female_artists, entities, 'artists')
  const mu6ik_charts   = mapItems(lists.mu6ik_charts, entities, 'playlists')
  const overlooked     = mapItems(lists.overlooked, entities, 'playlists')
  const recommended    = mapItems(lists.recommended, entities, 'playlists')
  const new_music      = getPlaylistDetails(state, lists.new_music)

  return {
    top_artists,
    female_artists,
    mu6ik_charts,
    overlooked,
    recommended,
    new_music,
  }
}

const defaultState = {
  isFetching: false,
  didInvalidate: false,
  error: null,
  lists: {
    top_artists: [],
    female_artists: [],
    mu6ik_charts: [],
    overlooked: [],
    recommended: [],
    new_music: null,
  }
}

const reducer = (state = defaultState, action) => {
  switch(action.type) {
    case 'FETCH_HOME_LISTS_REQUEST':
      return {
        ...state,
        isFetching: true
      }
    case 'FETCH_HOME_LISTS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        lists: {
          ...state.lists,
          ...action.payload.lists,
        }
      }
    case 'FETCH_HOME_LISTS_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true
      }
    default:
      return state;
  }
}

export default reducer;
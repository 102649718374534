import React from 'react'
import './m6k-checkbox.scss';

export default class M6kCheckbox extends React.Component {
  render () {
    return (
      <div className='m6k-checkbox'>
        <input type='checkbox' checked={this.props.checked || false} onChange={(e) => e.preventDefault()} />
        <span className='option-label'>
          {this.props.label}
        </span>
      </div>
    )
  }
}

import axios from 'lib/axios-config'
import { favorite, favorites } from 'app/schemas/main'
import { normalize } from 'normalizr'

import filter from 'lodash/filter'

export const updatePaginationPage = (page) => {
  return {
    type: 'UPDATE_PAGINATION_PAGE',
    payload: {
      page
    }
  }
}

export const resetPaginationLimit = () => {
  return {
    type: 'RESET_PAGINATION_LIMIT'
  }
}

function fetchFavoriteSongs(params, dispatch, loadMore = false) {
  const url = `/api/favorite_songs`

  dispatch({type: 'FETCH_FAVORITE_SONGS'})

  return axios.get(url, { params }).then((response) => {
    const results = filter(response.data.favorites, (result) =>
      (result.song.video && result.song.video.uuid)
    )

    const normalized = normalize(results, favorites)

    return dispatch({
      type: 'FETCH_FAVORITE_SONGS_SUCCESS',
      payload: {
        entities: normalized.entities,
        ids: normalized.result,
        append: loadMore
      }
    })
  }).catch((error) => {
    return dispatch({
      type: 'FETCH_FAVORITE_SONGS_FAILURE',
      payload: {
        error: error.response.data
      }
    })
  })
}

export function getUserFavoriteSongs (song_ids) {
  return (dispatch, getState) => {
    const state = getState()

    if (state.favorites.isFetching) {
      return Promise.reject()
    }

    const params = {
      song_ids,
    }

    return fetchFavoriteSongs(params, dispatch)
  }
}

export function getProfileFavoriteSongs (user_identifier, loadMore) {
  return (dispatch, getState) => {
    const state = getState()

    if (state.favorites.isFetching || state.favorites.form.pagination.reachedLimit) {
      return Promise.reject()
    }

    const limit = state.favorites.form.pagination.limit
    let page = state.favorites.form.pagination.page 

    if (loadMore) {
      page += 1
      dispatch(updatePaginationPage(page))
    }

    const params = {
      user_identifier,
      limit,
      page,
    }

    return fetchFavoriteSongs(params, dispatch, loadMore)
  }
}

export function favoriteSong (song_id, artist_id) {
  return (dispatch, getState) => {
    const favoriteState = getState().favorites
    const url = `/api/favorite_songs`

    if (favoriteState.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: 'REQUEST_ADD_FAVORITE_SONG'
    })

    return axios.post(url, {
      song_id,
      artist_id
    }).then((response) => {
      const normalized = normalize(response.data, favorite)
      dispatch({
        type: 'REQUEST_ADD_FAVORITE_SONG_SUCCESS',
        payload: {
          entities: normalized.entities,
          id: normalized.result
        }
      })
    }).catch((error) => {
      dispatch({
        type: 'REQUEST_ADD_FAVORITE_SONG_FAILURE',
        payload: {
          error: error.response.data
        }
      })
    })
  }
}

export function setFormField (field, value) {
  return {
    type: 'SET_FORM_FAVORITE_FIELD',
    payload: {
      field,
      value
    }
  }
}

export function unfavoriteSong (song_id, index) {
  return (dispatch, getState) => {
    const favoritesState = getState().favorites
    const url = `/api/favorite_songs/${song_id}`

    if (favoritesState.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: 'REQUEST_REMOVE_FAVORITE_SONG'
    })

    return axios.delete(url)
      .then((response) => {
        dispatch({
          type: 'REQUEST_REMOVE_FAVORITE_SONG_SUCCESS',
          payload: {
            index,
            song_id
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: 'REQUEST_REMOVE_FAVORITE_SONG_FAILURE',
          payload: {
            error: error.response.data
          }
        })
      })
  }
}

const defaultFormState = {
  sort: 'addedDESC',
  name: null,
  pagination: {
    total: 0,
    limit: 50,
    page: 1,
    reachedLimit: false,
  }
}

const form = (state = defaultFormState, action) => {
  switch (action.type) {
    case 'SET_FORM_FAVORITE_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value
      }
    case 'UPDATE_PAGINATION_TOTAL':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case 'FETCH_FAVORITE_SONGS_SUCCESS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case 'UPDATE_PAGINATION_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page
        }
      }
    case 'REACHED_PAGINATION_LIMIT':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          reachedLimit: true,
        }
      }
    case 'RESET_PAGINATION_LIMIT':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          reachedLimit: false,
        }
      }
    default:
      return state
  }
}

const defaultState = {
  isFetching: false,
  didInvalidate: false,
  error: null,
  ids: [],
  form: defaultFormState
}

const favoritesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_FAVORITE_SONGS':
    case 'REQUEST_ADD_FAVORITE_SONG':
    case 'REQUEST_REMOVE_FAVORITE_SONG':
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        error: null
      }
    case 'FETCH_FAVORITE_SONGS_FAILURE':
    case 'REQUEST_ADD_FAVORITE_SONG_FAILURE':
    case 'REQUEST_REMOVE_FAVORITE_SONG_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
        didInvalidate: true
      }
    case 'FETCH_FAVORITE_SONGS_SUCCESS':
      const ids = action.payload.ids

      if(action.payload.append && ids.length > 0) {
        return {
          ...state,
          isFetching: false,
          ids: [...state.ids, ...ids]
        }
      } else if (action.payload.append && ids.length === 0) {
        return {
          ...state,
          isFetching: false,
          form: form(state.form, {type: 'REACHED_PAGINATION_LIMIT'})
        }
      } else {
        return {
          ...state,
          ids,
          isFetching: false,
          form: form(state.form, action)
        }
      }

    case 'REQUEST_ADD_FAVORITE_SONG_SUCCESS':
      return {
        ...state,
        isFetching: false
      }
    case 'REQUEST_REMOVE_FAVORITE_SONG_SUCCESS':
      return {
        ...state,
        isFetching: false
      }
    case 'RESET_PAGINATION_LIMIT':
    case 'UPDATE_PAGINATION_PAGE':
    case 'UPDATE_PAGINATION_TOTAL':
    case 'SET_FORM_FAVORITE_FIELD':
      return {
        ...state,
        form: form(state.form, action)
      }
    default:
      return state
  }
}

export default favoritesReducer

import React from 'react';
import Modal from 'react-modal'

import './confirm-action-modal.scss'

export default class ConfirmActionModal extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<Modal
					contentLabel='ConfirmActionModal'
					isOpen={this.props.isOpen}
					overlayClassName='confirm-action-modal-overlay'
					className='confirm-action-modal'
					onRequestClose={this.props.onCancel}
					shouldCloseOnOverlayClick={false}
				>
					<div className='modal-header'>
						<h5>{this.props.message}</h5>
						<h4 
							className='cancel-btn'
							onClick={this.props.onCancel}
						>
							X
						</h4>
					</div>
					<div className='modal-footer'>
						<button 
							className='btn btn-default'
							onClick={this.props.onSuccess}
						>
							{this.props.handleActionLabel}
						</button>
						<button 
							className='btn btn-primary'
							onClick={this.props.onCancel}
						>
							{this.props.cancelActionLabel}
						</button>
					</div>
				</Modal>
			</div>
		);
	}
}

import axios from 'lib/axios-config'

export function sendInvite () {
  return (dispatch, getState) => {
    const invite = getState().invite
    const email = invite.email

    const url = `/api/users/invitations`

    if (invite.sendingInvite) {
      return Promise.reject()
    }

    dispatch({
      type: 'REQUEST_SEND_INVITE'
    })

    return axios.post(url, {
      email
    }).then((response) => {
      dispatch({
        type: 'REQUEST_SEND_INVITE_SUCCESS'
      })

      return Promise.resolve()
    })
      .catch((error) => {
        dispatch({
          type: 'REQUEST_SEND_INVITE_FAILURE',
          payload: {
            error: error.response.data
          }
        })

        return Promise.reject()
      })
  }
}

export function fetchSentInvites () {
  return (dispatch, getState) => {
    const url = `/api/users/invitations`

    dispatch({
      type: 'REQUEST_SENT_INVITES'
    })

    return axios.get(url)
      .then((response) => {
        dispatch({
          type: 'REQUEST_SENT_INVITES_SUCCESS',
          payload: {
            invites: response.data.invites,
            invitesLeft: response.data.invites_left
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: 'REQUEST_SENT_INVITES_FAILURE',
          payload: {
            error: error.response.data.errors
          }
        })
      })
  }
}

export function clearErrors () {
  return {
    type: 'CLEAR_INVITE_ERRORS'
  }
}

export function setInviteEmail (value) {
  return {
    type: 'SET_INVITE_EMAIL',
    payload: {
      value
    }
  }
}

const defaultState = {
  isFetching: false,
  sendingInvite: false,
  didInvalidate: false,
  inviteSuccess: false,
  error: null,
  email: null,
  invitesLeft: 0,
  invites: []
}

const invites = (state = defaultState, action) => {
  switch (action.type) {
    case 'REQUEST_SEND_INVITE':
      return {
        ...state,
        sendingInvite: true,
        didInvalidate: false,
        inviteSuccess: false,
        error: null
      }
    case 'REQUEST_SEND_INVITE_SUCCESS':
      return {
        ...state,
        sendingInvite: false,
        inviteSuccess: true,
        email: null
      }
    case 'REQUEST_SEND_INVITE_FAILURE':
      return {
        ...state,
        sendingInvite: false,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'REQUEST_SENT_INVITES':
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        error: null
      }
    case 'REQUEST_SENT_INVITES_SUCCESS':
      return {
        ...state,
        isFetching: false,
        invites: action.payload.invites,
        invitesLeft: action.payload.invitesLeft
      }
    case 'REQUEST_SENT_INVITES_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'SET_INVITE_EMAIL':
      return {
        ...state,
        email: action.payload.value
      }
    case 'CLEAR_INVITE_ERRORS':
      return {
        ...state,
        didInvalidate: false,
        error: null,
        inviteSuccess: false
      }
    default:
      return state
  }
}

export default invites

import React from 'react';
import Modal from 'react-modal'
import M6kRadio from 'app/components/m6k-radio' 

export default class ReportUserModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      flagType: null
    }
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.visible}
          contentLabel=''
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.props.closeModal}
          className='report-user-modal'
          overlayClassName='confirm-action-modal-overlay'
        >
          <div className='modal-header'>
            <h3>Report</h3>
            <h4 
              className='remove'
              onClick={this.props.closeModal}
            >
              X
            </h4>
          </div> 

          <div className='modal-body'>
            <M6kRadio
              checked={this.state.flagType === 0}
              label='This profile is spammy'
              handleClick={() => {
                this.setState({flagType: 0})
              }}
            />

            <M6kRadio
              checked={this.state.flagType === 1}
              label='This user is pretending to be someone else'
              handleClick={() => {
                this.setState({flagType: 1})
              }}
            />

            <M6kRadio
              checked={this.state.flagType === 2}
              label='This profile has offensive or abusive content or profile photo'
              handleClick={() => {
                this.setState({flagType: 2})
              }}
            />
          </div>

          <div className='modal-footer'>
            <button
              className='btn btn-default'
              onClick={this.props.closeModal}
            > 
              Cancel
            </button>
            <button 
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                if(this.state.flagType === null) return;
                
                this.props.reportUser(this.props.uuid, this.state.flagType)
              }}
            >
              Report 
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

import React, { Component } from 'react'
import Modal from 'react-modal'

class ConfirmPassword extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <div className='confirm-password'>
        <Modal
          isOpen={this.props.show}
          onRequestClose={this.props.onCancel}
          contentLabel='Confirm Modal'
          className='confirm-password-modal'
          >
          <div className='modal-header'>
            <h2>Save account changes</h2>
            <span className='glyphicon glyphicon-remove' onClick={this.props.onCancel} />
          </div>
          <div className='modal-body'>
            <form onSubmit={(e) => {
              e.preventDefault()
              this.props.onSubmit(this.password.value)
            }}>
              <div className='form-group'>
                <label className='control-label'>Re-enter your password to save changes to your account.</label>
                <input
                  type='password'
                  placeholder='Password'
                  className='form-control'
                  autoFocus
                  ref={(password) => this.password = password}
                />
              </div>
            </form>
          </div>
          <div className='modal-footer'>
            <button
              type='cancel'
              className='btn btn-default'
              onClick={this.props.onCancel}
            >Cancel</button>
            <button
              type='submit'
              className='btn btn-primary submit'
              onClick={(e) => {
                this.props.onSubmit(this.password.value)
              }}
            >Save Changes</button>
          </div>
        </Modal>
      </div>
    )
  }
}

export default ConfirmPassword

import React from 'react';

import {
  secondsToDurationString
} from 'lib/video';

import ProgressBar from './progress-bar';

class MobileProgress extends React.Component {
  constructor(props) {
    super(props);
    this.seekTo = this.seekTo.bind(this);
  }

  seekTo(progressPosition, allowSeekAhead) {
    const seconds = 60 * progressPosition * this.props.duration;
    this.props.seekTo(seconds, allowSeekAhead)
  }

  render() {
    const duration = secondsToDurationString(this.props.duration)
    const currentTime = secondsToDurationString(this.props.currentTime);
    
    const timeProgressed = this.props.currentTime / this.props.duration;

    return (
      <div className='player-progress'>
        <h5 id="start">{currentTime}</h5>
        <h5 id="end">{duration}</h5>

        <ProgressBar 
          progress={timeProgressed} 
          buffered={this.props.buffered} 
          seekTo={this.seekTo}
        />

      </div>
    );
  }
}

export default MobileProgress;
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Notice from 'components/Notice/Notice'
import ConfirmPassword from './confirm-password'
import FormGroup from './form-group'

import { formatErrors } from 'lib/format-message'

import {
  usernameAvailable,
  clearUsernameAvailable,
  clearEmailAvailable,
  emailAvailable,
} from 'modules/registration'

import {
  updateUserAccount,
  resetUserForm,
  initializeUserForm,
  setUserField,
} from 'app/modules/current-user/account'


class Account extends Component {
  constructor (props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.cancelChanges = this.cancelChanges.bind(this)
    this.submitChanges = this.submitChanges.bind(this)
    this.closeNotice = this.closeNotice.bind(this)

    this.validatorTimeout = null

    this.state = {
      message: null,
      messageVisible: false,
      keyMode: 'harmonic',
      messageClasses: null,
      unsavedChanges: false,
      confirmPassword: false
    }
  }

  handleChange (e, field, validator = null) {
    const newValue = e.currentTarget.value
    const existingValue = this.props.account.form[field]

    this.props.setUserField(field, newValue)
    this.setState({unsavedChanges: true})

    if (validator) {
      this.clearValidatorTimeout()

      this.validatorTimeout = setTimeout(() => {
        validator(existingValue, newValue)
        this.validatorTimeout = null
      }, 500)
    }
  }

  clearValidatorTimeout () {
    if (this.validatorTimeout) {
      clearTimeout(this.validatorTimeout)
      this.validatorTimeout = null
    }
  }

  handleSubmit (e) {
    e.preventDefault()

    if (!this.state.unsavedChanges) {
      return
    }

    this.setState({confirmPassword: true})
  }

  cancelChanges () {
    this.setState({confirmPassword: false})
  }

  submitChanges (password) {
    this.props.updateUserAccount(password).then(() => {
      const { account } = this.props

      this.props.clearUsernameAvailable()
      this.props.clearEmailAvailable()

      this.setState({
        message: account.message,
        messageVisible: true,
        messageClasses: 'yellow',
        unsavedChanges: false,
        confirmPassword: false
      })
    }).catch(() => {
      const { account } = this.props
      const errors = formatErrors(account.error.errors)

      this.setState({
        message: errors[0],
        messageVisible: true,
        messageClasses: 'red',
        unsavedChanges: false,
        confirmPassword: false
      })

      this.props.initializeUserForm()
    })
  }

  renderFieldAvailable (field) {
    const { account } = this.props

    if (!account) {
      return null
    }

    const available = account.form[`${field}_available`]

    if (available === null || available === undefined) {
      return null
    }

    const classes = 'info ' + (available.valid ? 'valid' : 'invalid')
    return (
      <div className={classes}>
        {available.msg}
      </div>
    )
  }

  closeNotice () {
    this.setState({
      message: null,
      messageVisible: false
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.account.uuid !== this.props.account.uuid) {
      this.props.initializeUserForm()
    }
  }

  componentWillUnmount () {
    this.props.clearUsernameAvailable()
    this.props.clearEmailAvailable()
  }

  componentDidMount() {
    this.props.initializeUserForm()
  }

  render () {
    return (
      <div>
        <h2>Account</h2>
        <Notice
          classes={this.state.messageClasses}
          visible={this.state.messageVisible}
          handleClick={this.closeNotice}
        >
          {this.state.message}
        </Notice>
        <ConfirmPassword
          show={this.state.confirmPassword}
          onSubmit={this.submitChanges}
          onCancel={this.cancelChanges}
        />
        <form className='form-horizontal' onSubmit={this.handleSubmit}>
          <FormGroup
            label='Username'
            user={this.props.account.form}
            value={this.props.account.form.username}
            handleChange={(e) =>
              this.handleChange(e, 'username', this.props.usernameAvailable)
            }
          >
            {this.renderFieldAvailable('username')}
          </FormGroup>
          <FormGroup
            label='Email'
            value={this.props.account.form.email}
            handleChange={(e) =>
              this.handleChange(e, 'email', this.props.emailAvailable)
            }
            info='Email is not publicly displayed.'
          >
            {this.renderFieldAvailable('email')}
          </FormGroup>
          <FormGroup
            label='First Name'
            value={this.props.account.form.first_name}
            handleChange={(e) => this.handleChange(e, 'first_name')}
          />
          <FormGroup
            label='Last Name'
            value={this.props.account.form.last_name}
            handleChange={(e) => this.handleChange(e, 'last_name')}
          />
          <button
            type='submit'
            className='btn btn-default'
            disabled={
              this.state.unsavedChanges ? '' : 'disabled'
            }>Save Changes</button>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.currentUser.account,
  }
}

export default connect(mapStateToProps, {
  setUserField,
  usernameAvailable,
  emailAvailable,
  clearEmailAvailable,
  clearUsernameAvailable,
  resetUserForm,
  initializeUserForm,
  updateUserAccount 
})(Account)

import React from 'react'
import { connect } from 'react-redux'
import { push, replace } from 'connected-react-router'

class ClickHandler extends React.Component {
  constructor (props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (e) {
    e.preventDefault()
    e.stopPropagation()

    // Simulate a "right" click
    if (e.shiftKey || e.ctrlKey || e.metaKey || this.props.newWindow) {
      window.open(this.props.url, '_blank')
      return
    }

    this.props.navigate(this.props.url, this.props.method)
    window.scrollTo(0, 0)
    
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  render () {
    return (
      <a href={this.props.url}
        onClick={this.handleClick}
        className={this.props.className}
      >
        {this.props.children}
      </a>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, getState) => {
  return {
    navigate: (url, method = 'push') => {
      if (method === 'push') {
        dispatch(push(url))
      } else if (method === 'replace') {
        dispatch(replace(url))
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClickHandler)

import React from 'react'

const SkeletonItem = (props) => {
	return (
		<li className='skeleton-listitem'>
      <div className='skeleton-video-thumbnail' />
			<div className='skeleton-description'>
        <div className='r1' />
        <div className='r2' />
      </div>
		</li>
	)
}

export default SkeletonItem;
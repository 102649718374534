import React from 'react'

export default class Status extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    const invites = this.props.invites.map((inv, i) =>
      <tr key={i} className={inv.invite_status ? 'accept' : 'invited'}>
        <td>{i + 1}</td>
        <td>{inv.email}</td>
        <td className='status'>
          {inv.invite_status ? 'Accepted' : 'Invited'}
        </td>
      </tr>
    )

    return (
      <div className='row'>
        <div className='col-sm-12'>
          <div className='invite-status'>
            <table className='table table-condensed'>
              <tbody>
                {invites}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

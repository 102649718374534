import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import defaultAvatar from 'assets/img/default_avatar'
import { getUrlFromPhoto } from 'lib/user'

export class Dropdown extends Component {
  constructor (props) {
    super(props)

    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.renderPhoto = this.renderPhoto.bind(this)

    this.state = {
      isExpanded: false,
      user: props.user
    }
  }

  handleKeyPress (e) {
    switch (e.key) {
      case 'Escape':
        e.preventDefault()
        this.setState({isExpanded: false})
        break
    }
  }

  toggleDropdown () {
    this.setState({isExpanded: !this.state.isExpanded})
  }

  componentDidMount () {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.user.email === '' && this.props.user.email !== '') {
      this.setState({user: this.props.user})
    }
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  renderPhoto() {
    const { user } = this.props;
    let url = '';

    if(!user.profile_photo) {
      url = defaultAvatar;
    } else {
      url = getUrlFromPhoto(user.profile_photo, 160);
    }

    return (
      <img src={url} className='profile-photo' />
    )
  }

  handleClickOutside (e) {
    const domNode = ReactDOM.findDOMNode(this)
    if (!domNode || !domNode.contains(e.target)) {
      this.setState({
        isExpanded: false
      })
    }
  }

  render () {
    const { user } = this.props;

    const active = this.state.isExpanded ? 'active' : ''
    const dropdownClasses = `dropdown ${active}`
    const iconClasses = this.state.isExpanded ? 'glyphicon glyphicon-menu-up' : 'glyphicon glyphicon-menu-down'

    return (
      <div className={dropdownClasses} onKeyUp={this.handleKeyPress}>
        <ul className='nav navbar-nav'>
          <li onClick={this.toggleDropdown}>
            <a href='#' onClick={e => e.preventDefault()}>
              { this.renderPhoto() }
              {this.props.user.username || this.props.user.email}
              <span className={iconClasses} />
            </a>
          </li>
        </ul>
        <ul className='selections' onClick={this.toggleDropdown}>
          <li>
            <Link to={`/${this.props.user.username}`} >
              <span className='glyphicon glyphicon-user' />
              Profile
            </Link>
          </li>

          <li>
            <Link to={`/help`} >
              <span className='glyphicon glyphicon-info-sign' />
              Help 
            </Link>
          </li>

          <li>
            <Link to={`/settings/invitations`} >
              <span className='glyphicon glyphicon-send' />
              Invites
            </Link>
          </li>
          <li>
            <Link to='/settings'>
              <span className='glyphicon glyphicon-cog' />
            Settings
            </Link>
          </li>
          <li>
            <Link to='/terms'>
              <i className="fas fa-balance-scale"></i>
              Legal
            </Link>
          </li>
          <li onClick={this.props.logout}>
            <Link to='/login'>
              <span className='glyphicon glyphicon-off' />
              Log out
            </Link>
          </li>
        </ul>
      </div>
    )
  }
}

export default Dropdown

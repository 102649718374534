import axios from 'lib/axios-config'
import { normalize } from 'normalizr'
import { cities as citySchema } from '../schemas/main'
import { createSelector } from 'reselect'

export function getCities (state) {
  const entities = state.entities;

  return state.cities.ids.map((id) =>
    entities.cities[id]
  )
}

export function searchCitiesById (ids) {
  return (dispatch, getState) => {
    const cities = getState().cities
    const url = '/api/cities/search'

    if (cities.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: 'REQUEST_CITIES'
    })

    return axios.get(url, { params: { ids }})
      .then((response) => {
        const normalized = normalize(response.data, citySchema)

        dispatch({
          type: 'REQUEST_CITIES_SUCCESS',
          payload: {
            entities: normalized.entities,
            ids: normalized.result
          }
        })

        return Promise.resolve()
      }).catch((error) => {
        dispatch({
          type: 'REQUEST_CITIES_FAILURE',
          payload: {
            error: error.response
          }
        })
      })
  }
}

export function searchCities (name) {
  return (dispatch, getState) => {
    const cities = getState().cities
    const url = '/api/cities'

    if (cities.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: 'REQUEST_CITIES'
    })

    return axios.get(url, { params: { name }})
      .then((response) => {
        const normalized = normalize(response.data, citySchema)

        dispatch({
          type: 'REQUEST_CITIES_SUCCESS',
          payload: {
            entities: normalized.entities,
            ids: normalized.result
          }
        })

        return Promise.resolve()
      }).catch((error) => {
        dispatch({
          type: 'REQUEST_CITIES_FAILURE',
          payload: {
            error: error.response
          }
        })
      })
  }
}

export function updateCitySearch (value) {
  return {
    type: 'UPDATE_CITY_SEARCH',
    payload: {
      value
    }
  }
}

export const clearCitySearch = () => {
  return {
    type: 'CLEAR_CITY_SEARCH'
  }
}

const defaultState = {
  isFetching: false,
  didInvalidate: false,
  error: null,
  ids: [],
  search: null
}

const cities = (state = defaultState, action) => {
  switch (action.type) {
    case 'REQUEST_CITIES':
      return {
        ...state,
        isFetching: true
      }
    case 'REQUEST_CITIES_SUCCESS':
      return {
        ...state,
        isFetching: false,
        ids: action.payload.ids
      }
    case 'REQUEST_CITIES_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'UPDATE_CITY_SEARCH':
      return {
        ...state,
        search: action.payload.value
      }
    case 'CLEAR_CITY_SEARCH':
      return defaultState
    default:
      return state
  }
}

export default cities 

import axios from 'lib/axios-config'
import { normalize } from 'normalizr'
import { createSelector } from 'reselect'

import { genres as genreSchema } from '../schemas/main'
import compact from 'lodash/compact'

export const selectGenres = (state) => {
  const entities = state.entities
  const filter = state.genres.search || ''

  const useFilter = filter !== ''

  const genres = state.genres.ids.map((id) => {
    const genre = entities.genres[id]

    if (!useFilter || genre.name.toLowerCase().startsWith(filter.toLowerCase())) {
      return genre
    }
  })

  return compact(genres)
}

export function fetchGenres () {
  return (dispatch, getState) => {
    const genres = getState().genres
    const url = '/api/genres?sort=popular'

    if (genres.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: 'REQUEST_GENRES'
    })

    return axios.get(url)
      .then((response) => {
        const normalized = normalize(response.data, genreSchema)
        dispatch({
          type: 'REQUEST_GENRES_SUCCESS',
          payload: {
            entities: normalized.entities,
            ids: normalized.result
          }
        })

        return Promise.resolve()
      }).catch((error) => {
        dispatch({
          type: 'REQUEST_GENRES_FAILURE',
          payload: {
            error: error.response
          }
        })

        return Promise.reject()
      })
  }
}

export function updateGenreSearch (value) {
  return {
    type: 'UPDATE_GENRE_SEARCH',
    payload: {
      value
    }
  }
}

const defaultState = {
  isFetching: false,
  didInvalidate: false,
  error: null,
  ids: [],
  search: null
}

const genres = (state = defaultState, action) => {
  switch (action.type) {
    case 'REQUEST_GENRES':
      return {
        ...state,
        isFetching: true
      }
    case 'REQUEST_GENRES_SUCCESS':
      return {
        ...state,
        isFetching: false,
        ids: action.payload.ids
      }
    case 'REQUEST_GENRES_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'UPDATE_GENRE_SEARCH':
      return {
        ...state,
        search: action.payload.value
      }
    default:
      return state
  }
}

export default genres

import React from 'react';

import VideoPlayer from './video-player'
import MobilePlayerControls from './player-controls/mobile-player-controls';

import Progress from './player-controls/progress';
import MobileCurrentDJ from './mobile-current-dj';

import {
  trimTitle
} from 'lib/string';

import '../styles/mobile-player.scss'

export default class MusicPlayerMobile extends React.Component {
  constructor(props) {
    super(props);

    this.renderMiniProgress = this.renderMiniProgress.bind(this)
    this.renderMiniControls = this.renderMiniControls.bind(this)
    this.renderFullScreenControls = this.renderFullScreenControls.bind(this)
  }

  renderMiniProgress() {
    const { song, player } = this.props;
    const video = song.video || {} 

    if(this.props.isFullScreen) {
      return null;
    } else {
      return(
        <Progress 
          currentTime={this.props.currentTime} 
          duration={video.duration} 
          buffered={this.props.buffered}
          seekTo={player.seekTo}
        />
      )
    }
  }

  renderMiniControls() {
    const { song, player } = this.props;
    const video = song.video || {} 

    if(this.props.isFullScreen) {
      return null;
    } else {
      return (
        <div className='expand-full-screen' onClick={() =>this.props.toggleFullScreen()}>
          <h3 className='video-title'>{video ? trimTitle(video.name) : ""}</h3>
          <span className='glyphicon glyphicon-menu-up'></span>
        </div>
      )
    }
  }

  renderFullScreenHeader() {
    if(this.props.isFullScreen) {
      return (
        <div className='fullscreen-header'>
          <span 
            className='glyphicon glyphicon-menu-down'
            onClick={() => this.props.toggleFullScreen() }>
          </span>
          <h2>Mu6ik</h2>
        </div>
      )
    } else {
      return null;
    }
  }

  renderFullScreenControls() {
    const { song, player } = this.props;
    const video = song.video || {} 
    const isFavorite = song.is_favorite

    if(this.props.isFullScreen) {
      return (
        <div className=''>
          <h3 className='video-title'>{video ? trimTitle(video.name) : ""}</h3>
          <span 
            className={`favorite glyphicon glyphicon-heart${isFavorite ? '' : '-empty'}`}
            onClick={this.props.toggleFavorite}
          >
          </span>

          <MobilePlayerControls 
            currentTime={this.props.currentTime} 
            buffered={this.props.currentBuffered}
            duration={video.duration}
            isMuted={this.props.isMuted}
            volume={this.props.volume}

            seekTo={player.seekTo}
            playerState={player.playerState}
            isRepeat={player.isRepeat}
            isShuffle={player.isShuffle}
            mute={player.mute}
            unMute={player.unMute}
            setVolume={player.setVolume}

            isFavorite={song.is_favorite}
            marketplace={song.marketplace}

            togglePlay={this.props.togglePlay}
            nextSong={this.props.nextSong}
            prevSong={this.props.nextSong}
            
            toggleRepeat={this.props.toggleRepeat}
            toggleShuffle={this.props.toggleShuffle}
            toggleFavorite={this.props.toggleFavorite}
          />

          <MobileCurrentDJ
            dj={this.props.dj} 
            handleClick={() => {
              this.props.setSearchField(this.props.dj.name) 
              this.props.toggleFullScreen()
            }}
            artists={this.props.artists}
            search={this.props.search}
          />
        </div>
      )
    } else {
      return null;
    }
  }

  render() {
    if(this.props.isBrowser) return null;

    return (
      <div className='m6k-player-mobile'>
        { this.renderMiniProgress() }
        { this.renderFullScreenHeader() }

        <VideoPlayer 
          setPlayerValues={this.props.setPlayerValues}
          isMobile={this.props.isMobile}
          queueNextSong={this.props.queueNextSong}
          currentBuffered={this.props.buffered}
          currentTime={this.props.currentTime}
          volume={this.props.volume}
          removeVideo={this.props.removeVideo}
        />

        { this.renderMiniControls() }
        { this.renderFullScreenControls() }

      </div>
    )
  }
}

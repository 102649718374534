import React from 'react';
import { connect } from 'react-redux';
import Loading from 'react-loading'

import {
  fetchTransitions,
  selectSong
} from 'app/modules/song'

import {
  updatePlaylistLocation 
} from 'app/shared/music-player/modules/player'

import withInfiniteScroll from 'app/shared/infinite-scroll'
import Setlist from 'app/shared/setlist'

import '../styles/transitions';

class Transitions extends React.Component {
  constructor(props) {
    super(props);

    this.renderEndListItem = this.renderEndListItem.bind(this)
  }

componentDidMount() {
    this.props.updatePlaylistLocation('transitions')
    this.props.fetchTransitions(this.props.song.id)
      .then(() => {})
      .catch((error) => {})
  }

  renderEndListItem () {
    if(this.props.isTransitionsFetching) {
      return (
        <li className='setlist-empty'>
          <div className='loading'>
            <Loading
              type='spin'
              color='#aaa'
              delay={0}
              height={45}
              width={45}
            />
          </div>
        </li>
      )
    } else {
      return (
        <li className='setlist-empty'>
          <h3 className='logo'>M</h3>
        </li>
      ) 
    }
  }

  render() {
    return (
      <div className='transitions'>
        <Setlist 
          songs={this.props.song.transitions.to}
          title={`Transitions`}
          endListItem={this.renderEndListItem}
          isInitialFetching={this.props.isTransitionsFetching}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    song: selectSong(state),
    isTransitionsFetching: state.song.isTransitionsFetching,
  }
}

export default connect(
  mapStateToProps,
  {
    fetchTransitions,
    updatePlaylistLocation,
  }
)(Transitions); 
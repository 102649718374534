import React from 'react';
import moment from 'moment'
import { connect } from 'react-redux'
import { Range } from 'rc-slider'
import Loading from 'react-loading'
import numeral from 'numeral'

import SearchDJsList from './search-djs-list'
import withInfiniteScroll from 'app/shared/infinite-scroll'

import FilterSection from 'app/shared/filters/filter-section';
import FilterPanel from 'app/shared/filters/filter-panel'
import Filters from 'app/shared/filters/filters'

import { selectDJs } from 'app/modules/search/search-djs'
import { isBrowser } from "react-device-detect";

import { 
  submitSearch, 
  toggleFilterOption,
  setSearchField,
  selectFilterGenres,
  updateUrl,
  setSearchQuery,
  selectFilterCountries,
  clearAllFilterOptions,
} from 'app/modules/search'

import {
  updateGenreSearch,
  selectGenres
} from 'app/modules/genres'

import {
  updateCountrySearch,
  getCountries
} from 'app/modules/countries'

import {
  toggleIsFollowed,
} from 'app/modules/artist'

import {
  getFollowedDJ,
  followDJ,
  unFollowDJ
} from 'app/modules/following'

import {
  markGenerator
} from 'lib/string'

class SearchArtists extends React.Component {
  constructor(props) {
    super(props);

    this.toggleFollow = this.toggleFollow.bind(this)
    this.onPaginatedSearch = this.onPaginatedSearch.bind(this)
    this.renderEndListItem = this.renderEndListItem.bind(this)

    this.handleRangeValues = this.handleRangeValues.bind(this)
    this.handleRelease = this.handleRelease.bind(this)
    this.handleSliding = this.handleSliding.bind(this)

    this.renderFilters = this.renderFilters.bind(this)
    this.renderMobile = this.renderMobile.bind(this)
    this.renderBrowser = this.renderBrowser.bind(this)
    this.renderHeader = this.renderHeader.bind(this)

    this.state = {
      mode: 'list',
      isPaginatedFetching: false,
      currentYear: new Date().getFullYear(),
      from: 2012,
      to: new Date().getFullYear()
    }
  }

  toggleFollow (profile) {
    if (profile.is_followed) {
      this.props.unFollowDJ(profile.id)
      this.props.toggleIsFollowed(profile.id, false)
    } else {
      this.props.followDJ(profile.id)
      this.props.toggleIsFollowed(profile.id, true)
    }
  }

  componentWillMount() {
    this.InfiniteSearchDJs = withInfiniteScroll(SearchDJsList)
  }

  componentWillUnmount() {
    this.props.clearAllFilterOptions('djs', 'genres')
    this.props.clearAllFilterOptions('djs', 'countries') 
  }

  onPaginatedSearch() {
    if(this.props.isFetching) return;

    this.setState({
      isPaginatedFetching: true
    })

    this.props.submitSearch(true)
      .then(() => {
        this.setState({isPaginatedFetching: false})
      })
      .catch(() => {})
  }

  renderEndListItem () {
    if(this.state.isPaginatedFetching && !this.props.reachedLimit) {
      return (
        <tr className='setlist-empty'>
          <td colSpan='8' >
            <div className='loading'>
              <Loading
                type='spin'
                color='#aaa'
                delay={0}
                height={45}
                width={45}
              />
            </div>
          </td>
        </tr>
      )
    } else {
      return (
        <tr className='setlist-empty'>
          <td colSpan='8' >
            <h3 className='logo'>M</h3>
          </td>
        </tr>
      ) 
    }
  }

  handleSliding (range) {
    this.handleRangeValues(range)
  }

  handleRelease (range) {
    this.handleRangeValues(range)
    this.props.updateUrl()
    this.props.submitSearch()
  }

  handleRangeValues (range) {
    let from = range[0]
    let to = range[1]

    this.setState({from, to})

    from = moment().year(from).month('January').date(1)
    to = moment().year(to)

    if (to !== this.state.currentYear) {
      to = to.month('December').date(1)
    }

    this.props.setSearchField('djs', 'from', from)
    this.props.setSearchField('djs', 'to', to)
  }

  renderFilters() {
    const from = parseInt(this.props.from.format("YYYY"));
    const to = parseInt(this.props.to.format("YYYY"))

    return (
      <div className='m6k-panel filters'>
        <h3>Filters</h3>
        <FilterSection name='Years Played'>
          <div className='date-slider'>
            <Range
              allowCross={false}
              defaultValue={[from, to]}
              value={[from, to]}
              step={1}
              pushable
              onChange={this.handleSliding}
              onAfterChange={this.handleRelease}
              min={1982}
              max={this.state.currentYear}
              tipFormatter={value => `${value}`}
              marks={markGenerator()}
            />
          </div>
        </FilterSection>
        <FilterSection name='Genres'>
          <FilterPanel 
            placeholder='Find a genre'
            items={this.props.genres} 
            filterValue={this.props.genreFilterValue}
            filter={this.props.updateGenreSearch}
            toggle={(id, isFilter) => {
              this.props.toggleFilterOption('djs', 'genres', id, isFilter)
              this.props.updateUrl()
              this.props.submitSearch()
            }}
          />
        </FilterSection>
        <FilterSection name='Countries'>
          <FilterPanel 
            placeholder='Find a country'
            items={this.props.countries} 
            filterValue={this.props.countryFilterValue}
            filter={this.props.updateCountrySearch}
            toggle={(id, isFilter) => {
              this.props.toggleFilterOption('djs', 'countries', id, isFilter)
              this.props.updateUrl()
              this.props.submitSearch()
            }}
          />
        </FilterSection>
      </div>
    )
  }

  renderMobile() {
    return(
      <div className='search-results-mobile'>
        <div className='results-container m6k-panel'>
          <table className={`results-${this.state.mode}`} >
            {
              React.createElement(this.InfiniteSearchDJs, {
                djs: this.props.djs,
                onPaginatedSearch: this.onPaginatedSearch,
                endListItem: this.renderEndListItem()
              })
            }
          </table>
        </div>
      </div>
    )
  }

  renderHeader() {
    const isFetching = this.props.isInitialFetching || 
      (this.props.isFetching && this.props.page === 1)

    const title = isFetching ?
      'Searching...' : 'Results' 

    return (
      <div className='row header'>
        <div className='col-md-10'>
          <h3>{title}</h3>
        </div>
        <div className='col-md-2'>
        </div>
      </div>
    )
  }

  renderBrowser() {
    const from = parseInt(this.props.from.format("YYYY"));
    const to = parseInt(this.props.to.format("YYYY"))
    
    const isFetching = this.props.isInitialFetching || 
      (this.props.isFetching && this.props.page === 1)

    return (
      <div>
        <div className='row'>
          <div className='col-md-12'>
            <Filters
              from={this.props.from.format("YYYY")}
              to={this.props.to.format("YYYY")}
              genres={this.props.genreFilters}
              countries={this.props.countryFilters}
              name={this.props.q}
              clearName={() => {
                this.props.setSearchQuery("")
                this.props.updateUrl()
                this.props.submitSearch()
              }}
              clearGenre={(id) => {
                this.props.toggleFilterOption('djs', 'genres', id)
                this.props.updateUrl()
                this.props.submitSearch()
              }}
              clearCountry={(id) => {
                this.props.toggleFilterOption('djs', 'countries', id)
                this.props.updateUrl()
                this.props.submitSearch()
              }}
              clearAll={() => {
                this.props.clearAllFilterOptions('djs', 'genres')
                this.props.clearAllFilterOptions('djs', 'countries')
                this.props.setSearchQuery("")
                this.props.updateUrl()
                this.props.submitSearch()
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3 m6k-fixed-col-3 col-md-4 col-sm-4 col-xs-4'>
            { this.renderFilters() }
          </div>

          <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>
            <div className='results-container m6k-panel'>
             { this.renderHeader() }
              <table className={`results-${this.state.mode}`} >
                {
                  React.createElement(this.InfiniteSearchDJs, {
                    djs: this.props.djs,
                    onPaginatedSearch: this.onPaginatedSearch,
                    toggleFollow: this.toggleFollow,
                    isFetching: isFetching,
                    endListItem: this.renderEndListItem(),
                    from: this.props.from,
                    to: this.props.to,
                  })
                }
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.renderBrowser()
  }

}

const mapStateToProps = (state) => {
  return {
    djs: selectDJs(state) || [],
    page: state.search.pagination.page,
    from: state.search.djs.filters.from,
    to: state.search.djs.filters.to,
    isFetching: state.search.isFetching,
    genres: selectGenres(state),
    countries: getCountries(state),
    genreFilterValue: state.genres.search,
    countryFilterValue: state.countries.search, 
    genreFilters:   selectFilterGenres(state),
    countryFilters: selectFilterCountries(state),
  }
}

export default connect(mapStateToProps, {
  followDJ,
  unFollowDJ,
  toggleIsFollowed,
  toggleFilterOption,
  setSearchQuery,
  submitSearch, 
  updateUrl,
  updateGenreSearch,
  updateCountrySearch,
  setSearchField,
  clearAllFilterOptions,
})(SearchArtists)

import React from 'react';

import './filters.scss'

export default class FilterSection extends React.Component {
  constructor(props) {
    super(props);

    this.toggleVisible = this.toggleVisible.bind(this)

    this.state = {
      isVisible: true
    }
  }

  toggleVisible() {
    const isVisible = this.state.isVisible;
    
    this.setState({
      isVisible: !isVisible
    })
  }

  render() {
    const iconClass = this.state.isVisible ? 'glyphicon-menu-down' : 'glyphicon-menu-right'
    
    return (
      <div className='filter-section'>
        <h4 onClick={this.toggleVisible}>
          {this.props.name}
          <span className={'glyphicon ' + iconClass}></span>
        </h4>

        {
          this.state.isVisible ?
          this.props.children : 
          null
        }
      </div>
    );
  }
}

import React from 'react';
import { connect } from 'react-redux'
import Modal from 'react-modal'

import {
  fetchFriends,
  selectFilteredFriends,
  selectFriendsFilter,
  filterFriends,
} from 'app/modules/friends'

import {
  selectCurrentCollaborators,
  createUserPlaylistPermission,
} from 'app/modules/playlists'

import FilterFriends from  './filter-friends'
import FriendsList from './friends-list'

import { selectCurrentUser } from 'app/modules/current-user/account'

const mapStateToProps = (state) => {
  return {
    collaborators: selectCurrentCollaborators(state),
    currentUser: selectCurrentUser(state), 
    friends: selectFilteredFriends(state), 
    filter: selectFriendsFilter(state),
  }
}

class AddCollaboratorModal extends React.Component {
  constructor(props) {
    super(props);

    this.updateFilter = this.updateFilter.bind(this)
    this.updatePendingCollabs = this.updatePendingCollabs.bind(this)
    this.addUserPlaylistPermission = this.addUserPlaylistPermission.bind(this)

    this.state = {
      pendingCollabs: {},
      addedCollabs: {}
    }
  }

  updateFilter(e) {
    this.props.filterFriends(e.target.value);
  }

  componentDidMount() {
    const userIds = this.props.collaborators.map((upp) => upp.user.id)
    this.props.fetchFriends(this.props.currentUser.uuid, userIds)
  }

  updatePendingCollabs(userId, property, value) {
    this.setState({
      pendingCollabs: {
        ...this.state.pendingCollabs,
        [userId]: {
          [property]: value
        }
      }
    })
  }

  addUserPlaylistPermission(collaborator) {
    const { playlist } = this.props;

    this.props.createUserPlaylistPermission({
      ...collaborator,
      playlist,
    })
    .then((upp) => {
      this.setState({
        addedCollabs: {
          ...this.state.addedCollabs,
          [upp.user.id]: true
        }
      })
    })
    .catch(() => {})
  } 

  render() {
    return (
      <Modal
        className='add-collaborator-modal m6k-panel'
        isOpen={this.props.show}
        contentLabel='Confirm Modal'
        onRequestClose={this.props.onCancel}
      >
        <div className='modal-header'>
          <div className='row'>
            <div className='col-md-8'>
              <h3>Add Collaborators</h3>
            </div>
            <div className='col-md-4'>
              <span className='glyphicon glyphicon-remove' onClick={this.props.onCancel} />
            </div>
          </div>
        </div>

        <div className='modal-body'>
          <FilterFriends updateFilter={this.updateFilter} filter={this.props.filter} />
          <FriendsList 
            friends={this.props.friends} 
            pendingCollabs={this.state.pendingCollabs} 
            addedCollabs={this.state.addedCollabs}
            updatePendingCollabs={this.updatePendingCollabs}
            addUserPlaylistPermission={this.addUserPlaylistPermission}
          />
        </div>
      </Modal>
    );
  }
}


export default connect(mapStateToProps, {
  fetchFriends,
  filterFriends,
  createUserPlaylistPermission,
})(AddCollaboratorModal);

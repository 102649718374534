import React from 'react'
import Slider from 'rc-slider'

export const ImageScaler = (props) => {
	return (
		<div className='image-scaler'>
			<span 
				className={`glyphicon glyphicon-picture small ${props.scale === 1.0 ? 'dim' : ''}`}
			/>
	    
	    <Slider
	    	onChange={props.scaleImage}
	    	className='slider'
	    	min={1}
	    	max={2}
	    	step={0.05}
	    />
			
			<span 
				className={`glyphicon glyphicon-picture large ${props.scale === 2.0 ? 'dim' : ''}`}
			/>						
	  </div>
	)
}

export default ImageScaler;
import React from 'react';
import { NavLink } from 'react-router-dom'

import { 
  formatYoutubeUrl,
  secondsToDurationString,
} from 'lib/video'

import {
  YOUTUBE_PLAYER_STATE_UNSTARTED,
  YOUTUBE_PLAYER_STATE_ENDED,
  YOUTUBE_PLAYER_STATE_PLAYING,
  YOUTUBE_PLAYER_STATE_PAUSED,
  YOUTUBE_PLAYER_STATE_BUFFERING,
  YOUTUBE_PLAYER_STATE_CUED,
} from 'lib/constants'

import unescape from 'lodash/unescape'

import ProfileNavItem from 'app/shared/profile/profile-nav-item'

import DropdownMenu from './dropdown-menu' 

class SongHeader extends React.Component {
  constructor(props) {
    super(props);

    this.playVideoAt = this.playVideoAt.bind(this)
    this.playVideo = this.playVideo.bind(this)
    this.pauseVideo = this.pauseVideo.bind(this) 

    this.renderMobile = this.renderMobile.bind(this)
    this.renderBrowser = this.renderBrowser.bind(this)
    this.renderDropdownMenu = this.renderDropdownMenu.bind(this)

    this.state = {
      showDropdownMenu: false,
      dropdownPositionTop: '40px'
    }
  }

  playVideoAt () {
    const song = this.props.song
    const videoId = song.video.youtube_id

    if(!song || !song.djs) return

    const dj = song.djs[0]

    this.props.setVideoId(videoId)
    this.props.player.loadVideo(videoId, song.id, dj.id, dj.name)

    this.props.setPlayerSongId(song.id)
    this.props.setPlayerArtistId(dj.id)
  }

  playVideo () {
    if (this.props.player.playerState === YOUTUBE_PLAYER_STATE_PAUSED) {
      this.props.player.playVideo()
    }
  }

  pauseVideo () {
    if (this.props.player.playerState === YOUTUBE_PLAYER_STATE_PLAYING) {
      this.props.player.pauseVideo()
    }
  }

  renderDropdownMenu() {
    if(!this.state.showDropdownMenu) { return null; }

    return (
      <DropdownMenu 
        handleClick={(action) => {
          this.props.showPlaylistModal(this.props.song.id, action)
        }}
        positionTop={this.state.dropdownPositionTop}
        closeDropdown={() => {
          this.setState({showDropdownMenu: false})
        }}
      /> 
    )
  }

  renderMobile() {
    const { song, player, url } = this.props;

    const isHover = false;
    const isSelect = false;

    const playerState = player.playerState
    const isActive = (player.songId === song.id) &&
      (playerState !== null) &&
      (playerState !== YOUTUBE_PLAYER_STATE_UNSTARTED) &&
      (playerState !== YOUTUBE_PLAYER_STATE_CUED)

    const isPlaying   = (player.songId === song.id) && (playerState === YOUTUBE_PLAYER_STATE_PLAYING)
    const isPaused    = (player.songId === song.id) && (playerState === YOUTUBE_PLAYER_STATE_PAUSED)
    const isBuffering = (player.songId === song.id) && (playerState === YOUTUBE_PLAYER_STATE_BUFFERING)

    let iconClass = 'glyphicon '

    if (isPlaying) {
      iconClass += 'glyphicon-pause'
    } else if (isBuffering) {
      iconClass += 'glyphicon-transfer'
    } else {
      iconClass += 'glyphicon-play'
    }

    const title = song.video ? song.video.name : song.name;

    return (
      <header className='m6k-panel song-header'>
        <div className='song-album-art'>
          <img src={ formatYoutubeUrl(song.video.youtube_id) } />
        </div>

        <div className='song-detail-panel'>
          <div className='title'>
            <div className='icon-display'>
              <span className={iconClass} onClick={() => {
                if (isActive) {
                  if (isPaused) {
                    this.playVideo()
                  } else {
                    this.pauseVideo()
                  }
                } else {
                  this.playVideoAt()
                }
              }}/>
            </div>

            <h1>{unescape(title)}</h1> 
            <h3 className='duration'> { secondsToDurationString(song.video.duration) } </h3>
          </div>

          <div className='song-actions'>
            <button className='btn btn-default'>
              <span className='favorite'>
                {
                  song.is_favorite ?
                    <span className='glyphicon glyphicon-heart' onClick={(e) => {
                      this.props.unfavoriteSong(song.id)
                    }}></span>
                  : <span className='glyphicon glyphicon-heart-empty' onClick={() => {
                      this.props.favoriteSong(song.id, song.djs[0].id)
                    }}></span>
               }
              </span>
              Favorite
            </button>
          </div>
        </div>
      </header>
    )
  }

  renderBrowser() {
    const { song, player, url } = this.props;

    const isHover = false;
    const isSelect = false;

    const playerState = player.playerState
    const isActive = (player.songId === song.id) &&
      (playerState !== null) &&
      (playerState !== YOUTUBE_PLAYER_STATE_UNSTARTED) &&
      (playerState !== YOUTUBE_PLAYER_STATE_CUED)

    const isPlaying   = (player.songId === song.id) && (playerState === YOUTUBE_PLAYER_STATE_PLAYING)
    const isPaused    = (player.songId === song.id) && (playerState === YOUTUBE_PLAYER_STATE_PAUSED)
    const isBuffering = (player.songId === song.id) && (playerState === YOUTUBE_PLAYER_STATE_BUFFERING)

    let iconClass = 'glyphicon '

    if (isPlaying) {
      iconClass += 'glyphicon-pause'
    } else if (isBuffering) {
      iconClass += 'glyphicon-transfer'
    } else {
      iconClass += 'glyphicon-play'
    }

    const title = song.video ? song.video.name : song.name;

    return (
      <header className='m6k-panel song-header'>
        <div className='song-album-art'>
          <img src={ formatYoutubeUrl(song.video.youtube_id) } />
        </div>

        <div className='song-detail-panel'>
          <div className='title'>
            <div className='icon-display'>
              <span className={iconClass} onClick={() => {
                if (isActive) {
                  if (isPaused) {
                    this.playVideo()
                  } else {
                    this.pauseVideo()
                  }
                } else {
                  this.playVideoAt()
                }
              }}/>
            </div>

            <h1> { unescape(title) } </h1>
            <h3 className='duration'> { secondsToDurationString(song.video.duration) } </h3>
          </div>

          <div className='song-actions btn-group'>
            <button className='btn btn-default' onClick={(e) => {
              e.preventDefault()
              if(song.is_favorite) {
                this.props.unfavoriteSong(song.id)
              } else {
                this.props.favoriteSong(song.id, song.djs[0].id)
              }
            }}>
              <span className={`glyphicon ${song.is_favorite ? 'glyphicon-heart' : 'glyphicon-heart-empty'}`}></span>
              { song.is_favorite ? 'Favorited' : 'Favorite' }
            </button>
            <button className='btn btn-default' onClick={(e) => {
              e.preventDefault();
              this.setState({showDropdownMenu: true})
            }}>
              <span  className='glyphicon glyphicon-option-horizontal'></span>
              More
            </button>

            { this.renderDropdownMenu() }
          </div>


          <ul className='song-nav'>
            <ProfileNavItem
              name='Transitions'
              url={`${url}/transitions`}
            />
            <ProfileNavItem
              name='Similar Songs'
              url={`${url}/similar-songs`}
            />
            <ProfileNavItem
              name='Marketplace'
              url={`${url}/marketplace`}
              count={song.marketplace.beatport_tracks.length}
            />
          </ul>
        </div>
      </header>
    );
  }

  render() {
    if(this.props.isMobile) {
      return this.renderMobile();
    } else {
      return this.renderBrowser()
    }
  }
}

export default SongHeader; 
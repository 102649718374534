import axios from 'lib/axios-config'
import queryString from 'query-string'
import moment from 'moment'
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import cloneDeep from 'lodash/cloneDeep'

import { normalize } from 'normalizr'
import { artist, artists, song, songs, user, users } from 'app/schemas/main'
import { createSelector } from 'reselect'
import { replace, push } from 'connected-react-router'

import searchDJsReducer from './search-djs'
import searchUsersReducer from './search-users'
import searchSongsReducer from './search-songs'

export const setSearchQuery = (q) => {
  return {
    type: 'SET_SEARCH_QUERY',
    payload: { q }
  }
}

export const setSearchMode = (mode) => {
  return {
    type: "SET_SEARCH_MODE",
    payload: { mode }
  }
}

export const setSearchField = (mode, field, value) => {
  return {
    type: 'SET_SEARCH_FIELD',
    payload: { mode, field, value }     
  }
}

export function toggleFilterOption (mode, key, id, isFilter = false) {
  return {
    type: 'TOGGLE_FILTER_OPTION',
    payload: {
      mode,
      id,
      isFilter,
      key,
    }
  }
}

export function clearAllFilterOptions (mode, key) {
  return {
    type: 'CLEAR_ALL_FILTER_OPTIONS',
    payload: { mode, key }
  }
}

export const selectFilterCities = (state) => {
  const entities = state.entities
  const mode = state.search.mode;
  
  if(mode !== 'people') {
    return []
  }

  return state.search.users.filters.cities.map((id) => {
    return entities.cities[id]
  })
}

export const selectFilterGenres = (state) => {
  const entities = state.entities
  const mode = state.search.mode;

  if(mode !== 'djs' && mode !== 'songs') {
    return []
  }

  return state.search[mode].filters.genres.map((id) => {
    return entities.genres[id]
  })
}

export const selectFilterCountries = (state) => {
  const entities = state.entities
  const mode = state.search.mode;
  
  if(mode !== 'djs') {
    return []
  }

  return state.search[mode].filters.countries.map((id) => {
    return entities.countries[id]
  })
}

export function paramsOnLoad (params) {
  return (dispatch, getState) => {
    if (params.q) {
      dispatch(setSearchQuery(params.q))
    }

    if (params.from) {
      dispatch(setSearchField('djs', 'from', moment(params.from, 'YYYY-MM-DD')))
      dispatch(setSearchField('songs', 'from', moment(params.from, 'YYYY-MM-DD')))
    }

    if (params.to) {
      dispatch(setSearchField('djs', 'to', moment(params.to, 'YYYY-MM-DD')))
      dispatch(setSearchField('songs', 'to', moment(params.to, 'YYYY-MM-DD')))
    }

    if (params.sort) {
      dispatch(setSearchField('djs', 'sort', params.sort))
    } else {
      dispatch(setSearchField('djs', 'sort', 'top'))
    }

    if (params.degree !== null && params.degree !== undefined && params.degree !== '') {
      dispatch(setSearchField('people', 'degree', parseInt(params.degree)))
    } else {
      dispatch(setSearchField('people', 'degree', 0))
    }

    let genres = Array.isArray(params.genres) ? params.genres : [params.genres]
    let countries = Array.isArray(params.countries) ? params.countries : [params.countries]
    let cities = Array.isArray(params.cities) ? params.cities : [params.cities]

    const isFilter = true
    for (let id of compact(genres)) {
      dispatch(toggleFilterOption('djs', 'genres', parseInt(id), isFilter))
      dispatch(toggleFilterOption('songs', 'genres', parseInt(id), isFilter))
    }

    for (let id of compact(countries)) {
      dispatch(toggleFilterOption('djs', 'countries', parseInt(id), isFilter))
    }

    for(let id of compact(cities)) {
      dispatch(toggleFilterOption('people', 'cities', parseInt(id), isFilter))
    }
  }
}

function buildUrlParams(state) {
  const search = state.search
  const mode = search.mode;

  const q = search.q === '' || search.q === null ? undefined : search.q

  let from, to, genres;
  if(mode === 'djs' || mode === 'songs') {
    from = moment(search[mode].filters.from).format('YYYY-MM-DD')
    to = moment(search[mode].filters.to).format('YYYY-MM-DD')
    genres = search[mode].filters.genres
  }

  const countries = search.djs.filters.countries
  const cities = search.users.filters.cities
  const degree = search.users.filters.degree

  return {
    q,
    to,
    from,
    genres,
    countries,
    cities,
    degree
  }
}

export function updateUrl () {
  return (dispatch, getState) => {
    const params = buildUrlParams(getState())

    dispatch(replace({
      search: queryString.stringify(params)
    }))
  }
}

export function gotoSearchPage () {
  return (dispatch, getState) => {
    const state = getState()
    const params = buildUrlParams(state)

    dispatch(push({
      pathname: "/search/djs",
      search: queryString.stringify(params)
    }))
  }
}

export function submitSearch (loadMore = false) {
  return (dispatch, getState) => {
    const url = `/api/search`
    const state = getState()

    if (state.search.isFetching) {
      return Promise.reject()
    }

    let page = state.search.pagination.page;

    if (loadMore) {
      page += 1
      dispatch(updatePaginationPage(page))
    } else if (page !== 1) {
      dispatch(updatePaginationPage(1))
      page = 1
    }

    let filters = null
    if(state.search.mode === 'djs') {
      filters = cloneDeep(state.search.djs.filters)

      filters.from = filters.from.format('YYYY-MM-DD'),
      filters.to = filters.to.format('YYYY-MM-DD')
    } else if(state.search.mode === 'people') {
      filters = cloneDeep(state.search.users.filters)
    } else {
      filters = cloneDeep(state.search.songs.filters)
      
      filters.from = filters.from.format('YYYY-MM-DD'),
      filters.to = filters.to.format('YYYY-MM-DD')
    }

    dispatch({
      type: "GET_SEARCH_RESULTS"
    })

    return axios.get(url, {
      params: {
        q: state.search.q,
        type: state.search.mode,
        page,
        ...filters,
      }
    }).then((response) => {
      const data = response.data;

      const userResults   = normalize(data.users.results, users)
      const songResults   = normalize(data.songs.results, songs)
      const djResults     = normalize(data.artists.results, artists) 

      dispatch({
        type: "GET_SEARCH_RESULTS_SUCCESS",
        payload: {
          loadMore: loadMore,
          users: {
            ids: userResults.result,
            total: data.users.total,
          },
          songs: {
            ids: songResults.result,
            total: data.songs.total,
          },
          djs: {
            ids: djResults.result,
            total: data.artists.total,
          },
          entities: {
            ...userResults.entities,
            ...songResults.entities,
            ...djResults.entities,
          }
        }
      })

      return Promise.resolve()
    }).catch((error) => {
      console.error(error);

      dispatch({
        type: "GET_SEARCH_RESULTS_FAILURE"
      })

      return Promise.reject()
    })
  }
}

export const selectSearchQuery = (state) => {
  return state.search.q;
}

export const updatePaginationPage = (page) => {
  return {
    type: 'SET_SEARCH_PAGINATION_PAGE',
    payload: { page }
  }
}

const defaultState = {
  isFetching: false,
  error: null,
  q: '',
  mode: 'all',
  pagination: {
    page: 1,
    limit: 35,
  },
  djs: null,
  users: null,
  songs: null 
}

const search = (state = defaultState, action) => {
  action.mode = state.mode

  switch(action.type) {
    case 'SET_SEARCH_MODE':
      return {
        ...state,
        mode: action.payload.mode,
      }
    case 'SET_SEARCH_PAGINATION_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
        }        
      }
    case 'SET_SEARCH_QUERY':
      return {
        ...state,
        q: action.payload.q,
      }
    case 'GET_SEARCH_RESULTS':
      return {
        ...state,
        isFetching: true
      }
    case 'GET_SEARCH_RESULTS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        djs:   searchDJsReducer(state.djs, action),
        users: searchUsersReducer(state.users, action),
        songs: searchSongsReducer(state.songs, action), 
      }
    case 'GET_SEARCH_RESULTS_FAILURE':
      return {
        ...state,
        isFetching: false
      }
    case 'SET_SEARCH_FIELD':
      return {
        ...state,
        djs:   searchDJsReducer(state.djs, action),
        users: searchUsersReducer(state.users, action),
        songs: searchSongsReducer(state.songs, action),
      }
    case 'CLEAR_ALL_FILTER_OPTIONS':
      return {
        ...state,
        djs:   searchDJsReducer(state.djs, action),
        users: searchUsersReducer(state.users, action),
        songs: searchSongsReducer(state.songs, action),
      }
    case 'TOGGLE_FILTER_OPTION':
      return {
        ...state,
        djs:   searchDJsReducer(state.djs, action),
        users: searchUsersReducer(state.users, action),
        songs: searchSongsReducer(state.songs, action),
      }
    default:
      return {
        ...state,
        djs:   searchDJsReducer(state.djs, action),
        users: searchUsersReducer(state.users, action),
        songs: searchSongsReducer(state.songs, action),
      }
  } 
}

export default search
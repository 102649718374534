import { injectReducer } from 'store/reducers'
import asyncComponent from 'components/async-component'
import { userIsAuthenticated, userIsNotAuthenticated } from 'lib/authentication'

export default (store) => asyncComponent(() => new Promise((resolve) => {
  require.ensure([], (require) => {
    const App = require('./components/app').default

    const currentUser = require('./modules/current-user').default
    const artist = require('./modules/artist').default
    const song = require('./modules/song').default
    const autocomplete = require('./modules/autocomplete').default
    const entities = require('./modules/entities').default
    const genres = require('./modules/genres').default
    const countries = require('./modules/countries').default
    const cities = require('./modules/cities').default
    const player = require('./shared/music-player/modules/player').default
    const profile = require('./modules/profile').default
    const following = require('./modules/following').default
    const favorites = require('./modules/favorites').default
    const friends = require('./modules/friends').default
    const friendRequests = require('./modules/friend-requests').default
    const blockedUsers = require('./modules/blocked-users').default
    const playlists = require('./modules/playlists').default
    const songFeed = require('./modules/song-feed').default;
    const keys = require('./modules/keys').default
    const history = require('./modules/history').default;
    const charts = require("./modules/charts").default;
    const search = require('./modules/search/index').default
    const feedTemplates = require('./modules/feed-templates').default

    injectReducer(store, { key: 'friends', reducer: friends })
    injectReducer(store, { key: 'currentUser', reducer: currentUser })
    injectReducer(store, { key: 'search', reducer: search })
    injectReducer(store, { key: 'genres', reducer: genres })
    injectReducer(store, { key: 'countries', reducer: countries })
    injectReducer(store, { key: 'cities', reducer: cities })
    injectReducer(store, { key: 'entities', reducer: entities })
    injectReducer(store, { key: 'artist', reducer: artist })
    injectReducer(store, { key: 'song', reducer: song })
    injectReducer(store, { key: 'autocomplete', reducer: autocomplete })
    injectReducer(store, { key: 'player', reducer: player })
    injectReducer(store, { key: 'profile', reducer: profile })
    injectReducer(store, { key: 'following' , reducer: following })
    injectReducer(store, { key: 'favorites', reducer: favorites })
    injectReducer(store, { key: 'friendRequests', reducer: friendRequests })
    injectReducer(store, { key: 'blockedUsers', reducer: blockedUsers })
    injectReducer(store, { key: 'playlists', reducer: playlists })
    injectReducer(store, { key: 'songFeed', reducer: songFeed })
    injectReducer(store, { key: 'keys', reducer: keys })
    injectReducer(store, { key: 'history', reducer: history })
    injectReducer(store, { key: 'charts', reducer: charts })
    injectReducer(store, { key: 'feedTemplates', reducer: feedTemplates })

    resolve(App)
  }, 'app')
}))

import { schema } from 'normalizr'

export const user = new schema.Entity('users', {}, {
	idAttribute: 'uuid'
})

export const users = [ user ]

export const artist = new schema.Entity('artists')
export const artists = [ artist ]

export const genre = new schema.Entity('genres')
export const genres = [ genre ]

export const key = new schema.Entity('keys');
export const keys = [ key ];

export const country = new schema.Entity('countries')
export const countries = [ country ]

export const city = new schema.Entity('cities')
export const cities = [ city ]

export const song = new schema.Entity('songs')
export const songs = [ song ]

song.define({
  djs: artists,
  similar_songs: songs,
  transitions: {
    to: songs,
    from: songs,
  }
})

export const following = new schema.Entity('followings')
export const followings = [ following ]

following.define({
  artist: artist,
})

export const favorite = new schema.Entity('favorites', {
  song: song,
  artist: artist
})

export const favorites = [ favorite ]

export const friendRequest = new schema.Entity('friendRequests', {
  user: user
})

export const friendRequests = [ friendRequest ]

export const playlist = new schema.Entity('playlists')
export const playlists = [ playlist ];

export const userPlaylistPermission = new schema.Entity('userPlaylistPermissions')
export const userPlaylistPermissions = [ userPlaylistPermission ]

userPlaylistPermission.define({
  user: user,
})

playlist.define({
  user_playlist_permissions: userPlaylistPermissions,
  playlistSongs: [{
    song: song
  }]
})

export const feedTemplate = new schema.Entity('feedTemplates', {}, {
  idAttribute: 'code'
})

export const feedTemplates = [ feedTemplate ]

import React, { Component } from 'react'
import { connect } from 'react-redux'

import NavLink from './nav-link'
import { Link } from 'react-router-dom'
import { selectCurrentUser } from 'app/modules/current-user/account'

import SearchBar from './search-bar'
import MobileSearchBar from './search-bar/mobile-search-bar'

import { isBrowser } from "react-device-detect";
import { setIndex } from 'app/shared/music-player/modules/player'

import {
  setSearchQuery,
  selectSearchQuery,
  submitSearch,
  updatePaginationPage,
  updateUrl,
  setSearchMode, 
} from 'app/modules/search/index'

class MobileHeader extends Component {
  constructor (props) {
    super(props)

    this.toggleMenu = this.toggleMenu.bind(this)
    this.setSubNavIndex = this.setSubNavIndex.bind(this)
    this.subNavClass = this.subNavClass.bind(this)

    this.state = {
      toggle: null,
      subNavIndex: 0
    }
  }

  toggleMenu () {
    const toggle = this.state.toggle
    this.setState({toggle: !toggle})
  }

  setSubNavIndex (index) {
    this.setState({subNavIndex: index})
  }

  subNavClass (index) {
    let classes = 'subnav'
    if (this.state.subNavIndex === index) {
      classes += ' subnav-active'
    }

    return classes
  }

  render () {
    let style = {}
    if (this.state.toggle !== null) {
      if (this.state.toggle) {
        style = {display: 'block'}
      } else {
        style = {display: 'none'}
      }
    }

    return (
      <header>
        <nav className='navbar navbar-default navbar-mobile app-navbar'>
          <div className='container-fluid'>
            <Link to='/' className='navbar-brand'>Mu6ik</Link>
            <span className='glyphicon glyphicon-menu-hamburger'
              onClick={this.toggleMenu}
             />
            <div className='dropdown-nav' style={style}>
              <div className={this.subNavClass(0)}>
                <ul className='nav navbar-nav'>
                  <NavLink to='/search' onClick={this.toggleMenu}>Search</NavLink>
                  <NavLink to='/feed' onClick={this.toggleMenu}>Feed</NavLink>
                  <NavLink to='/library' onClick={this.toggleMenu}>Library</NavLink>
                  <NavLink to={`/${this.props.user.username}`} onClick={this.toggleMenu}>Profile</NavLink>
                  <li onClick={() => {
                    this.setSubNavIndex(1)
                  }}>
                    <a href='#' onClick={e => e.preventDefault()}>
                      {this.props.user.username}
                      <span className='glyphicon glyphicon-menu-right' />
                    </a>
                  </li>
                </ul>
              </div>
              <div className={this.subNavClass(1)}>
                <ul className='nav navbar-nav'>
                  <li onClick={() => {
                    this.setSubNavIndex(0)
                  }}>
                    <a href='#' onClick={e => e.preventDefault()}>
                      <span className='glyphicon glyphicon-menu-left' />
                      Back
                    </a>
                  </li>
                  <NavLink to='/settings/invitations' onClick={this.toggleMenu}>
                    Invites
                  </NavLink>
                  <NavLink to='/settings' onClick={this.toggleMenu}>
                    Settings
                  </NavLink>
                  <NavLink to='/terms' onClick={this.toggleMenu}>
                    Legal
                  </NavLink>
                  <NavLink to='/login' onClick={this.props.logout}>
                    Log out
                  </NavLink>
                </ul>
              </div>
            </div>
          </div>
        </nav>

        {
          isBrowser ?
          <SearchBar
            q={this.props.q} 
            setSearchQuery={this.props.setSearchQuery} 
            submitSearch={this.props.submitSearch}
            updatePaginationPage={this.props.updatePaginationPage}
            updateUrl={this.props.updateUrl}
            location={this.props.location}
            setIndex={this.props.setIndex}
            /> :
          <MobileSearchBar
            q={this.props.q} 
            setSearchQuery={this.props.setSearchQuery} 
            submitSearch={this.props.submitSearch}
            updatePaginationPage={this.props.updatePaginationPage}
            updateUrl={this.props.updateUrl}
            location={this.props.location}
            setSearchMode={this.props.setSearchMode}
            setIndex={this.props.setIndex}
          />
        }

      </header>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: selectCurrentUser(state),
    q: selectSearchQuery(state),
    location: state.router.location
  }
}

export default connect(mapStateToProps, {
  submitSearch,
  setSearchQuery,
  updatePaginationPage,
  updateUrl,
  setIndex,
  setSearchMode,
})(MobileHeader)

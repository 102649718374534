import React from 'react';
import Loading from 'react-loading'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'

import { titleCase } from 'lib/string'

import {
  fetchArticle 
} from 'modules/articles'

import { setBreadcrumbs, clearBreadcrumbs } from 'modules/breadcrumbs'

import '../styles/articles.scss'

const mapStateToProps = (state) => {
  return {
    isFetching: state.articles.isFetching,
    article: state.articles.article,
    didInvalidate: state.articles.didInvalidate,
  }
}

export class Articles extends React.Component {
  constructor(props) {
    super(props);

    this.renderArticle = this.renderArticle.bind(this)

    this.state = {
      loaded: false,
      title: 'Mu6ik'
    }
  }

  componentDidMount() {
    const { uri } = this.props.match.params;

    this.props.fetchArticle(uri)
      .then((article) => {
        this.props.setBreadcrumbs([{
          name: 'Mu6ik Help Center',
          url: '/help/'
        }, {
          name: article.category,
          url: `/help/categories/${article.category_uri}`
        }]);

        this.setState({
          loaded: true,
          title: article.title,
        })
      })
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs()
  }

  renderArticle() {
    const { article } = this.props;

    return (
      <div className='article'>
        <div className='m6k-panel'>
          <div className='article-name'>
            <h1>{ article.name }</h1>
          </div>

          <div className='article-body'>
            <div dangerouslySetInnerHTML={{ __html: article.content }} />
            </div>
          </div>
      </div>
    )
  }

  renderLoading() {
    return (
      <div className='m6k-panel' style={{'padding': '20px'}}>
        <div style={{'width' : '60px', 'height': '60px', "margin": 'auto'}}>
          <Loading
            type='spin'
            color='#aaa'
            delay={0}
            height={60}
            width={60}
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>{ this.state.title }</title>
        </Helmet>

        { this.state.loaded ? this.renderArticle() : this.renderLoading() }
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  fetchArticle,
  setBreadcrumbs,
  clearBreadcrumbs,
})(Articles)
import React, { Component } from 'react'
import moment from 'moment'

import SquareImage from './square-image'

import buildURL from 'lib/artist-url'
import ClickHandler from 'components/click-handler'

class DJTile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      image: null
    }
  }

  render () {
    const colWidth = 12 / this.props.width
    const smColWidth = 12 / this.props.widthSmall
    const xsColWidth = 12 / this.props.widthXSmall

    const className = `dj col-md-${colWidth} col-sm-${smColWidth} col-xs-${xsColWidth}`

    const urlObj = buildURL(this.props)

    return (
      <div className={className}>
        <div className='overlay-wrapper'>

          { this.props.isPublic ?
              <a href="" className='video-title-wrapper' onClick={e => e.preventDefault()}>
                <SquareImage urlPath={urlObj.imgURL} />
                <div className='overlay'>
                  <h3>{this.props.name}</h3>
                </div>
              </a>
              :
              <ClickHandler url={urlObj.artistURL} >
                <SquareImage urlPath={urlObj.imgURL} />
                <div className='overlay'>
                  <h3>{this.props.name}</h3>
                </div>
              </ClickHandler>
          }
        </div>

        {
          this.props.children
        }

      </div>
    )
  }
}

export default DJTile

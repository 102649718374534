import axios from 'lib/axios-config'
import queryString from 'query-string'
import moment from 'moment'
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import uniq from 'lodash/uniq'

import { normalize } from 'normalizr'
import { artist, artists } from 'app/schemas/main'
import { createSelector } from 'reselect'
import { replace, push } from 'connected-react-router'

export function clearSearchArtists () {
  return {
    type: 'CLEAR_SEARCH_ARTISTS_RESULTS'
  }
}

export function clearAllSearchFilters (filterType) {
  return {
    type: `CLEAR_ALL_${filterType}_FILTERS`
  }
}

export const selectDJs = (state) => {
  const entities = state.entities
  const ids = state.search.djs.ids;

  return ids.map((id) => {
    const artist = entities.artists[id]

    return {
      ...artist
    }
  })
}

const defaultState = {
  ids: [],
  total: 0,
  filters: {
    sort: 'top',
    genres: [],
    countries: [],
    from: moment('2012-01-01'),
    to: moment(),
  }
}

export const searchDJsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_SEARCH_RESULTS_SUCCESS':
      const ids = action.payload.loadMore ? 
        [ ...state.ids, ...action.payload.djs.ids ] : 
        action.payload.djs.ids

      return {
        ...state,
        ids,
        total: action.payload.djs.total,
      }
    case 'SET_SEARCH_FIELD':
      if(action.payload.mode !== 'djs') return state;

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.field]: action.payload.value
        }
      }
    case 'CLEAR_SEARCH_ARTISTS_RESULTS':
      return defaultState
    case 'REQUEST_SEARCH_ARTISTS':
      return {
        ...state,
        isFetching: true
      }
    case 'REQUEST_SEARCH_ARTISTS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        ids: action.payload.ids
      }
    case 'REQUEST_SEARCH_ARTISTS_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      }
    case 'TOGGLE_FILTER_OPTION':
      if(action.payload.mode !== 'djs') return state;

      const key = action.payload.key

      if (action.payload.isFilter) {
        return {
          ...state,
          filters: {
            ...state.filters,
            [key]: uniq([
              ...state.filters[key],
              action.payload.id
            ])
          }
        }
      } else {
        const items = filter(state.filters[key], id => id !== action.payload.id)

        return {
          ...state,
          filters: {
            ...state.filters,
            [key]: items,
          }
        }
      }
    case 'CLEAR_ALL_FILTER_OPTIONS':
      if(action.payload.mode !== 'djs') return state;

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: []
        }
      }
    default:
      return state === null ? defaultState : state
  }
}

export default searchDJsReducer;
import { injectReducer } from 'store/reducers'
import asyncComponent from 'components/async-component'

export default (store) => asyncComponent(() => new Promise((resolve) => {
  require.ensure([], (require) => {
    const Public = require('./components/public').default

    const genres = require('./modules/genres').default
    const countries = require('./modules/countries').default
    const artist = require('./modules/artist').default
    const song = require('./modules/song').default
    const entities = require('./modules/entities').default
    const player = require('./shared/music-player/modules/player').default
    const playlists = require('./modules/playlists').default
    const keys = require('./modules/keys').default

    injectReducer(store, { key: 'genres', reducer: genres })
    injectReducer(store, { key: 'countries', reducer: countries })
    injectReducer(store, { key: 'entities', reducer: entities })
    injectReducer(store, { key: 'artist', reducer: artist })
    injectReducer(store, { key: 'song', reducer: song })
    injectReducer(store, { key: 'player', reducer: player })
    injectReducer(store, { key: 'playlists', reducer: playlists })
    injectReducer(store, { key: 'keys', reducer: keys })

    resolve(Public)
  }, 'public')
}))

import React from 'react'

class FormGroup extends React.Component {
  render () {
    return (
      <div className='form-group'>
        <label className='col-sm-3 control-label'>{this.props.label}</label>
        <div className='col-sm-5'>
          {this.props.children}
          <input
            type={this.props.type || 'text'}
            className='form-control'
            value={this.props.value || ''}
            onChange={this.props.handleChange}
          />
          <div className='info'>
            {this.props.info}
          </div>
        </div>
      </div>
    )
  }
}

export default FormGroup

import React from 'react'
import beatportLogo from 'assets/img/beatport-logo.png'
import sortBy from 'lodash/sortBy'

class MarketPlacePopover extends React.Component {
  render () {
    if (!this.props.isVisible) {
      return null
    }

    if (this.props.beatportTracks === null || this.props.beatportTracks.length === 0) {
      return (
        <div className='marketplace-popover'>
          <h3>
            <span className='glyphicon glyphicon-shopping-cart' />
            <span>Marketplace</span>
          </h3>
          <h5>Sorry, no tracks available.</h5>
        </div>
      )
    }

    let releasesRow = []
    let releases = []
    for (var i = 0; i < this.props.beatportTracks.length; ++i) {
      const bt = this.props.beatportTracks[i]
      const release = bt.beatport_release
      let imageUri = null

      if (release && release.beatport_images) {
        const images = sortBy(release.beatport_images, ['width'])
        imageUri = images.length > 0 ? images[images.length - 1].uri : beatportLogo;
      }

      if (!imageUri) {
        imageUri = beatportLogo
      }

      if ((i + 1) % 5 !== 0) {
        releasesRow.push(
          <div className='release-cell' key={`release-item-${i}`}>
            <a href={bt.uri} target='_blank'>
              <img src={imageUri} width={125} height={125} />
              <h5>{bt.artist} - {bt.name}</h5>
            </a>
          </div>
        )
      } else if (i !== 0) {
        releases.push(
          <div className='release-row' key={`release-row-${i}`}>
            {releasesRow}
          </div>
        )

        releasesRow = []
      }
    }

    if (releasesRow.length > 0) {
      releases.push(
        <div className='release-row' key={`release-row-end`}>
          {releasesRow}
        </div>
      )
    }

    return (
      <div className='marketplace-popover'>
        <h3>
          <span className='glyphicon glyphicon-shopping-cart' />
          <span>Marketplace</span>
        </h3>
        <div className='releases'>
         { releases }
        </div>
      </div>
    )
  }
}

MarketPlacePopover.defaultProps = {
  beatportTracks: []
}

export default MarketPlacePopover

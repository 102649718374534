import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'

import Notice from 'components/Notice/Notice'
import { formatErrors } from 'lib/format-message'

import MiniFooter from 'components/mini-footer'

import M6kCheckbox from 'components/m6k-checkbox'

import {
  verifyInviteToken,
  acceptInvitation
} from '../modules/invitation'

import {
  setRegistrationField,
  usernameAvailable,
  clearUsernameAvailable,
  clearEmailAvailable,
  emailAvailable
} from 'modules/registration'

import '../styles/invitation.scss'

class Invitation extends Component {
  constructor (props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.renderFieldAvailable = this.renderFieldAvailable.bind(this)
    this.closeNotice = this.closeNotice.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.toggle = this.toggle.bind(this)

    this.validatorTimeout = null

    this.state = {
      messages: [],
      messageVisible: false,
      messageClasses: null,
      unsavedChanges: false
    }
  }

  handleChange (e, field, validator = null) {
    const newValue = e.currentTarget.value
    const existingValue = this.props.registration[field]

    this.props.setRegistrationField(field, newValue)
    this.setState({unsavedChanges: true})

    if (validator) {
      this.clearValidatorTimeout()

      this.validatorTimeout = setTimeout(() => {
        validator(existingValue, newValue)
        this.validatorTimeout = null
      }, 500)
    }
  }

  handleSubmit () {
    const password = this.password.value
    const confirmPassword = this.confirmPassword.value
    const firstName = this.firstName.value
    const lastName = this.lastName.value

    this.props.handleSubmit(
      firstName,
      lastName,
      password,
      confirmPassword,
      this.token
    ).then(() => {
      this.props.clearUsernameAvailable()
    }).catch(() => {
      const { registration } = this.props
      this.props.clearUsernameAvailable()
      const errors = formatErrors(registration.error)

      this.setState({
        messages: errors,
        messageVisible: true,
        messageClasses: 'red',
        unsavedChanges: false
      })
    })
  }

  clearValidatorTimeout () {
    if (this.validatorTimeout) {
      clearTimeout(this.validatorTimeout)
      this.validatorTimeout = null
    }
  }

  renderFieldAvailable (field) {
    const user = this.props.registration
    if (!user) {
      return null
    }

    const available = user[`${field}_available`]
    if (available === null || available === undefined) {
      return null
    }

    let classes = 'info '
    if (user.isFetching && available.valid === undefined) {
      classes += 'fetching'
      return (
        <div className={classes}>
          Checking...
        </div>
      )
    }

    classes += available.valid ? 'valid' : 'invalid'
    return (
      <div className={classes}>
        {available.msg}
      </div>
    )
  }

  closeNotice () {
    this.setState({
      message: null,
      messageVisible: false
    })
  }

  componentDidMount () {
    const params = queryString.parse(this.props.location.search)
    this.token = params.token

    this.props.verifyInviteToken(this.token)
  }

  toggle(field) {
    this.props.setRegistrationField(field, !this.props.registration[field])
  }

  render () {
    let inputClass = 'form-control'

    if (!this.props.isVerified) {
      return (
        <div className='invitation-panel' />
      )
    }

    return (
      <div className='invitation'>
        <Helmet>
          <title>Mu6ik / Invitation</title>
        </Helmet>

        <div className='invitation-panel'>
          <h2>Mu6ik</h2>
          <h3>Welcome to the Private beta Sign up.</h3>
          <Notice
            classes={this.state.messageClasses}
            visible={this.state.messageVisible}
            handleClick={this.closeNotice}
          >
            {
              this.state.messages.map((message, i) =>
                <div key={i}>{message}</div>
              )
            }
          </Notice>
          <form onSubmit={(e) => {
            e.preventDefault()
            this.handleSubmit()
          }}>
            <div className='form-group'>
              <input
                type='text'
                className={inputClass}
                placeholder='Email'
                disabled
                value={this.props.registration.email}
                ref={(email) => this.email = email} />
            </div>
            <div className='form-group'>
              <input
                type='text'
                className={inputClass}
                placeholder='Username'
                onChange={(e) => {
                  this.handleChange(e, 'username', this.props.usernameAvailable)
                }}
                value={this.props.registration.username}
                ref={(username) => this.username = username} />
              {this.renderFieldAvailable('username')}
            </div>
            <br />
            <div className='form-group'>
              <input
                type='text'
                className={inputClass}
                placeholder='First Name'
                ref={(firstName) => this.firstName = firstName} />
            </div>
            <div className='form-group'>
              <input
                type='text'
                className={inputClass}
                placeholder='Last Name'
                ref={(lastName) => this.lastName = lastName} />
            </div>
            <br />
            <div className='form-group'>
              <input
                type='password'
                className={inputClass}
                placeholder='Password'
                ref={(password) => this.password = password}
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                className={inputClass}
                placeholder='Confirm Password'
                ref={(password) => this.confirmPassword = password}
              />
            </div>

            <div className='agreement-checkbox'>
              <div className='checkbox-wrapper' onClick={() => this.toggle('didAgree')}>
                <M6kCheckbox checked={this.props.registration.didAgree}  />
              </div>

              <h6 className='message'>
                I agree to the Mu6ik&nbsp; 
                <a href='/terms' target="_blank">Terms of Service</a> and&nbsp; 
                <a href='/privacy-policy' target="_blank">Privacy Policy</a>
              </h6>
            </div>

            <div className='agreement-checkbox'>
              <div className='checkbox-wrapper' onClick={() => this.toggle('sendMarketingEmails')} >
                <M6kCheckbox checked={this.props.registration.sendMarketingEmails} />
              </div>
              <h6 className='message'>
                I'd like to receive artist and song suggestions, as well as a periodic newsletter and other promotional material 
              </h6>
            </div>

            <button type='submit' className='btn btn-default sign-up'>Sign up</button>
          </form>
        </div>
        <MiniFooter />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    registration: state.registration,
    isVerified: state.invitation.isVerified,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    verifyInviteToken (token) {
      dispatch(verifyInviteToken(token))
    },
    setRegistrationField (field, value) {
      dispatch(setRegistrationField(field, value))
    },
    usernameAvailable (username, value) {
      dispatch(usernameAvailable(username, value))
    },
    clearUsernameAvailable () {
      dispatch(clearUsernameAvailable())
    },
    emailAvailable (email, value) {
      dispatch(emailAvailable(email, value))
    },
    handleSubmit (firstName, lastName, password, confirmPassword, token) {
      return dispatch(acceptInvitation(firstName, lastName, password, confirmPassword, token))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invitation)

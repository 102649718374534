import React from 'react';
import playlistEmpty from 'assets/img/playlist-empty.jpg'
import ClickHandler from 'components/click-handler'

import { formatYoutubeUrl } from 'lib/video'
import { buildResourceUrl } from 'lib/string'

export default class PlaylistSummary extends React.Component {
  constructor(props) {
    super(props);
  }
   
  render() {
    const url = buildResourceUrl('playlists', this.props.name, this.props.id)

    return (
      <div  className='playlist-summary'>
        <ClickHandler url={url}>
          { (this.props.youtube_id === null || this.props.youtube_id === '') ? 
            <img src={playlistEmpty} /> : 
            <img src={formatYoutubeUrl(this.props.youtube_id)} /> 
          }

          <h3>{this.props.name}</h3>
        </ClickHandler>
      </div>
    );
  }
}

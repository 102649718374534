import React from 'react';
import Modal from 'react-modal'

export default class DeletePlaylistModal extends React.Component {
  constructor(props) {
    super(props);

    this.deletePlaylist = this.deletePlaylist.bind(this)
    this.verifyName = this.verifyName.bind(this)

    this.state = {
      inputName: ''
    }
  }

  verifyName() {
    return this.state.inputName !== this.props.playlist.name
  }

  deletePlaylist(e) {
    e.preventDefault();

    if(this.verifyName()) {
      return false
    }

    this.props.deletePlaylist(this.props.playlist)
      .then(() => {
        this.props.onCancel()
      })
      .catch(() => {})
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.show}
          onRequestClose={this.props.onCancel}
          contentLabel='Delete Playlist Modal'
          className='playlist-modal m6k-panel'
        >
          <div className='modal-header'>
            <h3>Delete Playlist: {this.props.playlist.name}</h3>
          </div>

          <div className='modal-body'>
            <div className='row'>
              <div className='col-md-12'>
                <h4>To delete this playlist, type the name of the playlist and click delete</h4>

                <input
                  type='text'
                  className='form-control' 
                  value={this.state.inputName}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({inputName: e.target.value})
                  }}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12'>
                <div className='btn-group'>
                  <button className='btn btn-default' onClick={this.props.onCancel}>
                    Cancel
                  </button>
                  <button 
                    className='btn btn-danger' 
                    disabled={this.verifyName()}
                    onClick={this.deletePlaylist}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>

        </Modal>
      </div>
    );
  }
}

import React from 'react';

import { 
  formatYoutubeUrl,
} from 'lib/video'

import emptyPlaylist from 'assets/img/playlist-empty' 
import '../styles/compact-playlist'

export default class CompactPlaylist extends React.PureComponent {
  constructor(props) {
    super(props);

    this.renderCoverArt = this.renderCoverArt.bind(this)
    this.renderPlaylistItems = this.renderPlaylistItems.bind(this)
  }

  renderPlaylistItems() {
    const { playlist } = this.props;

    if(!playlist || !playlist.playlistSongs) return;

    const songs = this.props.playlist.playlistSongs.map((ps) => {
      return ps.song
    })

    return (
      <ul className='compact-list'>
        { songs.map((song, index) => {
          return (
            <li
              id={`compact-list-item-${index}`}
              index={index}
            >
              {
                song.nam
              }
            </li>
          )
        })}
      </ul>
    )
  }

  renderCoverArt() {
    const { playlist } = this.props;

    if(!playlist || !playlist.youtube_id) {
      return (
        <img src={emptyPlaylist} className='playlist-art' />
      );
    } else {
      return (
        <img src={formatYoutubeUrl(playlist.youtube_id)} className='playlist-art' />
      )
    }
  }

  render() {
    return (
      <div className='compact-playlist m6k-panel'>
        <div className='artwork-area'>
          { this.renderCoverArt() }
        </div>
        <div className='list-area'>
          { this.renderPlaylistItems() }
        </div>
      </div>
    );
  }
}

import React from 'react';
import M6kThumbnail from './m6k-thumbnail'

import { buildResourceUrl } from 'lib/string'
import { formatYoutubeUrl, secondsToDurationString } from 'lib/video'
import { trimTitle } from 'lib/string';

export default class SetlistItemSortable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.isCoverPhoto = this.isCoverPhoto.bind(this)
    this.handleControlAction = this.handleControlAction.bind(this)
  }


  isCoverPhoto(song) {
    return song && song.video && song.video.youtube_id === this.props.playlist.youtube_id   
  }

  handleControlAction(e) {
    e.preventDefault();
    e.stopPropagation()

    const index = this.props.listIndex

    const playerState = this.props.playerState
    const playerIndex = this.props.playerIndex

    const isPlaying = (index === playerIndex) && (playerState === 1)
    const isPaused = (index === playerIndex) && (playerState === 2)
    const isBuffering = (index === playerIndex) && (playerState === 3)

    const isActive = (index === playerIndex) &&
      playerState !== null &&
      playerState !== -1 &&
      playerState !== 5

    if (isActive) {
      if (isPaused) {
        this.props.playVideo()
      } else {
        this.props.pauseVideo()
      }
    } else {
      this.props.playVideoAt(this.props.listIndex)
    }
  }

  renderControls() {
    const { song } = this.props;
    
    const index = this.props.listIndex
    const playerState = this.props.playerState
    const playerIndex = this.props.playerIndex

    const isCurrent = (index === playerIndex) && (song.video.youtube_id === this.props.playerVideoId)

    const isPlaying   = isCurrent && (playerState === 1)
    const isPaused    = isCurrent && (playerState === 2)
    const isBuffering = isCurrent && (playerState === 3)

    const isActive = isCurrent &&
      playerState !== null &&
      playerState !== -1 &&
      playerState !== 5

    let iconClass = 'glyphicon '

    if (isPlaying) {
      iconClass += 'glyphicon-volume-up'
    } else if (isBuffering) {
      iconClass += 'glyphicon-transfer'
    } else {
      iconClass += 'glyphicon-play'
    }

    return (
      <button className={isActive ? 'icon-display active' : 'icon-display'} onClick={this.handleControlAction} >
        <span className={iconClass} onClick={this.handleControlAction} />
      </button>
    )
  }

  render() {
    const { playlist, song, listIndex } = this.props;

    const songUrl  = buildResourceUrl('song', song.video.name, song.id)
    const imageUrl = formatYoutubeUrl(song.video.youtube_id);

    const genreItems = song.genres ? song.genres : [];
    const totalGenres = this.props.isMobile ? 2 : 3;

    const duration = secondsToDurationString(this.props.duration)

    const genres = genreItems.slice(0, totalGenres).map((g, gIndex) => {
      return (
        <label className='genre-label no-select' key={`song-${this.props.index}-genre-${gIndex}`}>{ g.name }</label>
      )
    })

    const key = this.props.keyMode === 'camelot' ? translateKey(song.key) : (song.key ?  song.key : null)

    return (
      <li className='setlist-item no-select'>

        <div className='img-wrapper'>
          <img src={imageUrl} />
          <div className='duration-overlay'>
            <h6>{ duration }</h6> 
          </div>
        </div>

        <div className='song-details'>
          <div className='song-description'>
            <div className='title'>
              <span>{ song.video ? trimTitle(song.video.name) : '' }</span>
            </div>

            <div className='genres'>
              { genres }
            </div>

            <div className='song-bpm-key'>
              <h6 className='no-select'>
                { `${song.bpm !== null && song.bpm !== undefined ? song.bpm : ''}${ key !== null && key !== undefined ? " " + key : ''}` }
              </h6>
            </div>

          </div>
        </div>

        { this.renderControls() }

        <div className='btn-tools'>
          <button
            className={`btn btn-default set-cover-photo ${this.isCoverPhoto(song) ? 'active-cover-photo' : ''}`}
            disabled={this.isCoverPhoto(song)}
            onClick={(e) => {
              e.preventDefault()

              if(this.isCoverPhoto(song)) {
                return;
              }

              this.props.updatePlaylist(playlist, {youtube_id: song.video.youtube_id})
            }}
          >
            { this.isCoverPhoto(song) ? "Cover Photo" : "Set Cover Photo"}
          </button>

          <button 
            className='btn btn-danger remove-item'
            onClick={(e) => {
              e.preventDefault();
              this.props.removeSongFromPlaylist(playlist, listIndex)
            }}
          >
            X
          </button>
        </div>

      </li>
    );
  }
}

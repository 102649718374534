import React from 'react';
import { connect } from 'react-redux';

import defaultAvatar from 'assets/img/default_avatar'

import ProfileNavItem from 'app/shared/profile/profile-nav-item'
import SquareImage from 'app/shared/dj-wall/square-image'
import FollowButton from 'app/shared/follow-button'
import Country from './country'

import {
  followDJ,
  unFollowDJ
} from 'app/modules/following'

import { buildArtistUrl } from 'lib/artist-url'
import { selectSongForm } from 'app/modules/song'

class DJProfileHeader extends React.Component {
  constructor(props) {
    super(props);

    this.renderBrowser = this.renderBrowser.bind(this)
    this.renderMobile = this.renderMobile.bind(this)
    this.imageUrl = this.imageUrl.bind(this)
    this.toggleFollow = this.toggleFollow.bind(this)
  }

  imageUrl(dj) {
    if (dj && dj.discogs && dj.discogs.uri250) {
      return dj.discogs.uri250
    } else if (dj && dj.discogs && dj.discogs.uri150) {
      return dj.discogs.uri150
    } else {
      return defaultAvatar
    }
  }

  toggleFollow () {
    const dj = this.props.dj

    if (dj.is_followed) {
      this.props.unFollowDJ(dj.id)
      this.props.toggleIsFollowed(dj.id, false)
    } else {
      this.props.followDJ(dj.id)
      this.props.toggleIsFollowed(dj.id, true)
    }
  }

  renderMobile() {
    const { dj, form } = this.props;

    const imageUrl = this.imageUrl(dj)

    return (
      <header className='m6k-panel dj-profile-header'>
        <div className='dj-profile-photo'>
          <SquareImage urlPath={imageUrl} />
        </div>

        <div className='dj-profile-panel'>
          <div className='profile-info-short'>
            <h1>{dj.name}</h1>
            { dj.country.name ? <Country {...dj.country} /> : null }
            <FollowButton isFollowed={dj.is_followed} onClick={this.toggleFollow} />
          </div> 
        </div>
      </header>
    )
  }

  renderBrowser() {
    const { dj, form } = this.props;

    const imageUrl = this.imageUrl(dj)

    const baseProps = {
      from: form.from,
      to: form.to,
      slug: form.slug,
      id: form.id,
      bpm_min: form.bpm_min,
      bpm_max: form.bpm_max,
      only_bpm: form.only_bpm, 
    } 

    return (
      <header className='m6k-panel dj-profile-header'>
        <div className='dj-profile-photo'>
          <SquareImage urlPath={imageUrl} />
        </div>

        <div className='dj-profile-panel'>
          <div className='profile-info-short'>
            <h1>{dj.name}</h1>
            <FollowButton 
              isPublic={this.props.isPublic} 
              openLoginModal={this.props.openLoginModal}
              isFollowed={dj.is_followed} 
              onClick={this.toggleFollow} 
            />
            { dj.country.name ? <Country {...dj.country} /> : null }
          </div> 

          <ul className='dj-nav'>
            <ProfileNavItem
              name='Top Played'
              {...buildArtistUrl(Object.assign({}, baseProps, {
                mode: 'hits'
              }))}
            />
          </ul>
        </div>
      </header>
    )
  }

  render() {
    if(this.props.isMobile) {
      return this.renderMobile()
    } else {
      return this.renderBrowser()
    }
  }
}

const mapStateToProps = (state) => {
  return {
    form: selectSongForm(state)
  }
}

export default connect(mapStateToProps, {
  followDJ,
  unFollowDJ 
})(DJProfileHeader); 
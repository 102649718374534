import React from 'react';
import { Helmet } from 'react-helmet'
import { isBrowser } from "react-device-detect";
import NavLink from 'app/components/nav-link'
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchUserProfile, selectProfile, isCurrentUser } from 'app/modules/profile'

import {
  selectCurrentUser
} from 'app/modules/current-user/account'

import Favorites from './favorites'
import Playlists from './playlists'
import Following from './following'
import History from './history'

const mapStateToProps = (state) => {
  return {
    currentUser: selectCurrentUser(state)
  }
}

class Library extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      title: 'Library',
      isLoading: true,
      notFound: false,
    }
  }

  componentDidMount() {
    if(!this.props.currentUser) return;

    this.fetchUserProfile(this.props.currentUser.username)
  }

  componentDidUpdate(prevProps, prevState) {
    if((!prevProps.currentUser || !prevProps.currentUser.username) 
      && this.props.currentUser && this.props.currentUser.username) {
      this.fetchUserProfile(this.props.currentUser.username)
    }
  }

  fetchUserProfile(username) {
    this.props.fetchUserProfile(username)
      .then(() => {
        this.setState({
          isLoading: false,
          notFound: false,
        })
      })
      .catch((e) => {
        console.log('error', e)
        this.setState({
          isLoading: false, 
          notFound: true
        })
      })
  }

  renderNavTabs() {
    return (
      <div className='m6k-tabs-container'>
        <div className='container-fluid'>
          <ul className='m6k-nav-list'>
            <NavLink 
              to='/library/favorites'
            >Favorites</NavLink>
            <NavLink 
              to='/library/playlists'
            >Playlists</NavLink>
            <NavLink 
              to='/library/following'
            >Following</NavLink>
            <NavLink 
              to='/library/history'
            >History</NavLink>
          </ul>
        </div>
      </div>
    )
  }

  renderBody() {
    if(this.state.isLoading) {
      return null;
    }

    return (
      <div className='container-fluid'>
        <Switch>
          <Route path="/library/favorites" component={Favorites} />
          <Route path="/library/playlists" component={Playlists} />
          <Route path="/library/following" component={Following} />
          <Route path="/library/history" component={History} />
          <Redirect from='*' to='/library/favorites' />
        </Switch>
      </div>
    )
  }
 
  render() {
    return (
      <div>
        <Helmet>
          <title>{ this.state.title }</title>
        </Helmet>

        { isBrowser ? this.renderNavTabs() : null }
        { this.renderBody() }
      </div>
    );
  }
}
export default connect(mapStateToProps, {
  fetchUserProfile
})(Library) 
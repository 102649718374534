import React, { Component } from 'react'

class GenreTag extends Component {
  render () {
    return (
      <span className='genre-label label-default'>
      	<span className='name'>
	        {this.props.name}
        </span>
      </span>
    )
  }
}

export default GenreTag

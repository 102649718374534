export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  
  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  for(var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
  }



  return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}
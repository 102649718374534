import React from 'react';
import ClickHandler from 'components/click-handler'
import './index.scss'

export default class MiniFooter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const currentYear = new Date().getFullYear()
    const copyrightText = `\u00A9 ${currentYear} `
    
    return (
      <div className='footer-wrapper'>
        <div className='mini-footer'>
          <nav className='nav-bar'>
            <div className='navbar-header'>
              <div className='navbar-brand'>
                {copyrightText}
                <span className='title'>Mu6ik</span>
              </div>
            </div>

            <ul className="nav navbar-nav">
              <li>
                <ClickHandler url='/terms'>
                  Terms of Service
                </ClickHandler>
              </li>
              <li>
                <ClickHandler url='/privacy-policy'>
                  Privacy
                </ClickHandler>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

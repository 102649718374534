import axios from 'lib/axios-config'
import { push, replace } from 'connected-react-router'

export function clearSuggestions () {
  return (dispatch, getState) => {
    const suggestions = getState().autocomplete.results
    if (suggestions.length == []) {
      return
    }

    dispatch({
      type: 'CLEAR_SUGGESTIONS'
    })
  }
}

export function getNameSuggestions (value) {
  return (dispatch, getState) => {
    const autocomplete = getState().autocomplete
    const isFetching = autocomplete.isFetching
    const url = `/api/artists/${value}/autocomplete`

    if (isFetching == true || value == null || value == '') {
      return Promise.reject();
    }

    dispatch({
      type: 'FETCH_SUGGESTIONS'
    })

    const name = getState().autocomplete.input

    return axios.get(url).then((response) => {
      dispatch({
        type: 'FETCH_SUGGESTIONS_SUCCESS',
        results: response.data
      })

      return Promise.resolve()
    }).catch((error) => {
      dispatch({
        type: 'FETCH_SUGGESTIONS_FAILURE',
        error: error.message || 'Something went wrong'
      })

      return Promise.reject()
    })
  }
}

export const selectNameSuggestions = (state) => {
  return state.autocomplete.results
}

export function gotoUrl (url) {
  return (dispatch, getState) => {
    dispatch(push(url))
  }
}

const defaultRequestState = {
  isFetching: false,
  didInvalidate: false,
  error: null,
  results: []
}

const requestReducer = (state = defaultRequestState, action) => {
  switch (action.type) {
    case 'FETCH_SUGGESTIONS':
      return {
        ...state,
        isFetching: true
      }
    case 'FETCH_SUGGESTIONS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        results: action.results
      }
    case 'FETCH_SUGGESTIONS_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error
      }
    case 'CLEAR_SUGGESTIONS':
      return {
        ...state,
        results: []
      }
    default:
      return state
  }
}

export default requestReducer

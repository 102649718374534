import React from 'react'

import { sendResetEmail } from '../../../modules/password'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import Loading from 'react-loading'

import MiniFooter from 'components/mini-footer'

import queryString from 'query-string'

export class SendReset extends React.Component {
  constructor (props) {
    super(props)

    this.renderLoading = this.renderLoading.bind(this)
    this.renderForm = this.renderForm.bind(this)
  }

  componentDidMount () {
    const urlParams = queryString.parse(this.props.location.search)

    if (urlParams.email) {
      this.email.value = urlParams.email
    }
  }

  renderLoading () {
    return (
      <div className='loading'>
        <Loading
          type='spin'
          color='#aaa'
          delay={0}
          height={65}
          width={65}
        />
      </div>
    )
  }

  renderForm () {
    let inputClass = 'form-control'
    let errorMessage = null

    return (
      <div>
        <h3>Forgot your password?</h3>
        <p>Enter the email address you used when you joined and we’ll send you instructions to reset your password.</p>
        <form onSubmit={(e) => {
          e.preventDefault()
          if (this.email.value === null || this.email.value === '') return

          this.props.sendResetEmail(this.email.value)
        }}>
          <div className='form-group'>
            <input
              type='text'
              className={inputClass}
              placeholder='Email'
              ref={(email) => this.email = email} />
          </div>
          <button type='submit' className='btn btn-default reset'>Send Reset Instructions</button>
        </form>
      </div>
    )
  }

  render () {
    return (
      <div className='reset-password-page'>
        <Helmet>
          <title>Mu6ik / Reset Password</title>
        </Helmet>

        <div className='reset-password-panel'>
          <h1>Mu6ik</h1>
          {
            this.props.isFetching
            ? this.renderLoading()
            : this.renderForm()
          }
        </div>
        <MiniFooter />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isFetching: state.password.isFetching
  }
}

export default connect(mapStateToProps, {
  sendResetEmail
})(SendReset)

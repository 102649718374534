import React from 'react';

import ProfileNavItem from './profile-nav-item'
import ProfilePhoto from './profile-photo' 
import FriendButton from 'app/shared/friend-button'
import { Glyphicon, Dropdown, DropdownButton, MenuItem} from 'react-bootstrap'

import BlockUserModal from './block-user-modal';
import ReportUserModal from './report-user-modal';

export default class ProfileHeader extends React.Component {
	constructor(props) {
		super(props);

		this.handleClickNavItem = this.handleClickNavItem.bind(this)

		this.blockUser = this.blockUser.bind(this)
		this.reportUser = this.reportUser.bind(this)
		this.renderFriendButton = this.renderFriendButton.bind(this)

		this.state = {
			currentNavItem: 'Favorites',
			blockUserModalVisibile: false,
			reportUserModalVisibile: false,
		}
	}

	handleClickNavItem(itemName) {
		this.setState({currentNavItem: itemName})
	}

	blockUser(friendUUID) {
		this.props.blockUser(friendUUID)
			.then(() => {
				this.setState({blockUserModalVisibile: false})
				this.props.setNoticeMessage("This user has been blocked")
			})
	}

	reportUser(friendUUID, flagType) {
		this.props.reportUser(friendUUID, flagType)
			.then(() => {
				this.setState({reportUserModalVisibile: false})
				this.props.setNoticeMessage("Thank you for helping us keep the community safe. If the user has violated our terms of service, we will take appropriate action.")
			})
	}

	renderFriendButton() {
		return (
			<div className='btn-group profile-menu-options'>
				<FriendButton 
					user={this.props.profile}
					acceptFriendRequest={this.props.acceptFriendRequest}
          cancelFriendRequest={this.props.cancelFriendRequest}
          sendFriendRequest={this.props.sendFriendRequest}
          unfriend={this.props.unfriend}
				/>
        <Dropdown id={`more-options`} pullRight>
          <Dropdown.Toggle
            noCaret
            title={'More'}
          >
            <Glyphicon glyph="option-horizontal" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="super-colors">
            <MenuItem eventKey="1" onClick={(e) => {
            	this.setState({reportUserModalVisibile: true})
              e.preventDefault()
            }}>Report User</MenuItem>
           	<MenuItem eventKey="2" onClick={(e) => {
           		this.setState({blockUserModalVisibile: true})
              e.preventDefault()
            }}>Block User</MenuItem>
          </Dropdown.Menu>
        </Dropdown>
			</div>
		)	
	}

	render() {
		const { username, uuid } = this.props.profile

		return (
			<header className='m6k-panel profile-header'>
				{
					this.state.blockUserModalVisibile ? 
						<BlockUserModal 
							name={this.props.profile.name}
							visible={this.state.blockUserModalVisibile}
							uuid={uuid}
							blockUser={this.blockUser}
							closeModal={() => {
								this.setState({blockUserModalVisibile: false})
							}}
						/> : null 
				}

				{
					this.state.reportUserModalVisibile ?
						<ReportUserModal
							visible={this.state.reportUserModalVisibile}
							uuid={uuid}
							reportUser={this.reportUser}
							closeModal={() => {
								this.setState({reportUserModalVisibile: false})
							}}
						/> : null
				}

				<div className='profile-picture-border'>
					<ProfilePhoto 
						isCurrentUser={this.props.isCurrentUser}
						photo={this.props.profile.profile_photo}
					/>	
				</div>

				<div className='profile-panel'>
					<div className='profile-info-short'>
						<h3 className='name'>{this.props.displayName}</h3>
						{ this.props.isMobile || this.props.isCurrentUser ? null : this.renderFriendButton() }
						<h5 className='location'>{this.props.profile.location || ""}</h5>
						{ this.props.isMobile && !this.props.isCurrentUser ? this.renderFriendButton() : null }
					</div>

					<ul className='profile-nav'>
						<ProfileNavItem 
							username={username}
							name='Favorites' 
							count={this.props.profile.favorites_count}
						/>

						<ProfileNavItem 
							username={username}
							name='Playlists' 
							count={this.props.profile.playlists_count}
						/>

						<ProfileNavItem
							username={username}
							name='Following'
							count={this.props.profile.following_count}
						/>

						<ProfileNavItem
							username={username}
							name='Friends'
							count={this.props.profile.friends_count}
						/>
					</ul>
				</div>
			</header>
		);
	}
}

import React from 'react'
import ReactDOM from 'react-dom'
import addEventListener from 'rc-util/lib/Dom/addEventListener'
import debounce from 'lodash/debounce'

function pauseEvent (e) {
  if (e.stopPropagation) e.stopPropagation()
  if (e.preventDefault) e.preventDefault()
}

function getMousePosition (e) {
  return e.clientY
}

class VolumePopover extends React.Component {
  constructor (props) {
    super(props)

    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.getSliderLength = this.getSliderLength.bind(this)
    this.isMouseOutside = this.isMouseOutside.bind(this)

    this.state = {
      value: props.value
    }
  }

  getSliderLength () {
    if (this.sliderLength) {
      return this.sliderLength
    }

    const slider = this.refs.slider

    return (this.sliderLength = slider.clientHeight)
  }

  getSliderStart () {
    if (this.sliderStart) {
      return this.sliderStart
    }

    const slider = this.refs.slider
    const rect = slider.getBoundingClientRect()

    return (this.sliderStart = rect.top)
  }

  addDocumentEvents () {
    this.onMouseMoveListener = addEventListener(document, 'mousemove', this.onMouseMove.bind(this))
    this.onMouseUpListener = addEventListener(document, 'mouseup', this.onMouseUp.bind(this))
  }

  removeDocumentEvents () {
    if (this.onMouseMoveListener) {
      this.onMouseMoveListener.remove()
    }

    if (this.onMouseUpListener) {
      this.onMouseUpListener.remove()
    }
  }

  componentWillUnmount () {
    this.removeDocumentEvents()
  }

  calcValue (offset) {
    const ratio = Math.abs(offset / this.getSliderLength())
    const value = (1 - ratio) * (1 - 0) + 0

    return value
  }

  calcValueByPos (position) {
    const pixelOffset = position - this.getSliderStart()

    return this.calcValue(pixelOffset)
  }

  onMouseDown (e) {
    this.props.setAdjusting(true)

    const position = getMousePosition(e)
    const value = this.onStart(position)

    this.props.setVolume(value * 100)
    this.addDocumentEvents()
    pauseEvent(e)
  }

  onStart (position) {
    const value = this.calcValueByPos(position)
    this.startValue = value
    this.startPosition = position

    this.setState({
      sliderValue: value
    })

    return value
  }

  onMouseMove (e) {
    const position = getMousePosition(e)
    pauseEvent(e)

    const value = this.onMove(position)

    this.props.setVolume(value * 100)
    this.setState({
      sliderValue: value
    })
  }

  onMove (position) {
    let diffPosition = position - this.startPosition
    diffPosition = -diffPosition

    const diffValue = diffPosition / this.getSliderLength()
    const value = this.startValue + diffValue

    return value > 1 ? 1 : (value < 0 ? 0 : value)
  }

  isMouseOutside (e) {
    const domNode = ReactDOM.findDOMNode(this)
    return (!domNode || !domNode.contains(e.target))
  }

  onMouseUp (e) {
    pauseEvent(e)
    this.removeDocumentEvents()

    this.props.setAdjusting(false)

    if (this.isMouseOutside(e)) {
      this.props.onBlur()
    }
  }

  render () {
    const value = this.props.isAdjusting ? this.state.sliderValue : (
        this.props.isMuted ? 0 : this.props.value)

    return (
      <div className='volume-popover'
        onMouseDown={this.onMouseDown}
      >
        <div
          className='volume-slider'
          ref='slider'
        >
          <div
            className='volume-slider-fill volume-slider-level'
            style={{transform: `scaleY(${value})`}}
           />
        </div>
      </div>
    )
  }
}

export default class VolumeControl extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isExpanded: false
    }

    this.isActive = false
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)

    this.delayedOnFocus = debounce(this.onFocus, 150)
    this.delayedOnBlur = debounce(this.onBlur, 125)
    this.processEvent = false
  }

  toggleSelector (state) {
    if (state) {
      if (this.processEvent) {
        this.delayedOnBlur.cancel()
        this.processEvent = false
      } else {
        this.processEvent = true
        this.delayedOnFocus()
      }
    } else {
      if (this.processEvent) {
        this.delayedOnFocus.cancel()
        this.processEvent = false
      } else {
        this.processEvent = true
        this.delayedOnBlur()
      }
    }
  }

  onFocus () {
    this.setState({isExpanded: true})

    const node = ReactDOM.findDOMNode(this)
    node.focus()

    this.processEvent = false
  }

  onBlur () {
    if (this.isActive) return

    this.setState({isExpanded: false})

    const node = ReactDOM.findDOMNode(this)
    node.blur()
    this.processEvent = false
  }

  render () {
    const icon = (this.props.isMuted || this.props.volume === 0)
      ? 'glyphicon-volume-off' : (this.props.volume <= 50
      ? 'glyphicon-volume-down' : 'glyphicon-volume-up')

    return (
      <div
        className='volume-control'
        onMouseLeave={() => {
          this.toggleSelector(false)
        }}
        onMouseEnter={() => {
          this.toggleSelector(true)
        }}
      >
        {
          this.state.isExpanded
          ? <VolumePopover
            isMuted={this.props.isMuted}
            value={this.props.volume / 100}
            setVolume={(v) => {
              this.props.setVolume(v)

              if (this.props.isMuted) {
                this.props.unMute()
              }
            }}
            onBlur={this.onBlur}
            setAdjusting={(isAdjusting) => {
              this.isActive = isAdjusting
            }}
          />
          : null
        }

        <span className={`volume-icon glyphicon ${icon}`} onClick={(e) => {
          pauseEvent(e)
          if (this.props.volume === 0) {
            this.props.unMute()
            this.props.setVolume(50)
          } else if (this.props.isMuted) {
            this.props.unMute()
          } else {
            this.props.mute()
          }
        }} />
      </div>
    )
  }
}

import React from 'react';
import Helmet from 'react-helmet';

import djVinyl from 'assets/img/dj-vinyl-dark.jpg';
import mu6ikFeature  from 'assets/img/mu6ik-feature-half.png'; 

import '../styles/home.scss'; 
import Footer from 'components/footer/footer'
import Header from '../components/header'

export default class Home extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='home'>
        <Helmet>
          <title>Mu6ik - Discover songs, djs and mixes from over 50,000 professional djs and 500,0000 mixes</title>
        </Helmet>

        <section className='hero-section'>
          <img src={djVinyl} className='hero-image'/>

          <div className='hero-overlay'>
            <h2>Discover Electronic Music</h2>
            <h3>We've analyzed over half a million sets from DJs worldwide so that you can find the best music possible.</h3>
          </div>
        </section>

        <section className='summary-section'>
          <div className='container'>
            <h2>Reduce time spent looking for unique songs for your next show</h2>

            <img src={mu6ikFeature} />
          </div>
        </section>

        <section className='feature-section'>

        </section>

        <section className='about-section'>

        </section>
      </div>
    );
  }
}

import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import SendReset from '../routes/send-reset'
import UpdatePassword from '../routes/update-password'

export default class PasswordRouting extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <Switch>
        <Route exact path='/reset_password/new' component={SendReset} />
        <Route exact path='/reset_password/edit' component={UpdatePassword} />
        <Route from='*' to='login' />
      </Switch>
    )
  }
}

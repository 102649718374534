import React from 'react';

import  { connect } from 'react-redux'
import { titleCase } from 'lib/string'

import {
  selectPlaylist,
  selectUserPlaylistPermission,
  fetchPlaylist,
  updateUserPlaylistPermission,
} from 'app/modules/playlists'

import { getUrlFromPhoto } from 'lib/user'

import DeleteCollaboratorModal from './delete-collaborator-modal'
import AddCollaboratorModal from './add-collaborator-modal'
import ClickHandler from 'components/click-handler'

import { DropdownButton, MenuItem } from 'react-bootstrap'

const mapStateToProps = (state) => {
  return {
    playlist: selectPlaylist(state),
    userPlaylistPermission: selectUserPlaylistPermission(state),
  }
}

class PlaylistCollaborators extends React.Component {
  constructor(props) {
    super(props);

    this.renderHeader = this.renderHeader.bind(this)
    this.renderAddCollabButton = this.renderAddCollabButton.bind(this)
    this.renderBody = this.renderBody.bind(this)
    this.renderViewer = this.renderViewer.bind(this)
    this.renderAdmin = this.renderAdmin.bind(this);
    this.renderOwner = this.renderOwner.bind(this)

    this.renderInfoRow = this.renderInfoRow.bind(this)
    this.renderEditingRow = this.renderEditingRow.bind(this)

    this.isEditing = this.isEditing.bind(this)

    this.closeModal = this.closeModal.bind(this)
    this.closeDeleteModal = this.closeDeleteModal.bind(this)

    this.selectPermission = this.selectPermission.bind(this)
    this.cancelEdit = this.cancelEdit.bind(this)
    this.updateUpp = this.updateUpp.bind(this)

    this.setUserToDelete = this.setUserToDelete.bind(this)

    this.state = {
      showModal: false,
      showDeleteModal: false,
      userIsEditing: {},
      editingState: {},
      uppToDelete: null,
    }
  }

  closeModal() {
    this.setState({showModal: false})
  }

  closeDeleteModal() {
    this.setState({showDeleteModal: false, uppToDelete: null})
  }

  setUserToDelete(upp) {
    this.setState({
      uppToDelete: upp,
      showDeleteModal: true,
    })
  }

  renderEditButton(upp) {
    return (
      <div className='edit-collab'>
        <button 
          className='btn btn-default'
          onClick={(e) => {
            e.preventDefault();

            this.setState({
              userIsEditing: {
                ...this.state.userIsEditing,
                [upp.user.id]: true,
              },
              editingState: {
                ...this.state.editingState,
                [upp.id]: {
                  ...upp,
                }
              }
            })
          }}
        >
          Edit Collaborator
        </button>
      </div>
    )
  }

  isEditing(upp) {
    return this.state.userIsEditing[upp.user.id] 
  }

  renderViewer(upp, index) {
    const user = upp.user
    const url = getUrlFromPhoto(user.profile_photo)
    
    return (
      <li key={`user-${index}`} className='member' >
        <img src={url} className='profile-photo' />

        <div className='info'>
          <h3>
            <ClickHandler url={`/${user.username}`} newWindow >
              { user.display_name }
            </ClickHandler>
          </h3>

          <h4>
            { titleCase(upp.permission_level) }
          </h4>
        </div>
      </li>
    )
  }

  isActive(upp, level) {
    return level === upp.permission_level 
  }

  permissionTitle(upp) {
    return titleCase(upp.permission_level)
  }

  cancelEdit(upp) {
    this.setState({
      userIsEditing: {
        ...this.state.userIsEditing,
        [upp.user.id]: false,
      },
      editingState: {
        ...this.state.editingState,
        [upp.user.id]: null,
      }
    })
  }

  updateUpp(upp) {
    const { playlist } = this.props;

    this.props.updateUserPlaylistPermission({ ...upp, playlist })
      .then(() => {
        this.cancelEdit(upp)
      })
      .catch(() => {})
  }

  selectPermission(upp, level) {
    this.setState({
      editingState: {
        ...this.state.editingState,
        [upp.id]: {
          ...upp,
          permission_level: level
        }
      }
    })
  }

  renderEditingRow(upp, index) {
    const editUpp = this.state.editingState[upp.id]

    const user = editUpp.user
    const url = getUrlFromPhoto(user.profile_photo)

    return (
      <li key={`user-${index}`} className='edit-member member'>
        <img src={url} className='profile-photo' />

        <div className='info'>
          <h3>
            { user.display_name }
          </h3>

          <DropdownButton 
            title={this.permissionTitle(editUpp)} 
            id="user-permission"
          >
            <MenuItem 
              eventKey="admin" 
              onSelect={(k) => {
                this.selectPermission(editUpp, k)
              }} 
              className={this.isActive(editUpp, 'admin')} 
            >
              Admin
            </MenuItem>

            <MenuItem 
              eventKey="editor" 
              onSelect={(k) => {
                this.selectPermission(editUpp, k)
              }} 
              className={this.isActive(editUpp, 'editor')} 
            >
              Editor
            </MenuItem>

            <MenuItem 
              eventKey="viewer" 
              onSelect={(k) => {
                this.selectPermission(editUpp, k)
              }} 
              className={this.isActive(editUpp, 'viewer')} 
            >
              Viewer
            </MenuItem>
          </DropdownButton>
        </div>

        <div className='btn-group'>
          <button 
            className='btn btn-default'
            onClick={(e) => {
              e.preventDefault();
              this.setUserToDelete(editUpp)
            }}
          >
            Remove
          </button>

          <button 
            className='btn btn-default'
            onClick={(e) => {
              e.preventDefault()
              this.cancelEdit(editUpp)
            }}
          >
            Cancel
          </button>

          <button 
            className='btn btn-default'
            onClick={(e) => {
              e.preventDefault();
              this.updateUpp(editUpp)
            }}
          >
            Save
          </button>
        </div>
      </li>
    )
  }

  renderInfoRow(upp, index) {
    const user = upp.user
    const url = getUrlFromPhoto(user.profile_photo)

    return (
      <li key={`user-${index}`} className='member' >
        <img src={url} className='profile-photo' />

        <div className='info'>
          <h3>
            <ClickHandler url={`/${user.username}`} newWindow >
              { user.display_name }
            </ClickHandler>
          </h3>

          <h4>
            { titleCase(upp.permission_level) }
          </h4>
        </div>

        { this.renderEditButton(upp) }
      </li>
    )
  }

  renderAdmin(upp, index) {
    return upp.permission_level === 'owner' ?
      this.renderViewer(upp, index) : 
      (
        this.isEditing(upp) ? 
          this.renderEditingRow(upp, index) :
          this.renderInfoRow(upp, index)
      )
  }

  renderOwner(upp, index) {
    return this.isEditing(upp) ? 
      this.renderEditingRow(upp, index) : 
      this.renderInfoRow(upp, index)
  }

  renderAddCollabButton() {
    return (
      <div className='col-md-4 col-sm-4 col-xs-4'>
        <button 
          className='btn btn-default add-collab'
          onClick={(e) => {
            this.setState({showModal: true})
          }}
        >
          Add Collaborators 
        </button>
      </div>
    )
  }

  renderHeader() {
    const currentUpp = this.props.userPlaylistPermission;

    return (
      <div className='row heading'>
        <div className='col-md-8 col-sm-8 col-xs-8'>
          <h3>Collaborators</h3>
        </div>

        {
          currentUpp.permission_level === 'owner' || currentUpp.permission_level === 'admin' ?
            this.renderAddCollabButton() : null
        }

      </div>
    )
  }

  renderBody() {
    const currentUpp = this.props.userPlaylistPermission;
    const upps = this.props.playlist.user_playlist_permissions;

    const users =
      upps.map((upp, index) => {
        switch(currentUpp.permission_level) {
          case 'owner':
            return this.renderOwner(upp, index);
          case 'admin':
            return this.renderAdmin(upp, index);
          case 'editor':
          case 'viewer':
            return this.renderViewer(upp, index);
          default:
            return this.renderViewer(upp, index);
        } 
      })

    return (
      <ul className='collaborator-list'>
        { users }
      </ul>
    )
  }

  render() {
    return (
      <div className='m6k-panel collaborators'>
        { this.state.showModal ? 
            <AddCollaboratorModal
              playlist={this.props.playlist}
              show={this.state.showModal}
              onCancel={this.closeModal} 
              fetchPlaylist={this.props.fetchPlaylist}
            /> : null
        }

        {
          this.state.showDeleteModal ?
            <DeleteCollaboratorModal
              playlist={this.props.playlist}
              show={this.state.showDeleteModal}
              onCancel={this.closeDeleteModal}
              upp={this.state.uppToDelete}
            /> : null
        }

        { this.renderHeader() }

        { this.renderBody() }
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  fetchPlaylist,
  updateUserPlaylistPermission,
})(PlaylistCollaborators)

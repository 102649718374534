import axios from 'lib/axios-config'
import compact from 'lodash/compact'
import { following as followingSchema, followings as followingsSchema } from 'app/schemas/main'
import { normalize } from 'normalizr'

export function getFollowedDJs (user_identifier, feed = false) {
  return (dispatch, getState) => {
    const follow = getState().following
    const url = `/api/followed_artists${feed ? '/feed' : ''}`

    if (follow.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: 'FETCH_FOLLOWED_DJS_REQUEST'
    })

    return axios.get(url, { 
      params: {
        user_identifier,
      }
    })
      .then((response) => {
        const normalized = normalize(response.data, followingsSchema)

        dispatch({
          type: 'FETCH_FOLLOWED_DJS_SUCCESS',
          payload: {
            results: response.data,
            entities: normalized.entities,
          }
        })

        return  Promise.resolve(response.data)
      })
      .catch((error) => {
        console.error(error);

        dispatch({
          type: 'FETCH_FOLLOWED_DJS_FAILURE',
          payload: {
            error: error.response.data
          }
        })

        return Promise.reject()
      })
  }
}

export function getFollowedDJ (artistId) {
  return (dispatch, getState) => {
    const follow = getState().following
    const url = `/api/followed_artists/${artistId}`

    if (follow.isFetching || artistId === null) {
      return Promise.reject()
    }

    dispatch({
      type: 'FETCH_FOLLOWED_DJ_REQUEST'
    })

    return axios.get(url)
      .then((response) => {
        dispatch({
          type: 'FETCH_FOLLOWED_DJ_SUCCESS',
          payload: {
            follow: response.data
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_FOLLOWED_DJ_FAILURE',
          payload: {
            error: error.response.data
          }
        })
      })
  }
}

export function followDJ (artistId, mode = 'ADD', id = null) {
  return (dispatch, getState) => {
    const follow = getState().following
    const url = `/api/followed_artists`

    if (follow.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: `${mode}_FOLLOW_DJ_REQUEST`
    })

    return axios.post(url, {
      artist_id: artistId
    }).then((response) => {
      dispatch({
        type: `${mode}_FOLLOW_DJ_SUCCESS`,
        payload: {
          newFollow: response.data,
          id
        }
      })
    }).catch((error) => {
      dispatch({
        type: `${mode}_FOLLOW_DJ_FAILURE`,
        payload: {
          error: error.response.data
        }
      })
    })
  }
}

export function unFollowDJ (artistId, mode = 'REMOVE') {
  return (dispatch, getState) => {
    const following = getState().following
    const url = `/api/followed_artists/${artistId}`

    let index = -1
    let followId = null

    for (var i = 0; i < following.results.length; i++) {
      const follow = following.results[i]
      followId = follow.artist.id
      if (followId === artistId) {
        index = i
        break
      }
    }

    if (following.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: `${mode}_UNFOLLOW_DJ_REQUEST`
    })

    return axios.delete(url)
    .then((response) => {
      dispatch({
        type: `${mode}_UNFOLLOW_DJ_SUCCESS`,
        payload: {
          index,
          id: followId
        }
      })
    }).catch((error) => {
      dispatch({
        type: `${mode}_UNFOLLOW_DJ_FAILURE`,
        payload: {
          error: error.response.data
        }
      })
    })
  }
}

export const updateDJSearch = (value) => {
  return {
    type: 'UPDATE_DJ_SEARCH',
    payload: {
      value
    }
  }
}

export const selectFollowingDJs = (state) => {
  let results = state.following.results;

  const filter = state.following.search || ''
  const useFilter = filter !== ''

  results = results.map((result) => {
    if (result.is_following && (!useFilter || result.artist.name.toLowerCase().startsWith(filter.toLowerCase()))) {
      return {
        ...result.artist,
        ...state.entities.artists[result.artist.id],
      }
    }
  })

  results = compact(results);
  results = results.sort((a, b) => {
    let nameA = a.name.toLowerCase()
    let nameB = b.name.toLowerCase()

    if (nameA < nameB) { return -1 }
    if (nameA > nameB) { return 1 }

    return 0 // default return value (no sorting));
  })

  return results;
}

const defaultState = {
  results: [],
  isFetching: false,
  didInvalidate: false,
  search: '',
  error: null
}

const following = (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_FOLLOWED_DJS_REQUEST':
    case 'FETCH_FOLLOWED_DJ_REQUEST':
    case 'ADD_FOLLOW_DJ_REQUEST':
    case 'REMOVE_UNFOLLOW_DJ_REQUEST':
    case 'TOGGLE_FOLLOW_DJ_REQUEST':
    case 'TOGGLE_UNFOLLOW_DJ_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: null,
        didInvalidate: false
      }
    case 'FETCH_FOLLOWED_DJS_FAILURE':
    case 'FETCH_FOLLOWED_DJ_FAILURE':
    case 'ADD_FOLLOW_DJ_FAILURE':
    case 'REMOVE_UNFOLLOW_DJ_FAILURE':
    case 'TOGGLE_FOLLOW_DJ_FAILURE':
    case 'TOGGLE_UNFOLLOW_DJ_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'FETCH_FOLLOWED_DJS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        results: action.payload.results
      }
    case 'FETCH_FOLLOWED_DJ_SUCCESS':
      const results = state.results

      if (results.find(item => item.id === action.payload.follow.id)) {
        return state
      }

      return {
        ...state,
        isFetching: false,
        results: [...state.results, action.payload.follow]
      }
    case 'ADD_FOLLOW_DJ_SUCCESS':
      return {
        ...state,
        isFetching: false,
        results: [...state.results, action.payload.newFollow]
      }
    case 'REMOVE_UNFOLLOW_DJ_SUCCESS':
      if(action.payload.index === -1) {
        return { ...state, isFetching: false }
      } else {
        return {
          ...state,
          isFetching: false,
          results: [
            ...state.results.slice(0, action.payload.index),
            ...state.results.slice(action.payload.index + 1)
          ]
        }
      }
    case 'TOGGLE_FOLLOW_DJ_SUCCESS':
      return {
        ...state,
        isFetching: false,
        results: state.results.map((result) => {
          if (result.id !== action.payload.id) {
            return result
          }

          return {
            ...result,
            is_following: true
          }
        })
      }
    case 'TOGGLE_UNFOLLOW_DJ_SUCCESS':
      return {
        ...state,
        isFetching: false,
        results: state.results.map((result) => {
          if (result.id !== action.payload.id) {
            return result
          }

          return {
            ...result,
            is_following: false
          }
        })
      }
    case 'UPDATE_DJ_SEARCH':
      return {
        ...state,
        search: action.payload.value
      }
    default:
      return state
  }
}

export default following

import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import NavLink from 'app/components/nav-link'

import SearchDJs from './djs/search-djs'
import SearchUsers from './users/search-users'
import SearchSongs from './songs/search-songs'

import { isBrowser } from "react-device-detect";

import { 
  setSearchMode, 
  submitSearch,
  setSearchQuery,
  updateUrl,
  updatePaginationPage,
  paramsOnLoad,
} from 'app/modules/search'

import {
  fetchGenres,
} from 'app/modules/genres'

import {
  fetchCountries,
} from 'app/modules/countries'

import {
  searchCitiesById
} from 'app/modules/cities'

import queryString from 'query-string'

import '../styles/search.scss';

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.setupSearch = this.setupSearch.bind(this)
    this.setTitle = this.setTitle.bind(this)
    this.renderNavTabs = this.renderNavTabs.bind(this)

    this.state = {
      title: 'Mu6ik Search',
      isInitialFetching: true,
      q: this.props.q,
    }

    this.setTitle(this.props.q)
  }

  setTitle(q) {
    const title = q ? `${q} - Mu6ik Search` : 'Mu6ik Search'
    this.setState({ title, q })
  }

  componentDidMount() {
    const urlParams = queryString.parse(this.props.location.search)
    const tokens = this.props.location.pathname.split("/");
    let mode = tokens[tokens.length - 1]

    if(mode === null || mode === 'search') {
      mode = 'djs'
    }

    this.props.setSearchMode(mode)
    this.props.paramsOnLoad(urlParams)

    this.props.fetchGenres().then(() => {
      this.props.fetchCountries().then(() => {
        if(this.props.cities && this.props.cities.length > 0) {
          this.props.searchCitiesById(this.props.cities).then(() => {

            this.props.submitSearch()
              .then(() => {
                this.setState({isInitialFetching: false})
              })
              .catch(() => {})    
          })
        } else {
          this.props.submitSearch()
            .then(() => {
              this.setState({isInitialFetching: false})
            })
            .catch(() => {})
        }
      })
    }).catch(() => {})
  }

  componentWillUnmount() {
    this.props.setSearchQuery('')
    this.props.updatePaginationPage(1)
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.isFetching === true && this.props.isFetching === false) {
      this.setTitle(this.props.q)
    }
  }

  setupSearch(mode) {
    if(mode === this.props.mode) return;
   
    this.props.setSearchMode(mode);
    this.props.submitSearch()
      .then(() => {})
      .catch(() => {})
  }

  renderNavTabs() {
    const search = this.props.location.search;

    return (
      <div className='search-tabs-container'>
        <div className='container-fluid'>
          <ul className='search-nav-list'>
            <NavLink 
              onClick={() => { this.setupSearch('djs') }} 
              to={'/search/djs' + search}
            >DJs</NavLink>
            <NavLink 
              onClick={() => { this.setupSearch('songs') }} 
              to={'/search/songs' + search}
            >Songs</NavLink>
            <NavLink 
              onClick={() => { this.setupSearch('people') }} 
              to={'/search/people' + search}
            >People</NavLink>
          </ul>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className='search-container'>
        <Helmet>
          <title>{ this.state.title }</title>
        </Helmet>

        { isBrowser ? this.renderNavTabs() : null }

        <div className='search-results'>
          <div className='container-fluid'>
            <Switch>
              <Route path='/search/djs' render={(props) => {
                return (
                  <SearchDJs 
                    {...props} 
                    q={this.state.q} 
                    isInitialFetching={this.state.isInitialFetching} 
                  />
                )
              }} />

              <Route path='/search/people' render={(props) => {
                return (
                  <SearchUsers
                    isInitialFetching={this.state.isInitialFetching}
                  />
                )
              }} />

              <Route path='/search/songs' render={(props) => {
                return (
                  <SearchSongs
                    isInitialFetching={this.state.isInitialFetching}
                  />
                )
              }}
              />
              
              <Redirect from='*' to='/search/djs' />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
    q: state.search.q,
    isFetching: state.search.isFetching,
    mode: state.search.mode, 
    cities: state.search.users.filters.cities,
  }
}

export default connect(mapStateToProps, {
  setSearchMode,
  submitSearch,
  setSearchQuery,
  searchCitiesById,
  updatePaginationPage,
  paramsOnLoad,
  updateUrl,
  fetchGenres,
  fetchCountries,
})(Search)

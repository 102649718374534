import React, { Component } from 'react'
import Loading from 'react-loading'

class Img extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loaded: false,
      error: false
    }
  }

  componentDidMount () {
    let image = new Image()

    image.onload = function () {
      // Youtube error image
      // 120x90 image
      if (image.width === 120 && image.height === 90) {
        this.setState({loaded: true, error: true})
        this.props.onError()
      } else {
        if (this.placeholder) {
          this.setState({loaded: true})
          this.props.onLoad()
        }
      }
    }.bind(this)

    image.src = this.props.src
  }

  render () {
    if (this.state.loaded) {
      if(this.state.error) {
        return <div className='error-video'/>
      } else {
        return <img src={this.props.src} className='yt-thumbnail' />
      }
    } else {
      return (
        <div className='img-placeholder'
          ref={(placeholder) => { this.placeholder = placeholder }}
        >
          <div className='img-loading'>
            <Loading
              type='spin'
              color='#aaa'
              delay={0}
              height={45}
              width={45}
            />
          </div>
        </div>
      )
    }


  }
}

export default Img

import React from 'react';

import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import moment from 'moment'
import queryString from 'query-string'

import { replace } from 'connected-react-router'
import { Switch, Route, Redirect } from 'react-router-dom'

import {
  getUserFavoriteSongs
} from 'app/modules/favorites'

import {
  selectDJ,
  selectSimilarDJs,
  fetchDJProfile,
  fetchSimilarDJs,
  fetchGenreProfile,
  clearSimilarArtists,
  toggleIsFollowed,
} from 'app/modules/artist'

import {
  selectSongForm,
  updateUrlParams,
  fetchSongs,
  selectSongs,
  updatePaginationPage,
  resetPaginationLimit,
  setSongFields,
} from 'app/modules/song'

import {
  updatePlaylistLocation 
} from 'app/shared/music-player/modules/player'

import { buildArtistUrl } from 'lib/artist-url'

import DJProfileHeader from './dj-profile-header';
import DJInfo from './dj-info';

import ProfileNavItem from 'app/shared/profile/profile-nav-item'
import Setlist from 'app/shared/setlist'
import withInfiniteScroll from 'app/shared/infinite-scroll'

import LoginModal from 'app/components/login-modal'

import Loading from 'react-loading'

import '../styles/dj.scss'

const mapStateToProps = (state) => {
  return {
    dj: selectDJ(state),
    similarDJs: selectSimilarDJs(state),
    form: selectSongForm(state),
    songs: selectSongs(state),
    song: state.song,
    reachedLimit: state.song.form.pagination.reachedLimit,
    isFetchingProfile: state.artist.isFetchingProfile,
    isFetchingSimilar: state.artist.isFetchingSimilar,
  }
}

class DJ extends React.Component {
  constructor(props) {
    super(props);

    this.renderNav = this.renderNav.bind(this)
    this.handleResize = this.handleResize.bind(this)

    this.initializeArtist = this.initializeArtist.bind(this)
    this.getUrlParams = this.getUrlParams.bind(this)
    this.fetchSongs = this.fetchSongs.bind(this)
    this.fetchDJProfile = this.fetchDJProfile.bind(this)
    this.onPaginatedSearch = this.onPaginatedSearch.bind(this)

    this.getTracklistTitle = this.getTracklistTitle.bind(this)
    this.renderEndListItem = this.renderEndListItem.bind(this)

    this.renderLoginModal = this.renderLoginModal.bind(this)
    this.openLoginModal = this.openLoginModal.bind(this)

    this.refreshPlaylist = this.refreshPlaylist.bind(this)

    this.state = {
      isMobile: false,
      isInitialFetching: true,
      isPaginatedFetching: false,
      renderLoginModal: false,
    }
  }

  // --------------------------------------------------------------------
  // Component Lifecycle
  // --------------------------------------------------------------------
  componentWillMount() {
    this.ListenSetlistWithInfiniteScroll = withInfiniteScroll(Setlist)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    const params = this.getUrlParams(this.props);

    this.props.updatePlaylistLocation('artist')

    this.props.setSongFields(params);
    this.props.updateUrlParams(params)

    this.fetchDJProfile(params)
    this.fetchSongs(params)
    this.props.fetchGenreProfile(params.id, params.from, params.to)
  }

  componentDidUpdate(prevProps, prevState) {
    const prevParams = this.getUrlParams(prevProps);
    const params = this.getUrlParams(this.props)

    if(prevParams.id !== params.id && prevParams.id !== null) {
      this.props.setSongFields(params);

      this.fetchDJProfile(params)
      this.fetchSongs(params);
      // this.props.fetchSimilarDJs(params.id);
      this.props.fetchGenreProfile(params.id, params.from, params.to)

      return;
    }

    if(prevParams.mode !== params.mode) {
      this.props.setSongFields(params);
      this.props.resetPaginationLimit()
      this.props.updatePaginationPage(1)
      this.fetchSongs(params)

      return;
    }

    if(!moment(prevParams.from).isSame(params.from) || 
       !moment(prevParams.to).isSame(params.to)) {
      this.props.resetPaginationLimit()
      this.props.updatePaginationPage(1)
      this.fetchSongs(params)

      // this.props.fetchSimilarDJs(params.id)
      this.props.fetchGenreProfile(params.id, params.from, params.to)

      return;
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize () {
    const width = window.innerWidth
    const isMobile = this.state.isMobile

    if (width > 767 && isMobile) {
      this.setState({isMobile: false})
    } else if (width <= 767 && !isMobile) {
      this.setState({isMobile: true})
    }
  }

  openLoginModal() {
    this.setState({
      renderLoginModal: true
    })
  }

  getUrlParams(props) {
    const matchParams = props.match.params;
    const urlParams = queryString.parse(props.location.search)
    const params = Object.assign({}, matchParams, urlParams)

    return params;
  }

  initializeArtist() {
    this.props.clearSimilarArtists() 
    this.props.updatePaginationPage(1)
    this.props.resetPaginationLimit()
  }

  fetchDJProfile(params) {
    this.initializeArtist()

    this.setState({isInitialFetching: true, isPaginatedFetching: false})

    this.props.fetchDJProfile(params.id, params.from, params.to)
      .then((profile) => {})
      .catch((error) => {
        if(error && error.response && (error.response.status === 404 || error.response.status === 400)) {
          this.props.replace("/")
        }
      })
  }

  fetchSongs(params, loadMore = false) {
    this.props.fetchSongs(params, loadMore)
      .then(() => {
        this.setState({isInitialFetching: false, isPaginatedFetching: false})
      }) 
      .catch(() => {
        this.setState({isInitialFetching: false, isPaginatedFetching: false})
      })
  }

  refreshPlaylist() {
    const params = this.getUrlParams(this.props)

    this.props.updatePaginationPage(1)
    this.props.resetPaginationLimit()
    this.fetchSongs(params)
  }

  onPaginatedSearch () {
    if(this.props.song.isFetching ||
       this.state.isPaginatedFetching ||
       this.props.reachedLimit) {
      return;
    }

    this.setState({isPaginatedFetching: true})
    const params = this.getUrlParams(this.props) 
    this.fetchSongs(params, true)
  }

  renderNav() {
    const { dj, form } = this.props;
    const baseProps = {
      from: form.from,
      to: form.to,
      slug: form.slug,
      id: form.id,
      bpm_min: form.bpm_min,
      bpm_max: form.bpm_max,
      only_bpm: form.only_bpm, 
    } 
    
    return ( 
      <ul className='dj-nav'>
        <ProfileNavItem
          name='Top Played'
          {...buildArtistUrl(Object.assign({}, baseProps, {
            mode: 'hits'
          }))}
        />

        <li className='profile-nav-item icon-item'>
          <span className='glyphicon glyphicon-refresh'
            onClick={this.refreshPlaylist}
          />
        </li>
      </ul>
    )
  }

  renderEndListItem () {
    if(this.state.isPaginatedFetching && !this.props.reachedLimit) {
      return (
        <li className='setlist-empty'>
          <div className='loading'>
            <Loading
              type='spin'
              color='#aaa'
              delay={0}
              height={45}
              width={45}
            />
          </div>
        </li> 
      )
    } else {
      return (
        <li className='setlist-empty'>
          <h3 className='logo'>M</h3>
        </li>
      ) 
    }
  }

  getTracklistTitle() {
    if(this.props.form.mode === 'hits') {
      return 'Top Played'
    } else if (this.props.form.mode === 'radio') {
      return 'DJ Set'
    } else if(this.props.form.mode === 'recommended') {
      return 'Recommended'
    } else {
      return 'Tracklist'
    }
  }

  renderLoginModal() {
    return (
      <LoginModal
        isOpen={this.state.renderLoginModal}
        onCancel={() => {
          this.setState({renderLoginModal: false})
        }}
      />
    )
  }

  render() {
    const { dj } = this.props;

    return (
      <div className='dj-profile'>
        <Helmet>
          <title>
            { dj ? dj.name : 'Mu6ik / Listen' }
          </title>
        </Helmet>

        { this.state.renderLoginModal ? this.renderLoginModal() : null }

        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <DJProfileHeader
                isPublic
                openLoginModal={this.openLoginModal}
                isMobile={this.state.isMobile}
                toggleIsFollowed={this.props.toggleIsFollowed}
                dj={dj || {}}
              /> 
            </div>
          </div>

          <div className='row'>
            <div className='col-md-3'>
              <DJInfo 
                dj={dj} 
                isPublic
                {...this.props.form}
                isMobile={this.state.isMobile} 
                setSongFields={this.props.setSongFields}
                updateUrlParams={this.props.updateUrlParams}
                fetchSongs={this.fetchSongs}
                similarDJs={this.props.similarDJs}
                fetchSimilarDJs={this.props.fetchSimilarDJs}
              />
            </div>

            <div className='col-md-9 col-sm-9 results'>
              {
                this.state.isMobile ?
                  this.renderNav() : null
              }

              {
                React.createElement(this.ListenSetlistWithInfiniteScroll, 
                  {
                    songs: this.props.songs,
                    renderDJ: false,
                    dj: this.props.dj,
                    isPublic: true,
                    renderRefresh: true,
                    isInitialFetching: this.state.isInitialFetching,
                    isFetching: this.props.song.isFetching,
                    onPaginatedSearch: this.onPaginatedSearch,
                    endListItem:this.renderEndListItem,
                    refreshPlaylist: this.refreshPlaylist,
                    title: this.getTracklistTitle(),
                  }
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  fetchDJProfile,
  fetchSimilarDJs,
  fetchGenreProfile,
  clearSimilarArtists,
  toggleIsFollowed,
  getUserFavoriteSongs,
  updateUrlParams,
  setSongFields,
  fetchSongs,
  updatePaginationPage,
  resetPaginationLimit,
  updatePlaylistLocation,
  replace,
})(DJ);



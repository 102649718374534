import React from 'react';
import DJWall from 'app/shared/dj-wall'

import { translateKey } from 'lib/translate-key'

class SongInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { song } = this.props;
    const key = this.props.keyMode === 'harmonic' ? song.key : (song.key ? translateKey(song.key) : null)

    const songDetail = `${song.bpm !== null ? song.bpm : ''}${ key !== null ? " " + key : ''}`
    const genres = song && song.genres ? song.genres.slice(0, 3).map((g, gIndex) => {
      return (
        <label className='genre-label' key={`song-${song.id}-genre-${gIndex}`}>{ g.name }</label>
      )
    }) : []

    return (
      <div className='m6k-panel song-info'>
        {
          genres.length > 0 ?
            <section>
              <h4>Genres</h4>
              <div className='genres'>
                { genres }
              </div>
            </section> : null
        }

        <section>
          <h4>Years Played</h4>
          <div>
            { song && song.years_played ? song.years_played.join(", ") : '' } 
          </div>
        </section>
        
        {
          songDetail !== '' ?
            <section>
              <h4>BPM / KEY</h4>
              <div>{ songDetail }</div>
            </section> : null
        }

        <section>
          <h4>{`${song.total_djs} DJ${song.total_djs > 1 ? 's' : ''} played this song`}</h4>
          <DJWall djs={song.djs} width={3} limit={this.props.isMobile ? 9 : 32} />
        </section>
      </div>
    );
  }
}

export default SongInfo; 
import React from 'react';
import Modal from 'react-modal'
import Switch from 'react-toggle-switch'
import { connect } from 'react-redux'

import { formatYoutubeUrl } from 'lib/video'

import  './playlist-modal.scss'
import 'react-toggle-switch/dist/css/switch.min.css'
import playlistEmpty from 'assets/img/playlist-empty.jpg'

import { buildResourceUrl } from 'lib/string'

import { Img } from 'app/shared/setlist';

import {
  fetchPlaylists,
  setPlaylistModalTab,
  selectSongId,
  selectPlaylists,
  updateModalFilter,
  addSongToPlaylist,
  updatePlaylist,
  createPlaylist,
} from 'app/modules/playlists'

import ClickHandler from 'components/click-handler'

class PlaylistModal extends React.Component {
  constructor(props) {
    super(props);

    this.createPlaylist = this.createPlaylist.bind(this)
    this.renderCreateView = this.renderCreateView.bind(this)
    this.renderAddView = this.renderAddView.bind(this)

    this.addSongToPlaylist = this.addSongToPlaylist.bind(this)
    this.handleResize = this.handleResize.bind(this)

    this.state = {
      playlistForm: {
        name: "",
        description: "",
        is_private: false,
      },
      tab: 'CREATE',
      filter: '',
      psHash: {},
      isMobile: false,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    this.props.fetchPlaylists()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize(e) {
    const width = window.innerWidth
    const isMobile = this.state.isMobile

    if (width > 767 && isMobile) {
      this.setState({isMobile: false})
    } else if (width <= 767 && !isMobile) {
      this.setState({isMobile: true})
    }
  }

  createPlaylist(song) {
    if(this.state.playlistForm.name === null || this.state.playlistForm.name === '') {
      return false;
    } 

    this.props.createPlaylist({
      playlist: {
        ...this.state.playlistForm
      }
    })
    .then((playlist) => {
      this.setState({playlistForm: {
        name: '', description: '', is_private: false
      }})


      this.addSongToPlaylist(playlist, song)
        .then(() => {})
        .catch((e) => {
          console.error('error 1', e)
        })

      // this.props.updatePlaylist(playlist, {
      //   youtube_id: song.video.youtube_id,
      // })
      //   .then(() => {})
      //   .catch((e) => {
      //     console.error('error 2', e)
      //   })

      this.props.onCancel();     
    })
    .catch((e) => {
      console.error('error 3', e)
    })
  }

  addSongToPlaylist(playlist, song) {
    if(playlist.youtube_id === null || playlist.youtube_id === '') {
      this.props.updatePlaylist(playlist, {
        youtube_id: song.video.youtube_id
      })
    }

    this.setState({
      psHash: {
        ...this.state.psHash,
        [playlist.id] : {
          added: false
        }
      }
    })

    return this.props.addSongToPlaylist(playlist, song)
      .then(() => {
        this.setState({
          psHash: {
            ...this.state.psHash,
            [playlist.id] : {
              added: true
            }
          }
        })
      })
  }

  renderSongSummary(song) {
    if(song === null || song === undefined) {
      return null;
    }

    const imageUrl = formatYoutubeUrl(song.video.youtube_id);

    return (
      <div className='song-summary'>
        <Img 
          src={imageUrl} 
          onLoad={() => {}}
          onError={() => {}}
        />
        { song.video.name }
      </div>
    )
  }

  renderPlaylistSummary(playlist, song) {
    if(playlist === null || playlist === undefined) {
      return null;
    }

    const text = this.state.isMobile ? 
      "Add" :  "Add To Playlist"

    return(
      <li className='playlist-summary row' key={`${playlist.uuid}`}>
        <div className='col-md-2 col-sm-2 col-xs-3'>

          { (playlist.youtube_id === null || playlist.youtube_id === '') ? 
            <img src={playlistEmpty} /> : 
            <img src={formatYoutubeUrl(playlist.youtube_id)} /> 
          }

        </div>

        <div className='col-md-4 col-sm-4 col-xs-4' style={{'paddingLeft': '0px'}}>
          <h4>
            <ClickHandler url={buildResourceUrl('playlists', playlist.name, playlist.id)}>
              { playlist.name }
            </ClickHandler>
            </h4>
        </div>

        <div className='col-md-6 col-sm-6 col-xs-5'>
          <div className='m6k-float-right'>
            {
              this.state.psHash[playlist.id] && this.state.psHash[playlist.id].added ?
                <div className='added'>
                  Added!
                </div> :
                <button className='btn btn-default add-button' onClick={(e) => {
                  e.preventDefault()
                  this.addSongToPlaylist(playlist, song)
                }}>
                  { playlist.is_private ? <span className='glyphicon glyphicon-lock'></span> : null }
                  { text }
                </button>
            }
          </div>
        </div>
      </li>
    )
  }

  renderAddView() {
    const song = this.props.song;
    const playlists = this.props.playlists.map((p) => {
      return this.renderPlaylistSummary(p, song)
    })

    return (
      <form className='add-to-playlist-form'>
        { this.renderSongSummary(song) }

        <div className='form-group'>
          <input 
            type="text" 
            className="form-control" 
            id="playlist-name" 
            placeholder="Filter"
            autoComplete="off"
            value={this.props.filter}
            onChange={(e) => {
              this.props.updateModalFilter(e.target.value)
            }}
          />
        </div>

        <ul className='playlists'>
          { playlists }
        </ul>

        <div className='row'>
          <div className='col-md-12'>
            <button className='btn btn-default' onClick={this.props.onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </form>
    ) 
  }

  renderCreateView() {
    const song = this.props.song;

    return (
      <form className='create-playlist-form' onSubmit={(e) => {
        e.preventDefault();
        this.createPlaylist(song)
      }}>
        { this.renderSongSummary(song) }

        <div className="form-group">
          <label htmlFor="playlist-name">Name</label>
          <input 
            type="text" 
            className="form-control" 
            id="playlist-name" 
            placeholder="Miami Music Weekend"
            value={this.state.playlistForm.name}
            onChange={(e) => {
              this.setState({playlistForm: {
                  name: e.target.value 
                }
              })
            }}
          />
        </div>

        <div className='form-group'>
          <label htmlFor="playlist-description">Description</label>
          <textarea 
            type="text" 
            className="form-control" 
            id="playlist-description" 
            placeholder="A creative Description"
            value={this.state.playlistForm.description}
            onChange={(e) => {
              this.setState({
                playlistForm: {
                  description: e.target.value
                }
              })
            }}
          />
        </div>

        <div className='form-group'>
          <label>Privacy</label>
          <div className='switch-toggle-container'>
            <label>Private</label>
            <Switch 
              onClick={() => {
                this.setState(prevState => {
                  return {
                    playlistForm: {
                      ...prevState.playlistForm,
                      is_private: prevState.playlistForm.is_private ? false : true 
                    }
                  } 
                });
              }} 
              on={!this.state.playlistForm.is_private}
              className='switch-button'
            />
            <label>Public</label>
          </div>

          <p>When this playlist is marked private, only you and the people with permisisons can access this playlist.</p>
        </div>

        <div className='row'>
          <div className='col-md-12'>
          <div className='btn-group'>
            <button className='btn btn-default' onClick={this.props.onCancel}>
              Cancel
            </button>
            <button className='btn btn-primary'>
              Save
            </button>
          </div>
          </div>
        </div>
      </form>
    )
  }

  render() {
    return (
      <div className=''>
        <Modal
          isOpen={this.props.show}
          onRequestClose={this.props.onCancel}
          contentLabel='Confirm Modal'
          className='playlist-modal m6k-panel'
          htmlOpenClassName="playlist-modal-html"
          ariaHideApp={false}
        >
          <div className='modal-header'>
            <div className='row'>
              <div className='col-md-12'>
                <ul className='modal-nav'>
                  <li
                    className={this.props.tab === 'ADD' ? 'active' : ''}
                    onClick={(e) => {
                      e.preventDefault()
                      this.props.setPlaylistModalTab('ADD');
                    }}
                  >Add to Playlist</li>
                  <li
                    className={this.props.tab === 'CREATE' ? 'active' : ''}
                    onClick={(e) => {
                      e.preventDefault()
                      this.props.setPlaylistModalTab('CREATE');
                    }}
                  >Create a Playlist</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='modal-body'>
            {
              this.props.tab === 'CREATE' ?
                this.renderCreateView() :
                this.renderAddView()
            }
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tab: state.playlists.playlistModalTab,
    playlists: selectPlaylists(state),
    song: selectSongId(state),
    filter: state.playlists.playlistModalFilter,
  }
}

export default connect(mapStateToProps, {
  setPlaylistModalTab,
  fetchPlaylists,
  updateModalFilter,
  addSongToPlaylist,
  updatePlaylist,
  createPlaylist,
})(PlaylistModal)
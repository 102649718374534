import React from 'react';

import Terms from './terms';
import Privacy from './privacy';
import NavLink from 'app/components/nav-link'

import { Switch, Route, Redirect } from 'react-router-dom'

import '../styles/legal.scss'

export default class Legal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <nav className='legal-nav'>
          <div className='container-fluid'>
            <ul className='search-nav-list'>
              <NavLink 
                to={'/terms'}
              >Terms of Service</NavLink>
              <NavLink 
                to={'/privacy-policy'}
              >Privacy Policy</NavLink>
            </ul>
          </div>
        </nav>

        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-3'>
                            
            </div>
            <div className='col-md-6'>
              <div className='legal m6k-panel'>
                <Switch>
                  <Route path='/terms' component={Terms} />
                  <Route path='/privacy-policy' component={Privacy} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

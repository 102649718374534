import React from 'react';
import ClickHandler from 'components/click-handler'

import categories from '../../../categories'

export default class Categories extends React.Component {
  constructor(props) {
    super(props);
  }

  renderCategory(category, i) {
    if(!category) return;

    const urls = category.articles.map((article, index) => {
      return (
        <li key={index}>
          <ClickHandler url={article.url}>
            { article.name }
          </ClickHandler>
        </li>
      )
    })

    return (
      <div className='col-md-6' key={i}>
        <div className='category'>
          <h2>
            <ClickHandler url={category.url} >
              { category.name }
            </ClickHandler>
          </h2>
          <ul>
            { urls }
          </ul>
        </div>
      </div>
    )
  }
 
  render() {
    return (
      <div className='m6k-panel categories'>
        <div className='row display-inline-block'> 
          {
            categories.map((category, index) => {
              return this.renderCategory(category, index)
            })
          }
        </div>
      </div>
    );
  }
}

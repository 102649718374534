import axios from 'lib/axios-config'

import { normalize } from 'normalizr'
import { song, songs } from '../schemas/main'
import { push, replace } from 'connected-react-router'
import moment from 'moment'
import queryString from 'query-string'

import {
  filterEmptyVideos,
  filterDuplicateVideos
} from 'lib/video'

function verifyValue (value) {
  if (value === null || value === undefined || value === '') {
    return false
  }

  return true
}

const verifyFields = (form) => {
  const requiredFields = {
    'name': true,
    'from': true,
    'to': true,
    'mode': true
  }

  for (var field of Object.keys(form)) {
    requiredFields[field] = false

    if (form[field] === null || form[field] === '') {
      return false
    }
  }

  for (var field of Object.keys(requiredFields)) {
    if (requiredFields[field]) {
      return false
    }
  }

  return true
}

export function fetchSongs (params = {}, loadMore = false) {
  return (dispatch, getState) => {
    const state = getState()
    const form = state.song.form;

    const url = `/api/${form.mode}`

    const payload = {
      from: moment(params.from).format("YYYY-MM-DD"),
      to: moment(params.to).format("YYYY-MM-DD"),
      total: form.total,
      song_id: form.song_id,
      id: params.id,
    }

    if (form.mode === 'hits') {
      let page = form.pagination.page

      if (loadMore) {
        page += 1
        dispatch(updatePaginationPage(page))
      }

      payload.page = page
    } else if (form.mode === 'radio') {
      
      if(loadMore) {
        payload.ids = state.song.ids 
      }

    } else if (form.mode === 'recommended' && loadMore) {
      return Promise.reject()
    }

    dispatch({
      type: 'FETCH_SONGS'
    })

    return axios.get(url, { params: payload })
      .then((response) => {
        let results = filterEmptyVideos(response.data)
        results = filterDuplicateVideos(results)

        const normalized = normalize(results, songs)

        dispatch({
          type: 'FETCH_SONGS_SUCCESS',
          payload: {
            entities: normalized.entities,
            ids: normalized.result,
            append: loadMore,
          }
        })

        return Promise.resolve()
      }).catch((error) => {
        dispatch({
          type: 'FETCH_SONGS_FAILURE',
          error: error.response.data.message || 'Someting went wrong'
        })

        return Promise.reject(error)
      })
  }
}

export function fetchTransitions (id) {
  return (dispatch, getState) => {
    const url = `/api/songs/${id}/transitions`
    
    dispatch({
      type: 'FETCH_TRANSITIONS'
    })

    return axios.get(url)
      .then((response) => {
        const normalized = normalize(response.data, song)
        dispatch({
          type: 'FETCH_TRANSITIONS_SUCCESS',
          payload: {
            entities: normalized.entities,
            id: normalized.result
          }
        })

        return Promise.resolve()
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_TRANSITIONS_FAILURE',
          error: error.response.data.message || 'Something went wrong.'
        })

        return Promise.reject(error)
      })
  }
}

export function fetchSimilarSongs (id) {
  return (dispatch, getState) => {
    const url = `/api/songs/${id}/similar_songs`
    
    dispatch({
      type: 'FETCH_SIMILAR_SONGS'
    })

    return axios.get(url)
      .then((response) => {
        const normalized = normalize(response.data, song)

        dispatch({
          type: 'FETCH_SIMILAR_SONGS_SUCCESS',
          payload: {
            entities: normalized.entities,
            id: normalized.result
          }
        })

        return Promise.resolve()
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_SIMILAR_SONGS_FAILURE',
          error: error.response.data.message || 'Something went wrong.'
        })

        return Promise.reject(error)
      })
  }
}

export function fetchSong (id, detailed = null, updateSongId = false) {
  return (dispatch, getState) => {
    const url = `/api/songs/${id}`
    
    dispatch({
      type: 'FETCH_SONG',
      payload: {
        detailed,
      }
    })

    return axios.get(url, {params: {
      detailed,
    }})
      .then((response) => {
        const normalized = normalize(response.data, song)

        dispatch({
          type: 'FETCH_SONG_SUCCESS',
          payload: {
            updateSongId,
            entities: normalized.entities,
            id: normalized.result
          }
        })

        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_SONG_FAILURE',
          error: error.response.data.message || 'Someting went wrong'
        })

        return Promise.reject(error)
      })
  }
}

export function removeVideo (uuid) {
  return (dispatch, getState) => {
    const url = `/api/inspect`

    dispatch({
      type: 'REQUEST_INSPECT_VIDEO'
    })

    return axios.post(url, {
      uuid,
    }).then((response) => {
      dispatch({
        type: 'REQUEST_INSPECT_VIDEO_SUCCESS'
      })
    }).catch((error) => {
      dispatch({
        type: 'REQUEST_INSPECT_VIDEO_FAILURE',
        payload: {
          error: error.response.data
        }
      })
    })
  }
}

const defaultFormState = {
  slug: null,
  name: null,
  id: null,
  from: moment('2012-01-01'),
  to: moment(),
  mode: 'hits',
  total: 35,
  bpm_min: 0,
  bpm_max: 250,
  only_bpm: false,
  song_id: null,
  pagination: {
    total: 0,
    limit: 35,
    page: 1,
    reachedLimit: false,
  }
}

export const updatePaginationPage = (page) => {
  return {
    type: 'UPDATE_LISTEN_PAGINATION_PAGE',
    payload: {
      page
    }
  }
}

export const resetPaginationLimit = () => {
  return {
    type: 'RESET_LISTEN_PAGINATION_LIMIT'
  }
}

export const selectSongForm = (state) => {
  return state.song.form;
}


export function setSongFields(params) {
  const filteredParams = {}

  for(let field in params) {
    if(defaultFormState.hasOwnProperty(field) && verifyValue(params[field])) {
      filteredParams[field] = params[field];
    }
  } 

  const from = filteredParams.from
  const to   = filteredParams.to

  if (from) {
     filteredParams.from = moment(from, 'YYYY-MM-DD')
  }

  if (to) {
    filteredParams.to = moment(to, 'YYYY-MM-DD')
  }

  return {
    type: 'SET_SONG_FIELDS',
    payload: {
      ...filteredParams
    }
  }
}

export function updateUrlParams(params) {
  return (dispatch, getState) => {
    const form = getState().song.form;

    const from = params.from ? params.from : form.from;
    const to = params.to ? params.to : form.to;

    const queryParams = {
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
      bpm_min: params.bpm_min || form.bpm_min,
      bpm_max: params.bpm_max || form.bpm_max,
      only_bpm: params.only_bpm || form.only_bpm
    }

    const slug = params.slug || form.slug;
    const id = params.id     || form.id;
    const mode = params.mode || form.mode; 

    const url = `/dj/${slug}/${id}/${mode}`;

    dispatch(replace({
      pathname: url,
      search: queryString.stringify(queryParams)
    }))
  }
}

const form = (state = defaultFormState, action) => {
  switch (action.type) {
    case 'SET_SONG_FIELDS':
      const obj = {
        ...state,
        ...action.payload,
      }
      return obj
    case 'UPDATE_LISTEN_PAGINATION_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
        }
      }
    case 'RESET_LISTEN_PAGINATION_LIMIT':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          reachedLimit: false,
        }
      }
    case 'REACHED_PAGINATION_LIMIT':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          reachedLimit: true,
        }
      }
    case 'CLEAR_FORM':
      return defaultFormState
    default:
      return state
  }
}

export const selectSongs = (state) => {
  const entities = state.entities

  return state.song.ids.map((id) => {
    const song = entities.songs[id]

    return {
      ...song
    }
  })
}

export const selectSong = (state) => {
  const entities = state.entities
  console.log(state.song)
  const song = entities.songs[state.song.selectedId]  

  if(!song || !song.id) return {
    video: {},
    genres: [],
    years_played: [],
    similar_songs: [],
    total_djs: 0,
    marketplace: {
      beatport_tracks: [],
    },
    transitions: {
      from: [],
      to: [],
    }
  }

  const djs = song.djs ? song.djs.map((id) =>
    entities.artists[id]
  ) : [] 

  const from = song.transitions && song.transitions.from ? song.transitions.from.map((id) => {
    const s = entities.songs[id]
    return {
      ...s,
      dj: entities.artists[s.dj]
    }
  }) : []

  const to = song.transitions && song.transitions.to ? song.transitions.to.map((id) => {
    const s = entities.songs[id]
    return {
      ...s,
      dj: entities.artists[s.dj]
    }
  }) : []

  const similar_songs = song && song.similar_songs ? song.similar_songs.map((id) => 
    entities.songs[id]
  ) : []

  return {
    ...song,
    djs,
    similar_songs,
    transitions: {
      from,
      to,
    }
  }
}

export const nextSong = (state) => {
  const entities = state.entities
  const radio = state.radio
  return entities.songs[radio.songId]
}

export const selectFavoriteSongs = (state) => {
  const entities = state.entities

  return state.favorites.ids.map((id) => {
    const favorite = entities.favorites[id]
    const song = entities.songs[favorite.song]
    const artist = entities.artists[favorite.artist]

    return {
      ...song,
      created_at: favorite.created_at,
      dj: artist
    }
  })
}

export const selectHistorySongs = (state) => {
  const ids = state.history.ids;
  return ids.map((id) => {
    const song = state.entities.songs[id];

    return {
      ...song
    } 
  }) 
}

const defaultState = {
  isFetching: false,
  didInvalidate: false,
  isTransitionsFetching: false,
  form: defaultFormState,
  error: null,
  selectedId: null,
  ids: [],
}

const songReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_SONGS':
      return {
        ...state,
        isFetching: true
      }
    case 'FETCH_SONGS_SUCCESS':
      const ids = action.payload.ids

      if (action.payload.append && ids.length > 0) {
        return {
          ...state,
          isFetching: false,
          ids: [...state.ids, ...ids]
        }
      } else if (ids.length === 0) {
        return {
          ...state,
          isFetching: false,
          form: form(state.form, {type: 'REACHED_PAGINATION_LIMIT'})
        }
      } else {
        return {
          ...state,
          isFetching: false,
          ids: ids,
        }
      }
    case 'FETCH_SONGS_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error
      }
    case 'FETCH_SONG':
      return {
        ...state,
        isFetching: true,
      }
    case 'FETCH_SONG_SUCCESS':
      console.log(action);

      return {
        ...state,
        isFetching: false,
        selectedId: action.payload.updateSongId ? action.payload.id : state.selectedId
      }
    case 'FETCH_SONG_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error
      }
    case 'FETCH_SIMILAR_SONGS':
      return {
        ...state,
        isSimilarSongsFetching: true,
      }
    case 'FETCH_SIMILAR_SONGS_SUCCESS':
      return {
        ...state,
        isSimilarSongsFetching: false
      }
    case 'FETCH_SIMILAR_SONGS_FAILURE':
      return {
        ...state,
        isSimilarSongsFetching: false,
        didInvalidate: true,
        error: action.error
      } 
    case 'FETCH_TRANSITIONS':
      return {
        ...state,
        isTransitionsFetching: true,
      }
    case 'FETCH_TRANSITIONS_SUCCESS':
      return {
        ...state,
        isTransitionsFetching: false
      }
    case 'FETCH_TRANSITIONS_FAILURE':
      return {
        ...state,
        isTransitionsFetching: false,
        didInvalidate: true,
        error: action.error
      }
    case 'SET_SONG_FIELDS':
    case 'CLEAR_FORM':
    case 'UPDATE_LISTEN_PAGINATION_PAGE':
    case 'RESET_LISTEN_PAGINATION_LIMIT':
      return {
        ...state,
        form: form(state.form, action)
      }
    case 'CLEAR_SONGS':
      return {
        ...state,
        ids: [],
      }
    default:
      return state
  }
}

export default songReducer
import axios from 'lib/axios-config'
import { songs } from 'app/schemas/main'
import { normalize } from 'normalizr'

import filter from 'lodash/filter'

export const updatePaginationPage = (page) => {
  return {
    type: 'UPDATE_PAGINATION_PAGE',
    payload: {
      page
    }
  }
}

export const resetPaginationLimit = () => {
  return {
    type: 'RESET_PAGINATION_LIMIT'
  }
}

export function fetchHistory(loadMore = false) {
  return (dispatch, getState) => {
    const state = getState();
    const form = state.history.form;

    const url = `/api/history`

    dispatch({
      type: 'FETCH_HISTORY_REQUEST' 
    })

    const params = {
      limit: 45,
      page: 1,
    }

    if(loadMore) {
      let page = form.pagination.page;

      page += 1 
      params.page = page 
      dispatch(updatePaginationPage(page))
    }

    return axios.get(url, { params }).then((response) => {
      const results = filter(response.data, (result) =>
        result.video && result.video.uuid 
      )

      const normalized = normalize(results, songs)

      dispatch({
        type: 'FETCH_HISTORY_SUCCESS',
        payload: {
          entities: normalized.entities,
          ids: normalized.result,
          append: loadMore
        }
      })

      return Promise.resolve()
    }).catch((error) => {
      dispatch({
        type: 'FETCH_HISTORY_FAILURE',
        payload: {
          error: error.response.data
        }
      })

      return Promise.reject()
    })
  }
}

const defaultFormState = {
  sort: 'addedDESC',
  name: null,
  pagination: {
    total: 0,
    limit: 50,
    page: 1,
    reachedLimit: false,
  }
}

const form = (state = defaultFormState, action) => {
  switch (action.type) {
    case 'UPDATE_PAGINATION_TOTAL':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case 'FETCH_HISTORY_SUCCESS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case 'UPDATE_PAGINATION_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page
        }
      }
    case 'REACHED_PAGINATION_LIMIT':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          reachedLimit: true,
        }
      }
    case 'RESET_PAGINATION_LIMIT':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          reachedLimit: false,
        }
      }
    default:
      return state
  }
}

const defaultState = {
  isFetching: false,
  didInvalidate: false,
  error: null,
  ids: [],
  form: defaultFormState
}

const favoritesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_HISTORY_REQUEST':
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        error: null
      }
    case 'FETCH_HISTORY_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
        didInvalidate: true
      }
    case 'FETCH_HISTORY_SUCCESS':
      const ids = action.payload.ids

      if(action.payload.append && ids.length > 0) {
        return {
          ...state,
          isFetching: false,
          ids: [...state.ids, ...ids]
        }
      } else if (action.payload.append && ids.length === 0) {
        return {
          ...state,
          isFetching: false,
          form: form(state.form, {type: 'REACHED_PAGINATION_LIMIT'})
        }
      } else {
        return {
          ...state,
          ids,
          isFetching: false,
          form: form(state.form, action)
        }
      }

    case 'RESET_PAGINATION_LIMIT':
    case 'UPDATE_PAGINATION_PAGE':
    case 'UPDATE_PAGINATION_TOTAL':
      return {
        ...state,
        form: form(state.form, action)
      }
    default:
      return state
  }
}

export default favoritesReducer

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { StoreContext } from 'store/configure-store'

import Header from './header'
import MobileHeader from './mobile-header'
import Footer from 'components/footer/footer'

import Settings from '../routes/settings'
import Profile from '../routes/profile'
import Search from '../routes/search'
import Song from '../routes/song'
import DJ from '../routes/dj'
import Playlist from '../routes/playlist'
import SongFeed from '../routes/song-feed'
import Library from '../routes/library'
import Charts from '../routes/charts'

import Legal from 'routes/legal'

import MusicPlayer from '../shared/music-player'

import { getUserAccount } from 'app/modules/current-user/account'
import { logoutAuth } from 'modules/auth'
import { loadUI } from 'app/modules/current-user/ui'

import RenderedRoute from 'components/rendered-route'

import '../styles/app.scss'

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    playerActive: state.player.songId !== null && state.player.artistId !== null,
    currentUser: state.currentUser,
  }
}

class App extends Component {
  constructor (props) {
    super(props)

    this.handleResize = this.handleResize.bind(this)
    this.getMusicPlayerSide = this.getMusicPlayerSide.bind(this)

    this.state = {
      isMobile: false
    }
  }

  handleResize () {
    const width = window.innerWidth
    const isMobile = this.state.isMobile

    if (width > 767 && isMobile) {
      this.setState({isMobile: false})
    } else if (width <= 767 && !isMobile) {
      this.setState({isMobile: true})
    }
  }

  componentDidMount () {
    this.props.loadUI()
    this.props.getUserAccount(this.props.auth.uuid)
      .catch(() => {})

    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (this.state.isMobile !== nextState.isMobile) {
      return true
    } else if (this.props.location.pathname !== nextProps.location.pathname) {
      return true
    } else if (this.props.location.search !== nextProps.location.search) {
      return true
    } else if (this.props.playerActive !== nextProps.playerActive) {
      return true
    } else if(!this.props.currentUser.ui.playerLocation && nextProps.currentUser.ui.playerLocation) {
      return true
    } else if (this.props.currentUser.ui.playerLocation !== nextProps.currentUser.ui.playerLocation) {
      return true
    } else {
      return false
    }
  }

  componentWillMount () {
    const store = this.context

    this.Settings  = Settings(store)
    this.Profile   = Profile(store)
    this.Search    = Search(store)
    this.Song      = Song(store)
    this.DJ        = DJ(store)
    this.Library   = Library(store)
    this.Playlist  = Playlist(store)
    this.SongFeed  = SongFeed(store);
    this.Charts    = Charts(store);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }

  getMusicPlayerSide() {
    const { currentUser } = this.props; 
    const ui = currentUser.ui;

    if(ui && ui.playerLocation && ui.playerLocation === 'right') {
      return {'marginRight': '368px'}
    } else {
      return {'marginLeft': '368px'}
    }
  }

  render () {
    return (
      <div className='app' style={this.getMusicPlayerSide()}>
        <Header logout={this.props.logoutAuth} />
        
        <div className='app-container'>
          <Switch>
            <RenderedRoute path='/search'    component={this.Search} />
            <RenderedRoute path='/settings'  component={this.Settings} />
            <RenderedRoute path='/favorites' component={this.Favorites} />

            <RenderedRoute path='/library' component={this.Library} />

            <RenderedRoute path='/terms'     component={Legal} />
            <RenderedRoute path='/privacy-policy' component={Legal} />

            <RenderedRoute path='/dj/:slug/:id/'  component={this.DJ} />
            <RenderedRoute path='/song/:name/:id' component={this.Song} /> 

            <RenderedRoute path='/playlists/:name/:id' component={this.Playlist} />

            <RenderedRoute path="/charts" component={this.Charts}  />

            <RenderedRoute exact path="/" component={this.SongFeed} />
            <RenderedRoute path='*' component={this.Profile} />
          </Switch>
        </div>

        <MusicPlayer />
          
        <Footer playerActive={this.props.playerActive} isMobile={this.state.isMobile} />
        
        
        <div className='modal-container' />
      </div>
    )
  }
}

App.contextType = StoreContext; 


export default connect(
  mapStateToProps,
  {
    getUserAccount,
    logoutAuth,
    loadUI,
  }
)(App)

import { injectReducer } from 'store/reducers'
import asyncComponent from 'components/async-component'

export default (store) => asyncComponent(() => new Promise((resolve) => {
  require.ensure([], (require) => {
    const Invitation = require('./components/Invitation').default
    const reducer = require('./modules/invitation').default

    injectReducer(store, { key: 'invitation', reducer })

    resolve(Invitation)
  }, 'invitation')
}))

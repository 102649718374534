import React from 'react';
import Modal from 'react-modal'

export default class ProfileImageModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.visible}
          contentLabel=''
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.props.closeModal}
          className='block-user-modal'
          overlayClassName='confirm-action-modal-overlay'
        >
          <div className='modal-header'>
            <h3>Confirm Block</h3>
            <h4 
              className='remove'
              onClick={this.props.closeModal}
            >
              X
            </h4>
          </div> 

          <div className='modal-body'>
            <p>Are you sure you want to block {this.props.name}?</p>
            <p>This user won't be able to view your profile, will be removed as friend (if you are friends) and won't be able to interact with you.</p>
          </div>

          <div className='modal-footer'>
            <button
              className='btn btn-default'
              onClick={this.props.closeModal}
            >
              Cancel
            </button>
            <button 
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                this.props.blockUser(this.props.uuid)
              }}
            >
              Block
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

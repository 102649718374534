import React from 'react';

const FitlerFriends = (props) => {
  return (
    <div className='form-group fitler-friends'>
      <input 
        type='search' 
        value={props.filter} 
        onChange={props.updateFilter}
        placeholder='Filter Friends'
        className='form-control'
      />
    </div>
  )
}

export default FitlerFriends;
import React from 'react';
import { buildArtistUrl } from 'lib/artist-url'
import ClickHandler from 'components/click-handler'

class SearchDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.renderMobile = this.renderMobile.bind(this)
  }

  renderRecentSearch() {
    return (
      null
    )
  }

  renderAutoSuggest() {
    const djs = [];

    this.props.suggestions.map((dj, index) => {
      const isHovering = this.props.cursorHoverIndex === index;
      const urlObj = buildArtistUrl({
        name: dj.name,
        slug: dj.slug,
        id: dj.id
      }) 

      const url = urlObj.pathname + urlObj.search
       
      djs.push(
        <li 
          className={`${isHovering ? 'hover' : ''}`} 
          key={`${dj.id}-dj-suggestion`}
          onClick={(e) => {
            e.preventDefault();
            this.props.gotoDJUrl(url)
          }}
        >
          {dj.name}
        </li>
      )
    })
    
    return (
      <ul className='auto-suggest'>
        { djs }
      </ul>
    )
  }

  renderDropdown() {
    return (
      <div className='search-dropdown'>
        {
          this.props.isInputBlank ?
            this.renderRecentSearch() :
            this.renderAutoSuggest()
        }
      </div>
    )
  }

  renderMobile() {
    if(this.props.suggestions.length > 0 && !this.props.isInputBlank) {
      return (
        <div className='search-dropdown-mobile'>
          { this.renderAutoSuggest() }
        </div>
      )
    } else {
      return null;
    }
  }

  render() {
    if(this.props.isMobile) {
      return this.renderMobile();
    } else if (this.props.isFocusedInput && 
      this.props.suggestions.length > 0 && 
      !this.props.isInputBlank
    ) {
      return this.renderDropdown();
    } else {
      return null;
    }
  }
}

export default SearchDropdown;
import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Helmet } from 'react-helmet'
import Loading from 'react-loading'
import MiniFooter from 'components/mini-footer'

import { formatErrors } from 'lib/format-message'

import {
  joinWaitlist,
  resetWaitlist
} from '../modules/waitlist'

import '../waitlist.scss'

class Waitlist extends React.Component {
  constructor (props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit () {
    const email = this.email.value

    if (email === null || email === '') return

    this.props.joinWaitlist(email)
  }

  renderLoading () {
    return (
      <div className='loading'>
        <Loading
          type='spin'
          color='#aaa'
          delay={0}
          height={65}
          width={65}
        />
      </div>
    )
  }

  render () {
    let inputClass = 'form-control'
    let errorMessage = null

    if (this.props.waitlist.error) {
      inputClass += ' form-error'
      const messages = formatErrors(this.props.waitlist.error)

      errorMessage =
        <span>
          <span className='glyphicon glyphicon-exclamation-sign' />
          <p className='error-message'>
            {messages[0]}
          </p>
        </span>
    }
    return (
      <div className='waitlist'>
        <Helmet>
          <title>Mu6ik / Waitlist</title>
        </Helmet>
        <div className='waitlist-panel'>
          <h2>Mu6ik</h2>
          <h3>Join the waitlist and get early access to a new way to discover electronic dance music.</h3>
          <form onSubmit={(e) => {
            e.preventDefault()
            this.handleSubmit()
          }}>
            {errorMessage}
            <div className='form-group'>
              <input
                type='text'
                placeholder='Email'
                className={inputClass}
                value={this.props.email}
                onChange={() => {
                  if (this.props.waitlist.error !== null) {
                    this.props.resetWaitlist()
                  }
                }}
                ref={(email) => this.email = email} />
            </div>
            <button type='submit' className='btn btn-default join'>Get Early Access</button>
          </form>
        </div>
        <MiniFooter />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    waitlist: state.waitlist
  }
}

export default connect(mapStateToProps, {
  joinWaitlist,
  resetWaitlist
})(Waitlist)

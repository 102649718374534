import React from 'react';
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import HelpNav from '../components/help-nav'
import ClickHandler from 'components/click-handler'
import Footer from 'components/footer/footer' 

import Categories, { Category } from '../routes/categories'
import Articles from '../routes/articles'
import Requests from '../routes/requests'

import { Helmet } from 'react-helmet'

import '../styles/help.scss'

const mapStateToProps = (state) => {
  return {
    breadcrumbs: state.breadcrumbs.breadcrumbs
  }
}

class Help extends React.Component {
  constructor(props) {
    super(props);

    this.renderBreadcrumbs = this.renderBreadcrumbs.bind(this)
  }

  renderBreadcrumbs() {
    const { breadcrumbs } = this.props;
    if(!breadcrumbs || breadcrumbs.length === 0) return;

    const list = breadcrumbs.map((crumb, index) => {
      return (
        <li key={index}>
          <ClickHandler url={crumb.url} >
            { crumb.name }
          </ClickHandler>
        </li>
      )
    })

    return (
      <nav className='sub-nav'>
        <ol className='breadcrumbs'>
          { list }
        </ol>
      </nav>
    )
  }

  render() {
    return (
      <div className='help'>
        <Helmet>
          <title>Mu6ik Help Center</title>
        </Helmet>

        <HelpNav />

        <div className='container'>
          { this.renderBreadcrumbs() }
          <Switch>
            <Route path="/help/articles/:uri" component={Articles} />
            <Route path="/help/requests/new" component={Requests} />
            <Route path="/help/categories/:name" component={Category} />
            <Route exact path='/help' component={Categories} />
          </Switch>
        </div>

        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps, {})(Help)

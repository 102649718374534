import axios from 'lib/axios-config'
import { extractTokenFromHeaders } from 'lib/authentication'
import jwtDecode from 'jwt-decode'
import { replace } from 'connected-react-router'

import { setBannerNotification } from 'modules/banner-notification'

export function confirmUser (token) {
  return (dispatch, getState) => {
    const url = `/api/users/confirmations?confirmation_token=${token}`
    dispatch({type: 'CONFIRM_USER_REQUEST'})

    return axios.get(url)
      .then((response) => {
        try {
          const token = extractTokenFromHeaders(response)
          const uuid = jwtDecode(token).params

          localStorage.setItem('token', token)

          dispatch(setBannerNotification(
            'You have successfully confirmed your account. Welcome to the site!',
            'success'
          ))

          dispatch({ type: 'CONFIRM_USER_REQUEST_SUCCESS' })
          dispatch({ type: 'LOGIN_AUTH_SUCCESS', uuid, token })
        } catch (error) {
          dispatch({type: 'LOGIN_AUTH_FAILURE', error})
        }
      })
      .catch((error) => {
        dispatch({
          type: 'CONFIRM_USER_REQUEST_FAILURE',
          error: 'Confirmation failed.'
        })

        dispatch(replace('/login'))
      })
  }
}

const defaultState = {
  isVerifying: false,
  error: null
}

const confirmation = (state = defaultState, action) => {
  switch (action.type) {
    case 'CONFIRM_USER_REQUEST':
      return {
        ...state,
        isVerifying: true
      }
    case 'CONFIRM_USER_REQUEST_SUCCESS':
      return {
        ...state,
        isVerifying: false
      }
    case 'CONFIRM_USER_REQUEST_FAILURE':
      return {
        ...state,
        isVerifying: false,
        error: action.message
      }
    default:
      return state
  }
}

export default confirmation

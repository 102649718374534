import React from 'react';

import ClickHandler from 'components/click-handler';
import SquareImage from './square-image';

import './img-tile.scss'

class ImgTile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      image: null
    }
  }

  render () {
    const colWidth = 12 / this.props.width
    const smColWidth = 12 / this.props.widthSmall
    const xsColWidth = 12 / this.props.widthXSmall

    const className = `img-tile col-md-${colWidth} col-sm-${smColWidth} col-xs-${xsColWidth}`

    return (
      <div className={className}>
        <div className='overlay-wrapper'>
          <ClickHandler url={this.props.clickUrl} >
            <SquareImage urlPath={this.props.imgUrl} />
            <div className='overlay'>
              <h3>{this.props.name}</h3>
            </div>
          </ClickHandler>
        </div>

        {
          this.props.children
        }

      </div>
    )
  }
}

export default ImgTile

import React from 'react'
import { connect } from 'react-redux'
import { clearBannerNotification } from 'modules/banner-notification'

import './banner-notification.scss'

class BannerNotification extends React.Component {
  constructor (props) {
    super(props)

    this.delayedClearMessage = this.delayedClearMessage.bind(this)
  }

  delayedClearMessage (timeout = 3500) {
    this.timeout = setTimeout(function () {
      this.props.clearBannerNotification()
    }.bind(this), timeout)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.persistDuration !== this.props.persistDuration) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      if (this.props.persistDuration) {
        delayedClearMessage(this.props.persistDuration)
      }
    }

    if (this.props.message === null && this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  render () {
    if (this.props.message === null) return null
    if (this.props.persistDuration) this.delayedClearMessage(this.props.persistDuration)

    return (
      <div className={`banner-notification ${this.props.className}`} onClick={() => {
        this.props.clearBannerNotification()
      }}>
        <h3>{this.props.message}</h3>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    className: state.bannerNotification.className,
    message: state.bannerNotification.message
  }
}

export default connect(mapStateToProps, {
  clearBannerNotification
})(BannerNotification)

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import bannerNotification from 'modules/banner-notification'
import emailNotifications from 'modules/email-notifications'
import registration from 'modules/registration'
import auth from 'modules/auth'

import { createBrowserHistory } from 'history'
export const history = createBrowserHistory()

export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    router: connectRouter(history),
    bannerNotification,
    emailNotifications,
    registration,
    auth,
    ...asyncReducers
  })
}

export const injectReducer = (store, { key, reducer, initialState }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  if (!initialState) initialState = {}
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer

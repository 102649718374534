import React from 'react';
import { connect } from 'react-redux'
import Notice from 'components/Notice/Notice'
import Switch from 'react-toggle-switch'

import {
  setUIField,
} from 'app/modules/current-user/ui'

import 'react-toggle-switch/dist/css/switch.min.css'

class General extends React.Component {
  constructor(props) {
    super(props);

    this.closeNotice = this.closeNotice.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      message: null,
      messageVisible: false,
      keyMode: 'harmonic',
      playerLocation: 'left',
      messageClasses: null,
      unsavedChanges: false,
      options: [{
        key: 'keyMode',
        values: ['harmonic', 'camelot']
      }, {
        key: 'playerLocation',
        values: ['left', 'right']
      }],
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    if (!this.state.unsavedChanges) {
      return
    }

    const { options } = this.state;

    for(var i = 0; i < options.length; i++) {
      const option = options[i];
      const setting = this.state[option.key];

      if(option.values.includes(setting)) {
        this.props.setUIField(option.key, setting);
      }
    }

    this.setState({
      message: 'Settings updated',
      messageVisible: true,
      messageClasses: 'yellow',
      unsavedChanges: false,
    })
  }

  closeNotice() {
    this.setState({
      message: null,
      messageVisible: false
    })
  }

  componentDidMount() {
    const { options } = this.state;
    const { ui } = this.props;

    for(var i = 0; i < options.length; i++) {
      const option = options[i];

      if(ui.hasOwnProperty(option.key)) {
        const setting = ui[option.key];

        if(option.values.includes(setting)) {
          this.setState({
            [option.key]: setting
          })
        }
      }
    }
  }

  render() {
    return (
      <div>
        <h2>General Settings</h2>
        <Notice
          classes={this.state.messageClasses}
          visible={this.state.messageVisible}
          handleClick={this.closeNotice}
        >
          {this.state.message}
        </Notice>
        <form className='form-horizontal' onSubmit={this.handleSubmit}>
          <div className='form-group'>
            <label className='col-sm-3 control-label'>Key Display</label>
            <div className='col-sm-5'>
              <div className='switch-toggle-container'>
                <span>Harmonic</span>
                <Switch 
                  onClick={() => {
                    this.setState(prevState => {
                      return {
                        unsavedChanges: true,
                        keyMode: prevState.keyMode === 'camelot' ? 
                          'harmonic' : 'camelot'
                      };
                    });
                  }} 
                  on={this.state.keyMode === 'camelot'}
                  className='switch-button'
                />
                <span>Camelot</span>
              </div>
            </div>
          </div>

          <div className='form-group'>
            <label className='col-sm-3 control-label'>Player Location</label>
            <div className='col-sm-5'>
              <div className='switch-toggle-container'>
                <span>Left</span>
                <Switch 
                  onClick={() => {
                    this.setState(prevState => {
                      return {
                        unsavedChanges: true,
                        playerLocation: prevState.playerLocation === 'left' ? 
                          'right' : 'left'
                      };
                    });
                  }} 
                  on={this.state.playerLocation === 'right'}
                  className='switch-button'
                />
                <span>Right</span>
              </div>
            </div>
          </div>

          <button
            type='submit'
            className='btn btn-default'
            disabled={
              this.state.unsavedChanges ? '' : 'disabled'
            }>Save Changes</button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ui: state.currentUser.ui,
  }
}

export default connect(mapStateToProps, {
  setUIField,
})(General)
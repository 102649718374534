import React from 'react';
import { connect } from 'react-redux';

import Notice from 'components/Notice/Notice';
import Loading from 'react-loading';
import BlockedUserRow from './blocked-user-row';

import { fetchBlockedUsers, selectBlockedUsers, unblockUser } from 'app/modules/blocked-users'

import { getUrlFromPhoto } from 'lib/user';

class BlockedUsers extends React.Component {
  constructor(props) {
    super(props);

    this.renderBlocked = this.renderBlocked.bind(this)
  }

  componentDidMount() {
    this.props.fetchBlockedUsers()
  }

  renderBlocked() {
    const users = this.props.users.map((user, i) => {
      const urlSmall = getUrlFromPhoto(user.profile_photo, 160);

      return (
        <BlockedUserRow
          key={`user-${i}-${user.uuid}`}
          name={user.name}
          url={urlSmall}
          unblock={(e) => {
            e.preventDefault()
            this.props.unblockUser(user.uuid, i)
          }}
        />
      )
    })

    return (
      <div className='blocked-users-wrapper'>
        <table className='table table-striped table-hover'>
          <tbody>
            {
              users
            }
          </tbody>
        </table> 
      </div>
    )
  }

  renderLoading() {
    return (
      <div className='loading'>
        <Loading
          type='spin'
          color='#aaa'
          delay={0}
          height={45}
          width={45}
        />
      </div>
    ) 
  }

  renderEmpty() {
    return (
      <table className='table'>
        <tbody>
          <tr><td className='empty'>You aren't blocking any users</td></tr>
        </tbody>
      </table>
    )
  }

  render() {
    return (
      <div className='blocked-users'>
        <h2>Blocked Users</h2>
        <p>Blocking users on Mu6ik prevents them from viewing your profile, and will remove you from any matched searches from that account.</p>

        {
          this.props.isFetching ?
            this.renderLoading() :
            (this.props.users.length > 0 ?
              this.renderBlocked() : this.renderEmpty())
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: selectBlockedUsers(state),
    isFetching: state.blockedUsers.isFetching
  }
}

export default connect(mapStateToProps, {
  fetchBlockedUsers,
  unblockUser,
})(BlockedUsers)

/*

        <Notice 
          classes={this.state.messageClasses} 
          visible={this.state.messageVisible}
          handleClick={this.closeNotice}
        >
          {this.state.message}
        </Notice>

*/
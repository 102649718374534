import React from 'react';
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import NotFound from 'app/components/not-found'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loading from 'react-loading'
import { StoreContext } from 'store/configure-store'

import { 
  buildResourceUrl
} from 'lib/string'

import {
  fetchSong,
  selectSong,
} from 'app/modules/song'

import {
  setIndex,
  setVideoId,
  setPlayerState,
  setPlayerSongId,
  setPlayerArtistId,
  updatePlaylistocation,
} from 'app/shared/music-player/modules/player'

import {
  setPlaylistModalTab,
  setSongIdToAdd,
} from 'app/modules/playlists'

import {
  favoriteSong,
  unfavoriteSong
} from 'app/modules/favorites'

import unescape from 'lodash/unescape'

import SongInfo from './song-info';
import SongHeader from './song-header';
import SkeletonSongHeader from './skeleton-song-header';
import SkeletonSongInfo from './skeleton-song-info';

import Transitions from '../routes/transitions';
import SimilarSongs from '../routes/similar-songs';
import Marketplace from '../routes/marketplace';

import ProfileNavItem from 'app/shared/profile/profile-nav-item'

import PlaylistModal from 'app/shared/playlists/playlist-modal'

import '../styles/song.scss'

const mapStateToProps = (state) => {
  return {
    song: selectSong(state),
    player: state.player,
    ui: state.currentUser.ui,
  }
}

export class Song extends React.Component {
  constructor(props) {
    super(props);

    this.fetchSong = this.fetchSong.bind(this)
    this.handleResize = this.handleResize.bind(this)

    this.hidePlaylistModal = this.hidePlaylistModal.bind(this)
    this.showPlaylistModal = this.showPlaylistModal.bind(this)

    this.renderLoading = this.renderLoading.bind(this)
    this.renderNotFound = this.renderNotFound.bind(this)
    this.renderSongDetail = this.renderSongDetail.bind(this)
    this.renderNav = this.renderNav.bind(this);
    this.renderPlaylistModal = this.renderPlaylistModal.bind(this)

    this.state = {
      title: 'Song Information',
      isNotFound: false,
      url: null,
      isLoading: true,
      isMobile: false,
      showPlaylistModal: false,
    }
  }

  componentWillMount() {
    const store = this.context;

    this.Transitions = Transitions(store)
    this.SimilarSongs = SimilarSongs(store)
    this.Marketplace = Marketplace(store)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    const { id } = this.props.match.params

    this.fetchSong(id)
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchSong(this.props.match.params.id)
    }
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize () {
    const width = window.innerWidth
    const isMobile = this.state.isMobile

    if (width > 767 && isMobile) {
      this.setState({isMobile: false})
    } else if (width <= 767 && !isMobile) {
      this.setState({isMobile: true})
    }
  }

  fetchSong(id) {
    this.setState({isLoading: true})

    this.props.fetchSong(id, true, true)
      .then(() => {
        const { song } = this.props;
        const title = song.video ? song.video.name : song.name;

        this.setState({
          title,
          url: buildResourceUrl('song', title, song.id),
          isLoading: false,
        })
      })
      .catch((error) => {
        console.error(error)
        this.setState({isNotFound: true})
      })
  }

  hidePlaylistModal() {
    this.setState({showPlaylistModal: false})
  }

  renderPlaylistModal() {
    if(!this.state.showPlaylistModal) {
      return null
    }

    return (
      <PlaylistModal 
        show={this.state.showPlaylistModal}
        onCancel={this.hidePlaylistModal}
      />
    )
  }

  showPlaylistModal(songId, action) {
    this.props.setSongIdToAdd(songId)
    this.props.setPlaylistModalTab(action)

    this.setState({
      showPlaylistModal: true
    })
  }

  renderSongDetail() {
    const { song } = this.props;
    const { url } = this.state;
    const keyMode = this.props.ui.keyMode ? this.props.ui.keyMode : 'harmonic'

    return (
      <div>
        <Helmet>
          <title>{ unescape(this.state.title) }</title>
        </Helmet>

        <div className='container-fluid'> 
          { this.renderPlaylistModal() }
          
          <div className='row'>
            <div className='col-md-12'>
              <SongHeader 
                url={this.state.url}
                song={this.props.song}
                player={this.props.player}
                unfavoriteSong={this.props.unfavoriteSong} 
                favoriteSong={this.props.favoriteSong}
                setVideoId={this.props.setVideoId}
                setPlayerState={this.props.setPlayerState}
                setPlayerSongId={this.props.setPlayerSongId}
                setPlayerArtistId={this.props.setPlayerArtistId}
                isMobile={this.state.isMobile}
                showPlaylistModal={this.showPlaylistModal}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-3'>
              <SongInfo 
                song={this.props.song} 
                isMobile={this.state.isMobile} 
                url={this.state.url}
                keyMode={keyMode}
              />
            </div>

            <div className='col-md-9 col-xs-12'>
              {
                this.state.isMobile ? 
                  this.renderNav() : null
              }
              <Switch>
                <Route path={`${url}/transitions`} component={this.Transitions} />
                <Route path={`${url}/similar-songs`} component={this.SimilarSongs} />
                <Route path={`${url}/marketplace`} component={this.Marketplace} />
                <Redirect from={`${url}/`} to={`${url}/transitions`} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNav() {
    const { url } = this.state;
    const { song } = this.props;

    return (  
      <ul className='song-nav'>
        <ProfileNavItem
          name='Transitions'
          url={`${url}/transitions`}
        />
        <ProfileNavItem
          name='Similar Songs'
          url={`${url}/similar-songs`}
        />
        <ProfileNavItem
          name='Marketplace'
          url={`${url}/marketplace`}
          count={song.marketplace.beatport_tracks.length}
        />
      </ul>
    )
  }

  renderLoading() {
    return (
      <div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <SkeletonSongHeader 
                song={this.props.song}
                url={this.state.url}
                isMobile={this.state.isMobile}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-3'>
              <SkeletonSongInfo />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderNotFound() {
    return (
      <div>
        <Helmet>
          <title>Not Found</title>
        </Helmet>

        <NotFound>
          <h3>Page not found</h3>
        </NotFound>
      </div>
    )
  }

  render() {
    if(this.state.isNotFound) {
      return this.renderNotFound()
    } else if(this.state.isLoading) {
      return this.renderLoading()
    } else {
      return this.renderSongDetail()
    }
  }
}

Song.contextType = StoreContext;


export default connect(mapStateToProps, {
  fetchSong,
  favoriteSong,
  unfavoriteSong,
  setIndex,
  setVideoId,
  setPlayerState,
  setPlayerSongId,
  setPlayerArtistId,
  setPlaylistModalTab,
  setSongIdToAdd,
})(Song);
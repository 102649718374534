import axios from 'lib/axios-config'
import { normalize } from 'normalizr'
import { countries as countrySchema } from '../schemas/main'
import { createSelector } from 'reselect'

import compact from 'lodash/compact'

export const getCountries = (state) => {
  const entities = state.entities
  const filter = state.countries.search || ''

  const useFilter = filter !== ''

  const countries = state.countries.ids.map((id) => {
    const country = entities.countries[id]

    if (country.name.toLowerCase().startsWith(filter.toLowerCase()) || !useFilter) {
      return country
    }
  })

  return compact(countries)
}

export function fetchCountries () {
  return (dispatch, getState) => {
    const countries = getState().countries
    const url = '/api/countries?sort=popular'

    if (countries.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: 'REQUEST_COUNTRIES'
    })

    return axios.get(url)
      .then((response) => {
        const normalized = normalize(response.data, countrySchema)

        dispatch({
          type: 'REQUEST_COUNTRIES_SUCCESS',
          payload: {
            entities: normalized.entities,
            ids: normalized.result
          }
        })

        return Promise.resolve()
      }).catch((error) => {
        dispatch({
          type: 'REQUEST_COUNTRIES_FAILURE',
          payload: {
            error: error.response
          }
        })
      })
  }
}

export function updateCountrySearch (value) {
  return {
    type: 'UPDATE_COUNTRY_SEARCH',
    payload: {
      value
    }
  }
}

const defaultState = {
  isFetching: false,
  didInvalidate: false,
  error: null,
  ids: [],
  search: null
}

const countries = (state = defaultState, action) => {
  switch (action.type) {
    case 'REQUEST_COUNTRIES':
      return {
        ...state,
        isFetching: true
      }
    case 'REQUEST_COUNTRIES_SUCCESS':
      return {
        ...state,
        isFetching: false,
        ids: action.payload.ids
      }
    case 'REQUEST_COUNTRIES_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'UPDATE_COUNTRY_SEARCH':
      return {
        ...state,
        search: action.payload.value
      }
    default:
      return state
  }
}

export default countries

import { injectReducer } from 'store/reducers'
import asyncComponent from 'components/async-component'

export default (store) => asyncComponent(() => new Promise((resolve) => {
  require.ensure([], (require) => {
    const Settings = require('./components/settings').default
    const invites = require('./modules/invites').default

    injectReducer(store, { key: 'invite', reducer: invites })

    resolve(Settings)
  }, 'settings')
}))

import React from 'react'
import ReactDOM from 'react-dom'
import addEventListener from 'rc-util/lib/Dom/addEventListener'
import debounce from 'lodash/debounce'

function pauseEvent (e) {
  if (e.stopPropagation) e.stopPropagation()
  if (e.preventDefault) e.preventDefault()
}

function getMousePosition (e) {
  return e.pageX
}

function isNotTouchEvent(e) {
  return e.touches.length > 1 || (e.type.toLowerCase() === 'touchend' && e.touches.length > 0);
}

function getTouchPosition(e) {
  return e.touches.length > 0 ? e.touches[0].pageX : e.changedTouches[0].pageX
}

class VolumeSlider extends React.Component {
  constructor (props) {
    super(props)
    this.bar = {}

    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.onTouchEnd = this.onTouchEnd.bind(this)
    this.onTouchStart = this.onTouchStart.bind(this)

    this.getSliderLength = this.getSliderLength.bind(this)

    this.state = {
      sliderValue: 0
    }
  }

  addDocumentEvents (type) {
    if(type === 'touch') {
      this.onTouchMoveListener = addEventListener(document, 'touchmove', this.onTouchMove.bind(this));
      this.onTouchUpListener = addEventListener(document, 'touchend', this.onTouchEnd.bind(this));
    } else if(type === 'mouse') {
      this.onMouseMoveListener = addEventListener(document, 'mousemove', this.onMouseMove.bind(this))
      this.onMouseUpListener = addEventListener(document, 'mouseup', this.onMouseUp.bind(this))
    }
  }

  removeDocumentEvents (type) {
    if(type === 'touch') {
      if (this.onTouchMoveListener) {
        this.onTouchMoveListener.remove();
      }

      if(this.onTouchUpListener) {
        this.onTouchUpListener.remove();
      }
    } else if (type === 'mouse') {
      if (this.onMouseMoveListener) {
        this.onMouseMoveListener.remove()
      }

      if (this.onMouseUpListener) {
        this.onMouseUpListener.remove()
      }
    }
  }

  componentWillUnmount () {
    this.removeDocumentEvents('mouse')
    this.removeDocumentEvents('touch')
  }

  getSliderLength () {
    const slider = this.refs.slider

    return slider.clientWidth
  }

  getSliderStart () {
    const slider = this.refs.slider
    const rect = slider.getBoundingClientRect()

    return rect.left
  }

  onTouchMove(e) {
    if (isNotTouchEvent(e)) {
      this.removeDocumentEvents('touch')
      return;
    }

    const position = getTouchPosition(e);
    pauseEvent(e)

    const value = this.onMove(position);

    this.props.setVolume(value * 100)

    this.setState({
      sliderValue: value
    })
  }

  onMouseMove (e) {
    const position = getMousePosition(e)
    pauseEvent(e)

    const value = this.onMove(position)

    this.props.setVolume(value * 100)

    this.setState({
      sliderValue: value
    })
  }

  onTouchStart(e) {
    if (isNotTouchEvent(e)) return;

    const position = getTouchPosition(e);
    const value = this.onStart(position);

    this.props.setVolume(value * 100)
    this.addDocumentEvents('touch');

    this.setState({
      sliderValue: value
    })

    pauseEvent(e);
  }

  onMouseDown (e) {
    const position = getMousePosition(e)
    const value = this.onStart(position)

    this.props.setVolume(value * 100)
    this.addDocumentEvents('mouse')

    this.setState({
      sliderValue: value
    })

    pauseEvent(e)
  }

  onMouseUp (e) {
    pauseEvent(e)
    this.removeDocumentEvents('mouse')

    const position = getMousePosition(e)
    const value = this.onStart(position)

    this.props.setVolume(value * 100)

    this.setState({
      sliderValue: value
    })
  }

  onTouchEnd(e) {
    pauseEvent(e)
    this.removeDocumentEvents('touch')

    const position = getTouchPosition(e)
    const value = this.onMove(position)

    this.props.setVolume(value * 100)


    this.setState({
      sliderValue: value
    })
  }

  onMove (position) {
    const diffPosition = position - this.startPosition
    const diffValue = diffPosition / this.getSliderLength()
    const value = this.startValue + diffValue

    return value > 1 ? 1 : (value < 0 ? 0 : value)
  }

  onStart (position) {
    const value = this.calcValueByPos(position)
    this.startValue = value
    this.startPosition = position

    this.setState({
      sliderValue: value
    })

    return value
  }

  calcValue (offset) {
    const ratio = Math.abs(offset / this.getSliderLength())
    const value = ratio * (1 - 0) + 0

    return value
  }

  calcValueByPos (position) {
    const pixelOffset = position - this.getSliderStart()
    return this.calcValue(pixelOffset)
  }


  render () {
    const value = this.props.isAdjusting ? this.state.sliderValue : (
        this.props.isMuted ? 0 : this.props.value)

    return (
      <div className='volume-slider-wrapper'
        onMouseDown={this.onMouseDown}
        onTouchStart={this.onTouchStart}
      >
        <div
          className='volume-slider'
          ref='slider'
        >
          <div
            className='volume-slider-fill volume-slider-level'
            style={{transform: `scaleX(${value})`}}
           />
        </div>
      </div>
    )
  }
}

export default class MobileVolumeControl extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    const icon = (this.props.isMuted || this.props.volume === 0)
      ? 'glyphicon-volume-off' : (this.props.volume <= 50
      ? 'glyphicon-volume-down' : 'glyphicon-volume-up')

    return (
      <div
        className='mobile-volume-control'
      >
        <span className={`volume-icon glyphicon ${icon}`} onClick={(e) => {
          pauseEvent(e)
          if (this.props.volume === 0) {
            this.props.unMute()
            this.props.setVolume(50)
          } else if (this.props.isMuted) {
            this.props.unMute()
          } else {
            this.props.mute()
          }
        }} />

        <VolumeSlider
          isMuted={this.props.isMuted}
          value={this.props.volume / 100}
          setVolume={(v) => {
            this.props.setVolume(v)

            if (this.props.isMuted) {
              this.props.unMute()
            }
          }}
          onBlur={this.onBlur}
          setAdjusting={(isAdjusting) => {
            this.isActive = isAdjusting
          }}
        />
      </div>
    )
  }
}

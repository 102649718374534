import React from 'react';
import { connect } from 'react-redux'

import {
	uploadUserProfilePhoto,
	deleteUserPhoto,	
	deletePhoto,
} from 'app/modules/profile'

import ProfileImageModal from './profile-image-modal'
import EditImageModal from './edit-image-modal'
import UploadProgressModal from './upload-progress-modal'

import { getUrlFromPhoto } from 'lib/user'

import defaultAvatar from 'assets/img/default_avatar'

import reduce from 'lodash/reduce'

class ProfilePhoto extends React.Component {
	constructor(props) {
		super(props);

		this.renderUserProfile = this.renderUserProfile.bind(this)
		this.renderVistingProfile = this.renderVistingProfile.bind(this)

		this.setImageModal = this.setImageModal.bind(this)
		this.getUploadedFile = this.getUploadedFile.bind(this)
		this.uploadUserProfilePhoto = this.uploadUserProfilePhoto.bind(this)
		this.updateUploadPercentage = this.updateUploadPercentage.bind(this)
		this.closeEditModal = this.closeEditModal.bind(this)
		this.deleteUserPhoto = this.deleteUserPhoto.bind(this)

		this.state = {
			imageModalVisible: false,
			uploadedFileUrl: null,
			uploadPercentage: 0,
		}
	}

	setImageModal(visible) {
		this.setState({imageModalVisible: visible})
	}

	getUploadedFile(e) {
		this.file = e.target.files[0];

		if(!this.file) return;

		this.setState({
			imageModalVisible: true,
			uploadedFileUrl: URL.createObjectURL(this.file),
		})
	}

	updateUploadPercentage(uploadPercentage) {
		this.setState({
			uploadPercentage
		})
	}

	preCacheImage(url) {
		const image = new Image()
		
		image.src = url	
	}

	uploadUserProfilePhoto (blob) {
		if(!blob) return;

		this.setState({uploadPercentage: 0})

		this.props.uploadUserProfilePhoto(blob, this.updateUploadPercentage)
			.then(() => {
				this.closeEditModal()
			})
			.catch(() => {})
	}

	closeEditModal() {
		this.file = null

		this.setState({imageModalVisible: false, uploadedFileUrl: null})
	}

	deleteUserPhoto() {
		this.props.deleteUserPhoto()
			.then(() => this.closeEditModal())
	}

	renderUserProfile() {
		const { photo } = this.props;

		if (!photo) {
			return (
				<div className='profile-image-upload'>
					<img 
						className='profile-img profile-img-small' 
						src={defaultAvatar} 
						onClick={(e) => {
							e.preventDefault()
						}}
					/>

					<input 
						type='file' 
						accept='image/*' 
						className='file-uploader' 
						onChange={this.getUploadedFile}
					/>

					{
						this.props.isUploading ?
							<UploadProgressModal
								visible={this.props.isUploading}
								uploadPercentage={this.state.uploadPercentage}
							/> : null
					}

					{
						this.state.uploadedFileUrl !== null ?
							<EditImageModal
								visible={this.state.imageModalVisible} 
								changeUploadFile={this.getUploadedFile}
								tools={true}
								changed={true}
								closeModal={this.closeEditModal}
								deletePhoto={this.closeEditModal}
								uploadImage={this.uploadUserProfilePhoto}
								url={this.state.uploadedFileUrl}
							/> : null
					}
				</div>
			)
		} else {
			const urlSmall = getUrlFromPhoto(photo, 160);
			const urlLarge = getUrlFromPhoto(photo, 720);

			this.preCacheImage(urlLarge)

			return (
				<div>
					<img 
						className='profile-img profile-img-small' 
						src={urlSmall} 
						onClick={(e) => {
							e.preventDefault()
							this.setImageModal(true)
						}} 	
					/>

					{
						this.props.isUploading ?
							<UploadProgressModal
								visible={this.props.isUploading}
								uploadPercentage={this.state.uploadPercentage}
							/> : null
					}

					{
						this.state.imageModalVisible ?
							<EditImageModal
								visible={this.state.imageModalVisible} 
								deletePhoto={this.deleteUserPhoto}
								closeModal={this.closeEditModal}
								tools={this.state.uploadedFileUrl !== null}
								changed={false}
								uploadImage={(blob) => {
									// Need to delete photo from BE
									this.props.deletePhoto(photo.uuid)
									this.uploadUserProfilePhoto(blob)
								}}
								changeUploadFile={this.getUploadedFile}
								url={this.state.uploadedFileUrl || urlLarge}
							/> : null
					}
				</div>
			)
		}
	}

	renderVistingProfile () {
		const { isCurrentUser, photo } = this.props;
		const url = getUrlFromPhoto(photo, 720);

		return (
			<div>
				<img 
					className='profile-img profile-img-small' 
					src={url} 
					onClick={(e) => {
						e.preventDefault()
						this.setImageModal(true)
					}} 	
				/>
				<ProfileImageModal 
					visible={this.state.imageModalVisible} 
					closeModal={() => this.setImageModal(false) }
					url={url}
				/>
			</div>
		)
	}

	render() {
		if (this.props.isCurrentUser) {
			return this.renderUserProfile()
		} else {
			return this.renderVistingProfile()
		}
	}
}

const mapStateToProps = (state) => {
	return {
		isUploading: state.profile.photo.isUploading,
	}
}

export default connect(mapStateToProps, {
	uploadUserProfilePhoto,
	deleteUserPhoto,
	deletePhoto,
})(ProfilePhoto);
import defaultAvatar from 'assets/img/default_avatar.jpg'
import moment from 'moment'

export const buildImgUrl = function (props = {}) {
  const urls = props.urls;
  if(!urls) return defaultAvatar;
  
  if (urls.uri250) {
    return urls.uri250
  } else if (urls.uri150) {
    return urls.uri150
  } else {
    return defaultAvatar
  }
}

export const buildArtistUrl = function(props = {}) {
  let from = null

  if (props.from) {
    from = moment(props.from)
  } else {
    from = moment().subtract(5, 'years')
  }

  from = from.format('YYYY-MM-DD')

  let to = props.to ? moment(props.to) : moment()
  to = to.format('YYYY-MM-DD')

  const bpmMin = props.bpm_min ? props.bpm_min : 0
  const bpmMax = props.bpm_max ? props.bpm_max : 250
  const onlyBpm = props.only_bpm ? props.only_bpm : false
  const mode = props.mode || localStorage.getItem('mode') || 'hits'

  return {
    pathname: `/dj/${props.slug}/${props.id}/${mode}`,
    search: `?from=${from}&to=${to}&total=${35}&bpm_min=${bpmMin}&bpm_max=${bpmMax}&only_bpm=${onlyBpm}`
  }
}

export const buildURL = function (props = {}) {
  const artistLocation = buildArtistUrl(props);

  return {
    imgURL: buildImgUrl(props),
    artistURL: artistLocation.pathname + artistLocation.search 
  }
}

export default buildURL

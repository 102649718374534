import React from 'react';
import { connect } from 'react-redux'

import Loading from 'react-loading'
import Setlist from  'app/shared/setlist'

import {
  selectPlaylist,
  updatePlaylist,
  removeSongFromPlaylist,
  updatePlaylistSong,
} from 'app/modules/playlists'

class PlaylistTracksEditable extends React.Component {
  constructor(props) {
    super(props);

    this.renderEndListItem = this.renderEndListItem.bind(this)
    this.toggleEditMode = this.toggleEditMode.bind(this)
    this.toggleCompactMode = this.toggleCompactMode.bind(this)

    this.state = {
      isEditMode: false,
      isCompact: false,
    }
  }

  renderEndListItem() {
    if(this.props.isFetchingPlaylistSongs) {
      return (
        <li className='setlist-empty'>
          <div className='loading'>
            <Loading
              type='spin'
              color='#aaa'
              delay={0}
              height={45}
              width={45}
            />
          </div>
        </li>
      )
    } else {
      return (
        <li className='setlist-empty'>
          <h3 className='logo'>M</h3>
        </li>
      ) 
    }
  }

  toggleEditMode(e) {
    e.preventDefault()

    this.setState({
      isEditMode: !this.state.isEditMode
    })
  }

  toggleCompactMode(e) {
    e.preventDefault();

    this.setState({
      isCompact: !this.state.isCompact,
    })
  }

  render() {
    const songs = this.props.playlist.playlistSongs.map((ps) => {
      return ps.song
    })

    return (
      <div className='playlist-tracks'>
        <Setlist 
          songs={songs}
          playlist={this.props.playlist}
          title={this.props.playlist.name}
          endListItem={this.renderEndListItem}
          isInitialFetching={this.props.isFetchingPlaylistSongs}
          updatePlaylist={this.props.updatePlaylist}
          updatePlaylistSong={this.props.updatePlaylistSong}
          removeSongFromPlaylist={this.props.removeSongFromPlaylist}

          toggleCompactMode={this.toggleCompactMode}
          renderCompact={false}
          isCompact={this.state.isCompact}
          toggleEditMode={this.toggleEditMode}
          renderEdit={true}
          isEditMode={this.state.isEditMode}
        >
          <div className='empty'>
            <h4>This playlist is empty. Please add songs to enjoy.</h4>
          </div>
        </Setlist>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    playlist: selectPlaylist(state),
    isFetchingPlaylistSongs: state.playlists.isFetchingPlaylistSongs,
  }
}

export default connect(mapStateToProps, {
  updatePlaylist,
  updatePlaylistSong,
  removeSongFromPlaylist,
})(PlaylistTracksEditable)
import React from 'react';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy'

import {
  selectSong
} from 'app/modules/song'

import '../styles/marketplace.scss';
import beatportLogo from 'assets/img/beatport-logo.png';

class Marketplace extends React.Component {
  constructor(props) {
    super(props);
  }

  renderEmpty() {
    return (
      <h4 className='empty-state'>
        Sorry, no tracks available
      </h4>
    )
  }

  render() {
    const { song } = this.props;

    const beatportTracks = song.marketplace.beatport_tracks.map((bt, index) => {
      const release = bt.beatport_release;
      let imageUri = null;

      if(release && release.beatport_images) {
        const images = sortBy(release.beatport_images, ['width'])
        imageUri = images.length > 0 ? images[images.length - 1].uri : beatportLogo;
      } else {
        imageUri = beatportLogo
      }

      return (
        <div className='beatport-release' key={`beatport-release-${index}`}>
          <a href={bt.uri} target="_blank">
          <img src={`${imageUri}`} />
          <h5>{`${bt.artist} - ${bt.name}`}</h5> 
          </a>
        </div> 
      )
    });

    return (
      <div className='marketplace-panel m6k-panel'>
        <h3><span className='glyphicon glyphicon-shopping-cart'></span>Marketplace</h3>
        {
          beatportTracks.length > 0 ?
            <div className='song-options'>
              <div className='option-heading'>
                <img src={beatportLogo} className='option-logo' />
                <h4 className='option-title'>Beatport Tracks</h4>
              </div>
              { beatportTracks }
            </div> : this.renderEmpty()
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    song: selectSong(state),
  }
}

export default connect(mapStateToProps, {

})(Marketplace); 
export const getItem = (key) => {
  if (key === null || key === undefined) return

  let data = localStorage.getItem(key)
  if (data === null) return

  return JSON.parse(data)
}

export const setItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

import React from 'react';
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { replace } from 'connected-react-router'
import { StoreContext } from 'store/configure-store'
import { Switch, Route, Redirect } from 'react-router-dom'

import { fetchUserProfile, selectProfile, isCurrentUser } from 'app/modules/profile'

import ProfileHeader from './profile-header';
import ProfileInfo from './profile-info';

import Favorites from '../routes/favorites'
import Following from '../routes/following'
import Friends from '../routes/friends'
import Playlists from '../routes/playlists'

import NotFound from 'app/components/not-found'

import {
  sendFriendRequest,
  acceptFriendRequest,
  cancelFriendRequest,
  unfriend,
} from 'app/modules/friends'

import { 
	blockUser,
	reportUser,
} from 'app/modules/blocked-users'

import Notice from 'components/Notice/Notice'
import ProfileNavItem from './profile-nav-item'

import '../styles/profile.scss'

export class Profile extends React.Component {
	constructor(props) {
		super(props);

		this.fetchUserProfile = this.fetchUserProfile.bind(this)
		this.renderPrivateProfile = this.renderPrivateProfile.bind(this)
		this.renderProfileRoutes = this.renderProfileRoutes.bind(this)
		this.renderNav = this.renderNav.bind(this)

		this.setNoticeMessage = this.setNoticeMessage.bind(this)

		this.username = null

    this.handleResize = this.handleResize.bind(this)
		this.state = {
			isLoading: true,
			notFound: false,
			noticeVisible: false,
			message: null,
			isMobile: false,
		}
	}

	componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
	}

	componentWillMount() {
    const { store } = this.context;

    this.Playlists = Playlists(store)
		this.Favorites = Favorites(store)
		this.Following = Following(store)
    this.Friends   = Friends(store)
	}

	 handleResize () {
    const width = window.innerWidth
    const isMobile = this.state.isMobile

    if (width > 767 && isMobile) {
      this.setState({isMobile: false})
    } else if (width <= 767 && !isMobile) {
      this.setState({isMobile: true})
    }
  }

	extractUsername(params) {
		const url = params[0]
		const tokens = url.split("/")
		tokens.shift()

		return tokens[0]
	}

	componentDidMount() {
		this.username = this.extractUsername(this.props.match.params) 
		this.fetchUserProfile(this.username)

		window.addEventListener('resize', this.handleResize)
    this.handleResize()
	}

	fetchUserProfile(username) {
		this.props.fetchUserProfile(username)
			.then(() => {
				this.setState({
					isLoading: false,
					notFound: false,
				})
			})
			.catch((e) => {
				console.error(e)
				this.setState({
					isLoading: false, 
					notFound: true
				})
			})
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.match.url !== this.props.match.url) {	
			const newUsername = this.extractUsername(this.props.match.params)

			if(newUsername !== this.username) {
				this.username = newUsername
				this.fetchUserProfile(newUsername)
			}
		}
	}

	setNoticeMessage(message) {
		this.setState({
			noticeVisible: true,
			message,
		})
	}

	renderNav() {
		const { username, uuid } = this.props.profile
		
		return(
			<ul className='profile-nav'>
				<ProfileNavItem 
					username={username}
					name='Favorites' 
					count={this.props.profile.favorites_count}
				/>

				<ProfileNavItem 
					username={username}
					name='Playlists' 
					count={this.props.profile.playlists_count}
				/>

				<ProfileNavItem
					username={username}
					name='Following'
					count={this.props.profile.following_count}
				/>

				<ProfileNavItem
					username={username}
					name='Friends'
					count={this.props.profile.friends_count}
				/>
			</ul>	
		)
	}

	renderPrivateProfile() {
		const { profile } = this.props;

		return (
			<div className='row'>
				<div className='col-md-12'>
					<Switch>
						<Redirect from={`/${profile.username}/*`} to={`/${profile.username}`} />
					</Switch>

					<div className='private-profile m6k-panel'>
						<h3>This profile is private</h3>
						<h5>Please add this user as a friend to see their personal profile</h5>
					</div>
				</div>
			</div>
		)
	}

	renderProfileRoutes() {
		const { profile, isCurrentUser } = this.props;

		return (
			<div className='row'>
				<div className='col-md-3'>
					<ProfileInfo 
						genres={profile.genres}
						bio={profile.bio}
						website_url={profile.website_url}
						isCurrentUser={isCurrentUser}
						location={profile.location}
						visibility={profile.visibility_type}
					/>
				</div>
				<div className='col-md-9 col-xs-12'>
					{
						this.state.isMobile ?
							this.renderNav() : null
					}

					<Switch>
						<Route path={`/${profile.username}/favorites`} component={this.Favorites} />
						<Route path={`/${profile.username}/playlists`} component={this.Playlists} />
						<Route path={`/${profile.username}/following`} component={this.Following} />
			      <Route path={`/${profile.username}/friends`}   component={this.Friends} />
						<Redirect from={`/${profile.username}/`} to={`/${profile.username}/favorites`} />
					</Switch>
				</div>
			</div>
		)
	}

	render() {
		if(this.state.isLoading) {
			return (
				null
			)
		} else if(this.state.notFound) {
			return (
				<NotFound>
					<h3>Page not found</h3>
					<h4>User profile page not found</h4>
				</NotFound>
			)
		}

		const { profile, isCurrentUser } = this.props

		return (
			<div className='user-profile'>
				<Helmet>
					<title>{profile.name}</title>
				</Helmet>

				{
					this.state.noticeVisible ? 
						<Notice
							classes='white'
							visible={this.state.noticeVisible}
							handleClick={() => {
								this.setState({noticeVisible: false, message: null})
							}}
						> 
							<h5 className='message'>
								{this.state.message}
							</h5>
						</Notice>
						: null
				}
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-md-12'>
							<ProfileHeader 
								displayName={profile.name} 
								isCurrentUser={isCurrentUser}
								profile={profile}
								acceptFriendRequest={this.props.acceptFriendRequest}
	              cancelFriendRequest={this.props.cancelFriendRequest}
	              sendFriendRequest={this.props.sendFriendRequest}
	              unfriend={this.props.unfriend}
	              blockUser={this.props.blockUser}
	              reportUser={this.props.reportUser}
	              setNoticeMessage={this.setNoticeMessage}
	              isMobile={this.state.isMobile}
							/>	
						</div>
					</div>
					{
						profile.visibility_type === 'public_profile' || profile.is_friend || isCurrentUser ?
							this.renderProfileRoutes() :
							this.renderPrivateProfile()
					}
				</div>
			</div>
		);
	}
}

Profile.contextType = StoreContext

const mapStateToProps = (state) => {
	return {
		profile: selectProfile(state),
		isCurrentUser: isCurrentUser(state)
	}
}

export default connect(mapStateToProps, {
	fetchUserProfile,
	replace,
	acceptFriendRequest,
	sendFriendRequest,
	cancelFriendRequest,
	unfriend,
	blockUser,
	reportUser,
})(Profile)
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Notice from 'components/Notice/Notice'
import { formatErrors } from 'lib/format-message'

import {
  updateUserAccount
} from 'app/modules/current-user/account'

class Password extends Component {
  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.closeNotice  = this.closeNotice.bind(this)

    this.state = {
      unsavedChanges: false,
      message: null,
      messageVisible: false,
      messageClasses: null
    }
  }

  closeNotice () {
    this.setState({
      message: null,
      messageVisible: false
    })
  }

  handleSubmit (e) {
    e.preventDefault()

    if (!this.state.unsavedChanges) {
      return
    }

    const currentPassword = this.currentPassword.value
    const password = this.password.value
    const passwordConfirmation = this.passwordConfirmation.value

    if (password === '' && passwordConfirmation === '') {
      this.setState({
        message: "Password can't be blank",
        messageVisible: true,
        messageClasses: 'red'
      })

      return
    }

    this.props.updateUserAccount(currentPassword, password, passwordConfirmation).then(() => {
      const { account } = this.props
      this.setState({
        message: account.message,
        messageVisible: true,
        messageClasses: 'yellow',
        unsavedChanges: false
      })
    }).catch(() => {
      const { account } = this.props
      const errors = formatErrors(account.error.errors)
      this.setState({
        message: errors[0],
        messageVisible: true,
        messageClasses: 'red'
      })
    })
  }

  handleChange (e) {
    e.preventDefault()
    if (!this.state.unsavedChanges) {
      this.setState({
        unsavedChanges: true
      })
    }
  }

  render () {
    return (
      <div>
        <h2>Password</h2>
        <Notice
          classes={this.state.messageClasses}
          visible={this.state.messageVisible}
          handleClick={this.closeNotice}
        >
          {this.state.message}
        </Notice>
        <form className='form-horizontal' onSubmit={this.handleSubmit}>
          <div className='form-group'>
            <label className='col-sm-3 control-label'>Current Password</label>
            <div className='col-sm-5'>
              <input
                type='password'
                className='form-control'
                onChange={this.handleChange}
                ref={(currentPassword) => this.currentPassword = currentPassword}
              />
            </div>
          </div>
          <div className='form-group'>
            <label className='col-sm-3 control-label'>New Password</label>
            <div className='col-sm-5'>
              <input
                type='password'
                className='form-control'
                onChange={this.handleChange}
                ref={(password) => this.password = password}
              />
            </div>
          </div>
          <div className='form-group'>
            <label className='col-sm-3 control-label'>Verify Password</label>
            <div className='col-sm-5'>
              <input
                type='password'
                className='form-control'
                onChange={this.handleChange}
                ref={(passwordConfirmation) => this.passwordConfirmation = passwordConfirmation}
              />
            </div>
          </div>
          <button
            type='submit'
            className='btn btn-default'
            disabled={
              this.state.unsavedChanges
              ? '' : 'disabled'
            }
          >Save Changes</button>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.currentUser.account
  }
}

export default connect(mapStateToProps, {
 updateUserAccount 
})(Password)

import React, { Component } from 'react'

import './filters.scss'

class Filters extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const genreFilters = this.props.genres.map((genre, index) => {
      return (
        <label key={'key-' + index} onClick={() => {
          this.props.clearGenre(genre.id)
        }}>
          {genre.name}
          <span>x</span>
        </label>
      )
    })

    const keyFilters = this.props.keys.map((key, index) => {
      return (
        <label key={'key-' + index} onClick={() => {
          this.props.clearKey(key.id)
        }}>
          {key.name}
          <span>x</span>
        </label>
      )
    })

    const artistFilters = this.props.artists.map((artist, index) => {
      return (
        <label key={'key-' + index} onClick={() => {
          this.props.clearArtist(artist.id)
        }}>
          {artist.name}
          <span>x</span>
        </label>
      )
    })

    const countryFilters = this.props.countries.map((country, index) => {
      return (
        <label key={'key-' + index} onClick={() => {
          this.props.clearCountry(country.id)
        }}>
          {country.name}
          <span>x</span>
        </label>
      )
    })

    const cityFilters = this.props.cities.map((city, index) => {
      return (
        <label key={'key-' + index} onClick={() => {
          this.props.clearCity(city.id)
        }}>
          {city.name}
          <span>x</span>
        </label>
      )
    })

    const nameFilter = this.props.name !== null && this.props.name !== "" && this.props.name !== undefined ?
      <label onClick={this.props.clearName}>{this.props.name}<span>x</span></label>
      : null

    const timeFilter = this.props.from && this.props.to ? 
      <label>{`${this.props.from} - ${this.props.to}`}</label> :
      null

    const degreeFilter = this.props.degree !== null && this.props.degree !== undefined ? 
      <label>{this.props.degree === 0 ? 'Anyone' : this.props.degree == 1 ? 'Friends' : 'Friends of Friends'}</label> : 
      null

    const bpmMinFilter = this.props.bpmMin !== null && this.props.bpmMin !== "" && this.props.bpmMin !== undefined ?
      <label onClick={this.props.clearBpmMin}>Min: {this.props.bpmMin} BPM<span>x</span></label>
      : null

    const bpmMaxFilter = this.props.bpmMax && this.props.bpmMax !== "" ?
      <label onClick={this.props.clearBpmMax}>Max: {this.props.bpmMax} BPM<span>x</span></label>
      : null

    return (
      <div className='active-filters'>
        <h3>Filtering by:</h3>
        {degreeFilter}
        {timeFilter}
        {bpmMinFilter}
        {bpmMaxFilter}
        {keyFilters}
        {artistFilters}
        {nameFilter}
        {cityFilters}
        {genreFilters}
        {countryFilters}
        {
          (bpmMinFilter || bpmMaxFilter || 
          countryFilters.length > 0 || 
          artistFilters.length > 0 ||
          genreFilters.length > 0 || 
          keyFilters.length > 0 ||
          nameFilter !== null) ? 
            <label onClick={this.props.clearAll}>Clear All<span>x</span></label>
            : null
        }
      </div>
    )
  }
}

Filters.defaultProps = {
  cities: [],
  genres: [],
  countries: [],
  bpmMin: '',
  bpmMax: '',
  artists: [],
  keys: [],
}

export default Filters

import filter from 'lodash/filter'
import uniqBy from 'lodash/uniqBy'

export const filterEmptyVideos = (results) => {
  return filter(results, (result) =>
    result.video !== null && result.video.uuid !== undefined 
  )
}

export const filterDuplicateVideos = (results) => {
  return uniqBy(results, (result) =>
    result.video.youtube_id
  )
}

export const filterVideoIds = (results) => {
  return results.map((result) => result.video.youtube_id)
}

export const formatYoutubeUrl = (id) => {
  return `https://img.youtube.com/vi/${id}/mqdefault.jpg`
}

export const secondsToDurationString = (duration) => {
  if (duration === null ||
     duration === undefined) {
    return ''
  }

  const totalSeconds = duration * 60

  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = Math.floor(totalSeconds % 60)

  if (totalSeconds < 10) {
    return `0:0${seconds}`
  }

  if (totalSeconds < 60) {
    return `0:${seconds}`
  }

  let durationString = hours > 0 ? `${hours}:` : ''
  durationString += minutes > 0 ? `${minutes}:` : ''
  durationString += seconds >= 10 ? seconds : `0${seconds}`

  return durationString
}

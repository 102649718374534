import React from 'react';
import Helmet from 'react-helmet'

import ClickHandler from 'components/click-handler'

export default class Privacy extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Privacy Policy - Mu6ik</title>
        </Helmet>
        
        <h1>Mu6ik Inc. Privacy Policy</h1>
        <h5>Last Updated: May 13th, 2021</h5>
        <br/>

        <p> 
          This Privacy Policy ("Privacy Policy") explains how information is collected, used and disclosed by Mu6ik, Inc. (“Mu6ik”, "us", "we", or "our"), and applies to information we collect when you use or access our online or mobile websites, products, services or applications (collectively, the "Services"), or when you otherwise interact with us. We respect the privacy rights of users and recognize the importance of protecting information collected about you.
        </p>

        <p>
          We use your data to provide to improve the Service. By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from
          &nbsp;<a href='https:// mu6ik.com/terms'>https:// mu6ik.com</a>
        </p>

        
        <h2>
          Changes to This Policy
        </h2>

        <p>
          We may change this Privacy Policy from time to time. If we make any changes, we will notify you by revising the "Last Updated" date at the top of this Privacy Policy, by providing prominent notification of any change on the Service or the homepage of our website or by an email to the email address you provide to us.   We encourage you to review our Privacy Policy whenever you access the Services to stay informed about our practices in connection with your information and the ways you can help protect your privacy. Your use of any of the Services after the posting of such changes shall constitute your consent to such changes and your consent to all  of the terms and conditions of the revised Privacy Policy.
        </p>

        <h2>Definitions</h2>
        
        <h3>Personal Data</h3>
        <p>
          Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
        </p>

        <h3>Usage Data</h3>
        <p>
          Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
        </p>

        <h3>Cookies</h3>
        <p>
          Cookies are small pieces of data stored on a User’s device.
        </p>

        <h3>Data Controller</h3>
        <p>
          Data Controller means a person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed.
        </p>

        <p>
          For the purpose of this Privacy Policy, we are a Data Controller of your data.
        </p>
         
        <h3>Data Processor (or Service Providers)</h3>
        <p>
          Data Processor (or Service Provider) means any person (other than an employee of the Data Controller) who processes the data on behalf of the Data Controller.
        </p>

        <p>
          We may use the services of various Service Providers in order to process your data more effectively.
        </p>

        <h3>Data Subject</h3>
        <p>
          Data Subject is any living individual who is the subject of Personal Data.
        </p>

        <h3>User</h3>
        <p>
          The User is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.
        </p>

        <h2>Information Collection And Use</h2>
        <p>
          We collect several different types of information for various purposes to provide and improve our Services and to make them more tailored toward you.
        </p>

        <h3>Personal Data</h3>
        <ul>
          <li>
            For example, we collect information when you register with Mu6ik for an account, create or modify your account, access and use the Services (including but not limited to when you upload, download, or share data or other information), participate in any interactive features of the Services, make a purchase, request customer support, communicate with us via third party social media sites or otherwise communicate with us.
          </li>

          <li>
            The types of information we may collect directly from you include your name, username, email address, postal address, phone number, information about your data collection, processing and storage preferences, employer’s name, job title, transactional information (including services purchased or subscribed to and billing address) as well as any contact or other information you choose to provide. We also store the data or other information that you upload or provide to the Services ("Content") in order to be able to provide you with the features and functionality of the Services.
          </li>

          <li>
            We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.  Regardless of the foregoing, if you are a resident of the EU, we will only send you newsletters, marketing or promotional materials and other information if you expressly “opt in” – either during the account registration process, in an email to us, or other means – to receive such information. 
          </li>
        </ul>

        <h3>Usage Data</h3>
        <p>
          When you access or use the Services, we may automatically collect information about you, including:
        </p>

        <ul>
          <li>
            <b>Usage Information:</b>
            We monitor user activity in connection with the Services and may collect information about the applications and features you use, the websites you visit, the sizes and names of the files you upload, download, share or access while using the Services, the Content you access and any actions taken in connection with the access and use of your Content in the Services.
          </li>

          <li>
            <b>Log Information:</b>
            We log information about you when you access and use the Services including your Internet Protocol ("IP") address, access times, browser type and language, Internet Service Provider ("ISP"), the Web pages that you visit, the Content you use and the URL of the Web page you visited before navigating to the Services.
          </li>

          <li>
            <b>Device Information:</b>
            If you access the Services from a mobile device, we collect information about the device, including the hardware model, operating system and version, unique device identifiers, mobile network information (as allowed by the mobile network) or platform information (as allowed by the specific platform type). We do not ask for, access or track any location based information from your mobile device at any time while downloading or using our mobile apps.
          </li>

          <li>
            <b>Third party cookies:</b>
            The use of cookies by third party applications is not covered by our Privacy Policy. We do not have access to or control over such cookies.
          </li>

          <li>
            <b>Advertising Cookies:</b>
            We may partner with third parties to manage our advertising on other sites. Such third parties may use technologies such as cookies to gather information about your activities on our site and other sites you visit in order to provide you advertising based upon your browsing activities and interests.
          </li>
        </ul>

        <h3>Tracking Cookies and Other Tracking Technologies</h3>
        <p>
          We use cookies and similar tracking technologies to track the activity on our Services and hold certain information, which may include saving cookies to your computer or mobile device. We may also collect information using web beacons (also known as "tracking pixels"). Web beacons are electronic images (also called "gifs") that may be used in the Services or in emails that help us to deliver cookies, count visits, understand usage and campaign effectiveness and determine whether an email has been opened and acted upon. For more information about cookies and how to disable them, please see "Your Choices" below.
        </p>

        <p>
          Cookies are small data files stored on your hard drive or in device memory that help us to improve the Services and your experience, customize your experience and preferences, allow you to access and use the Services without re-entering your member ID or password, understand which areas and features of the Services are most popular and count visits.
        </p>
        
        <p>
          You can configure your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Services.
        </p>

        <h3>Examples of Cookies we use:</h3>
        <ul>
          <li>
            <b>Session Cookies:</b> 
            We use Session Cookies to operate our Service.
          </li>
          <li>
            <b>Preference Cookies:</b> 
            We use Preference Cookies to remember your preferences and various settings.
          </li>
          <li>
            <b>Security Cookies:</b> 
            We use Security Cookies for security purposes.
          </li>
        </ul>

        <h2>
          Use of Data
        </h2>

        <p>
          We may use the information we collect about you for a variety of purposes, including to:
        </p>

        <ul>
          <li>
            Provide, operate, maintain and improve the Mu6ik Services;
          </li>

          <li>
            Enable you to access and use the Mu6ik Services, including collecting, uploading, downloading, processing, storing and sharing Content;
          </li>

          <li>
            Send you technical notices, updates, security alerts and support and administrative messages;
          </li>

          <li>
            Provide and deliver the services and features you request, process and complete transactions, and send you related information, including purchase confirmations and invoices;
          </li>

          <li>
            Respond to your comments, questions, and requests and provide customer service and support;
          </li>

          <li>
            Communicate with you about services, features, offers and events, and provide other news or information about Mu6ik and our select partners;
          </li>

          <li>
            Monitor and analyze trends, usage, and activities in connection with the Mu6ik Services and for marketing or advertising purposes;
          </li>

          <li>
            Investigate and prevent fraudulent transactions, unauthorized access to the 
            Mu6ik Services, and other illegal activities;
          </li>

          <li>
            Personalize and improve the Mu6ik Services, and provide content, features, and/or advertisements that match your interests and preferences or otherwise customize your experience on the Mu6ik Services;
          </li>

          <li>
            Link or combine with other information we receive from third parties to help understand your needs and provide you with better service;
          </li>

          <li>
            Enable you to communicate, collaborate, and share files with users you designate; and
          </li>
          
          <li>
            For other purposes about which we notify you.
          </li>

        </ul>

        <h2>
          Retention of Data
        </h2>

        <p>
          Mu6ik Inc will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. 
        </p>

        <p>
          Mu6ik Inc will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods. 
        </p>

        <h2>
          Transfer Of Data
        </h2>

        <p>
          Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
        </p>

        <p>
          If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.
        </p>

        <p>
          Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
        </p>

        <p>
          Mu6ik will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
        </p>

        <h2>
          Sharing and Disclosure of Data
        </h2>

        <p>
          We will not share personal information about you or any Content with any third parties without your consent except as described in this Privacy Policy or in connection with the Services. For example, we may share personal information about you as follows: 
        </p>

        <ul>
          <li>
            <b>Third Party Applications:</b> 
            Certain aspects of the Services allow or require you to connect with third party applications or services. In connection with your use of any such third party applications or services, such third parties may receive information about you including your username and any Content you choose to use in connection with those applications and services, and such third parties may contact you directly as necessary. The Services may automatically collect and upload certain data from such applications. This Privacy Policy does not apply to your use of such third party applications and services, and we are not responsible for how those third parties collect, use and disclose your information and Content. We encourage you to review the privacy policies of those third parties before connecting to or using their applications or services to learn more about their information and privacy practices.
          </li>

          <li>
            <b>Compliance with Laws:</b> 
            We may disclose your information to a third party if (a) we believe that disclosure is reasonably necessary to comply with any applicable law, regulation, legal process or governmental request, (b) to enforce our agreements, policies and Terms of Service, (c) to protect the security or integrity of the Services, (d) to protect Mu6ik, our customers or the public from harm or illegal activities, or (e) to respond to what we believe in good faith to be an emergency.
          </li>

          <li>
            <b>Disclosure for Law Enforcement:</b> 
            We also may be required to disclose an individual’s personal information in response to a lawful request by public authorities (e.g. a court or a government agency), including to meet national security or law enforcement requirements.
          </li>

          <li>
            <b>Business Transfers:</b>
            We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.
          </li>

          <li>
            <b>Aggregated or Anonymized Data:</b>
            We may also share aggregated or anonymized information with third parties that does not directly identify you.
          </li>
        </ul>

        <h2>
          Service Providers
        </h2>

        <p>
          We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used. 
        </p>

        <p>
          These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
        </p>

        <ul>
          <li><b>Youtube: </b>
            Youtube is a video hosting platform, please visit the Youtube Privacy Terms web page:
            <br/>
            <a href="https://www.youtube.com/t/terms">
              https://www.youtube.com/t/terms
            </a>
          </li>
        </ul>

        <h3>
          Analytics
        </h3>

        <p>
          We may use third-party Service Providers to monitor and analyze the use of our Service.
        </p>

        <ul>
          <li>
            <b>Google Analytics: </b> 
            Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.
          </li>

          <li>
            You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.
          </li>

          <li>
            For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:
            <br/>
            <a href='https://www.google.com/intl/en/policies/privacy/'>
              https://www.google.com/intl/en/policies/privacy/
            </a>
          </li>

          <li>
            <b>Mixpanel: </b>
            Mixpanel is provided by Mixpanel Inc.
          </li>
         
          <li>
            You can prevent Mixpanel from using your information for analytics purposes by opting-out. To opt-out of Mixpanel service, please visit this page:
            <br/>
            <a href='https://mixpanel.com/optout/'> https://mixpanel.com/optout/</a>
          </li>

          <li>
            For more information on what type of information Mixpanel collects, please visit the Terms of Use page of Mixpanel:
            <br/>
            <a href='https://mixpanel.com/terms/'>https://mixpanel.com/terms/</a>
          </li>
        </ul>

        <h3>
          Payments
        </h3>

        <p>
          We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).
        </p>

        <p>
          We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
        </p>

        <p>
          The payment processors we work with are:
        </p>

        <ul>
          <li>
            <b>Stripe: </b>Their Privacy Policy can be viewed at 
            <a href='https://stripe.com/us/privacy'>https://stripe.com/us/privacy</a>
          </li>

          <li>
            <b>PayPal: </b> Their Privacy Policy can be viewed at &nbsp;
            <a href='https://www.paypal.com/webapps/mpp/ua/privacy-full'>
              https://www.paypal.com/webapps/mpp/ua/privacy-full
            </a>
          </li>
        </ul>

        <h2>
          Security
        </h2>

        <p>
          While no service is completely secure, Mu6ik takes reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction. 
        </p>

        <p>
          The servers on which personal information is stored are kept in a controlled environment with limited access. While we take reasonable efforts to guard personal information we knowingly collect directly from you, no security system is impenetrable. In addition, we cannot guarantee that any passively-collected personal information you choose to include in files you store on our systems are maintained at levels of protection to meet specific needs or obligations you may have relating to that information.
        </p>
        
        <p>
          You may access your account information and our service only through the use of an individual user ID and password. To protect the confidentiality of personal information, you must keep your password confidential and not disclose it to any other person. Please advise us immediately if you believe your password has been misused. In addition, always logout and close your browser when you finish your session.
        </p>

        <h2>
          Your Choices
        </h2>

        <h3>
          Account Information & Retention
        </h3>

        <p>
          You may update, correct or delete information about you at any time by logging into your online account and modifying your information. We will retain your personal information for the period necessary to fulfill the purposes outlined in this Privacy Policy. If you wish to deactivate your account, please email us at 
          <a href='mailto:legal@mu6ik.com'>legal@mu6ik.com</a> 
          but note that we may retain certain information as required by law or for legitimate business purposes. We may also retain cached or archived copies of information about you for a certain period of time.
        </p>

        <p>
          We will retain your information for as long as your account is active or as needed to provide you access to or use of the Services. We will retain and use your information to comply with our legal obligations, resolve disputes and enforce our agreements.
        </p>

        <h3>
          Promotional and Newsletter Communications 
        </h3>

        <p>
          If you opt out of receiving promotional or advertising materials or as an EU resident you do not opt in to receive such materials (as set forth above), we may still send you non-promotional communications, such as security alerts and notices related to your access to or use of the Services or those about your online account or our ongoing business relations.
        </p>

        <h3>
          Cookies 
        </h3>

        <p>
          Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies or to prompt you before accepting such a cookie. Please note that, if you choose to remove or reject browser cookies, this could affect the availability or functionality of the Services.
        </p>

        <h3>
          Direct Marketing 
        </h3>

        <p>
          All users who have an established business relationship with Mu6ik may choose to opt out of Mu6ik’s disclosure of personal information about them to third parties for direct marketing purposes. If you choose to opt-out at any time after granting approval email
          <a href='mailto:marketing@mu6ik.com'>marketing@mu6ik.com</a>.
        </p>


        <h2>
          "Do Not Track" Signals
        </h2>
        <p>
          We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.
        </p>

        <p>
          You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.
        </p>

        <h2>
          Links to Third Party Websites
        </h2>

        <p>
          We may include links to third party websites from the Services. When you click on a link to a third party website, your activity and use on the linked website is governed by that website’s policies, not by those of Mu6ik. We encourage you to visit their websites and review their privacy and user policies.
        </p>

        <h2>
          Our Policy Toward Children
        </h2>

        <p>
          The Mu6ik Services are not directed to individuals under 13. We do not knowingly collect personal information from children under 13. If you become aware that a child has provided us with personal information, please contact us at 
          <a href='mailto:legal@mu6ik.com'>legal@mu6ik.com</a>. 
          If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.
        </p>

        <h2>
          Contacting Us
        </h2>

        <p>
          Any questions about this Privacy Policy should be addressed to 
          <a href='mailto:legal@mu6ik.com'>legal@mu6ik.com</a>
        </p>

      </div>
    );
  }
}

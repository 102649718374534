import React from 'react';

export default class CityList extends React.Component {
	constructor(props) {
		super(props);
	}

	locationName(city) {
		if(city.country.name === 'United States') {
			return `${city.name}, ${city.province.name}`
		} else if (city.country.name === 'Canada') {
			return `${city.name}, ${city.province.name}, ${city.country.name}`
		} else {
			return `${city.name}, ${city.country.name}`
		}
	}

	render() {
		const { cities } = this.props;

		return (
			<ul className='list-dropdown'>
				{
					cities.map((c, i) => 
						<li key={i} className='list-item' onClick={() => this.props.handleClick(c, this.locationName(c))}>
							<span className='name'>{this.locationName(c)}</span>
							<span className='glyphicon glyphicon-plus'></span>
						</li>
					)
				}	
			</ul>
		);
	}
}

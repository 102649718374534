import React, { Component } from 'react'
import M6kCheckbox from 'components/m6k-checkbox';

class FilterOption extends Component {
  render () {
    return (
      <li
        className='filter-option'
        onClick={() => {
          const isFilter = this.props.item.isFilter === undefined ? true : !this.props.item.isFilter
          this.props.onChange(this.props.item.id, isFilter)
        }} 
      >
        <M6kCheckbox
          checked={this.props.item.isFilter}
          label={this.props.item.name}
        /> 
      </li>
    )
  }
}

export default FilterOption

import { injectReducer } from 'store/reducers'
import asyncComponent from 'components/async-component'

export default (store) => asyncComponent(() => new Promise((resolve) => {
  require.ensure([], (require) => {
    const PasswordRouting = require('./components/password-routing').default
    const reducer = require('./modules/password').default

    injectReducer(store, { key: 'password', reducer })

    resolve(PasswordRouting)
  }, 'password')
}))

import React from 'react';
import throttle from 'lodash/throttle'

const withInfiniteScroll = (Component) => (
	class InfiniteScroll extends React.Component {
		constructor(props) {
			super(props)
			this.onScroll = throttle(this.onScroll.bind(this), 500, {leading: true, trailer: true})
		}

		componentDidMount() {
			window.addEventListener('scroll', this.onScroll, false)
		}

		componentWillUnmount() {
			window.removeEventListener('scroll', this.onScroll, false)
		}

		onScroll() {
			const height = document.querySelector('.app').offsetHeight;
			const offset = window.innerHeight + window.pageYOffset

  		if(offset >= height - 350) {
  			this.props.onPaginatedSearch()
  		}
		}

		render() {
			return <Component {...this.props} />
		}
	}
)

export default withInfiniteScroll
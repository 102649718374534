import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import { verifyResetToken, updatePassword, clearPasswordError } from '../../../modules/password'
import { formatErrors } from 'lib/format-message'

import queryString from 'query-string'
import MiniFooter from 'components/mini-footer'

export class UpdatePassword extends React.Component {
  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.renderErrorMessages = this.renderErrorMessages.bind(this)
  }

  componentDidMount () {
    const params = queryString.parse(this.props.location.search)
    this.token = params.token

    this.props.verifyResetToken(this.token)
  }

  handleChange () { 
    if (this.password.value === '' && this.passwordConfirmation.value === '') {
      if (this.props.error !== null) {
        this.props.clearPasswordError()
      }
    }
  }

  handleSubmit () {
    const password = this.password.value
    const confirmPassword = this.confirmPassword.value

    this.props.updatePassword(
      password,
      confirmPassword,
      this.token
    )
  }

  renderErrorMessages() {
    let errorMessages = null
    const errors = formatErrors(this.props.error)
    errorMessages = errors.map((message, index) =>
      <span key={index} >
        <span className='glyphicon glyphicon-exclamation-sign' />
        <p className='error-message'>
          {message}
        </p>
      </span>
    )

    return errorMessages
  }

  render () {
    let inputClass = this.props.error !== null ?  'form-control form-error' : 'form-control'

    return (
      <div className='reset-password-page'>
        <Helmet>
          <title>Mu6ik / Create New Password</title>
        </Helmet>

        <div className='reset-password-panel'>
          <h1>Mu6ik</h1>
          <form onSubmit={(e) => {
            e.preventDefault()
            this.handleSubmit()
          }}>
            {
              this.props.error ?
                this.renderErrorMessages() :
                null
            }
            <div className='form-group'>
              <input
                type='password'
                className={inputClass}
                placeholder='Password'
                ref={(password) => this.password = password}
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                className={inputClass}
                placeholder='Confirm Password'
                ref={(password) => this.confirmPassword = password}
              />
            </div>
            <button type='submit' className='btn btn-default reset'>Create New Password</button>
          </form>
        </div>
        <MiniFooter />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.password.error
  }
}

export default connect(mapStateToProps, {
  clearPasswordError,
  verifyResetToken,
  updatePassword
})(UpdatePassword)

import React from 'react';

export default class GenreList extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { genres } = this.props;

		return (
			<ul className='list-dropdown'>
				{
					genres.map((g, i) => 
						<li key={i} className='list-item' onClick={() => this.props.handleClick(g)}>
							<span className='name'>{g.name}</span>
							<span className='glyphicon glyphicon-plus'></span>
						</li>
					)
				}	
			</ul>
		);
	}
}

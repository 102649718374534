import React from 'react';
import ClickHandler from 'components/click-handler'

import '../styles/header.scss'; 

export default class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <header className='public-header'>
        <nav className="navbar navbar-default">
          <div className='container'>
            <div className='navbar-header'>
              <h1>
                <ClickHandler url="/">
                  MU6IK
                </ClickHandler>
              </h1>
            </div>

            <ul className="nav navbar-nav navbar-right">
              <li>
                <ClickHandler url='/login' className='sign-in'>
                  Log In
                </ClickHandler>
              </li>

              <li>
                <ClickHandler url='/signup/underground'>
                  <button className='btn navbar-btn btn-primary sign-up'>
                    Get Access
                  </button>
                </ClickHandler>
              </li>
            </ul>

          </div>
        </nav>
      </header>
    );
  }
}

import axios from 'lib/axios-config'
import filter from 'lodash/filter'
import { replace } from 'connected-react-router'

export function fetchEmailSubscriptions () {
  return (dispatch, getState) => {
    const url = `/api/user_email_subscriptions`
    const email_notifications = getState().emailNotifications

    if (email_notifications.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: 'FETCH_EMAIL_SUBSCRIPTIONS'
    })

    return axios.get(url)
      .then((response) => {
        dispatch({
          type: 'FETCH_EMAIL_SUBSCRIPTIONS_SUCCESS',
          payload: {
            data: response.data
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_EMAIL_SUBSCRIPTIONS_FAILURE'
        })
      })
  }
}

export function toggleEmailSubscription (index, is_subscribed) {
  return {
    type: 'TOGGLE_EMAIL_SUBSCRIPTION',
    payload: {
      index,
      is_subscribed
    }
  }
}

export function verifySubscription (sid) {
  return (dispatch, getState) => {
    const email_notifications = getState().emailNotifications
    const url = `/api/user_email_subscriptions/${sid}/verify_subscription`

    if (email_notifications.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: 'VERIFY_SUBSCRIPTION_IDENTIFIER'
    })

    return axios.get(url)
      .then((response) => {
        dispatch({
          type: 'VERIFY_SUBSCRIPTION_IDENTIFIER_SUCCESS',
          payload: {
            data: response.data
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: 'VERIFY_SUBSCRIPTION_IDENTIFIER_FAILURE',
          payload: {
            error: error.response.data.message
          }
        })
        dispatch(replace('/login'))
      })
  }
}

export function unsubscribe (sid) {
  return (dispatch, getState) => {
    const email_notifications = getState().emailNotifications
    const url = `/api/user_email_subscriptions/${sid}/unsubscribe`

    if (email_notifications.isUpdating) {
      return Promise.reject()
    }

    dispatch({
      type: 'REQUEST_UNSUBSCRIBE_SUBSCRIPTION'
    })

    return axios.put(url)
      .then((response) => {
        dispatch({
          type: 'REQUEST_UNSUBSCRIBE_SUBSCRIPTION_SUCCESS'
        })
      })
      .catch((error) => {
        dispatch({
          type: 'REQUEST_UNSUBSCRIBE_SUBSCRIPTION_FAILURE'
        })
      })
  }
}

export function updateEmailNotifications () {
  return (dispatch, getState) => {
    const email_notifications = getState().emailNotifications
    const url = `/api/user_email_subscriptions/bulk_update`

    if (email_notifications.isFetching) {
      return Promise.reject()
    }

    const subscriptionsToUpdate = filter(email_notifications.subscriptions, {'update': true})

    dispatch({
      type: 'UPDATE_EMAIL_NOTIFICATIONS'
    })

    return axios.post(url, {
      user_email_subscriptions: subscriptionsToUpdate
    })
      .then((response) => {
        return dispatch({
          type: 'UPDATE_EMAIL_NOTIFICATIONS_SUCCESS',
          payload: {
            data: response.data
          }
        })
      })
      .catch((error) => {
        return dispatch({
          type: 'UPDATE_EMAIL_NOTIFICATIONS_FAILURE',
          payload: {
            error: error.response.data.message
          }
        })
      })
  }
}

const defaultState = {
  isFetching: false,
  isUpdating: false,
  didUnsubscribe: false,
  didInvalidate: false,
  error: null,
  subscriptions: []
}

const email_notifications = (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_EMAIL_SUBSCRIPTIONS':
    case 'UPDATE_EMAIL_NOTIFICATIONS':
    case 'VERIFY_SUBSCRIPTION_IDENTIFIER':
      return {
        ...state,
        isFetching: true,
        didInvalidate: false
      }
    case 'REQUEST_UNSUBSCRIBE_SUBSCRIPTION':
      return {
        ...state,
        isUpdating: true,
        didInvalidate: false,
        didUnsubscribe: false
      }
    case 'FETCH_EMAIL_SUBSCRIPTIONS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        subscriptions: action.payload.data
      }
    case 'UPDATE_EMAIL_NOTIFICATIONS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        subscriptions: action.payload.data
      }
    case 'VERIFY_SUBSCRIPTION_IDENTIFIER_SUCCESS':
      return {
        ...state,
        isFetching: false,
        subscriptions: [action.payload.data]
      }
    case 'REQUEST_UNSUBSCRIBE_SUBSCRIPTION_SUCCESS':
      return {
        ...state,
        isUpdating: false,
        didUnsubscribe: true
      }
    case 'VERIFY_SUBSCRIPTION_IDENTIFIER_FAILURE':
    case 'REQUEST_UNSUBSCRIBE_SUBSCRIPTION_FAILURE':
    case 'UPDATE_EMAIL_NOTIFICATIONS_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'FETCH_EMAIL_SUBSCRIPTIONS_FAILURE':
      return {
        ...state,
        isUpdating: false
      }
    case 'TOGGLE_EMAIL_SUBSCRIPTION':
      const subscription = state.subscriptions[action.payload.index]

      subscription.is_subscribed = action.payload.is_subscribed
      subscription.update = true

      return {
        ...state,
        subscriptions: [
          ...state.subscriptions.slice(0, action.payload.index),
          subscription,
          ...state.subscriptions.slice(action.payload.index + 1)
        ]
      }
    default:
      return state
  }
}

export default email_notifications

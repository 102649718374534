import indexOf from 'lodash/indexOf'
import axios from 'lib/axios-config'

import { normalize } from 'normalizr'
import { song } from '../../../schemas/main'
import { getItem } from 'lib/local-storage'

import {
  selectFavoriteSongs,
  selectHistorySongs
} from 'app/modules/song'

import {
  YOUTUBE_PLAYER_STATE_UNSTARTED,
  YOUTUBE_PLAYER_STATE_ENDED,
  YOUTUBE_PLAYER_STATE_PLAYING,
  YOUTUBE_PLAYER_STATE_PAUSED,
  YOUTUBE_PLAYER_STATE_BUFFERING,
  YOUTUBE_PLAYER_STATE_CUED
} from 'lib/constants'

export function setIndex (index) {
  return {
    type: 'SET_INDEX',
    index
  }
}

export function adjustCurrentVideoIndex (videoIds) {
  return (dispatch, getState) => {
    const state = getState()
    const player = state.player
    const playerState = player.playerState

    if (playerState !== YOUTUBE_PLAYER_STATE_PLAYING &&
       playerState !== YOUTUBE_PLAYER_STATE_BUFFERING) {
      return Promise.resolve()
    }

    const currentIndex = player.currentVideoIndex
    const currentId = player.currentVideoId
    const index = indexOf(videoIds, currentId)

    if (index !== currentIndex) {
      dispatch(setIndex(index))
    }
  }
}

export const trackPlay = (params = {}) => {
  return (dispatch, getState) => {
    const state = getState()
    const url = `/api/song_played_stats`

    if(state.player.isTrackingPlay) {
      return Promise.reject()
    }

    dispatch({
      type: 'CREATE_SONG_PLAYED_STAT_REQUEST'
    })

    return axios.post(url, params)
      .then(() => {
        dispatch({
          type: 'CREATE_SONG_PLAYED_STAT_SUCCESS'
        })

        return Promise.resolve()
      })
      .catch(() => {
        dispatch({
          type: 'CREATE_SONG_PLAYED_STAT_FAILURE'
        })

        return Promise.reject()
      })
  }
}

export function setVideoId (videoId) {
  return {
    type: 'SET_VIDEO_ID',
    videoId
  }
}

export function setPlayerFunction (fn, fnName) {
  return {
    type: 'SET_PLAYER_FUNCTION',
    fn,
    fnName
  }
}

export function setPlayerState (playerState) {
  return {
    type: 'SET_PLAYER_STATE',
    payload: {
      playerState
    }
  }
}

export function setPlayerSongId (songId) {
  return {
    type: 'SET_PLAYER_SONG_ID',
    songId
  }
}

export function setPlayerArtistId (artistId) {
  return {
    type: 'SET_PLAYER_ARTIST_ID',
    artistId
  }
}

export function toggleRepeat () {
  return {
    type: 'TOGGLE_REPEAT'
  }
}

export function toggleShuffle () {
  return {
    type: 'TOGGLE_SHUFFLE'
  }
}

export function nextSong (i) {
  return i + 1
}

export function prevSong (i) {
  return i - 1
}

export function repeatSong (i) {
  return i
}

export function shuffleSong (i, l) {
  let newIndex = i

  while (newIndex === i) {
    newIndex = Math.floor(Math.random() * l)
  }

  return newIndex
}

const seekSong = (currentIndex, playlistLength, operatorFn) => {
  if (playlistLength === 0) {
    return undefined
  }

  if (currentIndex === null || currentIndex === undefined) {
    return 0
  }

  const i = operatorFn(currentIndex, playlistLength)
  const l = playlistLength

  return ((i % l) + l) % l
}

export function queueNextSong (operatorFn) {
  return (dispatch, getState) => {
    const state = getState()
    const player = state.player
    const artist = state.artist

    let nextSong = null
    let nextIndex = null
    let playlist = null;
    let sid = null;

    switch(state.player.location) {
      case 'favorites':
        playlist = selectFavoriteSongs(state)

        nextIndex = seekSong(
          player.currentIndex,
          playlist.length,
          operatorFn
        )

        nextSong = playlist[nextIndex]
        break;
      case 'history':
        playlist = selectHistorySongs(state)

        nextIndex = seekSong(
          player.currentIndex,
          playlist.length,
          operatorFn
        )

        nextSong = playlist[nextIndex]
        break;
      case 'search':
        playlist = state.search.songs.ids
        
        nextIndex = seekSong(
          player.currentIndex,
          playlist.length,
          operatorFn
        )

        sid = nextIndex === undefined ? player.songId : playlist[nextIndex]
        nextSong = state.entities.songs[sid]
        break;
      case 'songFeed':
        playlist = state.songFeed.ids
        
        nextIndex = seekSong(
          player.currentIndex,
          playlist.length,
          operatorFn
        )

        sid = nextIndex === undefined ? player.songId : playlist[nextIndex]
        nextSong = state.entities.songs[sid]
        
        break;
      case 'transitions':
        const currentSong = state.entities.songs[state.song.selectedId]
        playlist = currentSong.transitions.to 

        nextIndex = seekSong(
          player.currentIndex,
          playlist.length,
          operatorFn
        )
        
        sid = playlist[nextIndex]
        nextSong = state.entities.songs[sid]
        break;
      case 'playlist':
        playlist = state.entities.playlists[state.playlists.playlist];


        nextIndex = seekSong(
          player.currentIndex,
          playlist.playlistSongs.length,
          operatorFn
        )

        const ps = playlist.playlistSongs[nextIndex];
        nextSong = state.entities.songs[ps.song]
        break;
      case 'artist':
        playlist = state.song.ids

        nextIndex = seekSong(
          player.currentIndex,
          playlist.length,
          operatorFn
        )

        sid = nextIndex === undefined ? player.songId : playlist[nextIndex]
        nextSong = state.entities.songs[sid]
      default:
        playlist = state.song.ids

        nextIndex = seekSong(
          player.currentIndex,
          playlist.length,
          operatorFn
        )

        sid = nextIndex === undefined ? player.songId : playlist[nextIndex]
        nextSong = state.entities.songs[sid]
    }
    
    if (player.loadVideo) {
      player.loadVideo(
        nextSong.video.youtube_id,
        nextSong.id
      )
    }
    
    dispatch({
      type: 'SET_NEXT_SONG',
      nextIndex,
      videoId: nextSong.video.youtube_id, 
      nextSongId: nextSong.id,
    })
  }
}

export const selectPlayerSong = (state) => {
  const entities = state.entities
  const id = state.player.songId

  if(!id) return {}

  const song = entities.songs[id]; 
  if(!song) return {}


  const djs = song.djs ? 
    song.djs.map((dj) => {
      return state.entities.artists[dj];
    }) : []

  return {
    ...song,
    djs: djs, 
  }
}

export const selectDJs = (state) => {
  const entities = state.entities

  return state.player.djs.map((id) => {
    const artist = entities.artists[id]

    return {
      ...artist
    }
  })
}

export const updatePlaylistLocation = (location) => ({
  type: 'UPDATE_PLAYLIST_LOCATION',
  payload: { location }
})

const playerState = getItem('playerState') || {}
const defaultState = {
  isFetching: false,
  isRepeat: playerState.isRepeat || false,
  isShuffle: playerState.isShuffle || false,

  isTrackingPlay: false,

  currentIndex: null,
  videoId: playerState.videoId || null,
  songId: playerState.songId || null,

  djs: [],

  playVideo: null,
  playerState: null,
  location: 'listen',
}

const player = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_INDEX':
      return {
        ...state,
        currentIndex: action.index
      }
    case 'CREATE_SONG_PLAYED_STAT_REQUEST':
      return {
        ...state,
        isTrackingPlay: true
      }
    case 'CREATE_SONG_PLAYED_STAT_SUCCESS':
      return {
        ...state,
        isTrackingPlay: false,
      }
    case 'CREATE_SONG_PLAYED_STAT_FAILURE':
      return {
        ...state,
        isTrackingPlay: false,
      }
    case 'SET_VIDEO_ID':
      return {
        ...state,
        videoId: action.videoId
      }
    case 'SET_PLAYER_FUNCTION':
      return {
        ...state,
        [action.fnName]: action.fn
      }
    case 'SET_PLAYER_STATE':
      return {
        ...state,
        playerState: action.payload.playerState
      }
    case 'SET_PLAYER_SONG_ID':
      return {
        ...state,
        songId: action.songId
      }
    case 'SET_PLAYER_ARTIST_ID':
      return {
        ...state,
        artistId: action.artistId
      }
    case 'TOGGLE_SHUFFLE':
      return {
        ...state,
        isShuffle: !state.isShuffle
      }
    case 'TOGGLE_REPEAT':
      return {
        ...state,
        isRepeat: !state.isRepeat
      }
    case 'SET_NEXT_SONG':
      return {
        ...state,
        currentIndex: action.nextIndex,
        songId: action.nextSongId,
        artistId: action.nextArtistId,
        videoId: action.videoId,
      }
    case 'SEARCH_ARTISTS_PLAYED_THIS_SONG':
      if (action.reducerName !== 'player') {
        return state
      }

      return {
        ...state,
        isFetching: true
      }
    case 'SEARCH_ARTISTS_PLAYED_THIS_SONG_SUCCESS':
      if (action.reducerName !== 'player') {
        return state
      }

      return {
        ...state,
        isFetching: false,
        djs: action.payload.ids
      }
    case 'SEARCH_ARTISTS_PLAYED_THIS_SONG_FAILURE':
      if (action.reducerName !== 'player') {
        return state
      }

      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      }
    case 'CLEAR_ARTISTS_PLAYED_THIS_SONG':
      if (action.reducerName !== 'player') {
        return state
      }

      return {
        ...state,
        djs: []
      }
    case 'UPDATE_PLAYLIST_LOCATION':
      return {
        ...state,
        location: action.payload.location
      }
    case 'RESET_PLAYER':
      return defaultState
    case 'UPDATE_PLAYLIST_SONG':
    case 'UPDATE_PLAYLIST_SONG_FAILURE':
      let currentIndex = state.currentIndex;

      if(action.payload.sourceIndex === currentIndex) {
        currentIndex = action.payload.destIndex
      }

      return {
        ...state,
        currentIndex,
      }
    default:
      return state
  }
}

export default player

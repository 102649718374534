import React from 'react';

import { NavLink } from 'react-router-dom'

export default class ProfileNavItem extends React.Component {
	render() {
		const { username, name, count } = this.props
		const url = `/${username}/${name.toLowerCase()}`

		return (
			<li className='profile-nav-item' >
				<NavLink to={url} activeClassName='active'>
					<h4>{name}</h4>
					<h5>{count}</h5>
				</NavLink>
			</li>
		);
	}
}

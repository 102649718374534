import React from 'react';

export default class BlockedUserRow extends React.Component {
  constructor(props) {
    super(props);

    this.toggleHover = this.toggleHover.bind(this)

    this.state = {
      isHover: false,
    }
  }

  toggleHover(isHover) {
    this.setState({
      isHover
    })
  }

  render() {
    return (
      <tr>
        <td className='img-prev'>
          <img src={this.props.url} />
        </td>
        <td className='title'>{this.props.name}</td>
        <td className='unblock-btn'
        >
          <button 
            onClick={this.props.unblock}
            onMouseEnter={(e) => {
              e.preventDefault()
              this.toggleHover(true)
            }}
            onMouseLeave={(e) => {
              e.preventDefault()
              this.toggleHover(false)
            }}
            className={`btn btn-${this.state.isHover ? 'primary' : 'danger'}`}
          >
            { this.state.isHover ? 'Unblock' : 'Blocked' }
          </button> 
        </td>
      </tr>
    );
  }
}

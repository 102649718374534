import { titleCase } from './string'

export function formatErrors (messageObject) {
  const keys = Object.keys(messageObject)
  const messages = []

  for (let key of keys) {
    const title = key.split('_').join(' ')
    const messagePrefix = titleCase(title)

    const errors = messageObject[key]
    for (let error of errors) {
      const message = `${messagePrefix} ${error}`
      messages.push(message)
    }
  }

  return messages
}
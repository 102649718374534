import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import { push } from 'connected-react-router'

import ClickHanlder from 'components/click-handler';
import { loginAuth, clearAuthError } from 'modules/auth'
import BannerNotification from 'components/banner-notification'

import MiniFooter from 'components/mini-footer'

import '../login.scss'

class Login extends Component {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      email: null
    }
  }

  handleChange () {
    if (this.email.value === '' && this.password.value === '') {
      if (this.props.auth.error !== null) {
        this.props.clearAuthError()
      }
    }

    this.setState({email: this.email.value})
  }

  render () {
    const { auth } = this.props

    let inputClass = 'form-control'
    let errorMessage = null

    if (auth.error) {
      inputClass += ' form-error'
      errorMessage =
        <span>
          <span className='glyphicon glyphicon-exclamation-sign' />
          <p className='error-message'>
            {auth.error.message}
          </p>
        </span>
    }

    const params = this.state.email ? '?email=' + this.state.email : ''
    const url = '/reset_password/new' + params

    return (
      <div className='login-page'>
        <Helmet>
          <title>Mu6ik / Login</title>
        </Helmet>

        <BannerNotification persistDuration={6500} />

        <div className='login-panel'>
          <h1>Mu6ik</h1>
          <form onSubmit={(e) => {
            e.preventDefault()
            this.props.loginAuth(this.email.value, this.password.value)
          }}>

            {errorMessage}

            <div className='form-group'>
              <input
                type='email'
                className={inputClass}
                placeholder='Email'
                onChange={this.handleChange}
                ref={email => this.email = email} />
            </div>
            <div className='form-group'>
              <input
                type='password'
                className={inputClass}
                placeholder='Password'
                onChange={this.handleChange}
                ref={(password) => this.password = password}
              />
              <a href={url} className='password-reset' onClick={(e) => {
                e.preventDefault()
                this.props.navigateToReset(this.email.value)
              }}>
                <h6>Forgot your password?</h6>
              </a>
            </div>
            <button type='submit' className='btn btn-default login'>Log In</button>
            
            <ClickHanlder url='/signup/underground' className='waitlist'>
              <h6>Don't have an account? Register</h6>
            </ClickHanlder>
          </form>
        </div>

        <MiniFooter />

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginAuth: (email, password) => {
      dispatch(loginAuth(email, password))
    },
    clearAuthError: () => {
      dispatch(clearAuthError())
    },
    navigateToWaitlist () {
      dispatch(push({pathname: '/waitlist'}))
    },
    navigateToReset (email) {
      const params = { email }

      dispatch(push({
        pathname: '/reset_password/new',
        search: queryString.stringify(params)
      }))
    }
  }
}

export default connect(mapStateToProps,
  mapDispatchToProps
)(Login)

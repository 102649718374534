import React from 'react'
import debounce from 'lodash/debounce'

export default class M6kPopoverWrapper extends React.Component {
  constructor (props) {
    super(props)

    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)

    this.renderPopover = this.renderPopover.bind(this)
    this.closePopover = this.closePopover.bind(this)

    this.delayedRenderPopover = debounce(this.renderPopover, 250)
    this.delayedClosePopover = debounce(this.closePopover, 225)

    this.processEvent = false

    this.state = {
      popoverVisible: false
    }
  }

  onMouseEnter (e) {
    e.preventDefault()
    e.stopPropagation()

    this.toggleSelector(true)
  }

  onMouseLeave (e) {
    e.preventDefault()
    e.stopPropagation()

    this.toggleSelector(false)
  }

  toggleSelector (state) {
    if (state) {
      if (this.processEvent) {
        this.delayedClosePopover.cancel()
        this.processEvent = false
      } else {
        this.processEvent = true
        this.delayedRenderPopover()
      }
    } else {
      if (this.processEvent) {
        this.delayedRenderPopover.cancel()
        this.processEvent = false
      } else {
        this.processEvent = true
        this.delayedClosePopover()
      }
    }
  }

  renderPopover () {
    if (this.props.onEnter) {
      this.props.onEnter()
    }

    this.setState({popoverVisible: true})
    this.processEvent = false
  }

  closePopover () {
    if (this.props.onClose) {
      this.props.onClose()
    }

    this.setState({popoverVisible: false})
    this.processEvent = false
  }

  render () {
    const childWithProp = React.Children.map(this.props.children, (child) => {
      if (child.type !== 'span') {
        return React.cloneElement(child, {
          isVisible: this.state.popoverVisible
        })
      } else {
        return child
      }
    })

    return (
      <div
        className='m6k-popover-wrapper'
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {childWithProp}
      </div>
    )
  }
}

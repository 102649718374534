import React from 'react';

import { buildResourceUrl } from 'lib/string'
import { formatYoutubeUrl, secondsToDurationString } from 'lib/video'

import M6kThumbnail from './m6k-thumbnail'
import SongDetails from './song-details'

import DropdownMenu from './dropdown-menu' 

import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'

export class CompactPlaylistItem extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      mouseEnter: false,
      mouseLeave: true,
      hover: false,
      isError: false,
      loaded: false,
      showDropdownMenu: false,
      dropdownPositionTop: '70px', 
    }

    this.renderItem = this.renderItem.bind(this)
    this.renderErrorItem = this.renderErrorItem.bind(this)

    this.toggleHover = debounce(this.toggleHover.bind(this), 5)
    this.handleLoad  = this.handleLoad.bind(this)
    this.handleError = this.handleError.bind(this)
    this.handleControlAction = this.handleControlAction.bind(this)
    this.handleSelect  = this.handleSelect.bind(this)
    this.handleDropdownMenu = this.handleDropdownMenu.bind(this)

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)

    this.onScroll = throttle(this.onScroll.bind(this), 500, {leading: true, trailer: true})

    this.toggleFavorite = this.toggleFavorite.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false)

    this.onScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(this.props.playerIndex === null && nextProps.playerIndex === nextProps.listIndex) {
      return true
    }

    // Changes to this item being played
    if(this.props.playerIndex !== this.props.listIndex &&
       nextProps.playerIndex === this.props.listIndex) {
      return true;
    }

    if(this.props.isMobile !== nextProps.isMobile) {
      return true;
    }

    if(this.state.loaded !== nextState.loaded) {
      return true;
    }

    if(this.props.renderDJ !== nextProps.renderDJ) {
      return true;
    }

    // Changes to a different item being played
    if(this.props.playerIndex === this.props.listIndex &&
       nextProps.playerIndex !== nextProps.listIndex) {
      return true
    }

    if(this.props.playerIndex === this.props.listIndex &&
       this.props.playerState !== nextProps.playerState) {
      return true
    }

    // Changes to this item being selected
    if(this.props.selectIndex !== this.props.listIndex &&
       nextProps.selectIndex === nextProps.listIndex) {
      return true
    }

    // Changes to a different item to be selected
    if(this.props.selectIndex === this.props.listIndex && 
      nextProps.selectIndex !== nextProps.listIndex) {
      return true
    }

    if(this.props.isFavorite !== nextProps.isFavorite) {
      return true
    }

    if(this.state.hover !== nextState.hover) {
      return true
    }

    if(this.state.isError !== nextState.isError) {
      return true
    }

    if(this.props.uuid !== nextProps.uuid) {
      return true
    }

    //  Clicked dropdown menu button
    if(this.state.showDropdownMenu !== nextState.showDropdownMenu) {
      return true;
    }

    // Position changed
    if(this.state.dropdownPositionTop !== nextState.dropdownPositionTop) {
      return true;
    }

    return false
  }

  handleMouseEnter(e) {
    e.preventDefault();

    if(this.state.mouseEnter && !this.state.mouseLeave) return;

    this.setState({
      mouseEnter: true,
      mouseLeave: false,
      hover: true,
    })
  }

  handleMouseLeave(e) {
    e.preventDefault();

    if(!this.state.mouseEnter && this.state.mouseLeave) return;

    this.setState({
      mouseEnter: false,
      mouseLeave: true,
      hover: false,
    })
  }

  toggleHover () {
    if(!this.state.loaded) return;

    const hover = !this.state.hover
    this.setState({hover})
  }

  toggleFavorite(e) {
    e.preventDefault();
    
    if (this.props.isFavorite) {
      this.props.unfavoriteSong(this.props.songId)
    } else {
      this.props.favoriteSong(this.props.songId, this.props.dj.id)
    }
  }

  handleLoad(id) {
    this.setState({loaded: true})
  }

  handleError(index) {
    this.setState({isError: true})

    if(this.props.uuid === null || this.props.uuid === undefined) return

    this.props.removeVideo(this.props.uuid)
      .then(() => {
        this.props.fetchSong(this.props.songId)
          .then(() => {})
          .catch((error) => {
            console.log(this.props.songId, error)
          })
      })
  }

  handleSelect() {
    this.props.select(this.props.listIndex)
  }

  handleControlAction(e) {
    e.preventDefault();
    e.stopPropagation()

    const { song } = this.props;
    
    const index = this.props.listIndex

    const playerState = this.props.playerState
    const playerIndex = this.props.playerIndex

    const isCurrent = (index === playerIndex) && (song.video.youtube_id === this.props.playerVideoId)

    const isPlaying = isCurrent && (playerState === 1)
    const isPaused = isCurrent && (playerState === 2)
    const isBuffering = isCurrent && (playerState === 3)

    const isActive = isCurrent &&
      playerState !== null &&
      playerState !== -1 &&
      playerState !== 5

    if (isActive) {
      if (isPaused) {
        this.props.playVideo()
      } else {
        this.props.pauseVideo()
      }
    } else {
      this.props.playVideoAt(this.props.listIndex)
    }
  }

  handleDropdownMenu(e) {
    e.preventDefault();

    this.setState({showDropdownMenu: true})
  }

  onScroll() {
    const ele = document.getElementById(`setlist-item-${this.props.listIndex}`);

    if(!ele) return;

    const viewportOffset = ele.getBoundingClientRect();

    const musicPlayerHeight = 90;
    const dropdownPadding = 200;
    const viewableHeight = window.innerHeight - musicPlayerHeight - dropdownPadding;

    if(viewportOffset.y >= viewableHeight) {
      if(!this.state.showDropdownMenu) {
        this.setState({
          dropdownPositionTop: '-70px'
        })
      }
    } else {
      if(!this.state.showDropdownMenu) {
        this.setState({
          dropdownPositionTop: '70px'
        })
      }
    }
  }

  renderDropdownMenu() {
    if(!this.state.showDropdownMenu) { return null; }

    return (
      <DropdownMenu 
        handleClick={(action) => {
          this.props.showPlaylistModal(this.props.song.id, action)
        }}
        positionTop={this.state.dropdownPositionTop}
        closeDropdown={() => {
          this.setState({showDropdownMenu: false})
        }}
      /> 
    )
  }

  renderItem () {
    const song = this.props.song;

    const duration = secondsToDurationString(song.video.duration)
    const imageUrl = formatYoutubeUrl(song.video.youtube_id);
    const songUrl  = buildResourceUrl('song', song.video.name, song.id)

    const index = this.props.listIndex

    const playerState = this.props.playerState
    const playerIndex = this.props.playerIndex

    const isCurrent = (index === playerIndex) && (song.video.youtube_id === this.props.playerVideoId)

    const isPlaying = isCurrent && (playerState === 1)
    const isPaused = isCurrent && (playerState === 2)
    const isBuffering = isCurrent && (playerState === 3)

    const isActive = isCurrent &&
      playerState !== null &&
      playerState !== -1 &&
      playerState !== 5

    const isSelect = index === this.props.selectIndex
    const isHover = this.state.hover

    const rowClass = `compact-playlist-item ${isActive ? 'active' : ''} ${isSelect ? 'select' : ''} ${isHover ? 'hover' : ''}`

    return (
      <li
        id={`setlist-item-${this.props.listIndex}`}
        className={rowClass}
        onClick={this.handleSelect}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        { this.renderDropdownMenu() }

        <M6kThumbnail
          index={index}
          imageUrl={imageUrl}
          songUrl={songUrl}

          handleLoad={this.handleLoad}
          handleError={this.handleError}
          duration={duration}
        />

        <SongDetails
          index={index}
          isPlaying={isPlaying}
          isPaused={isPaused}
          isBuffering={isBuffering}
          isActive={isActive}
          isSelect={isSelect}
          isHover={isHover}
          isMobile={this.props.isMobile}
          handleControlAction={this.handleControlAction}
          hideGenres={true}
          hideDetails={true}

          handleDropdownMenu={this.handleDropdownMenu}

          keyMode={this.props.keyMode}
          song={song}
          songUrl={songUrl}

          isFavorite={this.props.isFavorite}
          toggleFavorite={this.toggleFavorite}
        />
      </li>
    )
  }

  renderErrorItem () {
    const index = this.props.listIndex

    return (
      <li className='setlist-item error'>
        <div className='title'>
          <span>{this.props.name}</span>
        </div>
      </li>
    )
  }

  render () {
    if (this.state.isError && this.props.showError) {
      return this.renderErrorItem()
    } else if (!this.state.isError) {
      return this.renderItem()
    } else {
      return null
    }
  }
}


export default CompactPlaylistItem;
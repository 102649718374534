import React from 'react'

const M6kRadio = (props) => {
  return (
    <label className='m6k-radio' onClick={props.handleClick}>
      <input
        type='radio'
        name='option'
        checked={props.checked || false}
        onChange={e => e.preventDefault()}
        onClick={e => e.preventDefault()}
      />

      <span className='outer'>
        <span className='inner' />
      </span>

      {props.label}
    </label>
  )
}

export default M6kRadio

import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { replace } from 'connected-react-router'

const locationHelper = locationHelperBuilder({})

// export const userIsAuthenticated = (Component, FailureComponent) => connectedReduxRedirect({
//   redirectPath: (state, ownProps) => {
//     const location = ownProps.location

//     if (location.pathname === '/settings/email_notifications') {
//       return `/unsubscribe${location.search}`
//     } else {
//       return '/'
//     }
//   },
//   authenticatedSelector: state => state.auth.authenticated,
//   allowRedirectBack: (ownProps) => {
//     const location = ownProps.location

//     return location.pathname !== '/settings/email_notifications' && location.pathname !== '/'
//   },
//   wrapperDisplayName: 'UserIsAuthenticated',
//   redirectAction: replace
// })

export const userIsAuthenticated = (Component, FailureComponent) => connectedAuthWrapper({
  authenticatedSelector: state => state.auth.authenticated, 
  wrapperDisplayName: 'UserIsAuthenticated',
  FailureComponent
})(Component)

export const visibleOnlyLoggedOut = connectedAuthWrapper({
  authenticatedSelector: state => !state.auth.authenticated,
  wrapperDisplayName: 'VisibleOnlyLoggedOut',
})

export const userIsNotAuthenticated = connectedReduxRedirect({
  redirectPath: (state, ownProps) => {
    if (location.pathname === 'terms' || location.pathname === 'privacy-policy') {
      return location.pathname
    }

    return locationHelper.getRedirectQueryParam(ownProps) || '/'
  },
  authenticatedSelector: state => !state.auth.authenticated,
  allowRedirectBack: false,
  wrapperDisplayName: 'UserIsNotAuthenticated',
  redirectAction: replace
})

export const extractTokenFromHeaders = (response) => {
  console.log(response.headers)
  const auth = response.headers['authorization']
  const token = auth.split('Bearer ')[1]

  return token
}

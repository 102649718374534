import React from 'react';
import { connect } from 'react-redux'

import ClickHandler from 'components/click-handler'
import Loading from 'react-loading'
import categories from '../../../categories'
import find from 'lodash/find' 
import { titleCase } from 'lib/string'

import { setBreadcrumbs, clearBreadcrumbs } from 'modules/breadcrumbs'

class Category extends React.Component {
  constructor(props) {
    super(props);

    this.renderCategory = this.renderCategory.bind(this)

    this.state = {
      category: null,
      loaded: false,
    }
  }

  renderLoading() {
    return (
      <div className='m6k-panel' style={{'padding': '20px'}}>
        <div style={{'width' : '60px', 'height': '60px', "margin": 'auto'}}>
          <Loading
            type='spin'
            color='#aaa'
            delay={0}
            height={60}
            width={60}
          />
        </div>
      </div>
    )
  }

  renderCategory() {
    const { category } = this.state;

    const urls = category.articles.map((article, index) => {
      return (
        <li key={index}>
          <ClickHandler url={article.url}>
            { article.name }
          </ClickHandler>
        </li>
      )
    })

    return (
      <div className='m6k-panel categories'>
        <div className='category'>
          <h2>
            <ClickHandler url={category.url} >
              { category.name }
            </ClickHandler>
          </h2>
          <ul>
            { urls }
          </ul>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const { name } = this.props.match.params;

    const result = find(categories, (r) => {
      return r.name.replace(" ", "-").toLowerCase() === name.toLowerCase() 
    })

    if(result) {

      this.props.setBreadcrumbs([{
        name: 'Mu6ik Help Center',
        url: '/help/'
      }, {
        name: result.name,
        url: result.url
      }]);

      this.setState({
        category: result,
        loaded: true
      })
    } else {
    }
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs()
  }

  render() {
    return (
      <div>
        { this.state.loaded ? this.renderCategory() : this.renderLoading() }
      </div>
    );
  }
}

export default connect(null, {
  setBreadcrumbs,
  clearBreadcrumbs
})(Category)
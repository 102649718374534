import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { StoreContext } from 'store/configure-store'

import Header from '../../home/components/header'
import MobileHeader from './mobile-header'
import Footer from 'components/footer/footer'

import PublicDJ from '../routes/dj/public'
import Legal from 'routes/legal'
import Home from '../../home'
import NotFound from './not-found'

import LoginModal from 'app/components/login-modal'
import MusicPlayer from '../shared/music-player'

import { loadUI } from 'app/modules/current-user/ui'

import RenderedRoute from 'components/rendered-route'

import '../styles/app.scss'

const mapStateToProps = (state) => {
  return {
    playerActive: state.player.songId !== null && state.player.artistId !== null,
  }
}

export class Public extends React.Component {
  constructor(props) {
    super(props);

    this.handleResize = this.handleResize.bind(this)

    this.openLoginModal = this.openLoginModal.bind(this)
    this.renderLoginModal = this.renderLoginModal.bind(this)

    this.state = {
      isMobile: false,
      renderLoginModal: false,
    }
  }

  handleResize () {
    const width = window.innerWidth
    const isMobile = this.state.isMobile

    if (width > 767 && isMobile) {
      this.setState({isMobile: false})
    } else if (width <= 767 && !isMobile) {
      this.setState({isMobile: true})
    }
  }

  componentDidMount () {
    this.props.loadUI()

    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (this.state.isMobile !== nextState.isMobile) {
      return true
    } else if (this.props.location.pathname !== nextProps.location.pathname) {
      return true
    } else if (this.props.location.search !== nextProps.location.search) {
      return true
    } else if (this.props.playerActive !== nextProps.playerActive) {
      return true
    } else if (this.state.renderLoginModal !== nextState.renderLoginModal) {
      return true;
    } else {
      return false
    }
  }

  componentWillMount () {
    const store = this.context
    this.DJ   = PublicDJ(store)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }

  renderLoginModal() {
    if(!this.state.renderLoginModal) return;

    console.log('render login modal')

    return (
      <LoginModal
        isOpen={this.state.renderLoginModal}
        onCancel={() => {
          this.setState({renderLoginModal: false})
        }}
      />
    )
  }
  openLoginModal() {
    console.log('opening')
    this.setState({renderLoginModal: true}) 
  }

  render() {
    let styles = [];

    const isDJPath = this.props.location.pathname.includes("dj")

    if(isDJPath) {
      styles["margin-left"] = "368px"
    } else {
      styles["marginLeft"] = "0px"
    }

    return (
      <div className='app' style={styles}>
        <div className='app-container'>
          <Header />
          <Switch>
            <RenderedRoute path='/terms'     component={Legal} />
            <RenderedRoute path='/privacy-policy' component={Legal} />

            <RenderedRoute path='/dj/:slug/:id/'  component={this.DJ} />
            <RenderedRoute exact path="/" component={Home} />
            <RenderedRoute exact path="/" component={Home} />
            <RenderedRoute exact path="*" component={NotFound} />
          </Switch>

          { this.renderLoginModal() }

          { isDJPath ? 
              <MusicPlayer 
                isPublic 
                openLoginModal={this.openLoginModal}
              /> 
              : null 
          }

        </div>
        
        <Footer/>   

        <div className='modal-container' />
      </div>
    );
  }
}

Public.contextType = StoreContext; 

export default connect(
  mapStateToProps,
  {
    loadUI,
  }
)(Public)

//         <MusicPlayer />

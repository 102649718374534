import React from 'react';
import { connect } from 'react-redux'

import {
  selectPlaylists,
  fetchPlaylists,
  createPlaylist,
} from 'app/modules/playlists'

import CreatePlaylistModal from './create-playlist-modal';

import '../styles/playlists.scss'

import PlaylistSummary from './playlist-summary';

import {
  selectProfile,
  isCurrentUser,
} from 'app/modules/profile'

class Playlists extends React.Component {
  constructor(props) {
    super(props);

    this.handleResize = this.handleResize.bind(this)

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderCreateButton = this.renderCreateButton.bind(this)

    this.state = {
      showCreateModal: false,
      isMobile: false,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    if(this.props.isCurrentUser) {
      this.props.fetchPlaylists()
    } else {
      this.props.fetchPlaylists({user_id: this.props.profile.uuid})
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }


  handleResize () {
    const width = window.innerWidth
    const isMobile = this.state.isMobile

    if (width > 767 && isMobile) {
      this.setState({isMobile: false})
    } else if (width <= 767 && !isMobile) {
      this.setState({isMobile: true})
    }
  }

  renderCreateButton() {
    return (
        <button 
          className='btn btn-default create-playlist'
          onClick={(e) => {
            this.setState({showCreateModal: true})
          }}
        >
          Create New Playlist
        </button>
    )
  }

  renderHeader() {
    return (
      <div className='row heading'>
        <div className='col-md-8 col-sm-8 col-xs-8'>
          <h3>Playlists</h3>
        </div>

        <div className='col-md-4 col-sm-4 col-xs-4'>
          { this.props.isCurrentUser ? this.renderCreateButton() : null }
        </div>
      </div>
    )
  }

  renderEmpty() {
    <div className='body'>
      <h3>No playlists here</h3>
    </div>
  }

  renderBody() {
    const playlists = this.props.playlists.map((playlist, index) => {
      return (
        <div className='col-md-4 col-xs-6 col-sm-4' key={`playlist-detail-${index}`}>
          <PlaylistSummary
            {...playlist} 
          />
        </div>
      )
    })

    if(playlists.length === 0) {
      return this.renderEmpty();
    }
    
    return (
      <div className='row body'>
        { playlists }
      </div>
    )
  }

  renderModal() {
    if(this.state.showCreateModal) {
      return (
        <div>
          <CreatePlaylistModal
            show={this.state.showCreateModal}
            createPlaylist={this.props.createPlaylist}
            onCancel={() => {
              this.setState({showCreateModal: false})
            }}
          />
        </div>
      )
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className='playlists m6k-panel'> 
        { this.state.isMobile ? null : this.renderHeader() }
        { this.renderBody() } 

        { this.renderModal() }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    playlists: selectPlaylists(state),
    isCurrentUser: isCurrentUser(state),
    profile: selectProfile(state),
  }
}

export default connect(mapStateToProps, {
  fetchPlaylists,
  createPlaylist
})(Playlists);
import React from 'react';
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Range } from 'rc-slider'
import moment from 'moment'
import queryString from 'query-string'

import { isBrowser } from "react-device-detect";
import Loading from 'react-loading'

import {
  selectCurrentUser
} from 'app/modules/current-user/account'

import {
  selectFollowingDJs,
  updateDJSearch,
} from 'app/modules/following'

import {
  updatePlaylistLocation
} from 'app/shared/music-player/modules/player'

import {
  fetchFeedTemplates,
  fetchFeedTemplate,
  selectFeedTemplates,
} from 'app/modules/feed-templates'

import FeedTemplateModal from './feed-template-modal'

import {
 updateGenreSearch,
 fetchGenres,
 selectGenres 
} from 'app/modules/genres'

import {
  loadKeys,
  selectKeys,
  updateKeySearch
} from 'app/modules/keys'

import {
  getFollowedDJs
} from 'app/modules/following'

import {
  selectSongFeed,
  fetchSongFeed,
  clearSongFeed,
  cleanupSession,
  toggleFilterOption,
  resetIsEnd,
  selectFilters,
  clearAllFilterOptions,
} from 'app/modules/song-feed'

import {
  markGenerator
} from 'lib/string'

import Setlist from 'app/shared/setlist'
import withInfiniteScroll from 'app/shared/infinite-scroll'

import FilterSection from 'app/shared/filters/filter-section';
import FilterPanel from 'app/shared/filters/filter-panel/index'
import Filters from 'app/shared/filters/filters'

import '../styles/song-feed.scss'

const mapStateToProps = (state) => {
  return {
    cursor: state.songFeed.cursor,
    isEnd: state.songFeed.isEnd,
    sfSession: state.songFeed.sfSession,
    songs: selectSongFeed(state),
    currentUser: selectCurrentUser(state),
    isFetching: state.songFeed.isFetching,
    genres: selectGenres(state),
    genreFilters: selectFilters(state, 'genres'),
    artistFilters: selectFilters(state, 'artists'),
    djFilterValue: state.following.search,
    keyFilters: selectFilters(state, 'keys'),
    genreFilterValue: state.genres.search,
    djs: selectFollowingDJs(state),
    filters: state.songFeed.filters,
    keys: selectKeys(state),
    keyFilterValue: state.keys.search,
    templates: selectFeedTemplates(state), 
  }
}

class SongFeed extends React.Component {
  constructor(props) {
    super(props);

    this.renderSetlist = this.renderSetlist.bind(this)
    this.fetchSongFeed = this.fetchSongFeed.bind(this)
    this.cleanupAndFetchSongFeed = this.cleanupAndFetchSongFeed.bind(this)

    this.renderFilters     = this.renderFilters.bind(this)
    this.renderEndListItem = this.renderEndListItem.bind(this)

    this.renderBrowser = this.renderBrowser.bind(this);
    this.renderMobile = this.renderMobile.bind(this)

    this.openFeedTemplateModal = this.openFeedTemplateModal.bind(this)
    this.renderFeedTemplateModal = this.renderFeedTemplateModal.bind(this)

    this.toggleShowDetails = this.toggleShowDetails.bind(this)
    this.toggleShowDelete = this.toggleShowDelete.bind(this)

    this.clearAllFilterOptions = this.clearAllFilterOptions.bind(this)
    this.setFiltersFromTemplate = this.setFiltersFromTemplate.bind(this)

    this.handleSliding = this.handleSliding.bind(this)
    this.handleRelease = this.handleRelease.bind(this)
    this.handleRangeValues = this.handleRangeValues.bind(this)

    this.filterBpm = this.filterBpm.bind(this)

    this.state = {
      genres: [],
      artists: [],
      isPaginatedFetching: false,
      currentYear: new Date().getFullYear(),
      from: 2012,
      changedTo: false,
      to: new Date().getFullYear(),
      bpmMin: '',
      bpmMax: '',
      isInitialFetching: true,
      totalFollowing: null,
      showFeedTemplateModal: false,
      templates: [],
    }
  }

  componentWillMount() {
    this.InfiniteSongFeed = withInfiniteScroll(Setlist)
  }

  clearAllFilterOptions() {
    this.props.clearAllFilterOptions('genres')
    this.props.clearAllFilterOptions('artists')
    this.props.clearAllFilterOptions('keys')

    this.setState({
      bpmMin: '',
      bpmMax: '',
    })
  }

  setFiltersFromTemplate(template) {
    const settings = JSON.parse(template.settings);

    this.clearAllFilterOptions()

    this.setState({
      from: settings.from,
      to: settings.to,
      bpmMin: settings.bpmMin,
      bpmMax: settings.bpmMax,
    })

    if(settings.artists) {
      settings.artists.forEach((artist) => {
        this.props.toggleFilterOption('song-feed', 'artists', artist.id, true)
      })
    }

    if(settings.genres) {
      settings.genres.forEach((genre) => {
        this.props.toggleFilterOption('song-feed', 'genres', genre.id, true)
      })
    }

    if(settings.keys) {
      settings.keys.forEach((key) => {
        this.props.toggleFilterOption('song-feed', 'keys', key.id, true)
      })
    }

    this.cleanupAndFetchSongFeed()
  }

  componentDidMount() {
    this.props.updatePlaylistLocation('songFeed')

    this.props.getFollowedDJs(this.props.currentUser.uuid, true)
      .then((data) => {
        this.setState({
          isInitialFetching: false,
          totalFollowing: data.length
        })
      })
      
    this.props.fetchFeedTemplates()
      .then((results) => {
        this.setState({
          templates: results.map((t) => {
            return {
              ...t,
              showDetails: false,
              showUpdating: false,
              showDeleting: false,
            }
          })
        })
      })

    this.props.fetchGenres()

    const urlParams = queryString.parse(this.props.location.search)
    if(urlParams && urlParams.template && urlParams.template.includes("m6k-") && urlParams.template.length === 20) {
      this.props.fetchFeedTemplate(urlParams.template)
        .then((result) => {
          this.setFiltersFromTemplate(result); 
        })
        .catch(() => {
          this.fetchSongFeed()
        })
    } else {
      this.fetchSongFeed()
    }

    this.props.loadKeys();
  }

  componentWillUnmount() {
    this.props.cleanupSession(this.props.sfSession)
    this.props.clearSongFeed()
  }

  handleSliding (range) {
    this.handleRangeValues(range)
  }

  openFeedTemplateModal(e) {
    e.preventDefault();

    this.setState({
      showFeedTemplateModal: true
    })
  }

  handleRelease (range) {
    this.handleRangeValues(range)

    // if(!this.state.changedTo) return;

    this.props.cleanupSession(this.props.sfSession)
    this.props.clearSongFeed()
      .then(() => {
        this.props.resetIsEnd()
        this.fetchSongFeed()
        this.setState({changedTo: false})
      })
  }

  toggleShowDelete(index) {
    const templates = this.state.templates;
    
    this.setState({
      templates: templates.map((t, i) => i === index ? 
        {...t, showDeleting: !t.showDeleting, showDetails: false, showUpdating: false } : 
        t
      )
    })
  }

  toggleShowDetails(index) {
    const templates = this.state.templates;

    this.setState({
      templates: templates.map((t, i) => i === index ? 
        {...t, showDetails: !t.showDetails, showDeleting: false, showUpdating: false } : 
        t
      )
    })
  }

  handleRangeValues (range) {
    let from = range[0]
    let to   = range[1]

    let changedTo = false

    if(to !== this.state.to) {
      changedTo = true 
    }

    this.setState({
      from, 
      to,
      changedTo
    })

    return changedTo
  }

  fetchSongFeed() {
    if(this.props.isEnd || this.props.isFetching) return;

    const { from, to, bpmMin, bpmMax } = this.state;

    this.props.fetchSongFeed({
      filter_by_played: true,
      cursor: this.props.cursor,
      sf_session: this.props.sfSession,
      artists: this.props.filters.artists,
      genres: this.props.filters.genres, 
      keys: this.props.filters.keys,
      from: from,
      to: to,
      bpm_min: bpmMin === '' ? null : parseInt(bpmMin),
      bpm_max: bpmMax === '' ? null : parseInt(bpmMax), 
    })
  }

  renderSetlist() {
    return (
      React.createElement(this.InfiniteSongFeed, {
        songs: this.props.songs,
        onPaginatedSearch: this.fetchSongFeed,
        endListItem: this.renderEndListItem,
        isInitialFetching: this.state.isInitialFetching,
        isPaginatedFetching: this.props.isFetching,
        showError: false,
        title: 'Song Feed'
        // toggleFollow: this.toggleFollow,
      })
    )
  }

  filterBpm(e) {
    e.preventDefault()

    if(this.state.bpmMin === '' && this.state.bpmMax === '') return;

    this.cleanupAndFetchSongFeed()
  }

  componentDidUpdate(prevProps, prevState) {
    const prevTemp = prevProps.templates;
    const curTemp = this.props.templates;
    
    if(prevTemp && curTemp && prevTemp.length !== curTemp.length) {
      this.setState({
        templates: curTemp.map((t) => {
          return {
            ...t,
            showDetails: false,
            showUpdating: false,
            showDeleting: false,
          }
        })
      })
    }
  }

  cleanupAndFetchSongFeed() {
    this.props.cleanupSession(this.props.sfSession)
    this.props.clearSongFeed()
      .then(() => {
        this.props.resetIsEnd()
        this.fetchSongFeed()

        this.setState({changedTo: false})
       })
  }

  validInputNumber(value) {
    return value !== '' && !parseInt(value)
  }

  renderFeedTemplateModal() {
    if(!this.state.showFeedTemplateModal) return;

    return (
      <div>
        <FeedTemplateModal
          genres={this.props.genreFilters}
          artists={this.props.artistFilters}
          keys={this.props.keyFilters}
          from={this.state.from}
          to={this.state.to}
          bpmMin={this.state.bpmMin}
          bpmMax={this.state.bpmMax}

          toggleShowDetails={this.toggleShowDetails}
          toggleShowDelete={this.toggleShowDelete}
          setFiltersFromTemplate={this.setFiltersFromTemplate}

          clearAllFilterOptions={this.clearAllFilterOptions}
          cleanupAndFetchSongFeed={this.cleanupAndFetchSongFeed}
          
          templates={this.state.templates}
          show={this.state.showFeedTemplateModal}
          onCancel={() => {
            this.setState({
              showFeedTemplateModal: false,
            })
          }}
        />
      </div>
    )
  }

  renderFilters() {
    const { from, to } = this.state;

    return (
      <div className='m6k-panel filters'>
        <h3>Filters</h3>

        <FilterSection name='Years Played'>
          <div className='date-slider'>
            <Range
              allowCross={false}
              defaultValue={[from, to]}
              value={[from, to]}
              step={1}
              pushable
              onChange={this.handleSliding}
              onAfterChange={this.handleRelease}
              min={1982}
              max={this.state.currentYear}
              tipFormatter={value => `${value}`}
              marks={markGenerator()}
            />
          </div>
        </FilterSection>

        <FilterSection name='DJs You Follow'>
          <FilterPanel 
            placeholder='Find a DJ'
            items={this.props.djs} 
            filterValue={this.props.djFilterValue}
            filter={this.props.updateDJSearch}
            toggle={(id, isFilter) => {
              // this.props.updateUrl()
              this.props.toggleFilterOption('song-feed', 'artists', id, isFilter)
              
              this.cleanupAndFetchSongFeed()
            }}
          />
        </FilterSection>

        <FilterSection name='Genres'>
          <FilterPanel 
            placeholder='Find a Genre'
            items={this.props.genres} 
            filterValue={this.props.genreFilterValue}
            filter={this.props.updateGenreSearch}
            toggle={(id, isFilter) => {
              // this.props.updateUrl()
              this.props.toggleFilterOption('song-feed', 'genres', id, isFilter)

              this.cleanupAndFetchSongFeed()
            }}
          />
        </FilterSection>

        <FilterSection name='BPM'>
          <div>
            <div className='bpm-filters'>
              <div className='input-group' style={{'marginRight': '15px'}}>
                <label>Min</label>
                <input 
                  type='text' 
                  className='form-control' 
                  value={this.state.bpmMin}
                  onChange={(e) => {
                    e.preventDefault();
                    if(this.validInputNumber(e.target.value)) return;

                    this.setState({bpmMin: e.target.value})
                  }}
                />
              </div> 
              <div className='input-group'>
                <label>Max</label>
                <input 
                  type='text' 
                  className='form-control'
                  value={this.state.bpmMax}
                  onChange={(e) => {
                    e.preventDefault();
                    if(this.validInputNumber(e.target.value)) return;

                    this.setState({bpmMax: e.target.value})
                  }}
                />
              </div>
            </div>
            
            <button onClick={this.filterBpm} className='btn bpm-submit' style={{'display': 'block', 'marginLeft' : '15px'}}>
              Apply
            </button>
          </div>
        </FilterSection>

        <FilterSection name='Key'>
          <FilterPanel 
            placeholder='Find a Key'
            items={this.props.keys} 
            filterValue={this.props.keyFilterValue}
            filter={this.props.updateKeySearch}
            toggle={(id, isFilter) => {
              this.props.toggleFilterOption('song-feed', 'keys', id, isFilter)

              this.cleanupAndFetchSongFeed()
            }}
          />
        </FilterSection>
      </div>
    )
  }

  renderEndListItem() {
    if(this.props.isFetching && !this.props.isEnd) {
      return (
        <li className='setlist-empty'>
          <div className='loading'>
            <Loading
              type='spin'
              color='#aaa'
              delay={0}
              height={45}
              width={45}
            />
          </div>
        </li>
      )
    } else {
      return (
        <li className='setlist-empty'>
          <h3 className='logo'>M</h3>
        </li>
      )
    }
  }

  renderBrowser() {
    if(!this.props.isInitialFetching && this.state.totalFollowing === 0) {
      return (
        <div className='container-fluid'>
          <div className='row' style={{'textAlign': 'center', 'marginTop': '100px'}}>
            <div className='col-md-4 col-md-offset-4'>
              <h1>Welcome to Mu6ik</h1>
              <br/>
              <h3>Start by following the DJs you love.</h3>
              <h5>You'll get up-to-date songs in your feed of the DJs you follow. From festivals, live events, clubs and radio shows, discover all of the latest tracks in an easy to discover interfact.</h5>
            </div>
          </div>
        </div>
      )
    };

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-10 col-md-10 col-sm-10 col-xs-10'>
            <Filters
              genres={this.props.genreFilters}
              artists={this.props.artistFilters}
              keys={this.props.keyFilters}
              from={this.state.from}
              to={this.state.to}
              bpmMin={this.state.bpmMin}
              bpmMax={this.state.bpmMax}

              clearGenre={(id) => {
                this.props.toggleFilterOption('song-feed', 'genres', id)
                this.cleanupAndFetchSongFeed()
              }}

              clearKey={(id) => {
                this.props.toggleFilterOption('song-feed', 'keys', id)
                this.cleanupAndFetchSongFeed()
              }}

              clearArtist={(id) => {
                this.props.toggleFilterOption('song-feed', 'artists', id)
                this.cleanupAndFetchSongFeed()
              }}

              clearBpmMin={() => {
                this.setState({
                  bpmMin: ''
                })

                this.cleanupAndFetchSongFeed()
              }}

              clearBpmMax={() => {
                this.setState({
                  bpmMax: ''
                })

                this.cleanupAndFetchSongFeed()
              }}

              clearAll={() => {
                this.props.clearAllFilterOptions('genres')
                this.props.clearAllFilterOptions('artists')
                this.props.clearAllFilterOptions('keys')

                this.setState({
                  bpmMax: '',
                  bpmMin: '',
                })

                this.cleanupAndFetchSongFeed()
              }}
            />
          </div>

          <div className='col-lg-1 col-md-1 col-sm-1 col-xs-1'>
            <button className='btn template-button m6k-float-right' onClick={this.openFeedTemplateModal}>
              <i className="fas fa-cogs"></i>
            </button>
          </div>

        </div>
        <div className='row'>
          <div className='col-lg-3 m6k-fixed-col-3 col-md-4 col-sm-4 col-xs-4'>
            { this.renderFilters() }
          </div>
          <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>
            { this.renderSetlist() }
          </div>
        </div>
      </div>
    )
  }

  renderMobile() {
    if(!this.props.isInitialFetching && this.state.totalFollowing === 0) {
      return (
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <h1>Welcome to Mu6ik</h1>
            </div>
          </div>
        </div>
      )
    };

    return this.renderSetlist();
  }

  render() {
    return (
      <div className='song-feed'>
        <Helmet>
          <title>Mu6ik</title>
        </Helmet>

        { this.state.showFeedTemplateModal ? this.renderFeedTemplateModal() : null }

        { this.renderBrowser() }
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  fetchSongFeed,
  clearSongFeed,
  cleanupSession,
  getFollowedDJs,
  fetchGenres,
  toggleFilterOption,
  updateGenreSearch,
  clearAllFilterOptions,
  resetIsEnd,
  loadKeys,
  updateKeySearch,
  updateDJSearch,
  updatePlaylistLocation,
  fetchFeedTemplates,
  fetchFeedTemplate
})(SongFeed);

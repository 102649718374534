import React from 'react';
import Helmet from 'react-helmet'

export default class Terms extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Terms of Service - Mu6ik</title>
        </Helmet>

        <h1>Mu6ik Inc. Terms of Service</h1>
        <h5>Last Updated: May 13th, 2021</h5>
        <br/>

        <p>
          Please read these Terms of Service ("Terms" or "Terms of Service") carefully before accessing or using our online or mobile websites, products, services or applications (collectively the "Services") operated by Mu6ik Inc. (“Mu6ik”, "us", "we", or "our").
        </p>

        <p>
          Your access to and use of the Services are conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who wish to access or use the Services.
        </p>

        <p>
          By accessing or using the Services you agree to be bound by these Terms. If you disagree with any part of the Terms then you do not have permission to access or use the Services.
        </p>

        <p>
          If you are using the Services on behalf of an organization, you are agreeing to these Terms on your own behalf and on behalf of that organization and promising to Mu6ik that you have the authority to bind that organization to these Terms (in which case, "you" and "your" will refer to you individually and that organization) unless that organization has a separate paid contract in effect with us, in which event the terms of that contract will govern your use of the Services. You may use the Services only in compliance with these Terms and only if you have the power to form a contract with Mu6ik and are not barred under any applicable laws from doing so. If you do not agree to be bound by these terms, you must not use the Services.
        </p>

        <h2>Changes To These Terms</h2>
        <p>
          We reserve the right, at our sole discretion, to revise these Terms from time to time. We will date and post the most current version of these Terms on the Mu6ik website. Any changes will be effective upon posting the revised version of these Terms on
          <a href='https://mu6ik.com'>https://mu6ik.com</a>
          (the “Site”) (or such later effective date as may be indicated at the top of the revised Terms).
        </p>

        <p>
          We encourage you to check the date of these Terms whenever you visit the Site to see if these Terms have been updated. Your continued access or use of any portion of the Services constitutes your acceptance of such changes and the revised Terms. 
        </p>

        <p>
          If you don't agree to any of the changes, we're not obligated to keep providing the Services, and you must cancel and stop using the Services. In other words, if you do not agree to the revised Terms, you will no longer by authorized to access or use the Services.
        </p>

        <h2>Use of Service</h2>
        
        <p>
          You may use the Service only in strict compliance with these Terms and all applicable laws.
        </p>

        <h2>Accounts</h2>

        <p>
          To obtain access to certain Services, you may be required to obtain an account with Mu6ik (in which case you will become a "Registered User"), by completing a registration form and designating a user ID and password. Until you apply for and are approved for an account your access to the Services will be limited to the areas of the Services, if any, that Mu6ik makes available to the general public. Once you become a Registered User, you may invite others to access your account by registering with the Services and entering a designated  user ID and password. When registering with Mu6ik and accessing the Services, all Registered Users and persons and entities such Registered Users invite to access their accounts must: (a) provide true, accurate, current and complete information about themselves as requested by the Services’ registration form (such information being the "Registration Data"); (b) maintain and promptly update the Registration Data to keep it true, accurate, current and complete at all times thereafter, and (c) strictly comply at all times with these Terms. Mu6ik may deny approval or withdraw such approval at any time in its sole discretion, with or without cause.
        </p>

        <p>
          Only persons or entities designated by you may use your Mu6ik account. All Registered Users must keep their user IDs and passwords confidential and not authorize any third party to access or use the Services on their or your behalf, unless we provide an approved mechanism for such use. Mu6ik will not be liable for any loss or damage arising from any unauthorized use of your account(s).
        </p>

        <p>
          You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.
        </p>

        <p>
          If a third party such as an employer or customer provided you with access to its account, that party has rights to the account and is authorized to: manage your access, reset your password, or suspend or cancel your access; view your usage and profile data, including how and when the account is used; and read or store Content in the account. 
        </p>

        <h2>Communications and Solicitation</h2>

        <p>
          By registering with Mu6ik, you understand that we may send you communications or data regarding the Services, including but not limited to: (a) notices about your use of the Services, including any notices concerning violations of use; (b) updates; and (c) promotional information and materials regarding Mu6ik's products and services, via electronic mail. We will give you the opportunity to opt-out of receiving promotional electronic mail from us by following the opt-out instructions provided in the message.  For additional information, please see Mu6ik’s Privacy Policy at
          <a href='/privacy-policy'> https://mu6ik.com/privacy-policy/</a>
        </p>

        <h2>Notices</h2>

        <p>
          We may send you, in electronic form, information about the Services, additional information, and information the law requires us to provide. We may provide required information to you by email at the address you specified when you signed up for the Services or by access to a website that we identify. Notices emailed to you will be deemed given and received when the email is sent. If you don't consent to receive notices electronically, you must stop using the Services.
        </p>

        <h2>Content</h2>

        <p>
          Except for material that we license to you, we don't claim ownership of any data or content that is uploaded, transmitted, stored, or processed in connection with the Services (collectively, “Content”). We also don't control, verify, or endorse the Content that you and others make available on the Services. Mu6ik has the right but not the obligation to monitor and edit all Content provided by users.
        </p>

        <p>
          If you enable any features that allow you to share the Content with others, anyone you've shared Content with may have access to your Content.
        </p>

        <p>
          You hereby grant Mu6ik and its agents the right to transmit, use and disclose your Content solely to the extent necessary to provide the Services, as otherwise permitted by these Terms, or to comply with any request of a governmental or regulatory body (including subpoenas or court orders), as otherwise required by law, or to respond to an emergency.
        </p>

        <p>
          You represent and warrant that: (a) you have all the rights in the Content necessary for you to use the Services and to grant the rights in this Section; and (b) the collection, storage, use, or transmission of the Content doesn't violate any law or these Terms.
        </p>

        <p>
          You will: (a) be solely responsible for the nature, quality and accuracy of the Content; (b) ensure that the Content (including the collection, storage, use, and transmission thereof) complies with these Terms and any and all applicable laws and regulations; (c) promptly handle and resolve any notices and claims relating to the Content, including any notices sent to you by any person claiming that any Content violates any person's rights, such as take-down notices pursuant to the Digital Millennium Copyright Act and any other notices; and (d) maintain appropriate security, protection and backup copies of the Content, which may include, your use of additional encryption technology to protect the Content from unauthorized access. Mu6ik will have no liability of any kind as a result of the deletion of, correction of, destruction of, damage to, loss of or failure to store or encrypt any Content.
        </p>

        <p>
          You must immediately notify Mu6ik in writing of any unauthorized use of: (a) any Content, (b) any account, or (c) the Services that comes to your attention. In the event of any such unauthorized use by any third party that obtained unauthorized access through you, you will take all steps necessary to terminate such unauthorized use. You will provide Mu6ik with such cooperation and assistance related to any such unauthorized use as Mu6ik may reasonably request.
        </p>

        <h2>Content Stored in the United States</h2>

        <p>
          The Services are provided from the United States. By using and accessing the Services, you understand and consent to the storage and processing of the Content and any other personal information in the United States. In the case of users located outside the United States, Mu6ik reserves the right to store and process Content, including without limitation personal information, outside of the United States.  Any questions on storage or processing of the Content should be sent to
          <a href='mailto:legal@mu6ik.cojm'>legal@mu6ik.com</a>
        </p>

        <h2>Suspension and Termination</h2>

        <p>
          We reserve the right to temporarily suspend or terminate your access to the Services at any time in our sole discretion, with or without cause, with or without notice, and without incurring liability of any kind. For example, we may suspend or terminate your access to or use of the Services for: (a) the actual or suspected violation of these Terms; (b) the use of the Services in a manner that may cause Mu6ik to incur legal liability or disrupt others' use of the Services; (c) the suspicion or detection of any malicious code, virus or other harmful code transmitted by you or in your account; (d) scheduled downtime and recurring downtime; (e) use of excessive storage or processing capacity or bandwidth; or (f) unplanned technical problems and outages. If, in Mu6ik's determination, the suspension might be indefinite and/or Mu6ik has elected to terminate your access to the Services, Mu6ik will use commercially reasonable efforts to notify you through the Services and/or by email to the email address associated with your account. You acknowledge that if your access to the Services is suspended or terminated, you may no longer have access to the Content that is stored within the Services.
        </p>

        <p>
          Upon termination by Mu6ik for reasons other than cause, you may request access to your Content, which we will make available for an additional fee. You must make such request with five (5) days following termination. Otherwise, any Content you have stored in the Services will not be retrievable, and we will have no obligation to maintain any Content you have stored in the Services.
        </p>

        <p>
          In addition to other termination provisions, if your account is not currently subject to a paid subscription plan with us, we at our discretion may terminate your account if: (a) you do not engage in any activity in your account within thirty (30) days after becoming a Registered User; or (b) you do not engage in any activity in your account for any period of one hundred and twenty (120) consecutive days. In the event of such termination, any Content you may have stored will be lost.
        </p>

        <p>
          If you wish to terminate your account, you may simply discontinue using the Services.  You may also request that your account, and any information or Content therein or connected thereto, be deleted from our systems.
        </p>

        <p>
          All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
        </p>

        <h2>Acceptable Use</h2>

        <p>
          You must not use the Services to harm others or the Services. For example, you must not use the Services to harm, threaten, or harass another person, organization, or Mu6ik and/or to build a similar service, application or website. You must not: damage, disable, overburden, or impair the Services (or any network connected to the Services); resell or redistribute the Services or any part of them; use any unauthorized means to modify, reroute, or gain access to the Services or attempt to carry out such activities; or use any automated process or service (such as a bot, a spider, or periodic caching of information stored by Mu6ik) to access or use the Services. In addition, you promise that you will not and will not encourage or assist any third party to:
        </p>

        <ol>
          <li>
            modify, alter, tamper with, repair or otherwise create derivative works of any software made available for download via the Services (“Software”);
          </li>

          <li>
            reverse engineer, disassemble or decompile the software used to provide or access the Services, including the Software, or attempt to discover or recreate the source code used to provide or access the Services, except and only to the extent that the applicable law expressly permits doing so;
          </li>

          <li>
            use the Services in any manner or for any purpose other than as expressly permitted by these Terms, the Privacy Policy, any User Guides or any other policy, instruction or terms applicable to the Services that are made available on through the Services from time to time ("Policies");
          </li>

          <li>
            sell, lend, rent, resell, lease, sublicense or otherwise transfer any of the rights granted to you with respect to the Services to any third party;
          </li>

          <li>
            remove, obscure or alter any proprietary rights notices pertaining to the Services;
          </li>

          <li>
            access or use the Services in a way intended to improperly avoid: i) incurring fees or ii) exceeding usage limits or quotas;
          </li>

          <li>
            use the Services in any situation in which the failure of the Services could lead to death, personal injury, or physical property or environmental damage;
          </li>

          <li>
            use the Services to: (i) engage in any unlawful or fraudulent activity or perpetrate a hoax or engage in phishing schemes or forgery or other similar falsification or manipulation of data; (ii) send unsolicited or unauthorized junk mail, spam, chain letters, pyramid schemes or any other form of duplicative or unsolicited messages, whether commercial or otherwise; (iii) store or transmit inappropriate Content, such as Content: (1) containing unlawful, defamatory, threatening, pornographic, abusive, libelous or otherwise objectionable material of any kind or nature, (2) containing any material that encourages conduct that could constitute a criminal offense, or (3) in a way that violates or infringes upon the intellectual property rights or the privacy or publicity rights of any person or entity or that may otherwise be unlawful or give rise to civil or criminal liability; (iv) store or transmit any Content that contains or is used to initiate a denial of service attack, software viruses or other harmful or deleterious computer code, files or programs such as Trojan horses, worms, time bombs, cancelbots, or spyware; or (v) abuse, harass, stalk or otherwise violate the legal rights of a third party;
          </li>

          <li>
            interfere with or disrupt servers or networks used by Mu6ik to provide the Services or used by other users to access the Services, or violate any third party regulations, policies or procedures of such servers or networks or harass or interfere with another user's full use and enjoyment of any Software or the Services;
          </li>

          <li>
            access or attempt to access Mu6ik's other accounts, computer systems or networks not covered by these Terms, through password mining or any other means;
          </li>

          <li>
            cause, in Mu6ik's sole discretion, inordinate burden on the Services or Mu6ik's system resources or capacity; or
          </li>

          <li>
            share passwords or other access information or devices or otherwise authorize any third party to access or use the Software or the Services.
          </li>
        </ol>

        <p>
          We will make all judgments concerning the applicability of these guidelines in our sole and exclusive discretion. We reserve the right to determine whether and what action to take in response to each such instance, and any action or inaction in a particular instance will not dictate or limit our response to a future instance. We will not assume or have any liability for any action or inaction with respect to any Content.
        </p>

        <h2>Subscriptions</h2>
        <p>
          Some parts of the Services are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.
        </p>

        <p>
          At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or Mu6ik. cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting Mu6ik. customer support team.
        </p>

        <h2>Payment</h2>
        <p>
          A valid payment method, including credit card or PayPal (“Payment Method”), is required to process the payment for your Subscription. You shall provide Mu6ik. with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize Mu6ik to charge all Subscription fees incurred through your account to any such payment instruments.
        </p>

        <p>
          You must be authorized to use the payment method that you enter when you create a billing account. You authorize us to charge you for the Services using your Payment Method and for any paid feature of the Services that you choose to sign up for or use while these Terms are in force. We may bill: (a) in advance; (b) at the time of purchase; (c) shortly after purchase; or (d) on a recurring basis for subscription Services. We may automatically renew your Services and charge you for any renewal term. All paid accounts are due the date the invoice is posted on your account. Except as specifically set forth in this Section, all Services, including account renewals, are prepaid for the period selected (monthly, yearly or otherwise) and are non-refundable.  
        </p>

        <p>
          Should automatic billing fail to occur for any reason, Mu6ik will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.
        </p>

        <p>
          You must keep all information in your billing account current. You may change your Payment Method at any time. If you tell us to stop using your Payment Method and we no longer receive payment from you for any paid Services, we may cancel the Services. Your notice to us will not affect charges we submit to your billing account before we reasonably could act on your request.
        </p>

        <p>
          Except as prohibited by law, we may assess a late charge if you do not pay on time. You must pay these late charges when we bill you for them. We may use a third party to collect past due amounts. You must pay for all reasonable costs we incur to collect any past due amounts, including reasonable attorneys' fees and other legal fees and costs. We may suspend or cancel your Services if you fail to timely pay in full.
        </p>

        <h2>Free Trial</h2>
        <p>
          Mu6ik may, at its sole discretion, offer a Subscription with a free trial for a limited period of time ("Free Trial”). You may be required to enter your billing information in order to sign up for the Free Trial.
        </p>

        <p>
          If you are currently on a free trial, you may cancel or downgrade your account, free of charge, at any time until your paid subscription commences.
        </p>

        <p>
          The last day of the free trial signifies the due date of the first payment. If payment is not received by Mu6ik on the due date, we may elect to disable your account until we have processed all outstanding payments, during which time your Content will not be available. You retain the responsibility for settling all outstanding balances in a timely manner and maintaining updated billing information.
        </p>

        <p>
          Unless we notify you otherwise, if you are participating in any free trial, you must cancel the Services by the end of the trial period to avoid incurring charges. If you do not cancel your Services and we have notified you that the Services will convert to a paid subscription at the end of the trial period, you authorize us to charge you for the Services and no credits or refunds will be available.
        </p>

        <p>
          At any time and without notice, Mu6ik reserves the right to (i) modify the terms and conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.
        </p>

        <h2>Fee Changes</h2>
        <p>
          Mu6ik, in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.
        </p>

        <p>
          Mu6ik will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.
        </p>

        <p>
          Your continued use of the Services after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.
        </p>

        <h2>Updates to the Services</h2>
        <p>
          Mu6ik reserves the right to make necessary unscheduled deployments of changes, updates or enhancements to the Services at any time. We may add or remove functionalities or features, and we may suspend or stop a Service altogether.
        </p>

        <h2>Software</h2>
        <p>
          If you receive Software from us, its use is governed in one of two ways: 1) If you are presented with license terms that you must accept in order to use the Software, those terms apply; 2) if no license is presented to you, these Terms apply. We reserve all other rights to the Software that may exist or arise outside of these Terms or a license.
        </p>

        <p>
          Any Software is licensed, not sold. Unless we notify you otherwise, the Software license ends when your subscription ends or when any other right to use the Services ends. You must then promptly uninstall the Software, or we may disable it. You must not work around any technical limitations in the Software.
        </p>

        <p>
          The Software is subject to applicable U.S. export laws and regulations. You must comply with all domestic and international export laws and regulations that apply to the Software. These laws include restrictions on destinations, end users, and end use. Without limitation, you may not transfer the Software or Services without U.S. government permission to anyone on any U.S. government exclusion lists. You represent and warrant that you are not on any such lists or under the control of or an agent for anyone on any such lists.
        </p>

        <h2>Third Party Services and Content</h2>
        <p>
          All transactions using Mu6ik's Services are between the transacting parties only. The Services may contain features and functionalities linking you or providing you with certain functionality and access to third party content or services, including Web sites, directories, servers, networks, systems, information and databases, applications, software, programs, products or services, and the Internet as a whole. Similarly, some features and functionalities of the Services may be provided by third parties, and the use of such features and functionalities may require you to provide your Content to such third parties. We may also provide information regarding third parties (including third party websites) and some third party content to you as part of the Services.
        </p>

        <p>
          We are not, however, responsible for anything on a third party site or any third party content you may gain access to when using the Services, and you irrevocably waive any claim against us with respect to such sites and third party content. Mu6ik shall have no liability, obligation or responsibility for any correspondence, purchase or promotion between you and any third party. 
        </p>

        <p>
          You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any third party.
        </p>

        <p>
          We strongly advise you to read the terms and conditions and privacy policies of any third party web sites or services that you visit.
        </p>

        <p>
          Should you incur any potential or actual liability resulting from your use of any third party services (which are not required to use the Services), or should you suffer data loss or other losses as a result of problems with any of your other service providers or any third party services, we will not be responsible unless the problem was the direct result of our negligence or the breach of any agreement between us and you.
        </p>

        <p>
          Mu6ik has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.
        </p>

        <p>
          You acknowledge and agree that Mu6ik shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third party web sites or services.
        </p>

        <h2>Proprietary Rights</h2>
        <p>
          As between Mu6ik and you, Mu6ik or its licensors own and reserve all right, title and interest in and to the Services and all hardware, software (including the Software) and other items used to provide the Services, other than the rights explicitly granted to you to use the Services in accordance with these Terms. No title to or ownership of any proprietary rights related to the Services is transferred to you pursuant to these Terms. All rights not explicitly granted to you are reserved by Mu6ik. In the event that you provide comments, suggestions and recommendations to Mu6ik with respect to the Services (including, without limitation, with respect to modifications, enhancements, improvements and other changes to the Services) (collectively, "Feedback"), You hereby grant to Mu6ik a world-wide, royalty free, irrevocable, perpetual license to use any Feedback in connection with the Services.
        </p>

        <p>
          The Services and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Mu6ik and its licensors. The Services are protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Mu6ik.
        </p>

        <h2>Confidentiality</h2>
        <p>
          You acknowledge that you may obtain proprietary information of Mu6ik (“Proprietary Information”). Such Proprietary Information shall belong solely to Mu6ik and includes, but is not limited to, trade secrets, know-how, inventions (whether or not patentable), techniques, processes, programs, ideas, algorithms, schematics, testing procedures, software design and architecture, computer code, internal documentation, design and function specifications, product requirements, problem reports, analysis and performance information, benchmarks, software documents, and other technical, business, product, marketing and financial information, plans and data. In regard to this Proprietary Information:
        </p>

        <p>
          You shall not use (except as expressly authorized by these Terms) or disclose Mu6ik’s Proprietary Information without our prior written consent unless such Proprietary Information becomes part of the public domain without breach of these Terms.
        </p>

        <p>
          You agree to take all reasonable measures to maintain the Proprietary Information in confidence.
        </p>

        <p>
          If you are an organization, you will disclose the Proprietary Information only to those of your employees and consultants as are necessary for the use expressly and unambiguously authorized hereunder, and only if such employees and consultants are bound by confidentiality obligations no less restrictive than those herein. You shall not, without our prior written consent, disclose or otherwise make available any of the Proprietary Information or copies thereof to any third party.
        </p>

        <h2>Privacy</h2>
        <p>
          In order to operate and provide the Services, we collect certain information from and about you. As part of the Services, we may also automatically upload certain Content, information about your computer or device, your use of the Services, and Services performance. We use and protect that information as described in our privacy policy ("Privacy Policy") which you may review at https://mu6ik.com/privacy-policy. You further acknowledge and agree that we may access or disclose information about you, including the Content, in order to: (a) comply with the law or respond to lawful requests or legal process; (b) protect the rights or property of Mu6ik or our customers, including the enforcement of our agreements or policies governing your use of the Services; or (c) act on a good faith belief that such access or disclosure is necessary to protect the personal safety of Mu6ik employees, customers, or the public.
        </p>

        <p>
          We retain the right to block or otherwise prevent delivery of any type of file, email or other communication to or from the Services as part of our efforts to protect the Services, protect our customers, or stop you from breaching these Terms.
        </p>

        <h2>No Warranty</h2>
        <p>
          Mu6ik PROVIDES THE SERVICES "AS IS", "WITH ALL FAULTS" AND "AS AVAILABLE". TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Mu6ik MAKES NO (AND SPECIFICALLY DISCLAIMS ALL) REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY THAT THE SERVICES WILL BE UNINTERRUPTED, ERROR-FREE OR FREE OF HARMFUL COMPONENTS; THAT THE CONTENT WILL BE SECURE OR NOT OTHERWISE LOST OR DAMAGED; OR ANY IMPLIED WARRANTY OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT; AND ANY WARRANTY ARISING OUT OF ANY COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE OF TRADE. SOME JURISDICTIONS DO NOT ALLOW THE FOREGOING EXCLUSIONS. IN SUCH JURISDICTIONS, THE DISALLOWED EXCLUSION WILL NOT APPLY SOLELY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
        </p>

        <h2>Indemnification</h2>
        <p>
          To the extent permitted by applicable law, you will defend Mu6ik against any cost, loss, damage, or other liability arising from any third party demand or claim that any Content provided by you or your use of the Services in breach of these Terms: (a) infringes a patent, trademark, or copyright of a third party, or misappropriates a trade secret (to the extent that such misappropriation is not the result of Mu6ik's actions); or (b) violates applicable law or these Terms. Mu6ik will reasonably notify you of any such claim or demand that is subject to your indemnification obligation.
        </p>

        <p>
          You agree to defend, indemnify and hold harmless Mu6ik and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Services, by you or any person using your account and password; b) a breach of these Terms, or c) Content posted on the Services.
        </p>

        <h2>Limitation Of Liability</h2>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL Mu6ik, ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS OR LICENSORS BE LIABLE FOR (A): ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, COVER OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, REVENUE, GOODWILL, USE OR CONTENT) HOWEVER CAUSED, UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, CONTRACT, TORT, WARRANTY, NEGLIGENCE OR OTHERWISE, EVEN IF MU6IK HAS BEEN ADVISED AS TO THE POSSIBILITY OF SUCH DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE AGGREGATE LIABILITY OF MU6IK AND ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS OR LICENSORS RELATING TO THE SERVICES WILL BE LIMITED TO THE GREATER OF AN AMOUNT EQUAL TO THREE (3) MONTHS OF YOUR SERVICE FEE FOR THE SERVICES OR TEN DOLLARS ($10.00). THE LIMITATIONS AND EXCLUSIONS ALSO APPLY IF THIS REMEDY DOES NOT FULLY COMPENSATE YOU FOR ANY LOSSES OR FAILS OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES. IN SUCH AN EVENT THIS LIMITATION WILL NOT APPLY TO YOU TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
        </p>

        <h2>Governing Law</h2>
        <p>
          The laws of the State of California govern the interpretation of these Terms and apply to claims for breach of these Terms, without reference to or application of conflict of laws principles. The parties specifically exclude from application to these Terms the United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transactions Act. You and we irrevocably consent to the exclusive jurisdiction and venue of the state or federal courts for Santa Clara County, California for all disputes arising out of or relating to these Terms. Mu6ik may assign this contract to another entity at any time with or without notice to you.
        </p>

        <p>
          Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Services, and supersede and replace any prior agreements we might have had between us regarding the Services.
        </p>

        <h2>Severability</h2>
        <p>
          These Terms apply to the maximum extent permitted by relevant law. If a court holds that we cannot enforce a part of these Terms as written, you and we will replace those terms with similar terms to the extent enforceable under the relevant law, but the rest of these Terms will remain in effect. This is the entire contract between you and us regarding the Services. It supersedes any prior contract or oral or written statements regarding your use of the Services.
        </p>
        
        <h2>
          Contacting Us
        </h2>

        <p>
          Any questions about this Privacy Policy should be addressed to 
          <a href='mailto:legal@mu6ik.com'>legal@mu6ik.com</a>
        </p>
      </div>
    );
  }
}

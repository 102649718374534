import React from 'react';
import Modal from 'react-modal'

import AvatarEditor from 'react-avatar-editor'
import ImageScaler from './image-scaler'
import ConfirmActionModal from 'components/confirm-action-modal'

import { dataURItoBlob } from 'lib/to-blob'

export default class EditImageModal extends React.Component {
	constructor(props) {
		super(props);

		this.scaleImage = this.scaleImage.bind(this)
		this.uploadImage = this.uploadImage.bind(this)
		this.renderModalBody = this.renderModalBody.bind(this)
		this.renderModalHeader = this.renderModalHeader.bind(this)
		this.renderModalFooter = this.renderModalFooter.bind(this)

		this.adjustImageRotation = this.adjustImageRotation.bind(this)
		this.renderRotate = this.renderRotate.bind(this)

		this.closeModal = this.closeModal.bind(this)

		this.editor = null;

		this.state = {
			scale: 1.0,
			isConfirmDelete: false,
			isConfirmDiscard: false,
			changed: this.props.changed,
			pictureWidth: 0,
			canvasBorderWidth: 0,
			canvasBorderHeight: 0,
			rotation: 0,
		}
	}

	scaleImage(value) {
		this.setState({scale: value})
	}

	uploadImage(e) {
		e.preventDefault()
		if(!this.editor || !this.state.changed) return;

		const canvas = this.editor.getImage();

		const dataURI = canvas.toDataURL('image/jpeg', 1);
		const blob = dataURItoBlob(dataURI)

		this.props.uploadImage(blob)
	}

	componentDidMount() {
		if(this.state.changed) {
			window.addEventListener("beforeunload", this.handleUnload); 
		}

    const width = window.innerWidth

    if(width >= 750) {
    	this.setState({
    		pictureWidth: 400,
    		canvasBorderWidth: 175,
    		canvasBorderHeight: 50,
    	})
    } else if (width < 750 && width >= 400) {
    	this.setState({
    		pictureWidth: 350,
    		canvasBorderWidth: Math.max((width - 350) / 2, 0),
    		canvasBorderHeight: 35,
    	})
    } else {
    	this.setState({
    		pictureWidth: 300,
    		canvasBorderWidth: Math.max((width - 300) / 2, 0),
    		canvasBorderHeight: 15,
    	})
    }
	}

	componentWillUnmount() {
		window.removeEventListener("beforeunload", this.handleUnload)
	}

	handleUnload(e) {
    e.preventDefault();
    return e.returnValue = 'Are you sure you want to close?';
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevState.changed !== this.state.changed && this.state.changed) {
			window.addEventListener('beforeunload', this.handleUnload)
		}
	}

	renderModalHeader() {
		return (
			<div className='modal-header'>
				<h3>Edit Profile Photo</h3>
				<h4 
					className='remove'
					onClick={this.closeModal}
				>
					X
				</h4>
			</div>
		)
	}

	renderRotate() {
		return (
			<div className='rotate-toolbar'>
				<i className="fas fa-redo rotate-left"
					onClick={() => this.adjustImageRotation(-90) }
				></i>
				<i className="fas fa-redo rotate-right"
					onClick={() => this.adjustImageRotation(90) }
				></i>
			</div>
		)
	}


	renderModalBody() {
		return (
			<div className='modal-body'>
				<AvatarEditor
					image={this.props.url}
					width={this.state.pictureWidth}
		      height={this.state.pictureWidth}
		      border={[this.state.canvasBorderWidth, this.state.canvasBorderHeight]}
		      color={[220, 220, 220, 0.7]}
		      scale={this.state.scale}
		      ref={(editor) => this.editor = editor}
		      rotate={this.state.rotation}
				/>

				{
					this.props.tools ?
						<ImageScaler 
							scale={this.state.scale}
							scaleImage={this.scaleImage}
						/> : null
				}

				{
					this.props.tools ?
						this.renderRotate() : null
				}
			</div>
		)
	}

	adjustImageRotation(degrees) {
		this.setState({rotation: this.state.rotation + degrees})
	}

	renderModalFooter() {
		return (
			<div className='modal-footer'>
				<button 
					className='btn btn-delete'
					onClick={() => this.setState({isConfirmDelete: true})}
				>
					Delete Photo
				</button>
				<span className='profile-image-upload btn-change'>
					<input 
						type='file' 
						accept='image/*' 
						className='file-uploader' 
						onChange={(e) => {
							this.setState({changed: true})
							this.props.changeUploadFile(e)
						}}
					/>
					<label 
						className='btn btn-default' 
					>
						Change Photo
					</label>
				</span>
				<button 
					className='btn btn-primary'
					onClick={this.uploadImage}
				>
					Apply
				</button>
			</div>
		)	
	}

	closeModal() {
		if(this.state.changed) {
			this.setState({isConfirmDiscard: true})
		} else {
			this.props.closeModal()
		}
	}

	render() {
		return (
			<div>
				<Modal
					isOpen={this.props.visible}
					contentLabel='EditPhotoModal'
					shouldCloseOnOverlayClick={false}
					onRequestClose={this.closeModal}
				  overlayClassName='profile-img-modal-overlay'
					className='profile-edit-img-modal'
				>
					{this.renderModalHeader()}
					{this.renderModalBody()}
					{this.renderModalFooter()}
				</Modal>

				<ConfirmActionModal
					isOpen={this.state.isConfirmDelete}
					onCancel={() => this.setState({isConfirmDelete: false})}
					onSuccess={() => {
						this.setState({isConfirmDelete: false})
						this.props.deletePhoto()
					}}
					message='Are you sure you want to delete this photo?'
					handleActionLabel='Delete'
					cancelActionLabel='Cancel'
				/>

				<ConfirmActionModal
					isOpen={this.state.isConfirmDiscard}
					onCancel={() => this.setState({isConfirmDiscard: false})}
					onSuccess={() => {
						this.setState({isConfirmDiscard: false})
						this.props.closeModal()
					}}
					message="The changes you made to your profile picture won't be saved if you close this dialog."
					handleActionLabel='Leave This Page'
					cancelActionLabel='Stay Here'
				/>
			</div>
		);
	}
}

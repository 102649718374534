import React, { Component } from 'react'

import ReactDOM from 'react-dom'
import YoutubePlayer from 'react-youtube'
import { connect } from 'react-redux'
import { isBrowser } from "react-device-detect";

import throttle from 'lodash/throttle'

import {
  YOUTUBE_PLAYER_STATE_UNSTARTED,
  YOUTUBE_PLAYER_STATE_ENDED,
  YOUTUBE_PLAYER_STATE_PLAYING,
  YOUTUBE_PLAYER_STATE_PAUSED,
  YOUTUBE_PLAYER_STATE_BUFFERING,
  YOUTUBE_PLAYER_STATE_CUED
} from 'lib/constants'

import {
  setPlayerFunction,
  setPlayerState
} from '../modules/player'

class VideoPlayer extends Component {
  constructor (props) {
    super(props)

    // this.onScroll = throttle(this.onScroll.bind(this), 5, {leading: true, trailer: true})

    // this.onScroll = this.onScroll.bind(this)

    this.onReady = this.onReady.bind(this)
    this.onEnd = this.onEnd.bind(this)
    this.onError = this.onError.bind(this)
    this.onStateChange = this.onStateChange.bind(this)
    this.pollPlayerState = this.pollPlayerState.bind(this)
    this.timeoutFn = null

    this.state = {
      bottom: '100px'
    }
  }

  pollPlayerState () {
    this.timeoutFn = setTimeout(() => {
      const currentTime = this.eventTarget.getCurrentTime() / 60
      const buffered = this.eventTarget.getVideoLoadedFraction()

      this.props.setPlayerValues({
        currentTime,
        buffered
      })

      this.pollPlayerState()
    }, 1000)
  }

  onError (event) {
    // this.props.sliceResult()
    // this.props.removeVideos()
    this.props.queueNextSong()
  }

  onEnd (event) {
    this.props.queueNextSong()
  }

  onReady (event) {
    this.eventTarget = event.target

    let playVideo = () => {
      event.target.playVideo()
      if (!this.timeoutFn) {
        this.pollPlayerState()
      }
    }

    let pauseVideo = () => {
      event.target.pauseVideo()

      clearTimeout(this.timeoutFn)
      this.timeoutFn = null
    }

    let seekTo = (seconds, allowSeekAhead = false) => {
      event.target.seekTo(seconds, allowSeekAhead)

      const buffered = event.target.getVideoLoadedFraction()

      this.props.setPlayerValues({
        currentTime: seconds / 60,
        buffered
      })
    }

    let setVolume = (volume) => {
      event.target.setVolume(volume)

      this.props.setPlayerValues({
        volume,
        forceStash: true
      })
    }

    let mute = () => {
      event.target.mute()

      this.props.setPlayerValues({
        isMuted: true,
        forceStash: true
      })
    }

    let unMute = () => {
      event.target.unMute()

      this.props.setPlayerValues({
        isMuted: false,
        forceStash: true
      })
    }

    let getVolume = () => {
      return event.target.getVolume()
    }

    let getCurrentTime = () => {
      return event.target.getCurrentTime()
    }

    let loadVideo = (videoId, songId) => {
      event.target.loadVideoById(videoId)

      if (this.timeoutFn) {
        clearTimeout(this.timeoutFn)
        this.timeoutFn = null
      }

      this.props.setPlayerValues({
        currentTime: 0,
        buffered: 0,
        forceStash: true,
        videoId,
        songId
      })

      this.pollPlayerState()
    }

    let cueVideoById = (videoId, startSeconds = 0) => {
      event.target.cueVideoById(videoId, startSeconds)
    }

    let fullScreen = () => {
      const node = ReactDOM.findDOMNode(this.playerRef)
      if (node.childNodes.length === 0) return

      const iframeNode = node.childNodes[0]

      if (iframeNode.requestFullscreen) {
        iframeNode.requestFullscreen()
      } else if (iframeNode.webkitRequestFullscreen) {
        iframeNode.webkitRequestFullscreen()
      } else if (iframeNode.mozRequestFullScreen) {
        iframeNode.mozRequestFullScreen()
      } else if (iframeNode.msRequestFullscreen) {
        iframeNode.msRequestFullscreen()
      }
    }

    this.props.setPlayerFunction(playVideo, 'playVideo')
    this.props.setPlayerFunction(pauseVideo, 'pauseVideo')
    this.props.setPlayerFunction(loadVideo, 'loadVideo')
    this.props.setPlayerFunction(seekTo, 'seekTo')
    this.props.setPlayerFunction(cueVideoById, 'cueVideoById')
    this.props.setPlayerFunction(setVolume, 'setVolume')
    this.props.setPlayerFunction(mute, 'mute')
    this.props.setPlayerFunction(unMute, 'unMute')
    this.props.setPlayerFunction(getVolume, 'getVolume')
    this.props.setPlayerFunction(getCurrentTime, 'getCurrentTime')
    this.props.setPlayerFunction(fullScreen, 'fullScreen')

    if (this.props.player.videoId) {
      cueVideoById(this.props.player.videoId, this.props.currentTime * 60)
    }

    if (this.props.volume !== 100) {
      setVolume(this.props.volume)
    }
  }

  onStateChange (event) {
    const state = event.data

    if (state === YOUTUBE_PLAYER_STATE_PLAYING ||
       state === YOUTUBE_PLAYER_STATE_BUFFERING) {
      if (!this.timeoutFn) {
        this.pollPlayerState()
      }
    }

    this.props.setPlayerState(state)
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (nextProps.player.currentVideoIndex === null) {
      return true
    }

    if (nextProps.player.playerState === YOUTUBE_PLAYER_STATE_UNSTARTED ||
       nextProps.player.playerState === YOUTUBE_PLAYER_STATE_ENDED ||
       nextProps.player.playerState === YOUTUBE_PLAYER_STATE_CUED) {
      return true
    }

    if(nextState.bottom !== this.state.bottom) {
      return true;
    }

    return false
  }

  onScroll(e) {
    const musicPlayerHeight = 90;
    const footerHeight = 80;
    const playerPadding = 10;

    const scrollPositionBottom = window.scrollY + window.innerHeight
    const scrollHeight = document.body.scrollHeight;
    const scrollThreshold = scrollHeight - playerPadding;

    if(scrollPositionBottom >= scrollThreshold) {
      this.setState({
        bottom: `${musicPlayerHeight + footerHeight + playerPadding}px`
      })
    } else if (this.state.bottom !== '100px') {

      this.setState({
        bottom: `${musicPlayerHeight + playerPadding}px`
      })
    }
  }

  componentDidMount() {
    // window.addEventListener('scroll', this.onScroll, false)
  }

  componentWillUnmount() {
    // window.removeEventListener('scroll', this.onScroll, false)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.isFetching && prevState.init !== -1) {
      this.setState({init: -1})
    }
  }

  render () {
    return (
      <div className='player' id="player" >
        <YoutubePlayer
          opts={{
            height: '207px',
            width: '368px',
            playerVars: {
              controls: 0,
              modestbranding: 1,
              rel: 0,
              fs: 0,
              iv_load_policy: 3,
              playsinline: 1,
            }
          }}
          onReady={this.onReady}
          onEnd={this.onEnd}
          onStateChange={this.onStateChange}
          onError={this.onError}
          ref={ref => this.playerRef = ref}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    player: state.player
  }
}

export default connect(
  mapStateToProps,
  {
    setPlayerFunction,
    setPlayerState
  }
)(VideoPlayer)

import React from 'react';

import { getUrlFromPhoto } from 'lib/user';
import { SquareImage } from 'app/components/img-tile'

import { DropdownButton, MenuItem } from 'react-bootstrap'

import M6kRadio from 'app/components/m6k-radio'

const FriendListItem = (props) => {
  function selectPermission(permission)  {
    props.updatePendingCollabs(props.friend.id, 'permission', permission)
  }

  function addUser(e) {
    e.preventDefault();

    if(!isPending()) {
      return;
    }

    props.addUserPlaylistPermission({
      userId: props.friend.id,
      ...props.pendingCollabInfo
    })
  }

  function isSelected(level) {
    const { pendingCollabInfo } = props;
    return pendingCollabInfo && pendingCollabInfo['permission'] === level; 
  }

  function isPending() {
    const { pendingCollabInfo } = props;
    return pendingCollabInfo && pendingCollabInfo['permission']
  }

  function isActive(level) {
    return isSelected(level) ? 'active' : ''
  }

  function isAdded() {
    const { addedCollab } = props;
    return addedCollab;
  }

  function liClassName() {
    if(isAdded()) {
      return 'friends-list-item added'
    } else if (isPending()) {
      return 'friends-list-item pending';
    } else {
      return 'friends-list-item';
    }
  }

  function renderAddButton() {
    if(isAdded()) {
      return (
        <div className='btn btn-primary'>
          Added!
        </div>
      )
    } else {
      return (
        <button className='btn btn-primary' disabled={!isPending()} onClick={addUser}>
          Add User
        </button>
      )
    }
  }

  const { friend } = props;
  const urlMedium = getUrlFromPhoto(friend.profile_photo, 320)

  return (
    <li className={liClassName()}>
      <SquareImage urlPath={urlMedium} />
      <h5>{ friend.display_name }</h5>

      <div className='btn-group'>
        <DropdownButton 
          title="Permission" 
          id="user-permission"
        >
          <MenuItem eventKey="admin" onSelect={selectPermission} className={isActive('admin')} >
            Admin
          </MenuItem>

          <MenuItem eventKey="editor" onSelect={selectPermission} className={isActive('editor')}>
            Editor
          </MenuItem>

          <MenuItem eventKey="viewer" onSelect={selectPermission} className={isActive('viewer')}> 
            Viewer
          </MenuItem>
        </DropdownButton>

        { renderAddButton() }
      </div>
    </li>
  )
}

export default FriendListItem;
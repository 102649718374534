export function translateKey(key) {
  switch(key) {
    case 'Cmaj':
      return '8B';
    case 'Gmaj':
      return '9B';
    case 'Dmaj':
      return '10B';
    case 'Amaj':
      return '11B';
    case 'Emaj':
      return '12B';
    case 'Bmaj':
      return '1B';
    case 'F♯maj':
      return '2B';
    case 'G♭maj':
      return '2B';
    case 'C#maj':
      return '3B';
    case 'D♭maj':
      return '3B'
    case 'G#maj':
      return '4B';
    case 'A♭maj':
      return '4B'
    case 'E♭maj':
      return '5B';
    case 'B♭maj':
      return '6B';
    case 'Fmaj':
      return '7B';
    case 'Amin':
      return '8A';
    case 'Emin':
      return '9A';
    case 'Bmin':
      return '10A';
    case 'F♯min':
      return '11A';
    case 'D♭min':
      return '12A';
    case 'C#min':
      return '12A';
    case 'G#min':
      return '1A';
    case 'A♭min':
      return '1A';
    case 'E♭min':
      return '2A';
    case 'D#min':
      return '2A';
    case 'B♭min':
      return '3A';
    case 'Fmin':
      return '4A';
    case 'Cmin':
      return '5A';
    case 'Gmin':
      return '6A';
    case 'Dmin':
      return '7A';
    default:
      return '';
  }
}
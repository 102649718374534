import React from 'react';
import debounce from 'lodash/debounce';

import SquareImage from 'app/shared/dj-wall/square-image';
import DJWall from 'app/shared/dj-wall';

import buildURL from 'lib/artist-url';
import ClickHandler from 'components/click-handler';

export default class MobileCurrentDJ extends React.Component {
  constructor(props) {
    super(props);
    this.renderArtist = this.renderArtist.bind(this)
    this.renderOtherDJs = this.renderOtherDJs.bind(this)

    this.state = {
      uuid: null,
      id: null,
    }
  }

  componentDidMount() {
  }

  renderArtist(a) {
    const { search } = this.props;

    const urlObj = buildURL({
      urls: a.discogs, 
      name: a.name,
      from: search.from,
      to: search.to,
    })

    return (
      <ClickHandler key={a.id} url={urlObj.artistURL} onClick={this.props.handleClick} >
        <SquareImage urlPath={urlObj.imgURL} />
      </ClickHandler>
    )
  }

  renderOtherDJs() {
    if(this.props.artists.length === 0) {
      return null;
    }
    
    const artists = this.props.artists.map((a, i) => {
      return this.renderArtist(a)
    })

    return(
      <div className='artist-wrapper' key='artist-wrapper-others'>
        <h3>Other DJs Played this Song</h3>
        { artists }
      </div>
    )
  }

  render() {
    if(!this.props.dj || this.props.dj.id === null || this.props.dj.id === undefined) {
      return null;
    }

    return (
      <div className='mobile-dj-playing' 
      >
          <div className='artist-wrapper' key='artist-wrapper-current'>
            <h3 id='current'>Current DJ</h3>
            { this.renderArtist(this.props.dj) }
          </div>
          { this.renderOtherDJs() } 

      </div>
    );
  }
}

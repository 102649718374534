export const setBannerNotification = (message, className = 'notice') => {
  return {
    type: 'SET_BANNER_NOTIFICATION',
    message,
    className
  }
}

export const clearBannerNotification = () => {
  return {
    type: 'CLEAR_BANNER_NOTIFICATION'
  }
}

const defaultState = {
  message: null,
  className: null
}

const bannerNotification = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_BANNER_NOTIFICATION':
      return {
        ...state,
        message: action.message,
        className: action.className
      }
    case 'CLEAR_BANNER_NOTIFICATION':
      return defaultState
    default:
      return state
  }
}

export default bannerNotification

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { selectCurrentUser } from 'app/modules/current-user/account'
import defaultAvatar from 'assets/img/default_avatar'

import {
  setSearchQuery,
  selectSearchQuery,
  submitSearch,
  updatePaginationPage,
  updateUrl,
  gotoSearchPage,
} from 'app/modules/search/index'

import { setIndex } from 'app/shared/music-player/modules/player'

import Dropdown from './dropdown'
import SearchBar from './search-bar'
import NavLink from './nav-link';

class Header extends Component {
  render () {
    return (
      <header>
        <nav className='navbar navbar-default app-navbar'>
          <div className='container-fluid'>
            <Link to='/' className='navbar-brand'>Mu6ik</Link>
            
            <SearchBar
              q={this.props.q} 
              setSearchQuery={this.props.setSearchQuery} 
              submitSearch={this.props.submitSearch}
              updatePaginationPage={this.props.updatePaginationPage}
              updateUrl={this.props.updateUrl}
              location={this.props.location}
              setIndex={this.props.setIndex}
              gotoSearchPage={this.props.gotoSearchPage}
            />

            <ul className='nav navbar-nav m6k-navbar navbar-left'>
              <NavLink to='/search'>Search</NavLink>
              <NavLink to='/charts'>Charts</NavLink>
              <NavLink to='/library'>Library</NavLink>
            </ul>
            
            <Dropdown user={this.props.user} logout={this.props.logout} />
          </div>
        </nav>
      </header>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: selectCurrentUser(state),
    q: selectSearchQuery(state),
    location: state.router.location.pathname
  }
}

export default connect(mapStateToProps, {
  submitSearch,
  setSearchQuery,
  updatePaginationPage,
  updateUrl,
  setIndex,
  gotoSearchPage,
})(Header)

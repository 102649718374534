import React from 'react';

export default class SkeletonSongInfo extends React.Component {
  render() {
    return (
      <div className='m6k-panel song-info'>
        <section>
          <h4>Years Played</h4>
          <div className='empty-text'>
          </div>
        </section>

        <section>
          <h4>DJs played this song</h4>
          <div className='empty-image'>
          </div>
        </section>
      </div>
    );
  }
}

import React from 'react';
import { connect } from 'react-redux'
import Modal from 'react-modal'
import queryString from 'query-string'
import { push } from 'connected-react-router'

import ClickHanlder from 'components/click-handler';
import { loginAuth, clearAuthError } from 'modules/auth'

import './login-modal.scss'

class LoginModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null
    }
  }

  componentWillUnmount() {
    this.props.clearAuthError()
  }

  render() {
    const { auth } = this.props

    let inputClass = 'form-control'
    let errorMessage = null

    if (auth.error) {
      inputClass += ' form-error'
      errorMessage =
        <span>
          <p className='error-message'>
            {auth.error.message}
          </p>
        </span>
    }

    return (
      <div>
        <Modal
          contentLabel='LoginModal'
          isOpen={this.props.isOpen}
          overlayClassName='login-modal-overlay'
          className='login-modal'
          onRequestClose={this.props.onCancel}
        >
          <div className='modal-header'>
            <h5>Login</h5>
          </div>

          <form className='login-form' onSubmit={(e) => {
            e.preventDefault()
            this.props.loginAuth(this.email.value, this.password.value)
          }}>
            {errorMessage}
            <div className='form-group'>
              <input
                type='email'
                className={inputClass}
                placeholder='Email'
                onChange={this.handleChange}
                ref={email => this.email = email} />
            </div>
            <div className='form-group'>
              <input
                type='password'
                className={inputClass}
                placeholder='Password'
                onChange={this.handleChange}
                ref={(password) => this.password = password}
              />

              <ClickHanlder 
                url='/reset_password/new'
                newWindow 
                className='password-reset'
              >
                Forgot your password?
              </ClickHanlder>
            </div>

            <button type='submit' className='btn btn-default login'>Log In</button>
            
            <ClickHanlder url='/signup/underground' className='register-link' newWindow>
              Don't have an account? Register
            </ClickHanlder>

          </form> 
        </Modal> 
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginAuth: (email, password) => {
      dispatch(loginAuth(email, password))
    },
    clearAuthError: () => {
      dispatch(clearAuthError())
    },
    navigateToWaitlist () {
      dispatch(push({pathname: '/waitlist'}))
    },
    navigateToReset (email) {
      const params = { email }

      dispatch(push({
        pathname: '/reset_password/new',
        search: queryString.stringify(params)
      }))
    }
  }
}

export default connect(mapStateToProps,
  mapDispatchToProps
)(LoginModal)
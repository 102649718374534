import React from 'react';
import MobileProgress from './mobile-progress';
import MobileVolumeControl from './mobile-volume-control';
import MarketPlace from './market-place/market-place';

import {
  YOUTUBE_PLAYER_STATE_PLAYING
} from 'lib/constants'; 

export default class MobilePlayerContrls extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const isFavorite = this.props.isFavorite;
    const isPlaying = this.props.playerState === YOUTUBE_PLAYER_STATE_PLAYING;

    return (
      <div className='mobile-m6k-player-controls'>
        <div className='player-controls-bar'>
          <MobileProgress 
            currentTime={this.props.currentTime} 
            duration={this.props.duration} 
            buffered={this.props.buffered}
            seekTo={this.props.seekTo}
          />

          <div className='controls'>
            <span 
              className={`player-control glyphicon glyphicon-random ${this.props.isShuffle ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                this.props.toggleShuffle();
              }}
            >
            </span>
            <span 
              className='player-control glyphicon glyphicon-step-backward'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                
                this.props.prevSong();
              }}
            >
            </span>
    
            <span 
              className={`player-control glyphicon glyphicon-${isPlaying ? 'pause' : 'play'}`}
              onClick={this.props.togglePlay}
            >
            </span>
            <span 
              className='player-control glyphicon glyphicon-step-forward'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.nextSong();
              }}
            >
            </span>
            <span 
              className={`player-control glyphicon glyphicon-repeat ${this.props.isRepeat ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                this.props.toggleRepeat();
              }}
            >
            </span>
          </div>
        
          <MobileVolumeControl 
            isMuted={this.props.isMuted}
            volume={this.props.volume}
            mute={this.props.mute}
            unMute={this.props.unMute}
            setVolume={this.props.setVolume}
          />

          
        </div>
      </div>
    );
  }
}

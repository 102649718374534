import { key, keys } from 'app/schemas/main' 
import { normalize } from 'normalizr'
import compact from 'lodash/compact'

import keyArray from 'assets/keys'

export const loadKeys = () => {
  return (dispatch, getState) => {
    const state = getState();

    if(state.keys.isLoading) {
      return Promise.reject()
    }

    dispatch({
      type: 'LOAD_KEYS'
    })

    const normalized = normalize(keyArray, keys);

    dispatch({
      type: 'LOAD_KEYS_SUCCESS',
      payload: {
        entities: normalized.entities,
        ids: normalized.result
      }
    })

    return Promise.resolve()
  }
}

export const selectKeys = (state) => {
  const entities = state.entities
  const filter = state.keys.search || ''

  const useFilter = filter !== ''
  const keyResults = state.keys.ids.map((id) => {
    const key = entities.keys[id]

    if (!useFilter || key.name.toLowerCase().startsWith(filter.toLowerCase())) {
      return key
    }
  })

  return compact(keyResults);
}

export const updateKeySearch = (value) => {
  return {
    type: 'UPDATE_KEY_SEARCH',
    payload: {
      value
    }
  }
}

const defaultState = {
  isLoading: false,
  ids: [],
  search: '',
}

const reducer = (state = defaultState, action) => {
  switch(action.type) {
    case 'LOAD_KEYS':
      return {
        ...state,
        isLoading: true,
      }
    case 'LOAD_KEYS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        ids: action.payload.ids,
      }
  case 'UPDATE_KEY_SEARCH':
      return {
        ...state,
        search: action.payload.value
      }
    default:
      return state;
  }
}

export default reducer;
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

import {
  getFollowedDJs,
  followDJ,
  unFollowDJ
} from 'app/modules/following'

import {
  selectProfile
} from 'app/modules/profile'

import DJTile from 'app/shared/dj-wall/dj-tile'

import '../styles/following.scss'

const mapStateToProps = (state) => {
  return {
    following: state.following,
    profile: selectProfile(state) 
  }
}

class Following extends Component {
  constructor (props) {
    super(props)

    this.toggleFollow = this.toggleFollow.bind(this)
  }

  toggleFollow (follower) {
    if (follower.is_following) {
      this.props.unFollowDJ(follower.artist.id, 'TOGGLE')
    } else {
      this.props.followDJ(follower.artist.id, 'TOGGLE', follower.id)
    }
  }

  componentDidMount () {
    this.props.getFollowedDJs(this.props.profile.uuid)
  }

  render () {
    const following = this.props.following
    const total = following.results.filter(result => result.is_following).length

    const sorted = following.results.sort(function (a, b) {
      let nameA = a.artist.name.toLowerCase()
      let nameB = b.artist.name.toLowerCase()

      if (nameA < nameB) { return -1 }
      if (nameA > nameB) { return 1 }

      return 0 // default return value (no sorting));
    })

    const djs = sorted.map((follower, index) => {
      const dj = follower.artist
      const buttonText = follower.is_following ? 'Following' : 'Follow'

      return (
        <DJTile name={dj.name}
          slug={dj.slug}
          urls={dj.discogs}
          id={dj.id}
          key={dj.id}
          width={6}
          widthSmall={2}
          widthXSmall={2}
        >
          <button
            className={
              follower.is_following
              ? 'following btn btn-default'
              : 'follow btn btn-default'
            }
            onClick={(e) => {
              e.preventDefault()
              this.toggleFollow(follower)
            }
          }>
            <span className='glyphicon glyphicon-headphones' />
            <span className='hover-text'>Unfollow</span>
            <span className='button-text'>{buttonText}</span>
          </button>
        </DJTile>
      )
    })

    return (
      <div className='following-panel'>
        <div className='m6k-panel'>
          <div className='row'>
            {djs}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, {
  getFollowedDJs,
  followDJ,
  unFollowDJ,
})(Following)

import axios from 'lib/axios-config'
import { push } from 'connected-react-router'

import { setBannerNotification } from 'modules/banner-notification'

export function resetWaitlist () {
  return {
    type: 'RESET_WAITLIST'
  }
}

export function joinWaitlist (email) {
  return (dispatch, getState) => {
    const waitlist = getState().waitlist
    const url = `/api/users/waitlist`

    if (waitlist.isFetching) {
      return Promise.reject()
    }

    dispatch({ type: 'REQUEST_JOIN_WAITLIST' })

    return axios.post(url, { email })
      .then((response) => {
        dispatch({ type: 'REQUEST_JOIN_WAITLIST_SUCCESS' })

        dispatch(setBannerNotification(
          "You've successfully joined the waitlist. We will send you an invite when we are ready to add more users.",
          'success'
        ))

        dispatch(resetWaitlist())
        dispatch(push('/login'))
      }).catch((error) => {
        console.log(error)
        dispatch({
          type: 'REQUEST_JOIN_WAITLIST_FAILURE',
          payload: {
            error: error.response.data.errors
          }
        })
      })
  }
}

const defaultState = {
  isFetching: false,
  success: false,
  didInvalidate: false,
  error: null
}

const waitlist = (state = defaultState, action) => {
  switch (action.type) {
    case 'REQUEST_JOIN_WAITLIST':
      return {
        ...state,
        isFetching: true,
        success: false
      }
    case 'REQUEST_JOIN_WAITLIST_SUCCESS':
      return {
        ...state,
        success: true,
        isFetching: false
      }
    case 'REQUEST_JOIN_WAITLIST_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'RESET_WAITLIST':
      return {
        ...state,
        error: null,
        didInvalidate: false,
        success: false,
        isFetching: false
      }
    default:
      return state
  }
}

export default waitlist

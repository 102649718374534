import React from 'react';
import { connect } from 'react-redux';
import Loading from 'react-loading'

import {
  selectHistorySongs
} from 'app/modules/song'

import {
 fetchHistory,
 updatePaginationPage,
 resetPaginationLimit
} from 'app/modules/history'

import {
  selectProfile
} from 'app/modules/profile'
 
import {
  filterEmptyVideos,
  filterDuplicateVideos,
  filterVideoIds
} from 'lib/video'

import {
  updatePlaylistLocation 
} from 'app/shared/music-player/modules/player'

import withInfiniteScroll from 'app/shared/infinite-scroll'
import Setlist from 'app/shared/setlist'

const mapStateToProps = (state) => {
  return {
    songs: selectHistorySongs(state),
    profile: selectProfile(state),
    reachedLimit: state.history.form.pagination.reachedLimit,
    isFetching: state.history.isFetching,
  }
}

class History extends React.PureComponent {
  constructor(props) {
    super(props);

    this.fetchHistory = this.fetchHistory.bind(this)
    this.onPaginatedSearch = this.onPaginatedSearch.bind(this)
    this.renderEndListItem = this.renderEndListItem.bind(this)

    this.state = {
      isInitialFetching: true,
      isPaginatedFetching: false,
    }
  }

  componentWillMount() {
    this.SetlistWithInfiniteScroll = withInfiniteScroll(Setlist)
  }

  componentDidMount () {
    this.props.updatePlaylistLocation('history')

    this.fetchHistory(false)
      .then(() => {
        this.setState({isInitialFetching: false})
      })
      .catch(() => {
        this.setState({isInitialFetching: false})
      })
  }

  componentWillUnmount() {
    this.props.updatePaginationPage(1)
    this.props.resetPaginationLimit()
  }

  fetchHistory(loadMore) {
    return this.props.fetchHistory(loadMore)
      .then(() => {
        return Promise.resolve()
      })
      .catch(() => {
        return Promise.reject()
      })
  }

  onPaginatedSearch() {
    if(this.props.isFetching || this.props.reachedLimit) return;

    this.setState({isPaginatedFetching: true})

    this.fetchHistory(true) 
      .then(() => {
        this.setState({isPaginatedFetching: false})
      })
      .catch(() => {
        this.setState({isPaginatedFetching: false})
      })
  }

  renderEndListItem() {
    if(this.state.isPaginatedFetching && !this.props.reachedLimit) {
      return (
        <li className='setlist-empty'>
          <div className='loading'>
            <Loading
              type='spin'
              color='#aaa'
              delay={0}
              height={45}
              width={45}
            />
          </div>
        </li>
      )
    } else {
      return (
        <li className='setlist-empty'>
          <h3 className='logo'>M</h3>
        </li>
      ) 
    }
  }

  render() {
    return (
      <div className='m6k-favorites'>
        <div className='row'>
          <div className='col-md-12'>
            {
                React.createElement(this.SetlistWithInfiniteScroll, 
                  {
                    songs: this.props.songs,
                    renderDJ: true,
                    renderCreated: true,
                    dj: this.props.dj,
                    renderRefresh: true,
                    isInitialFetching: this.state.isInitialFetching,
                    isFetching: this.props.isFetching,
                    onPaginatedSearch: this.onPaginatedSearch,
                    endListItem:this.renderEndListItem,
                    refreshPlaylist: this.refreshPlaylist,
                    renderHeader: false,
                  }
                )
              }
            </div>
          </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  updatePlaylistLocation,
  fetchHistory,
  updatePaginationPage,
  resetPaginationLimit,
})(History) 
import React from 'react';
import Loading from 'react-loading'

import Switch from 'react-toggle-switch'
import EditButton from './edit-button';
import DeletePlaylistModal from './delete-playlist-modal';

import 'react-toggle-switch/dist/css/switch.min.css'


export default class PlaylistInfo extends React.Component {
  constructor(props) {
    super(props);

    this.renderInfoPublic = this.renderInfoPublic.bind(this)
    this.renderInfoViewer = this.renderInfoViewer.bind(this)

    this.renderEditingAdmin = this.renderEditingAdmin.bind(this)
    this.renderInfoAdmin = this.renderInfoAdmin.bind(this)
    this.renderAdmin = this.renderAdmin.bind(this)

    this.renderDeletePlaylistModal = this.renderDeletePlaylistModal.bind(this)

    this.setEdit = this.setEdit.bind(this)
    this.saveChanges = this.saveChanges.bind(this)

    this.state = {
      isEditing: false,
      changed: false,
      showDeleteModal: false,
    }
  }

  setEdit(e) {
    e.preventDefault() 
    this.setState({
      isEditing: true,
      changed: false,
      is_private: this.props.playlist.is_private || false,
    })
  }

  saveChanges(e) {
    e.preventDefault()
    const { playlist } = this.props;

    if(this.title.value === null || this.title.value === '') {
      return;
    }

    if(this.title.value !== playlist.name ||
       this.description.value !== playlist.description ||
       this.state.is_private !== playlist.is_private
      ) {
      this.props.updatePlaylist(playlist, {
        name:  this.title.value,
        description: this.description.value,
        is_private: this.state.is_private
      }).then(() => {
        this.setState({isEditing: false})

        if(this.title.value !== playlist.name) {
        }
      })
    }
  }

  renderGenres() {
    const { playlist } = this.props
    const genres = playlist && playlist.genre_profile ? playlist.genre_profile.map((g, gIndex) => {
      return (
        <label className='genre-label' key={`p-${playlist.id}-genre-${gIndex}`}>{ g.name }</label>
      )
    }) : [] 

    if(genres.length > 0) {
      return (
        <section>
          <h4>Genres</h4>
          <div className='genres'>
            { genres }
          </div>
        </section>
      )
    } else {
      return null;
    }
  }

  renderInfoPublic() {
    const { playlist } = this.props;

    return (
      <div className='playlist-info m6k-panel'>
        <section>
          <h4>Description</h4>
          <p>{ playlist.description }</p>
        </section>

        { this.renderGenres() }
      </div>
    );
  }

  renderInfoViewer() {
    const { playlist } = this.props;

    return (
      <div className='playlist-info m6k-panel'>
        <section>
          <h4>Description</h4>
          <p>{ playlist.description }</p>
        </section>

        { this.renderGenres() }

        <section>
          <h4>Visibility</h4> 
          <p>{ playlist.is_private ? 'Private' : 'Public'}</p>
        </section> 
      </div>
    );
  }

  renderDeleteButton() {
    return (
      <section>
        <h4>Delete Playlist</h4>
        <button 
          className='btn btn-warning'
          onClick={(e) => {
            e.preventDefault()
            this.setState({showDeleteModal: true})
          }}
        >
          Delete
        </button>
        <p>Only the owner of this playlist can perform this action.</p>
      </section>
    )
  }

  renderDeletePlaylistModal() {
    const upp = this.props.upp
    if(!this.state.showDeleteModal || !upp) {
      return null
    }

    if(upp.permission_level !== 'owner') {
      return null
    }

    return (
      <DeletePlaylistModal
        playlist={this.props.playlist}
        deletePlaylist={this.props.deletePlaylist}
        show={this.state.showDeleteModal}
        onCancel={() => {
          this.setState({showDeleteModal: false})
        }}
      />
    )
  }

  renderEditingAdmin() {
    const { playlist, upp } = this.props;

    return (
      <div className='m6k-panel playlist-info editing'>
        <section>
          <h4>Title</h4>
          <input 
            className='form-control'
            type='text'
            defaultValue={playlist.name}
            ref={(ref) => this.title = ref}
          />
        </section>

        <section>
          <h4>Description</h4>
          <textarea 
            defaultValue={playlist.description}
            ref={(ref) => this.description = ref}
          />
        </section>
        
        { this.renderGenres() }

        <section>
          <h4>Visibility</h4>

          <div className='switch-toggle-container'>
            <label>Private</label>
            <Switch 
              onClick={() => {
                this.setState(prevState => {
                  return {
                    is_private: prevState.is_private ? false : true
                  };
                });
              }} 
              on={!this.state.is_private}
              className='switch-button'
            />
            <label>Public</label>
          </div>

          <p className='info-text'>When this playlist is marked private, only you and collaborators can view this playlist.</p>
        </section>

        {
          upp && upp.permission_level === 'owner' ?
            this.renderDeleteButton() : null
        }

        {
          upp && upp.permission_level === 'owner' ?
            this.renderDeletePlaylistModal() : null
        }

        <section>
          <div className='centered'>
            <button 
              className='btn btn-default'
              onClick={() => this.setState({isEditing: false})} 
            >
              Cancel
            </button>
            <button 
              className='btn btn-primary'
              onClick={this.saveChanges}
            >
              Save
            </button>
          </div>
        </section>
      </div>
    )
  }

  renderInfoAdmin() {
    const { playlist, upp } = this.props;

    return (
      <div className='playlist-info editable m6k-panel'>
        <section>
          <h4 className='edit-title'>Title</h4>
          <EditButton handleClick={this.setEdit} />
          <p>{ playlist.name }</p>
        </section>

        <section>
          <h4 className='edit-title'>Description</h4>
          <EditButton handleClick={this.setEdit} />
          <p>{ playlist.description }</p>
        </section>

        { this.renderGenres() }

        <section>
          <h4 className='edit-title'>Visibility</h4> 
          <EditButton handleClick={this.setEdit} />
          <p>{ playlist.is_private ? 'Private' : 'Public'}</p>
        </section> 
      </div>
    )
  }

  renderAdmin() {
    if(this.state.isEditing) {
      return this.props.playlistUpdating ?
        this.renderPlaylistUpdating() :
        this.renderEditingAdmin()
    } else {
      return this.renderInfoAdmin()
    }
  }

  renderPlaylistUpdating() {
    return (
      <div className='m6k-panel playlist-info editing' style={{lineHeight: '300px', height: '300px'}}>
        <div className='profile-loading'>
          <Loading
            type='spin'
            color='#aaa'
            delay={0}
            height={60}
            width={60}
          />
        </div>
      </div>
    )
  }
 
  render() {
    const upp = this.props.upp;

    if(!upp) {
      return this.renderInfoPublic();
    } else if(upp.permission_level === 'viewer') {
      return this.renderInfoViewer()
    } else if (upp.permission_level === 'owner' || upp.permission_level === 'admin') {
      return this.renderAdmin()
    } else {
      return this.renderInfoPublic()
    }
  }
}

export const setBreadcrumbs = (breadcrumbs) => {
  return {
    type: 'SET_BREADCRUMBS',
    breadcrumbs,
  }
}

export const clearBreadcrumbs = () => {
  return {
    type: 'CLEAR_BREADCRUMBS'
  }
}

const defaultState = {
  breadcrumbs: []
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_BREADCRUMBS':
      return {
        ...state,
        breadcrumbs: action.breadcrumbs,
      }
    case 'CLEAR_BREADCRUMBS':
      return defaultState;
    default:
      return state
  }
}

export default reducer
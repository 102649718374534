import React from 'react';
import Progress from './progress';
import VolumeControl from './volume-control';
import MarketPlace from './market-place/market-place';

import {
  YOUTUBE_PLAYER_STATE_PLAYING
} from 'lib/constants'; 

class PlayerControls extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const isFavorite = this.props.isFavorite;
    const isPlaying = this.props.playerState === YOUTUBE_PLAYER_STATE_PLAYING;

    return (
      <div className='m6k-player-controls'>
        <div className='player-controls-bar'>
            <div className='controls'>
              <span 
                className={`player-control glyphicon glyphicon-random ${this.props.isShuffle ? 'active' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  this.props.toggleShuffle();
                }}
              >
              </span>
              <span 
                className='player-control glyphicon glyphicon-step-backward'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  
                  this.props.prevSong();
                }}
              >
              </span>
              <span 
                className={`player-control glyphicon glyphicon-${isPlaying ? 'pause' : 'play'}`}
                onClick={this.props.togglePlay}
              >
              </span>
              <span 
                className='player-control glyphicon glyphicon-step-forward'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.nextSong();
                }}
              >
              </span>
              <span 
                className={`player-control glyphicon glyphicon-repeat ${this.props.isRepeat ? 'active' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  this.props.toggleRepeat();
                }}
              >
              </span>
            </div>
            
            <Progress 
              currentTime={this.props.currentTime} 
              duration={this.props.duration} 
              buffered={this.props.buffered}
              seekTo={this.props.seekTo}
            />
        </div>

        <VolumeControl 
          isMuted={this.props.isMuted}
          volume={this.props.volume}
          mute={this.props.mute}
          unMute={this.props.unMute}
          setVolume={this.props.setVolume}
        />

        <span 
          className={`favorite glyphicon glyphicon-heart${isFavorite ? '' : '-empty'}`}
          onClick={this.props.toggleFavorite}
        >
        </span>

        <MarketPlace marketplace={this.props.marketplace} />
      </div>
    );
  }
}

export default PlayerControls;
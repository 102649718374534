import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Loading from 'react-loading';

import { confirmUser } from '../modules/confirmation';

import '../confirm.scss';

export class Confirmation extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    this.props.confirmUser(params.token);
  }

  render() {
    return (
      <div className='confirm'>
         <Helmet>
          <title>Mu6ik / Confirmation</title>
        </Helmet> 

        <h1>Mu6ik</h1>

        <h3>Confirming Account</h3>
        <div className='loading'>
          <Loading 
            type="spin" 
            color="#eee" 
            delay={0}
            height={75}
            width={75}
          />
        </div>
      </div>
    );
  }
}


export default connect(null, {
  confirmUser,
})(Confirmation);

import React, { Component } from 'react'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'

const NameDrag = SortableHandle(({ name }) => 
	<span className='name'>
		{name}
	</span>
)

const SortableGenre = SortableElement((props) => 
	<span className='genre-label label-edit noselect'>
		<NameDrag name={props.name} />
		<span onClick={props.handleClick} className='remove'>X</span>
	</span>
)

export default SortableGenre

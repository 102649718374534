import React from 'react'
import { Route } from 'react-router-dom'

const RenderedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    <Component {...props} />
  )} />
)

export default RenderedRoute

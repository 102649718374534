import React from 'react'

export default class Subscription extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
  	const {
  		subscription,
  		handleClick,
  		index,
      onChange
  	} = this.props

    return (
      <div className='subscription' onClick={(e) => {
        onChange()
      	handleClick(index, !subscription.is_subscribed)
      }} >
        <input type='checkbox'
          checked={subscription.is_subscribed}
          onChange={e => e.preventDefault()} />
        <span className='subscription-label'>
          {subscription.email_campaign.name}
        </span>
      </div>
    )
  }
}

import React from 'react';

import './index.scss'

export default class FollowButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isFollowed } = this.props;

    const followedClass = isFollowed ? 
    'follow-button following btn btn-default' : 
    'follow-button follow btn btn-default';
    
    const buttonText = isFollowed ? 'Following' : 'Follow'

    return (
      <button
        className={followedClass}
        onClick={(e) => {
          e.preventDefault();
          if(this.props.isPublic) {
            this.props.openLoginModal();
          } else {
            this.props.onClick()
          }
        }}
      >
        <span className='glyphicon glyphicon-headphones' />
        <span className='hover-text'>Unfollow</span>
        <span className='button-text'>{buttonText}</span>
      </button>
    );
  }
}

import axios from 'lib/axios-config'
import { feedTemplates, feedTemplate } from 'app/schemas/main'
import { normalize } from 'normalizr'

import { push, replace } from 'connected-react-router'
import queryString from 'query-string'

import filter from 'lodash/filter'
import find from 'lodash/find'

const defaultState = {
  isFetching: false,
  isUpdating: false,
  isCreating: false,
  isDeleting: false, 

  templates: [],
  template: null,
  result: null,
}

export const fetchFeedTemplates = () => {
  return (dispatch, getState) => {
    const url = `/api/feed_templates`
    const state = getState()

    if(state.feedTemplates.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: 'FETCH_FEED_TEMPLATES_REQUEST'
    })

    return axios.get(url)
      .then((response) => {
        const normalized = normalize(response.data, feedTemplates)
        dispatch({
          type: 'FETCH_FEED_TEMPLATES_SUCCESS',
          payload: {
            entities: normalized.entities,
            templates: normalized.result
          }
        })

        return Promise.resolve(response.data)
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: 'FETCH_FEED_TEMPLATES_FAILURE',
          payload: {
            error: error.response.data.errors
          }
        })
        return Promise.reject()
      })
 }
}


export const fetchFeedTemplate = (code) => {
  return (dispatch, getState) => {
    const url = `/api/feed_templates/${code}`
    const state = getState()
    
    // if (state.feedTemplates.isFetching) {
    //   return Promise.reject()
    // }

    dispatch({
      type: 'FETCH_FEED_TEMPLATE_REQUEST'
    })

    return axios.get(url)
      .then((response) => {
        const normalized = normalize(response.data, feedTemplate)

        dispatch({
          type: 'FETCH_FEED_TEMPLATE_SUCCESS',
          payload: {
            entities: normalized.entities,
            result: normalized.result,
          }
        })

        return Promise.resolve(response.data)
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: 'FETCH_FEED_TEMPLATES_FAILURE',
          payload: {
            error: error.response.data.errors
          }
        }) 

        return Promise.reject()
      })
  }
}

export const findFeedTemplate = (code) => {
  return (dispatch, getState) => {
    const url = `/api/feed_templates/${code}/find`
    const state = getState()
    
    if (state.feedTemplates.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: 'FIND_FEED_TEMPLATE_REQUEST'
    })

    return axios.get(url)
      .then((response) => {
        const normalized = normalize(response.data, feedTemplate)

        dispatch({
          type: 'FIND_FEED_TEMPLATE_SUCCESS',
          payload: {
            entities: normalized.entities,
            result: normalized.result,
          }
        })

        return Promise.resolve(response.data)
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: 'FIND_FEED_TEMPLATES_FAILURE',
          payload: {
            error: error.response.data.errors
          }
        }) 

        return Promise.reject()
      })
  }
}

export const createFeedTemplate = (params) => {
  return (dispatch, getState) => {
    const url = `/api/feed_templates`
    const state = getState()

    if (state.feedTemplates.isCreating) {
      return Promise.reject()
    }

    dispatch({
      type: 'CREATE_FEED_TEMPLATE_REQUEST'
    })

    return axios.post(url, params)
      .then((response) => {
        const normalized = normalize(response.data, feedTemplate)

        dispatch({
          type: 'CREATE_FEED_TEMPLATE_SUCCESS',
          payload: {
            entities: normalized.entities,
            result: normalized.result,
          }
        })

        dispatch(replace({
          pathname: '/feed',
          search: queryString.stringify({ template: response.data.code }) 
        }))

        return Promise.resolve(response.data)
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: 'CREATE_FEED_TEMPLATE_FAILURE',
          payload: {
            error: error.response.data.errors
          }
        }) 
        return Promise.reject()
      })
  }
}

export const updateFeedTemplate = (id, params) => {
  return (dispatch, getState) => {
    const url = `/api/feed_templates/${id}`
    const state = getState()
   
    if (state.feedTemplates.isUpdating) {
      return Promise.reject()
    } 

    dispatch({
      type: 'UPDATE_FEED_TEMPLATE_REQUEST'
    })

    return axios.put(url, params)
      .then((response) => {
        const normalized = normalize(response.data, feedTemplate)

        dispatch({
          type: 'UPDATE_FEED_TEMPLATE_SUCCESS',
          payload: {
            entities: normalized.entities,
            result: entities.result,
          }
        })

        return Promise.resolve()
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: 'UPDATE_FEED_TEMPLATE_FAILURE',
          payload: {
            error: error.response.data.errors
          }
        }) 
        return Promise.reject()
      })
  }
}
    
export const deleteFeedTemplate = (template, index) => {
  return (dispatch, getState) => {
    const url = `/api/feed_templates/${template.id}`
    const state = getState()

    if (state.feedTemplates.isDeleting) {
      return Promise.reject()
    }

    dispatch({
      type: 'DELETE_FEED_TEMPLATE_REQUEST'
    })

    return axios.delete(url)
      .then((response) => {
        dispatch({
          type: 'DELETE_FEED_TEMPLATE_SUCCESS',
          payload: {
            result: template.id,
            code: template.code,
            index,
          }
        })

        if(template.code === state.feedTemplates.template) {
          dispatch(replace({
            pathname: '/feed',
            search: null
          }))
        }

        return Promise.resolve(template.code === state.feedTemplates.template)
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: 'UPDATE_FEED_TEMPLATE_FAILURE',
          payload: {
            error: error.response.data.errors
          }
        }) 
        return Promise.reject()
      })
  }
}

export const selectFeedTemplates = (state) => {
  const codes = state.feedTemplates.templates;

  return codes.map((code) => {
    return {
      ...state.entities.feedTemplates[code]
    }
  })
}

export const selectFeedTemplate = (state, type) => {
  const code  = state.feedTemplates[type]; 

  if(!code) return {};

  return state.entities.feedTemplates[code];
}

export const setFeedTemplate = (code) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_FEED_TEMPLATE',
      payload: {
        code,
      }
    })

    dispatch(replace({
      pathname: '/feed',
      search: queryString.stringify({ template: code }) 
    }))
  }
}


const reducer = (state = defaultState, action) => {
  switch(action.type) {
    case "SET_FEED_TEMPLATE":
      return {
        ...state,
        template: action.payload.code,
      }
    case 'FETCH_FEED_TEMPLATES_REQUEST':
      return {
        ...state,
        isFetching: true
      }
    case 'FETCH_FEED_TEMPLATES_SUCCESS':
      return {
        ...state,
        isFetching: false,
        templates: action.payload.templates
      }
    case 'FETCH_FEED_TEMPLATES_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      }

    case 'FETCH_FEED_TEMPLATE_REQUEST':
      return {
        ...state,
        isFetching: true,
      }
    case 'FETCH_FEED_TEMPLATE_SUCCESS':
      return {
        ...state,
        isFetching: false,
        template: action.payload.result,
      }
    case 'FETCH_FEED_TEMPLATE_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      }

    case 'FIND_FEED_TEMPLATE_REQUEST':
      return {
        ...state,
        isFetching: true,
      }
    case 'FIND_FEED_TEMPLATE_SUCCESS':
      return {
        ...state,
        isFetching: false,
        result: action.payload.result,
      }
    case 'FIND_FEED_TEMPLATE_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      }
    case 'CREATE_FEED_TEMPLATE_REQUEST':
      return {
        ...state,
        isCreating: true,
      }
    case 'CREATE_FEED_TEMPLATE_SUCCESS':
      return {
        ...state,
        isCreating: false,
        templates: [
          ...state.templates,
          action.payload.result,
        ],
        template: action.payload.result,
      }
    case 'CREATE_FEED_TEMPLATE_FAILURE':
      return {
        ...state,
        isCreating: false,
        error: action.payload.error,
      }
    case 'DELETE_FEED_TEMPLATE_REQUEST':
      return {
        ...state,
        isDeleting: true
      }
    case 'DELETE_FEED_TEMPLATE_SUCCESS':
      return {
        ...state,
        isDeleting: false,
        template: action.payload.code === state.template ? null : state.template,
        templates: [
          ...state.templates.slice(0, action.payload.index),
          ...state.templates.slice(action.payload.index + 1)
        ]
      }
    default:
      return state;
  }
}

export default reducer;
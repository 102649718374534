import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import { Switch, Route, Redirect } from 'react-router-dom'
import NavLink from 'app/components/nav-link'

import Account from '../routes/account'
import Password from '../routes/password'
import EmailNotifications from '../routes/email-notifications'
import Invitations from '../routes/invitations'
import General from '../routes/general'
import BlockedUsers from '../routes/blocked-users'

import '../styles/settings.scss'

class Settings extends Component {
  componentWillUnmount () {
  }

  render () {
    return (
      <div className='settings'>

        <Helmet>
          <title>Mu6ik / Settings</title>
        </Helmet>

        <div className='container-fluid'>
          <div className='settings-panel'>
            <h1>Settings</h1>
            <div className='m6k-panel'>
              <div className='row'>
                <div className='col-md-3 vertical-nav'>
                  <ul>
                    <NavLink to='/settings/general'>General</NavLink>
                    <NavLink to='/settings/account'>Account</NavLink>
                    <NavLink to='/settings/password'>Password</NavLink>
                    <NavLink to='/settings/email_notifications'>Email Notifications</NavLink>
                    <NavLink to='/settings/invitations'>Invitations</NavLink>
                    <NavLink to='/settings/blocked_users'>Blocked Users</NavLink>
                  </ul>
                </div>
                <div className='col-md-9 content'>
                  <Switch>
                    <Route path='/settings/general' component={General} />
                    <Route path='/settings/account' component={Account} />
                    <Route path='/settings/password' component={Password} />
                    <Route path='/settings/email_notifications' component={EmailNotifications} />
                    <Route path='/settings/invitations' component={Invitations} />
                    <Route path='/settings/blocked_users' component={BlockedUsers} />
                    <Redirect from='/settings' to='/settings/account' />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default Settings

import axios from 'lib/axios-config'
import queryString from 'query-string'
import moment from 'moment'
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import uniq from 'lodash/uniq'

import { normalize } from 'normalizr'
import { artist, artists } from 'app/schemas/main'
import { createSelector } from 'reselect'
import { replace, push } from 'connected-react-router'

const defaultState = {
  ids: [],
  total: 0,
  filters: {
    degree: 0,
    cities: [],
  }
}

export const getUsers = (state) => {
  const entities = state.entities;
  const ids = state.search.users.ids;

  return ids.map((id) => {
    const user = entities.users[id];

    return {
      ...user
    }
  })
}

const searchUsersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_SEARCH_RESULTS_SUCCESS':
      const ids = action.payload.loadMore ? 
        [ ...state.ids, ...action.payload.users.ids ] : 
        action.payload.users.ids

      return {
        ...state,
        ids,
        total: action.payload.users.total,
      }
    case 'TOGGLE_FILTER_OPTION':
      if(action.payload.mode !== 'people') return state;
      const key = action.payload.key

      if (action.payload.isFilter) {
        return {
          ...state,
          filters: {
            ...state.filters,
            [key]: uniq([
              ...state.filters[key],
              action.payload.id
            ])
          }
        }
      } else {
        const items = filter(state.filters[key], id => id !== action.payload.id)

        return {
          ...state,
          filters: {
            ...state.filters,
            [key]: items,
          }
        }
      } 
    case 'CLEAR_ALL_FILTER_OPTIONS':
      if(action.payload.mode !== 'people') return state;

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: []
        }
      }
    case 'SET_SEARCH_FIELD':
      if(action.payload.mode !== 'people') return state;
      
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.field]: action.payload.value
        }
      }
    default:
      return state === null ? defaultState : state
  }
}

export default searchUsersReducer;

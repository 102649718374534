import React, { Component } from 'react'
import FilterOption from './filter-option'

import './filter-panel.scss'

class FilterPanel extends Component {
  constructor (props) {
    super(props)

    this.handleResize = this.handleResize.bind(this)

    this.state = {
      isMobile: false
    }
  }

  handleResize () {
    if (window.innerWidth > 767 && this.state.isMobile === true) {
      this.setState({isMobile: false})
    } else if (window.innerWidth <= 767 && this.state.isMobile === false) {
      this.setState({isMobile: true})
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize)

    this.handleResize()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }

  render () {
    const items = [[], []]

    if (this.props.items.length === 0) {
      items[0].push(<li className='filter-option' key={'key-empty'}><h3>No results found</h3></li>)
    }

    for (var i = 0; i < this.props.items.length; i++) {
      const item = this.props.items[i]
      const listIndex = i % 2;

      items[listIndex].push(
        <FilterOption
          key={`${i}-${listIndex}`}
          item={item}
          onChange={this.props.toggle}
        />
      )
    }

    return (
      <div className='filter-panel'>
        <div className='filter'>
          <span className='glyphicon glyphicon-search' />
          <input
            type='text'
            placeholder={this.props.placeholder}
            onChange={(e) => {
              e.preventDefault()
              this.props.filter(e.currentTarget.value)
            }}
            value={this.props.filterValue || ''}
          />
        </div>
        <div className='filter-results'>
          <ul className='filter-list'>
            {items[0]}
          </ul>
          <ul className='filter-list'>
            {items[1]}
          </ul>
        </div>
      </div>
    )
  }
}

export default FilterPanel

import { setItem, getItem } from 'lib/local-storage'

export const setUIField = (field, value, persist = true) => {
  if (persist) {
    let uiState = getItem('ui')

    if (!uiState) {
      uiState = {}
    }

    uiState[field] = value

    setItem('ui', uiState)
  }

  return {
    type: 'SET_UI_FIELD',
    payload: {
      field,
      value
    }
  }
}

export const loadUI = () => {
  let uiState = getItem('ui')
  
  if (!uiState) {
    uiState = {}
  }

  return {
    type: 'LOAD_UI',
    payload: uiState
  }
}

const defaultState = {
}

const ui = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_UI_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value
      }
    case 'LOAD_UI':
      return {
        ...state,
        ...action.payload
      }      
    default:
      return state
  }
}

export default ui

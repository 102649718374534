import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

class NavLink extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const { search, pathname } = this.props.location;
    const isActive = (this.props.to.includes(pathname) && pathname != "/") || (
      pathname.includes(this.props.to))

    const className = isActive ? 'active' : ''
    const to = this.props.to === undefined || this.props.to === null ? '#' : this.props.to

    return (
      <li className={className} onClick={this.props.onClick}>
        <Link to={to}>
          {this.props.children}
        </Link>
      </li>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    path: state.router.location.pathname,
    location: state.router.location
  }
}

export default connect(mapStateToProps)(NavLink)

import React from 'react';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import ReactGA from 'react-ga';

import Notice from 'components/Notice/Notice';
import Status from './Status';

import {
  validEmail
} from 'lib/validate';

import { formatErrors } from 'lib/format-message'
import {
  sendInvite,
  setInviteEmail,
  clearErrors,
  fetchSentInvites,
} from '../../../modules/invites';

const SendingInvite = () => {
  return (
    <div className='sending'>
      <h3>Sending Invite</h3>
      <span className='spinner'>
        <ReactLoading type='bars' color='#E74C3C' delay={0} />
      </span>
    </div>
  )
}

class Invitations extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeNotice = this.closeNotice.bind(this);

    this.state = {
      message: null,
      messageVisible: false,
      messageClasses: null,
      validEmail: false,
    }
  }

  componentDidMount() {
    this.props.fetchSentInvites()
  }

  handleSubmit(e) {
    e.preventDefault();

    if(!this.props.validEmail || this.props.invite.invitesLeft === 0) {
      return;
    }

    this.props.sendInvite()
      .then(() => {
        this.setState({
          message: "Invite successfully sent!",
          messageVisible: true,
          messageClasses: "green",
          validEmail: false,
        })

        this.props.fetchSentInvites();

        ReactGA.event({
          category: "User",
          action: "Sent Invitation",
        })
      })
      .catch(() => {
        const messages = formatErrors(this.props.invite.error.errors);

        this.setState({
          message: messages[0],
          messageVisible: true,
          messageClasses: "red",
        })
      })
  }

  closeNotice() {
    this.setState({
      message: null,
      messageVisible: false,
    })
  }

  handleChange(e) {
    const value = e.currentTarget.value;

    this.setState({
      validEmail: validEmail(value)
    });

    this.props.setInviteEmail(value);
  }

  render() {
    const isDisabled = !this.state.validEmail || this.props.invite.invitesLeft === 0;

    return (
      <div>
        <h2>Invitations
          <span style={{float: 'right'}}>
          {this.props.invite.invitesLeft} Invites Left</span>
        </h2>
        <Notice 
          classes={this.state.messageClasses} 
          visible={this.state.messageVisible}
          handleClick={this.closeNotice}
        >
          {this.state.message}
        </Notice>

        {
          this.props.invite.sendingInvite ?
          <SendingInvite />
           :
          <form className='form-horizontal' onSubmit={this.handleSubmit}>
            <div className='form-group'>
              <label className="col-sm-2 control-label">Email</label>
              <div className='col-sm-7'>
                <input 
                  type={"text"} 
                  className="form-control" 
                  value={this.props.invite.email || ""}
                  onChange={this.handleChange}
                  placeholder="example@email.com"
                />
              </div>
              <div className='col-sm-3'>
                <button 
                  className='btn btn-default invite' 
                  disabled={isDisabled}
                >
                  Send Invite
                </button>
              </div>
            </div>
          </form>
        }

        <h2>Status</h2>
        {
          this.props.invite.invites.length > 0 ?
            <Status invites={this.props.invite.invites} /> :
            <div style={{padding: "20px"}}>
              <h4>You haven't sent any invitations yet.</h4> 
              <h4>To send an invite
                <ol>
                  <li>Fill out the input box above with an email of someone you know</li>
                  <li>Hit send</li>
                  <li>Check out this page later to see the status of all of your invitations</li>
                </ol>
              </h4>
            </div>
        } 
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invite: state.invite,
  }
}

export default connect(mapStateToProps, {
  sendInvite,
  setInviteEmail,
  clearErrors,
  validEmail,
  fetchSentInvites,
})(Invitations);

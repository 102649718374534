import React from 'react';
import FriendListItem from './friend-list-item';

const FriendsList = (props) => {
  const friendsListItems = props.friends.map((friend, index) => {
    return (
      <FriendListItem 
        pendingCollabInfo={props.pendingCollabs[friend.id]}
        addedCollab={props.addedCollabs[friend.id]}
        updatePendingCollabs={props.updatePendingCollabs}
        addUserPlaylistPermission={props.addUserPlaylistPermission}
        key={`friends-list-item-${index}`}
        friend={friend}
      />
    );
  });

  return (
    <ul className='friends-list'>
      { friendsListItems }
    </ul>
  )
}

export default FriendsList;
import axios from 'lib/axios-config'
import { user, users } from 'app/schemas/main' 
import { normalize } from 'normalizr'
import { concatName } from 'lib/user'

import Raven from 'raven-js'

export function fetchUserProfileFailure(payload) {
	return {
		type: "FETCH_USER_PROFILE_FAILURE",
		payload
	}
}

export function fetchUserProfileSuccess(payload) {
	return {
		type: "FETCH_USER_PROFILE_SUCCESS",
		payload
	}
}

export const fetchUserProfile = (username) => {
	return (dispatch, getState) => {
		const state = getState()
		const url = `/api/users/${username}/profile`

		if(state.profile.isFetching) {
			return Promise.reject()
		}

		dispatch({
			type: "FETCH_USER_PROFILE"
		})

		return axios.get(url)
			.then((response) => {
				const normalized = normalize(response.data, user)
				dispatch(fetchUserProfileSuccess({
					entities: normalized.entities,
					uuid: normalized.result
				}))

				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(fetchUserProfileFailure({
					error: error.response.data.error
				}))

				return Promise.reject()
			})
	}
}

export const updateUserProfile = (params) => {
	return (dispatch, getState) => {
		const url = `/api/users/update_profile`

		dispatch({
			type: "UPDATE_USER_PROFILE"
		})

		return axios.put(url, { user: params })
			.then((response) => {
				const normalized = normalize(response.data, user)
				dispatch({
					type: "UPDATE_USER_PROFILE_SUCCESS",
					payload: {
						entities: normalized.entities,
					}
				});

				return Promise.resolve()
			})
		.catch((error) => {
			dispatch({
				type: "UPDATE_USER_PROFILE_FAILURE",
				payload: {
					error: error.response.data.errors
				}
			})
		})
	}
}

export function isCurrentUser (state) {
	const profile_uuid = state.profile.uuid;
	const user_uuid = state.currentUser.account.uuid;

	return profile_uuid === user_uuid;
}

export function uploadUserProfilePhoto(blob, updateFn) {
	return (dispatch, getState) => {
		const uuid = getState().currentUser.account.uuid;
		const url = `/api/users/${uuid}/photos`

		dispatch({
			type: 'UPDATE_USER_PROFILE_PHOTO'
		})

		const formData = new FormData();
		formData.append('file', blob)

		return axios.post(url, formData, {
			onUploadProgress: function (progressEvent) {
				let uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

				uploadPercentage = uploadPercentage >= 90 ? 90 : uploadPercentage

				updateFn(uploadPercentage)
				return uploadPercentage
			},
			onDownloadProgress: function(progressEvent) {
				updateFn(100)
			}
		})
			.then((response) => {
				dispatch(updateUserProfile({ profile_photo_id: response.data.id }, updateFn))
					.then(() => {
						dispatch({
							type: 'UPDATE_USER_PROFILE_PHOTO_SUCCESS'
						})

						return Promise.resolve()
					})
			})
			.catch((error) => {
				Raven.captureException(error);

				dispatch({
					type: 'UPDATE_USER_PROFILE_PHOTO_FAILURE'
				})
			})
	}
}

export function deletePhoto(photoId) {
	return (dispatch, getState) => {
		const uuid = getState().currentUser.account.uuid;
		const url = `/api/users/${uuid}/photos/${photoId}`

		dispatch({
			type: 'DELETE_USER_PROFILE_PHOTO'
		})

		return axios.delete(url)
			.then((response) => {
				dispatch({
					type: 'DELETE_USER_PROFILE_PHOTO_SUCCESS',
				})
			})
			.catch((error) => {
				dispatch({
					type: 'DELETE_USER_PROFILE_PHOTO_FAILURE',
					payload: {
						error: error.response.data
					}
				})
			})
	}
}

export function deleteUserPhoto() {
	return (dispatch, getState) => {
		const url = `/api/users/delete_profile_photo`
		const state = getState();

		if (state.profile.photo.isDeleting) {
			return Promise.reject()
		}

		dispatch({
			type: 'DELETE_USER_PROFILE_PHOTO'
		})

		return axios.delete(url)
			.then((response) => {
				const normalized = normalize(response.data, user)

				dispatch({
					type: 'DELETE_USER_PROFILE_PHOTO_SUCCESS',
					payload: {
						entities: normalized.entities
					}
				})

				return Promise.resolve()
			})
			.catch((error) => {
				dispatch({
					type: 'DELETE_USER_PROFILE_PHOTO_FAILURE',
					payload: {
						error: error.response.data
					}
				})

				return Promise.reject()
			})
	}
}

export const removeUserGenres = (genres) => {
	return (dispatch, getState) => {
		const url = `/api/favorite_genres/bulk_remove`

		if (!genres || genres.length === 0) {
			return Promise.resolve()
		}

		const ids = genres.map((g) => g.id);

		return axios.delete(url, { params: { ids } })
			.then((response) => {
				return Promise.resolve()
			})
			.catch((error) => {
				return Promise.reject()
			})
	}
}

export const addUserGenres = (newGenres) => {
	return (dispatch, getState) => {
		const url = `/api/favorite_genres/bulk_add`
		const uuid = getState().currentUser.account.uuid;

		const genres = newGenres.map((g, i) => {
			return {
				id: g.id,
				name: g.name,
				position: i,
			}
		})

		if(!genres || genres.length === 0) {
			return Promise.resolve()
		}

		dispatch({
			type: "ADD_USER_GENRES"
		})

		return axios.post(url, { genres })
			.then((response) => {
				dispatch({
					type: "ADD_USER_GENRES_SUCCESS",
					payload: {
						uuid,
						genres,
					}
				})

				return Promise.resolve()
			})
			.catch((error) => {

				dispatch({
					type: "ADD_USER_GENRES_FAILURE"
				})

				return Promise.reject()
			})
	}
}

export const selectProfile = (state) => {
	const uuid = state.profile.uuid;

	if (state.entities.users.hasOwnProperty(uuid)) {
		const user = state.entities.users[uuid]
		user.name = concatName(user.first_name, user.last_name, user.username)

		return user
	} else {
		return {}
	}
}

const defaultState = {
	isFetching: false,
	isUpdating: false,
	didInvalidate: false,
	error: null,
	uuid: null,
	photo: {
		uploadFailure: false,
		isUploading: false,
		isDeleting: false,
		error: null,
	}
}

const profile = (state = defaultState, action) => {
	switch(action.type) {
		case "FETCH_USER_PROFILE":
			return {
				...state,
				isFetching: true,
				error: null,
			}
		case "FETCH_USER_PROFILE_SUCCESS":
			return {
				...state,
				isFetching: false,
				uuid: action.payload.uuid	
			}
		case "FETCH_USER_PROFILE_FAILURE":
			return {
				...state,
				isFetching: false,
				error: action.payload.error,
			}
		case 'UPDATE_USER_PROFILE':
			return {
				...state,
				isUpdating: true
			}
		case 'UPDATE_USER_PROFILE_SUCCESS':
			return {
				...state,
				isUpdating: false,
			}	
		case 'UPDATE_USER_PROFILE_FAILURE':
			return {
				...state,
				isUpdating: false,
				error: action.payload.error
			}
		case 'UPDATE_USER_PROFILE_PHOTO':
			return {
				...state,
				photo: {
					...state.photo,
					uploadFailure: false,
					isUploading: true,
				}
			}
		case 'UPDATE_USER_PROFILE_PHOTO_SUCCESS':
			return {
				...state,
				photo: {
					...state.photo,
					isUploading: false
				}
			}
		case 'UPDATE_USER_PROFILE_PHOTO_FAILURE':
			return {
				...state,
				photo: {
					...state.photo,
					isUploading: false,
					uploadFailure: true,
				}
			}
		case 'DELETE_USER_PROFILE_PHOTO':
			return {
				...state,
				photo: {
					...state.photo,
					isDeleting: true,
					error: null,
				}
			}
		case 'DELETE_USER_PROFILE_PHOTO_SUCCESS':
			return {
				...state,
				photo: {
					...state.photo,
					isDeleting: false
				}
			}
		case 'DELETE_USER_PROFILE_PHOTO_FAILURE':
			return {
				...state,
				photo: {
					...state.photo,
					isDeleting: false,
					error: action.payload.error,
				}
			}
		default:
			return state;
	}
}

export default profile;
import axios from 'axios'

export const client = axios.create()

client.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')

  if (token) {
    config.headers = {
      'Authorization': `Bearer ${token}`
    }
  }

  return config
})

client.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    // Do something with response error
    if(error.response.status === 401) {
    	localStorage.removeItem('token');

      const rootPath = window.location.pathname.split("/")[1]

      switch(rootPath) {
        case 'login':
          break;
        case 'dj':
          break;
        default:
          window.location = '/login'
      }

      return Promise.reject(error)
    }

    return Promise.reject(error);
  });

export default client
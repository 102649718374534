import axios from 'lib/axios-config'
import { extractTokenFromHeaders } from 'lib/authentication'
import jwtDecode from 'jwt-decode'

import { replace } from 'connected-react-router'

export function verifyInviteToken (token) {
  return (dispatch, getState) => {
    const state = getState()
    const url = `/api/users/valid_invite_token?invitation_token=${token}`

    if (state.invitation.isFetching) {
      return Promise.reject()
    }

    dispatch({type: 'VERIFY_INVITE_TOKEN_REQUEST'})

    return axios.get(url)
      .then((response) => {
        dispatch({
          type: 'VERIFY_INVITE_TOKEN_SUCCESS',
          payload: {
            email: response.data.email
          }
        })
      }).catch((error) => {
        dispatch({
          type: 'VERIFY_INVITE_TOKEN_FAILURE',
          error: error.response.data
        })

        dispatch(replace('/login'))
      })
  }
}

export function acceptInvitation (
  first_name, last_name, password, password_confirmation, invitation_token) {
  return (dispatch, getState) => {
    const registration = getState().registration
    const url = `/api/users/invitations`

    if (registration.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: 'CREATE_USER_REQUEST'
    })

    if(registration.didAgree !== true) {
      dispatch({
        type: 'CREATE_USER_FAILURE',
        payload: {
          error: {
            you: ['must accept the terms of service and privacy policy agreement.']
          }
        }
      })

      return Promise.reject()
    }

    const params = {
      username: registration.username,
      email: registration.email,
      send_marketing_emails: registration.sendMarketingEmails,
      first_name,
      last_name,
      password,
      password_confirmation,
      invitation_token,
    }

    return axios.put(url, params)
      .then((response) => {
        try {
          const token = extractTokenFromHeaders(response)
          const uuid = jwtDecode(token).params

          localStorage.setItem('token', token)

          dispatch({ type: 'CREATE_USER_SUCCESS' })
          dispatch({ type: 'LOGIN_AUTH_SUCCESS', uuid, token })

          return Promise.resolve()
        } catch (error) {
          dispatch({type: 'LOGIN_AUTH_FAILURE', error})

          return Promise.reject()
        }
      }).catch((error) => {
        dispatch({
          type: 'CREATE_USER_FAILURE',
          payload: {
            error: error.response.data.errors
          }
        })

        return Promise.reject()
      })
  }
}

const defaultState = {
  isFetching: false,
  isVerified: false,
  error: null
}

const invitation = (state = defaultState, action) => {
  switch (action.type) {
    case 'VERIFY_INVITE_TOKEN_REQUEST':
      return {
        ...state,
        isFetching: true
      }
    case 'VERIFY_INVITE_TOKEN_SUCCESS':
      return {
        ...state,
        isFetching: false,
        isVerified: true,
        error: null
      }
    case 'VERIFY_INVITE_TOKEN_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    case 'CREATE_USER_REQUEST':
      return {
        ...state,
        isFetching: true
      }
    case 'CREATE_USER_SUCCESS':
      return {
        ...state,
        isFetching: false,
        didInvalidate: false
      }
    case 'CREATE_USER_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default invitation

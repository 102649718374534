import React from 'react'
import ReactDOM from 'react-dom'
import Raven from 'raven-js'

import './styles/main.scss'

Raven.config('https://11e9d507fc224a63a323f924122d07c8@sentry.io/215757').install()

const MOUNT_NODE = document.getElementById('root')

// -----------------------------------------
// ------------ RENDER SETUP
// -----------------------------------------
let render = () => {
  const Root = require('./components/root').default

  ReactDOM.render(
    <Root />,
	  MOUNT_NODE
	)
}

if (__DEV__) {
  if (module.hot) {
    const renderApp = render

    const renderError = (error) => {
      const RedBox = require('redbox-react').default

      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE)
    }

    render = () => {
      try {
        renderApp()
      } catch (e) {
        console.error(e)
        renderError(e)
      }
    }

    module.hot.accept([
      './components/root'
    ], () =>
			setImmediate(() => {
  ReactDOM.unmountComponentAtNode(MOUNT_NODE)
  render()
})
		)
  }
}

if (!__TEST__) render()

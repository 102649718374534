import axios from 'lib/axios-config'
import { playlist, playlists, userPlaylistPermission } from 'app/schemas/main'
import { normalize } from 'normalizr'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'

import { push, replace } from 'connected-react-router'
import { buildResourceUrl } from 'lib/string'

import { selectCurrentUser } from 'app/modules/current-user/account'

import compact from 'lodash/compact'
import find from 'lodash/find';
import reject from 'lodash/reject'
import orderBy from 'lodash/orderBy'

export const fetchPlaylists = (params = {}) => {
  return (dispatch, getState) => {
    const url = `/api/playlists`
    const state = getState();

    if (state.playlists.isFetching) {
      return Promise.reject();
    }

    dispatch({ type: 'FETCH_PLAYLISTS' })

    return axios.get(url, { params })
      .then((response) => {
        const normalized = normalize(response.data, playlists)
        dispatch({
          type: "FETCH_PLAYLISTS_SUCCESS",
          payload: {
            ids: normalized.result,
            entities: normalized.entities
          }
        })

        return Promise.resolve()
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: "FETCH_PLAYLISTS_FAILURE",
          payload: {
            error: error.response.data.error
          }
        })

        return Promise.reject()
      })
  }
}

export const fetchPlaylist = (uuid) => {
  return (dispatch, getState) => {
    const url = `/api/playlists/${uuid}`;
    const state = getState();
  
    if (state.playlists.isFetchingPlaylist) {
      return Promise.reject();
    }

    dispatch({ type: 'FETCH_PLAYLIST' })

    return axios.get(url)
      .then((response) => {
        const normalized = normalize(response.data, playlist)

        dispatch({
          type: 'FETCH_PLAYLIST_SUCCESS',
          payload: {
            id: normalized.result,
            entities: normalized.entities
          }
        })

        return Promise.resolve(response.data)
      })
      .catch((error) => {
        console.error(error)

        dispatch({
          type: 'FETCH_PLAYLIST_FAILURE',
          payload: {
            error: error.response.data.error
          }
        })

        return Promise.reject()
      })
  }
}

export const fetchPlaylistSongs = (playlistData) => {
  return (dispatch, getState) => {
    const url = `/api/playlists/${playlistData.uuid}/playlist_songs`
    const state = getState();

    if(state.playlists.isFetchingPlaylistSongs) {
      return Promise.reject()
    }

    dispatch({ type: 'FETCH_PLAYLIST_SONGS' })

    return axios.get(url)
      .then((response) => {
        const normalized =  normalize({
          ...playlistData,
          playlistSongs: response.data
        }, playlist)

        dispatch({
          type: 'FETCH_PLAYLIST_SONGS_SUCCESS',
          payload: {
            entities: normalized.entities,
            result: normalized.result
          }
        })

        return Promise.resolve()
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: 'FETCH_PLAYLIST_SONGS_FAILURE',
          payload: {

          }
        })

        return Promise.reject()
      })
  }
}

export const createPlaylist = (params = {}) => {
  return (dispatch, getState) => {
    const url = `/api/playlists`;
    const state = getState();

    if (state.playlists.isCreating) {
      return Promise.reject();
    }

    dispatch({ type: 'CREATE_PLAYLIST' })

    return axios.post(url, params)
      .then((response) => {
        const normalized = normalize(response.data, playlist)

        dispatch({ 
          type: 'CREATE_PLAYLIST_SUCCESS', 
          payload: {
            id: normalized.result,
            entities: normalized.entities,
          } 
        })

        return Promise.resolve(response.data)
      })
      .catch((error) => {
        console.error(error);
        dispatch({ 
          type: 'CREATE_PLAYLIST_FAILURE', 
          payload: {
            error: error.response.data.error
          } 
        })

        return Promise.reject(error) 
      })
  }
}

export const updatePlaylist = (p, params) => {
  return (dispatch, getState) => {
    const url = `/api/playlists/${p.uuid}`;
    const state = getState();

    if (state.playlists.isUpdating) {
      return Promise.reject('already updating...');
    }

    dispatch({ type: 'UPDATE_PLAYLIST' })

    return axios.put(url, {playlist: params})
      .then((response) => {
        const normalized = normalize(response.data, playlist)

        dispatch({ 
          type: 'UPDATE_PLAYLIST_SUCCESS', 
          payload: {
            id: normalized.result,
            entities: normalized.entities,
          } 
        })

        if (params.name && p.name !== params.name) {
          dispatch(replace({
            pathname: buildResourceUrl('playlists', params.name, p.id),
          }))
        }

        return Promise.resolve()
      })
      .catch((error) => {
        console.error(error);

        dispatch({ 
          type: 'UPDATE_PLAYLIST_FAILURE', 
          payload: {
            error: error.response.data.error
          } 
        })

        return Promise.reject(error) 
      })
  }
}

export const deletePlaylist = (p) => {
  return (dispatch, getState) => {
    const url = `/api/playlists/${p.uuid}`;
    const state = getState();

    if (state.playlists.isDeleting) {
      return Promise.reject();
    }

    dispatch({ type: 'DELETE_PLAYLIST' })

    return axios.delete(url, {params: {force_destroy: true}})
      .then((response) => {
        dispatch({
          type: 'DELETE_PLAYLIST_SUCCESS',
          payload: {
            playlistId: p.id,
          }
        })

        const user = selectCurrentUser(state);
        
        dispatch(replace({
          pathname: `/${user.username}/playlists`
        }))
      })
      .catch((error) => {
        console.error(error);

        dispatch({
          type: 'DELETE_PLAYLIST_FAILURE',
          payload: {
            error: error.response.data.error
          }
        })
        return Promise.reject()
      })
  }
}

export const addSongToPlaylist = (p, song) => {
  return (dispatch, getState) => {
    if(p === null || p === undefined) {
      return Promise.reject();
    }

    const url = `/api/playlists/${p.uuid}/playlist_songs`;
    const state = getState();

    if(state.playlists.isAddingSong) {
      return Promise.reject()
    }

    dispatch({
      type: 'ADD_SONG_TO_PLAYLIST'
    })

    return axios.post(url, {
      playlist_song: {
        song_id: song.id,
      }
    })
      .then((response) => {
        dispatch({
          type: 'ADD_SONG_TO_PLAYLIST_SUCCESS'
        })

        return Promise.resolve()
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: 'ADD_SONG_TO_PLAYLIST_FAILURE'
        })

        return Promise.reject()
      })
  }
}

export const updatePlaylistSong = (p, sourceIndex, destIndex) =>  {
  return (dispatch, getState) => {
    if(p === null || p === undefined) {
      return Promise.reject();
    }
    
    const state = getState();
    const playlistSongs = state.entities.playlists[p.id].playlistSongs;
    const ps = playlistSongs[sourceIndex];

    if(!ps) {
      return Promise.reject();
    }

    const url = `/api/playlists/${p.uuid}/playlist_songs/${ps.id}`;

    if(state.playlists.isUpdatingPlaylistSong) {
      return Promise.reject()
    }

    dispatch({
      type: 'UPDATE_PLAYLIST_SONG',
      payload: {
        playlistId: p.id,
        sourceIndex,
        destIndex,
      }
    })

    return axios.put(url, {
      playlist_song: {
        playlist_order_position: destIndex,
      }
    })
      .then((response) => {
        dispatch({
          type: 'UPDATE_PLAYLIST_SONG_SUCCESS',
          payload:  {
            sourceIndex,
            destIndex
          }
        })

        return Promise.resolve()
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: 'UPDATE_PLAYLIST_SONG_FAILURE',
          payload: {
            playlistId: p.id,
            sourceIndex,
            destIndex,
          }
        })

        return Promise.reject()
      })
  }
}

export const removeSongFromPlaylist = (p, psIndex) =>  {
  return (dispatch, getState) => {
    if(p === null || p === undefined) {
      return Promise.reject();
    }
    
    const state = getState();
    const playlistSongs = state.entities.playlists[p.id].playlistSongs;
    const ps = playlistSongs[psIndex];

    if(!ps) {
      return Promise.reject();
    }

    const url = `/api/playlists/${p.uuid}/playlist_songs/${ps.id}`;

    if(state.playlists.isRemovingSong) {
      return Promise.reject()
    }

    dispatch({
      type: 'REMOVE_SONG_FROM_PLAYLIST'
    })

    return axios.delete(url)
      .then((response) => {
        dispatch({
          type: 'REMOVE_SONG_FROM_PLAYLIST_SUCCESS',
          payload: {
            playlistId: p.id,
            psIndex,
          }
        })

        return Promise.resolve()
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: 'REMOVE_SONG_FROM_PLAYLIST_FAILURE'
        })

        return Promise.reject()
      })
  }
}

export const createUserPlaylistPermission = (upp) => {
  return (dispatch, getState) => {
    const state = getState();

    if(state.playlists.isCreatingUpp) {
      return Promise.reject();
    }

    const url = `/api/playlists/${upp.playlist.uuid}/user_playlist_permissions`;

    dispatch({
      type: 'CREATE_UPP_REQUEST'
    })

    return axios.post(url, {
      user_playlist_permission: {
        user_id: upp.userId,
        permission_level: upp.permission,
      }
    })
      .then((response) => {
        const normalized = normalize(response.data, userPlaylistPermission)

        dispatch({
          type: 'CREATE_UPP_SUCCESS',
          payload: {
            entities: normalized.entities,
            result: normalized.result,
            playlistId: upp.playlist.id,
          }
        })

        return Promise.resolve(response.data)
      })
      .catch((error) => {
        console.error(error)

        dispatch({
          type: 'CREATE_UPP_FAILURE',
          payload: {
            error: error.response.data.error,
          }
        })

        return Promise.reject(error)
      })

  }
}

export const updateUserPlaylistPermission = (upp) => {
  return  (dispatch, getState) => {
    const url = `/api/playlists/${upp.playlist.uuid}/user_playlist_permissions/${upp.id}`;
    const state = getState();

    if(state.playlists.isUpdatingUpp) {
      return Promise.reject();
    }

    dispatch({
      type: 'UPDATE_UPP_REQUEST'
    })

    return axios.put(url, {
      user_playlist_permission: {
        permission_level: upp.permission_level,
      }
    })
      .then((response) => {
        dispatch({
          type: 'UPDATE_UPP_SUCCESS',
          payload: {
            upp,
          }
        })

        return Promise.resolve()
      })
      .catch((error) => {        
        dispatch({
          type: 'UPDATE_UPP_FAILURE',
          payload: {
            error: error.response.data.error
          }
        })

        return Promise.reject()
      })
  }
}

export const removeUserPlaylistPermission = (upp) => {
  return (dispatch, getState) => {
    const url = `/api/playlists/${upp.playlist.uuid}/user_playlist_permissions/${upp.id}` 
    const state = getState()

    if(state.playlists.isDeletingUpp) {
      return Promise.reject()
    }

    dispatch({
      type: 'DELETE_UPP_REQUEST'
    })

    return axios.delete(url)
      .then((response) => {
        dispatch({
          type: 'DELETE_UPP_SUCCESS',
          payload: {
            upp,
          }
        })

        return Promise.resolve()
      })
      .catch((error) => {
        console.error(error)

        dispatch({
          type: 'DELETE_UPP_FAILURE',
          payload: {
            error:  error.response.data.error,
          }
        })

        return Promise.reject()
      })
  }
}

export const setPlaylistModalTab = (tab = 'CREATE') => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_PLAYLIST_MODAL_TAB',
      payload: {
        tab
      }
    })
  }
}

export const setSongIdToAdd = (songId) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_SONG_ID_TO_ADD',
      payload: {
        songId
      }
    })
  }
}

export const updateModalFilter = (value) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_MODAL_FILTER',
      payload: {
        value
      }
    })
  }
}

// Selectors
export const selectSongId = (state) => {
  const id = state.playlists.songIdToAdd
  const songs = state.entities.songs;

  return songs[id];
}

export const selectPlaylists = (state) => {
  const ids = state.playlists.ids;
  const playlists = state.entities.playlists;
  const filter = state.playlists.playlistModalFilter;

  const useFilter = filter !== ''; 

  return ids.map((id) => {
    const playlist = playlists[id];

    if(!useFilter || playlist.name.toLowerCase().startsWith(filter.toLowerCase())) {
      return playlist
    }
  }) 
}

export const getPlaylistDetails = (state, id) => {
  if(id === null || id === undefined) {
    return {
      playlistSongs: [],
      user_playlist_permissions: [],
    }
  }

  const playlist = state.entities.playlists[id]
  const songs = state.entities.songs;

  let playlistSongs = [];

  if(playlist.playlistSongs) {
    playlistSongs = playlist.playlistSongs.map((ps) => {
      const song = songs[ps.song]
      
      return {
        id: ps.id,
        position: ps.position,
        song: {
          ...song,
        }
      }
    })
  }

  let user_playlist_permissions = [];

  if(playlist.user_playlist_permissions) {
    user_playlist_permissions = playlist.user_playlist_permissions.map((uppId) => {
      const upp = state.entities.userPlaylistPermissions[uppId];
      const user = state.entities.users[upp.user];

      return {
        ...upp,
        user,
      }  
    })

    user_playlist_permissions = orderBy(user_playlist_permissions, ['created_at'], ['asc'])
  }

  return {
    ...playlist,
    playlistSongs,
    user_playlist_permissions,
  }
}

export const selectPlaylist = (state) => {
  const id = state.playlists.playlist;
  return getPlaylistDetails(state, id);
}

export const selectUserPlaylistPermission = (state) => {
  const currentUser = state.currentUser.account;
  const id = state.playlists.playlist;

  if(id === null) {
    return {}
  }

  const playlist = state.entities.playlists[id]
  const uppIds = playlist.user_playlist_permissions;

  const result = find(uppIds, (uppId) => {
    const upp = state.entities.userPlaylistPermissions[uppId]
    const user = state.entities.users[upp.user];

    return user.uuid === currentUser.uuid
  })

  if(!result) return {};

  const upp = state.entities.userPlaylistPermissions[result];
  const user = state.entities.users[upp.user];

  return {
    ...upp,
    user,
  } 
}

export const selectCurrentCollaborators = (state) => {
  const currentUser = state.currentUser.account;
  const id = state.playlists.playlist;

  const playlist = state.entities.playlists[id]
  const uppIds = playlist.user_playlist_permissions;

  const results = reject(uppIds, (uppId) => {
    const upp = state.entities.userPlaylistPermissions[uppId]
    const user = state.entities.users[upp.user];

    return user.uuid === currentUser.uuid
  })

  if (!results) return [];

  return results.map((result) => {
    const upp = state.entities.userPlaylistPermissions[result];
    const user = state.entities.users[upp.user];

    return {
      ...upp,
      user,
    } 
  })
}

const defaultState = {
  isFetching: false,
  isFetchingPlaylist: false,
  isFetchingPlaylistSongs: false,
  isCreatingUpp: false,
  isUpdatingUpp: false,
  isDeletingUpp: false,
  isCreating: false,
  isDeleting: false,

  didInvalidate: false,
  error: null,
  ids: [],
  playlist: null,
  playlistModalTab: 'CREATE',
  songIdToAdd: null,
  playlistModalFilter: '',
  isAddingSong: false,
  isRemovingSong: false,
  isUpdatingPlaylistSong: false,
}

const playlistReducer = (state = defaultState, action) => {
  switch(action.type) {
    case "FETCH_PLAYLISTS":
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        error: null
      }
    case 'FETCH_PLAYLISTS_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
        didInvalidate: true
      }
    case 'FETCH_PLAYLISTS_SUCCESS':
      return {
        ...state,
        ids: action.payload.ids,
        isFetching: false
      }
    case  'FETCH_PLAYLIST':
      return {
        ...state,
        isFetchingPlaylist: true,
      }
    case 'FETCH_PLAYLIST_SUCCESS':
      return {
        ...state,
        isFetchingPlaylist: false,
        playlist: action.payload.id,
      }
    case 'FETCH_PLAYLIST_FAILURE':
      return {
        ...state,
        isFetchingPlaylist: false,
        error: action.payload.error,
      }
    case 'CREATE_PLAYLIST':
      return {
        ...state,
        isCreating: true,
        didInvalidate: false,
        error: null
      }
    case 'CREATE_PLAYLIST_SUCCESS':
      return {
        ...state,
        isCreating: false,
        ids: [action.payload.id, ...state.ids] 
      }
    case 'CREATE_PLAYLIST_FAILURE':
      return {
        ...state,
        isCreating: false,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'UPDATE_PLAYLIST':
      return {
        ...state,
        isUpdating: true,
        didInvalidate: false,
        error: null
      }
    case 'UPDATE_PLAYLIST_SUCCESS':
      return {
        ...state,
        isUpdating: false,
        ids: [...state.ids, action.payload.id] 
      }
    case 'UPDATE_PLAYLIST_FAILURE':
      return {
        ...state,
        isUpdating: false,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'SET_PLAYLIST_MODAL_TAB':
      return {
        ...state,
        playlistModalTab: action.payload.tab
      }
    case 'SET_SONG_ID_TO_ADD':
      return {
        ...state,
        songIdToAdd: action.payload.songId
      }
    case 'UPDATE_MODAL_FILTER':
      return {
        ...state,
        playlistModalFilter: action.payload.value
      }
    case 'ADD_SONG_TO_PLAYLIST':
      return {
        ...state,
        isAddingSong: true
      }
    case 'ADD_SONG_TO_PLAYLIST_SUCCESS':
      return {
        ...state,
        isAddingSong: false
      }
    case 'ADD_SONG_TO_PLAYLIST_FAILURE':
      return {
        ...state,
        isAddingSong: false
      }
    case 'UPDATE_PLAYLIST_SONG':
      return {
        ...state,
        isUpdatingPlaylistSong: true,
        didInvalidate: false,
        error: null
      }
    case 'UPDATE_PLAYLIST_SONG_SUCCESS':
      return {
        ...state,
        isUpdatingPlaylistSong: false,
      }
    case 'UPDATE_PLAYLIST_SONG_FAILURE':
      return {
        ...state,
        isUpdatingPlaylistSong: false,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'REMOVE_SONG_FROM_PLAYLIST':
      return {
        ...state,
        isRemovingSong: true
      }
    case 'REMOVE_SONG_FROM_PLAYLIST_SUCCESS':
      return {
        ...state,
        isRemovingSong: false
      }
    case 'REMOVE_SONG_FROM_PLAYLIST_FAILURE':
      return {
        ...state,
        isRemovingSong: false
      }
    case 'FETCH_PLAYLIST_SONGS':
      return {
        ...state,
        isFetchingPlaylistSongs: true,
        didInvalidate: false,
        error: null
      }
    case 'FETCH_PLAYLIST_SONGS_SUCCESS':
      return {
        ...state,
        isFetchingPlaylistSongs: false
      }
    case 'FETCH_PLAYLIST_SONGS_FAILURE':
      return {
        ...state,
        isFetchingPlaylistSongs: false,
        didInvalidate: true,
        error: action.payload.error
      }
    case 'DELETE_PLAYLIST':
      return {
        ...state,
        isDeleting: true,
      }
    case 'DELETE_PLAYLIST_SUCCESS':
      return {
        ...state,
        isDeleting: false,
      }
    case 'DELETE_PLAYLIST_FAILURE':
      return {
        ...state,
        isDeleting: false,
        error: action.payload.error
      }
    case 'CREATE_UPP_REQUEST':
      return {
        ...state,
        isCreatingUpp: true,
        error: null,
      }
    case 'CREATE_UPP_SUCCESS':
      return {
        ...state,
        isCreatingUpp: false,
      }
    case 'CREATE_UPP_FAILURE':
      return  {
        ...state,
        isCreatingUpp: false,
        didInvalidate: true,
        error: action.payload.error,
      }
    case 'UPDATE_UPP_REQUEST':
      return {
        ...state,
        isUpdatingUpp: true,
        error: null,
        didInvalidate: false,
      }
    case 'UPDATE_UPP_SUCCESS':
      return {
        ...state,
        isUpdatingUpp: false,
      }
    case 'UPDATE_UPP_FAILURE':
      return {
        ...state,
        didInvalidate: true,
        isUpdatingUpp: false,
        error: action.payload.error,
      }
    case 'DELETE_UPP_REQUEST':
      return {
        ...state,
        isDeletingUpp: true,
        error: null,
        didInvalidate: false,
      }
    case 'DELETE_UPP_SUCCESS':
      return {
        ...state,
        isDeletingUpp: false,
      }
    case 'DELETE_UPP_FAILURE':
      return {
        ...state,
        isDeletingUpp: false,
        didInvalidate: true,
        error: action.payload.error,
      }
    default:
      return state;
  }
}

export default playlistReducer;
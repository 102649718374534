import React from 'react'
import { connect } from 'react-redux'

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { isBrowser } from 'react-device-detect'

import emptyPlaylist from 'assets/img/playlist-empty'

import {
  setIndex,
  setVideoId,
  setPlayerState,
  setPlayerSongId,
  setPlayerArtistId,
  trackPlay,
} from 'app/shared/music-player/modules/player'

import {
  favoriteSong,
  unfavoriteSong
} from 'app/modules/favorites'

import {
  removeVideo,
  fetchSong
} from 'app/modules/song'

import {
  setPlaylistModalTab,
  setSongIdToAdd,
} from 'app/modules/playlists'

import PlaylistModal from 'app/shared/playlists/playlist-modal'
import SetlistItem from './setlist-item'
import SetlistItemSortable from  './setlist-item-sortable'
import SkeletonItem from './skeleton-item'
import CompactSetlistItem from './compact-setlist-item'

import CompactPlaylistItem from './compact-playlist-item'
import ClickHandler from 'components/click-handler'

import LoginModal from 'app/components/login-modal'

import {
  setUIField
} from 'app/modules/current-user/ui'

import {
  formatYoutubeUrl
} from 'lib/video'

import {
  buildResourceUrl
} from 'lib/string'

import '../styles/setlist.scss'
import '../styles/compact-playlist'

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    player: state.player,
  }
}

class Setlist extends React.Component {
  constructor (props) {
    super(props)

    this.playVideoAt = this.playVideoAt.bind(this)
    this.playVideo = this.playVideo.bind(this)
    this.pauseVideo = this.pauseVideo.bind(this)

    this.renderListBody = this.renderListBody.bind(this)
    this.renderListItems = this.renderListItems.bind(this)
    this.renderCompactListItems = this.renderCompactListItems.bind(this)

    this.renderCoverArt = this.renderCoverArt.bind(this)
    this.renderPlaylistItems = this.renderPlaylistItems.bind(this)

    this.renderToolBar = this.renderToolBar.bind(this)
    this.renderHeader = this.renderHeader.bind(this)

    this.renderPlaylistModal = this.renderPlaylistModal.bind(this)
    this.showPlaylistModal = this.showPlaylistModal.bind(this)
    this.hidePlaylistModal = this.hidePlaylistModal.bind(this)

    this.removeSongFromPlaylist = this.removeSongFromPlaylist.bind(this)

    this.renderLoginModal = this.renderLoginModal.bind(this)
    this.openLoginModal = this.openLoginModal.bind(this)

    this.handleResize = this.handleResize.bind(this)
    this.setMode = this.setMode.bind(this)
    this.selectItem = this.selectItem.bind(this)

    this.onSortEnd = this.onSortEnd.bind(this)

    this.state = {
      selected: null,
      isMobile: false,
      showPlaylistModal: false,
      renderLoginModal: false,
    }

    this.loadedVideos = []
    this.errorVideos = []
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize)

    if(this.handleResize()) {
      this.setMode('compact')
    } else if (this.props.mode === null || this.props.mode === undefined) {
      this.setMode('list')
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(this.props.songs.length !== nextProps.songs.length) {
      return true
    }

    const songsChanged = nextProps.songs.find((target, i) => {
      const song = this.props.songs[i];

      return song.id !== target.id || 
        song.is_favorite !== target.is_favorite ||
        target.video.uuid === undefined ||
        song.video.youtube_id !== target.video.youtube_id
    })

    if(songsChanged) {
      return true
    }

    if (this.state.renderLoginModal !== nextState.renderLoginModal) {
      return true;
    }

    if (this.props.mode !== nextProps.mode) {
      return true
    }

    if(this.state.isMobile !== nextState.isMobile) {
      return true
    }

    if(this.state.selected !== nextState.selected) {
      return true
    }

    if(this.props.isInitialFetching !== nextProps.isInitialFetching) {
      return true
    }

    if(this.props.isFetching !== nextProps.isFetching) {
      return true
    }

    if(this.props.player.playerState !== nextProps.player.playerState) {
      return true
    }

    if(this.props.player.currentIndex !== nextProps.player.currentIndex) {
      return true
    }

    if(this.props.isPaginatedFetching !== nextProps.isPaginatedFetching) {
      return true
    }

    if(this.state.showPlaylistModal !== nextState.showPlaylistModal) {
      return true
    }

    if(this.props.isEditMode !== nextProps.isEditMode) {
      return true;
    }

    if(this.props.isCompact !== nextProps.isCompact) {
      return true;
    }

    if(this.props.playlist && (this.props.playlist.youtube_id !== nextProps.playlist.youtube_id)) {
      return true;
    }

    return false
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
    this.props.setIndex(null)
  }

  playVideoAt (index) {
    const song = this.props.songs[index]
    const videoId = song.video.youtube_id

    this.props.setIndex(index)
    this.props.setVideoId(videoId)
    this.props.setPlayerSongId(song.id)
    this.props.player.loadVideo(videoId, song.id)
  }

  playVideo () {
    if (this.props.player.playerState === 2) {
      this.props.player.playVideo()
    }
  }

  pauseVideo (index) {
    if (this.props.player.playerState === 1) {
      this.props.player.pauseVideo(index)
    }
  }

  selectItem (index) {
    this.setState({
      selected: index
    })
  }

  openLoginModal() {
    this.setState({
      renderLoginModal: true
    })
  }

  renderLoginModal() {
    return (
      <LoginModal
        isOpen={this.state.renderLoginModal}
        onCancel={() => {
          this.setState({renderLoginModal: false})
        }}
      />
    )
  }

  handleResize () {
    const w = window.innerWidth

    if (w > 767 && this.state.isMobile === true) {
      this.setState({isMobile: false})

      return false;
    } else if (w <= 767 && this.state.isMobile === false) {
      this.setMode('compact')
      this.setState({isMobile: true})

      return true;
    }

    return false;
  }

  setMode (mode) {
    if (mode === this.props.mode) return
    
    this.props.setUIField('tracklistMode', mode)
  }

  renderSkeletonItems() {
    const items = []
    
    for(let i = 0; i < 10; i++) {
      items.push(
        <SkeletonItem key={`skeleton-item-${i}`} />
      )
    }

    return items;
  }

  renderToolBar() {
    if(this.state.isMobile || !isBrowser) return null;

    return (
      <div className='col-md-2 col-sm-2 col-xs-4'>
         {
          !this.props.renderRefresh ? null :
            <span
              className='glyphicon glyphicon-refresh'
              onClick={this.props.refreshPlaylist}
            />
         }

         {
            !this.props.renderEdit ?  null :
              <span
                className={`glyphicon ${this.props.isEditMode ? 'glyphicon-ok' : 'glyphicon-pencil'}`}
                onClick={this.props.toggleEditMode}
              />
         }

         {
            !this.props.renderCompact ?  null :
              <span
                className={`glyphicon ${this.props.isCompact ? 'glyphicon-list' : 'glyphicon-th-list'}`}
                onClick={this.props.toggleCompactMode}
              />
         }
      </div>
    )
  }

  renderPlaylistModal() {
    if(!this.state.showPlaylistModal) {
      return null
    }

    return (
      <PlaylistModal 
        show={this.state.showPlaylistModal}
        onCancel={this.hidePlaylistModal}
      />
    )
  }

  showPlaylistModal(songId, action) {
    this.props.setSongIdToAdd(songId)
    this.props.setPlaylistModalTab(action)

    this.setState({
      showPlaylistModal: true
    })
  }

  hidePlaylistModal() {
    this.setState({showPlaylistModal: false})
  }

  onSortEnd({oldIndex, newIndex}) {
    this.props.updatePlaylistSong(this.props.playlist, oldIndex, newIndex)
  }

  removeSongFromPlaylist(playlist, index) {
    this.props.removeSongFromPlaylist(playlist, index) 
    this.props.setVideoId(null)
  }

  renderSortableSetlist() {
    return (
      <SortableList 
        songs={this.props.songs}
        onSortEnd={this.onSortEnd}
        playlist={this.props.playlist}
        updatePlaylist={this.props.updatePlaylist}
        removeSongFromPlaylist={this.removeSongFromPlaylist}

        playerIndex={this.props.player.currentIndex}
        playerState={this.props.player.playerState}
        playVideoAt={this.playVideoAt}
        playVideo={this.playVideo}
        pauseVideo={this.pauseVideo}

        lockToContainerEdges
        useWindowAsScrollContainer={true}
        transitionDjuration={150}
        pressDelay={100}
        lockAxis='y'
        hideSortableGhost
        helperClass='dragging-track-helper'
      />
    )
  }

  shouldCancelStart(e) {
    // Cancel sorting if the event target is an `input`, `textarea`, `select` or `option`
    if (['input', 'textarea', 'select', 'option', 'button'].indexOf(e.target.tagName.toLowerCase()) !== -1) {
      return true; // Return true to cancel sorting
    } 
    return false
  }

  renderCompactListItems() {
    const {
      songs,
      favoriteSong,
      unfavoriteSong,
      removeVideo,
      fetchSong,
      ordered,
      player,
      mode,
      renderCreated,
      showError,

      playlist,
      updatePlaylist,
      removeSongFromPlaylist,
      isPublic,
      currentUser,
    } = this.props;

    let keyMode = null;

    if(isPublic) {
      keyMode = 'harmonic'
    } else {
      keyMode =  currentUser.ui.keyMode
    }

    let items = []

    for (let i in this.props.songs) {
      const song = this.props.songs[i]
      const index = parseInt(i)

      items.push(
        <CompactSetlistItem
          key={`set-list-item-${i}-${song.video.youtube_id}`}

          song={song}
          listIndex={index}
          
          favoriteSong={favoriteSong}
          unfavoriteSong={unfavoriteSong}
          removeVideo={removeVideo}
          fetchSong={fetchSong}
          ordered={ordered}
          keyMode={keyMode}

          isPublic={isPublic}

          showError={showError}

          playerVideoId={player.videoId}
          playerIndex={player.currentIndex}
          playerState={player.playerState}

          renderCreated={renderCreated}
          
          id={song.video.youtube_id}
          uuid={song.video.uuid}
          songId={song.id}
          name={song.video.name || song.artist + " - " + song.name}
          duration={song.video.duration}
          created={song.created_at}
          isFavorite={song.is_favorite}
          
          isMobile={this.state.isMobile}
          selectIndex={this.state.selected}

          select={this.selectItem}
          playVideoAt={this.playVideoAt}
          playVideo={this.playVideo}
          pauseVideo={this.pauseVideo}
          showPlaylistModal={this.showPlaylistModal}
        />
      )
    }

    return (
      <ul className='setlist'>
        { items }
      </ul>
    )
  }

  renderListItems () {
    const {
      songs,
      favoriteSong,
      unfavoriteSong,
      removeVideo,
      fetchSong,
      ordered,
      player,
      renderCreated,
      showError,

      playlist,
      updatePlaylist,
      removeSongFromPlaylist,
      isPublic,
      currentUser,
    } = this.props;

    let keyMode = null;
    if(isPublic) {
      keyMode = 'harmonic'
    } else {
      keyMode =  currentUser.ui.keyMode
    }

    if(this.props.isEditMode) {
      const DragHandle = SortableHandle(() => <span>::</span>);

      const SortableItem = SortableElement((props) => {
        return (
          <SetlistItemSortable
            {...props}
          />
        )
      })

      const SortableList = SortableContainer((props) => {
        return (
          <ul className='setlist setlist-sortable'>
            {
              props.songs.map((song, index) => {

                return (
                  <SortableItem
                    index={index}
                    key={`sortable-setlist-item-${index}`}

                    song={song}
                    listIndex={index}
                    
                    favoriteSong={favoriteSong}
                    unfavoriteSong={unfavoriteSong}
                    removeVideo={removeVideo}
                    fetchSong={fetchSong}
                    ordered={ordered}
                    keyMode={keyMode}

                    playerVideoId={player.videoId}
                    playerIndex={player.currentIndex}
                    playerState={player.playerState}

                    playlist={playlist}

                    renderCreated={renderCreated}

                    updatePlaylist={updatePlaylist}
                    removeSongFromPlaylist={removeSongFromPlaylist}
                    
                    id={song.video.youtube_id}
                    uuid={song.video.uuid}
                    songId={song.id}
                    name={song.video.name || song.artist + " - " + song.name}
                    duration={song.video.duration}
                    created={song.created_at}
                    isFavorite={song.is_favorite}
                    
                    isMobile={this.state.isMobile}
                    selectIndex={this.state.selected}

                    select={this.selectItem}
                    playVideoAt={this.playVideoAt}
                    playVideo={this.playVideo}
                    pauseVideo={this.pauseVideo}
                    showPlaylistModal={this.showPlaylistModal}
                  />
                )
              })
            }
          </ul>
        )
      })

      return (
        <SortableList
          songs={songs}

          shouldCancelStart={this.shouldCancelStart}
          onSortEnd={this.onSortEnd}
          lockToContainerEdges
          useWindowAsScrollContainer={true}
          transitionDjuration={150}
          pressDelay={100}
          lockAxis='y'
          hideSortableGhost
          helperClass='dragging-track-helper'
        />
      )
    } else {
      let items = []

      for (let i in this.props.songs) {
        const song = this.props.songs[i]
        const index = parseInt(i)

        items.push(
          <SetlistItem
            key={`set-list-item-${i}-${song.video.youtube_id}`}

            isPublic={isPublic}
            openLoginModal={this.openLoginModal}

            song={song}
            listIndex={index}
            
            favoriteSong={favoriteSong}
            unfavoriteSong={unfavoriteSong}
            removeVideo={removeVideo}
            fetchSong={fetchSong}
            ordered={ordered}
            keyMode={keyMode}

            showError={showError}

            playerVideoId={player.videoId}
            playerIndex={player.currentIndex}
            playerState={player.playerState}

            renderCreated={renderCreated}
            
            id={song.video.youtube_id}
            uuid={song.video.uuid}
            songId={song.id}
            name={song.video.name || song.artist + " - " + song.name}
            duration={song.video.duration}
            created={song.created_at}
            isFavorite={song.is_favorite}
            
            isMobile={this.state.isMobile}
            selectIndex={this.state.selected}

            select={this.selectItem}
            playVideoAt={this.playVideoAt}
            playVideo={this.playVideo}
            pauseVideo={this.pauseVideo}
            showPlaylistModal={this.showPlaylistModal}
          />
        )
      }

      return (
        <ul className='setlist'>
          { items }
        </ul>
      )
    }
  }

  renderListBody () {
    const { songs }  = this.props;
    if(this.props.isInitialFetching) {
      return this.renderSkeletonItems()
    } else if (this.props.songs.length > 0) {
      return this.renderSetlist()
    } else {
      return this.props.children
    }
  }

  renderSetlist() {
    if(this.props.isCompact)  {
      return this.renderCompactListItems()
    } else {
      return this.renderListItems()
    }
  }

  renderHeader() {
    return (
      <div className='row heading'>
        <div className='col-md-10 col-sm-10 col-xs-8'>
          <h3>{this.props.title || 'Tracklist'}</h3>
        </div>

        { this.renderToolBar() }
      </div>
    )
  }

  renderCoverArt() {
    const { playlist } = this.props;

    let imgUrl = null;
    if(!playlist || !playlist.youtube_id) {
      imgUrl = emptyPlaylist;
    } else {
      imgUrl = formatYoutubeUrl(playlist.youtube_id);
    } 

    return (
      <ClickHandler url={buildResourceUrl('playlists', playlist.name, playlist.id)} >
        <img src={imgUrl} className='playlist-art' />
      </ClickHandler>
    )
  }

  renderPlaylistItems() {
    const {
      songs,
      favoriteSong,
      unfavoriteSong,
      removeVideo,
      fetchSong,
      ordered,
      player,
      renderCreated,
      showError,

      playlist,
      updatePlaylist,
      removeSongFromPlaylist,
      isPublic,
      currentUser,
    } = this.props;

    if(!playlist || !playlist.playlistSongs) return;

    let keyMode = null;

    if(isPublic) {
      keyMode = 'harmonic'
    } else {
      keyMode =  currentUser.ui.keyMode
    }

    let results = [];
    for(var i = 0; i < songs.length; i++) {
      const song = this.props.songs[i]
      const index = parseInt(i)

      results.push(
        <CompactPlaylistItem
          song={song}
          key={`set-list-item-${i}-${song.video.youtube_id}`}
          listIndex={index}
          openLoginModal={this.openLoginModal}
          
          favoriteSong={favoriteSong}
          unfavoriteSong={unfavoriteSong}
          removeVideo={removeVideo}
          fetchSong={fetchSong}
          ordered={ordered}
          keyMode={keyMode}

          showError={showError}

          playerVideoId={player.videoId}
          playerIndex={player.currentIndex}
          playerState={player.playerState}

          renderCreated={renderCreated}
          
          id={song.video.youtube_id}
          uuid={song.video.uuid}
          songId={song.id}
          name={song.video.name || song.artist + " - " + song.name}
          duration={song.video.duration}
          created={song.created_at}
          isFavorite={song.is_favorite}
          
          isMobile={this.state.isMobile}
          selectIndex={this.state.selected}

          select={this.selectItem}
          playVideoAt={this.playVideoAt}
          playVideo={this.playVideo}
          pauseVideo={this.pauseVideo}
          showPlaylistModal={this.showPlaylistModal}
        />
      )
    } 
     
    return (
      <ul className='compact-list'>
        { results }
      </ul>
    ) 
  }

  render () {
    if(this.props.compactPlaylist) {
      return (
        <div className='compact-playlist m6k-panel'>
          { this.state.renderLoginModal ? this.renderLoginModal() : null }

          { this.renderPlaylistModal() }

          <div className='artwork-area'>
            { this.renderCoverArt() }
          </div>
          <div className='list-area'>
            { this.renderPlaylistItems() }
          </div>
        </div>
      )
    } else {
      return (
        <div className='setlist-container m6k-panel' id='setlist-container'>
          { this.state.renderLoginModal ? this.renderLoginModal() : null }

          { this.renderPlaylistModal() }

          { this.props.renderHeader === false ? null : this.renderHeader() }

          { this.renderListBody() }
          { this.props.endListItem ? this.props.endListItem() : null }
        </div>
      )
    }
  }
}

export default connect(
  mapStateToProps,
  {
    setIndex,
    setVideoId,
    setPlayerSongId,
    setPlayerArtistId,
    setPlayerState,
    favoriteSong,
    unfavoriteSong,
    setUIField,
    removeVideo,
    fetchSong,
    setPlaylistModalTab,
    setSongIdToAdd,
    trackPlay,
  }
)(Setlist)

import slugify from 'slugify'
import unescape from 'lodash/unescape'

export function titleCase (text) {
  return text.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() })
}

export function trimTitle (text) {
  if (text === null || text === undefined) return ''

  return text.replace(/\[[^)]*\]/g, '').replace('▶', '')
}

export const buildResourceUrl = (resource, name, id) => {
  let uriFragment = name

  // Remove anything in brackets - eg: [spammy text]
  uriFragment = trimTitle(uriFragment)
  uriFragment = unescape(uriFragment)

  // Format to url
  uriFragment = slugify(uriFragment, {
    replacement: '-',
    remove: /[/$*_$+~.,()'"!\-–—:@#?]/g,
    lower: true
  })

  return `/${resource}/${uriFragment}/${id}`
}

export function createMarkup(text) {
  return {
    __html: text
  }
}

// Memoize to keep results across pages.
export const markGenerator = (function() {
  var marks = {};

  return function() {
    const maxYear = new Date().getFullYear();
    const minYear = 1980
    const ticks = 7;

    const range = maxYear - minYear
    const steps = Math.ceil(range / ticks)
    

    for(var i = maxYear; i >= minYear; i -= steps) {
      var key = i.toString();
      marks[key] = key
    }

    return marks;
  }
})();

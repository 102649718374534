import React from 'react';
import Modal from 'react-modal'
import Switch from 'react-toggle-switch'

import 'react-toggle-switch/dist/css/switch.min.css'

export default class CreatePlaylistModal extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this)

    this.state = {
      name: "",
      description: "",
      is_private: false,
    }
  }

  submit(e) {
    e.preventDefault();

    if(this.state.name === null || this.state.name === '') {
      return false;
    } 

    this.props.createPlaylist({
      playlist: {
        ...this.state
      }
    })
    .then(() => {
      this.setState({name: '', description: '', is_private: false})
      this.props.onCancel();
    })
    .catch(() => {
    })
  }

  render() {
    return (
      <Modal
        isOpen={this.props.show}
        onRequestClose={this.props.onCancel}
        contentLabel='Confirm Modal'
        className='create-playlist-modal m6k-panel'
      >
        <div className='modal-header'>
          <div className='row'>
            <div className='col-md-8'>
              <h3>Create Playlist</h3>
            </div>
            <div className='col-md-4'>
              <span className='glyphicon glyphicon-remove' onClick={this.props.onCancel} />
            </div>
          </div>
        </div>

        <div className='modal-body'>
          <form className='create-playlist-form' onSubmit={this.submit}>
            <div className="form-group">
              <label htmlFor="playlist-name">Name</label>
              <input 
                type="text" 
                className="form-control" 
                id="playlist-name" 
                placeholder="Miami Music Weekend"
                value={this.state.name}
                onChange={(e) => {
                  this.setState({
                    name: e.target.value 
                  })
                }}
              />
            </div>

            <div className='form-group'>
              <label htmlFor="playlist-description">Description</label>
              <textarea 
                type="text" 
                className="form-control" 
                id="playlist-description" 
                placeholder="A creative Description"
                value={this.state.description}
                onChange={(e) => {
                  this.setState({
                    description: e.target.value
                  })
                }}
              />
            </div>

            <div className='form-group'>
              <label>Privacy</label>
              <div className='switch-toggle-container'>
                <label>Private</label>
                <Switch 
                  onClick={() => {
                    this.setState(prevState => {
                      return {
                        is_private: prevState.is_private ? false : true 
                      } 
                    });
                  }} 
                  on={!this.state.is_private}
                  className='switch-button'
                />
                <label>Public</label>
              </div>

              <p>When your playlist is marked private, only you and the people with permisisons can view this playlist.</p>
            </div>

            <div className='row'>
              <div className='col-md-12'>
              <div className='btn-group'>
                <button className='btn btn-default' onClick={this.props.onCancel}>
                  Cancel
                </button>
                <button className='btn btn-primary'>
                  Save
                </button>
              </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

import React, { Component } from 'react'
import { isBrowser } from "react-device-detect";

import ClickHandler from 'components/click-handler'
import './footer.scss'

class Footer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      currentYear: new Date().getFullYear()
    }
  }

  render () {
    const copyrightText = `\u00A9 ${this.state.currentYear} `

    return (
      <footer>
        <nav className='nav-bar'>
          <div className='container-fluid'>
            <div className='navbar-header'>
              <div className='navbar-brand'>
                {copyrightText}
                <span className='title'>Mu6ik Inc.</span>
              </div>
            </div>

            <ul className="nav navbar-nav">
              <li>
                <ClickHandler url='/terms'>
                  Terms of Service
                </ClickHandler>
              </li>
              <li>
                <ClickHandler url='/privacy-policy'>
                  Privacy Policy
                </ClickHandler>
              </li>
              <li>
                <ClickHandler url='/help'>
                  Support Center
                </ClickHandler>
              </li>
            </ul>

          </div>
        </nav>
      </footer>
    )
  }
}

export default Footer

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import FriendButton from 'app/shared/friend-button'
import ImgTile from 'app/components/img-tile'
import Loading from 'react-loading'

import { getUrlFromPhoto } from 'lib/user';
import queryString from 'query-string'

import { connect } from 'react-redux';

import {
  fetchFriends,
  sendFriendRequest,
  acceptFriendRequest,
  cancelFriendRequest,
  unfriend,
  selectFriends,
} from 'app/modules/friends'

import {
  fetchFriendRequests,
  selectFriendRequests,
} from 'app/modules/friend-requests'

import { selectProfile } from 'app/modules/profile'

import '../styles/friends.scss'

class Friends extends React.Component {
  constructor(props) {
    super(props);

    this.renderFriends = this.renderFriends.bind(this)
    this.renderUserNav = this.renderUserNav.bind(this)
    this.renderFriends = this.renderFriends.bind(this)
    this.renderSent    = this.renderSent.bind(this)
    this.renderPanel   = this.renderPanel.bind(this)

    this.setMode = this.setMode.bind(this)

    this.state = {
      mode: 'friends',
    }
  }

  componentDidMount() {
    this.props.fetchFriends(this.props.profile.uuid)

    if(this.props.profile.uuid === this.props.currentUserId) {
      this.props.fetchFriendRequests()
    }

    const route = this.props.location.pathname
    if(route && route.includes("requests")) {
      const tokens = this.props.location.pathname.split("/");
      const uuid = tokens[tokens.length - 1];
      const uuidV4Regex = /^[A-F\d]{8}-[A-F\d]{4}-4[A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}$/i;

      if(uuidV4Regex.test(uuid)) {
        this.props.acceptFriendRequest(uuid)
      }
      
    }
  }

  setMode(mode) {
    if(mode === 'friends') {
      this.props.fetchFriends(this.props.profile.uuid)
    } else if (mode === 'sent') {
      // 
    } else {
      // 
    }

    this.setState({
      mode
    })
  }

  isActive(mode) {
    return this.state.mode === mode ?
      'active' : ''
  }

  renderUserNav() {
    return (
      <div className=''>
        <div className='btn-group'>
          <button 
            type="button" 
            className={`btn user-option-btn btn-default ${this.isActive('friends')}`}
            onClick={() => this.setMode('friends')}
          >Friends</button>
          <button 
            type="button" 
            className={`btn user-option-btn btn-default ${this.isActive('sent')}`}
            onClick={() => this.setMode('sent')}
          >Requests Sent</button>
          <button 
            type="button" 
            className={`btn user-option-btn btn-default ${this.isActive('received')}`}
            onClick={() => this.setMode('received')}
          >Requests Received</button>
        </div>
      </div>
    )
  }

  renderFriends() {
    const friends = this.props.friends.map((user, i) => {
      const urlMedium = getUrlFromPhoto(user.profile_photo, 320)

      return (
        <ImgTile
          name={user.display_name}
          key={user.uuid}
          width={4}
          widthSmall={2}
          widthXSmall={2}
          clickUrl={`/${user.username}`}
          imgUrl={urlMedium}
        >
          {
            this.props.currentUserId === user.uuid ?
              null :
              <FriendButton
                user={user}
                sendFriendRequest={this.props.sendFriendRequest}
                acceptFriendRequest={this.props.acceptFriendRequest}
                cancelFriendRequest={this.props.cancelFriendRequest}
                unfriend={this.props.unfriend}
              />
          }
        </ImgTile>
      )
    })

    return friends;
  }

  renderReceived() {
    const incoming = this.props.requests.incoming.map((user, i) => {
      const urlMedium = getUrlFromPhoto(user.profile_photo, 320)

      return (
        <ImgTile
          name={user.display_name}
          key={user.uuid}
          width={4}
          widthSmall={2}
          widthXSmall={2}
          clickUrl={`/${user.username}`}
          imgUrl={urlMedium}
        >
          {
            this.props.currentUserId === user.uuid ?
              null :
              <FriendButton
                user={user}
                sendFriendRequest={this.props.sendFriendRequest}
                acceptFriendRequest={this.props.acceptFriendRequest}
                cancelFriendRequest={this.props.cancelFriendRequest}
                unfriend={this.props.unfriend}
              />
          }
        </ImgTile>
      )
    })

    return incoming;
  }

  renderSent() {
    const outgoing = this.props.requests.outgoing.map((user, i) => {
      const urlMedium = getUrlFromPhoto(user.profile_photo, 320)
      return (
        <ImgTile
          name={user.display_name}
          key={user.uuid}
          width={4}
          widthSmall={2}
          widthXSmall={2}
          clickUrl={`/${user.username}`}
          imgUrl={urlMedium}
        >
          {
            this.props.currentUserId === user.uuid ?
              null :
              <FriendButton
                user={user}
                sendFriendRequest={this.props.sendFriendRequest}
                acceptFriendRequest={this.props.acceptFriendRequest}
                cancelFriendRequest={this.props.cancelFriendRequest}
                unfriend={this.props.unfriend}
              />
          }
        </ImgTile>
      )
    })

    return outgoing;
  }

  renderPanel() {
    switch(this.state.mode) {
      case 'friends':
        return this.renderFriends();
      case 'received':
        return this.renderReceived();
      case 'sent':
        return this.renderSent();
      default:
        return this.renderFriends()
    }
  }

  render() {
    return (
      <div className='friends m6k-panel'>
        { this.props.profile.uuid === this.props.currentUserId ? this.renderUserNav() : null }
        <div className='row'>
          { 
            this.props.isFetching ? 
            (
              <div className='img-loading'>
                <Loading
                  type='spin'
                  color='#aaa'
                  delay={0}
                  height={45}
                  width={45}
                />
              </div>
            ) : this.renderPanel()
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetching: state.friends.isFetching,
    requests: selectFriendRequests(state),
    friends: selectFriends(state), 
    profile: selectProfile(state),
    currentUserId: state.currentUser.account.uuid,
    location: state.router.location
  }
}

export default connect(mapStateToProps, {
  fetchFriends,
  sendFriendRequest,
  acceptFriendRequest,
  cancelFriendRequest,
  unfriend,
  fetchFriendRequests,
})(Friends);
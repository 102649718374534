import React from 'react'
import MarketPlacePopover from './market-place-popover'
import M6kPopoverWrapper from 'app/components/m6k-popover-wrapper'

class MarketPlace extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <div className='marketplace'>
        <M6kPopoverWrapper>
          <span className='glyphicon glyphicon-shopping-cart' />

          <MarketPlacePopover
            beatportTracks={this.props.marketplace.beatport_tracks}
          />
        </M6kPopoverWrapper>
      </div>
    )
  }
}

MarketPlace.defaultProps = {
  marketplace: {}
}

export default MarketPlace

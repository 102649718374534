import axios from 'lib/axios-config'
import { extractTokenFromHeaders } from 'lib/authentication'
import jwtDecode from 'jwt-decode'
import { replace } from 'connected-react-router'


export function clearAuthError () {
  return {
    type: 'CLEAR_AUTH_ERROR'
  }
}

export function loginAuthRequest() {
  return {
    type: "LOGIN_AUTH_REQUEST"
  }
}

export function loginAuthSuccess (token, uuid) {
  return {
    type: 'LOGIN_AUTH_SUCCESS',
    token,
    uuid
  }
}

export function loginAuthFailure (error) {
  return {
    type: 'LOGIN_AUTH_FAILURE',
    error
  }
}

export function logoutAuthRequest () {
  return {
    type: 'LOGOUT_AUTH_REQUEST'
  }
}

export function logoutAuthSuccess () {
  localStorage.removeItem('token')
  window.location = '/login'
  return {
    type: 'LOGOUT_AUTH_SUCCESS'
  }
}

export function logoutAuthFailure () {
  return {
    type: 'LOGOUT_AUTH_FAILURE'
  }
}

export function verifyAuthToken() {
  return (dispatch, getState) => {
    const auth = getState().auth;

    return new Promise((resolve, reject) => {
      try {
        const token = localStorage.getItem('token')
        const uuid = jwtDecode(token).uuid

        dispatch(loginAuthSuccess(token, uuid))
        resolve()
      } catch (error) {
        localStorage.removeItem('token')

        reject(error)
      }
    })
  }
}

export function logoutAuth () {
  return (dispatch, getState) => {
    const auth = getState().auth
    const isLoggingOut = auth.isLoggingOut
    const url = '/api/logout'

    if (isLoggingOut) {
      return Promise.reject()
    }

    dispatch(logoutAuthRequest())

    return axios.delete(url)
      .then((response) => {
        dispatch(logoutAuthSuccess())
      }).catch((error) => {
        dispatch(logoutAuthFailure())
      })
  }
}

export function loginAuth (email, password) {
  return (dispatch, getState) => {
    const auth = getState().auth
    const isAuthenticating = auth.isAuthenticating
    const url = '/api/login'

    if (isAuthenticating) {
      return Promise.reject()
    }

    dispatch(loginAuthRequest())

    return axios.post(url, {
      user: {
        email: email,
        password: password
      }
    }).then((response) => {
      try {
        const token = extractTokenFromHeaders(response)
        const uuid = jwtDecode(token).uuid

        localStorage.setItem('token', token)

        dispatch(loginAuthSuccess(token, uuid))
      } catch (error) {
        dispatch(loginAuthFailure('Something went wrong.'))
      }
    }).catch((error) => {
      let message = ''

      if (error.response.status === 401) {
        message = error.response.data.error
      } else {
        message = 'Something went wrong.'
      }

      dispatch(loginAuthFailure({
        message: message,
        status: error.response.status
      }))
    })
  }
}

const defaultState = {
  authenticated: false,
  token: null,
  isAuthenticating: false,
  isVerifying: false,
  isLoggingOut: false,
  error: null,
  message: null,
  uuid: null
}

const auth = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOGIN_AUTH_REQUEST':
      return {
        ...state,
        isAuthenticating: true,
        error: null
      }
    case 'LOGIN_AUTH_SUCCESS':
      return {
        ...state,
        isAuthenticating: false,
        authenticated: true,
        token: action.token,
        uuid: action.uuid
      }
    case 'LOGIN_AUTH_FAILURE':
      return {
        ...state,
        isAuthenticating: false,
        authenticated: false,
        error: action.error
      }
    case 'LOGOUT_AUTH_REQUEST':
      return {
        ...state,
        isLoggingOut: true,
        error: null
      }
    case 'LOGOUT_AUTH_SUCCESS':
      return {
        ...state,
        authenticated: false,
        isLoggingOut: false,
        token: null,
        uuid: null
      }
    case 'LOGOUT_AUTH_FAILURE':
      return {
        ...state,
        isLoggingOut: false,
        error: action.error
      }
    case 'CLEAR_AUTH_ERROR':
      return {
        ...state,
        error: null
      }
    default:
      return state
  }
}

export default auth
export default [{
  name: 'Account',
  url: '/help/categories/account',
  articles: [{
    name: 'How do I create a Mu6ik account?',
    url: '/help/articles/How-do-I-create-a-Mu6ik-account'
  }, {
    name: 'How do I make changes to my account?',
    url: '/help/articles/How-do-I-make-changes-to-my-account'
  }, {
    name: 'How do I edit my Mu6ik profile?',
    url: '/help/articles/How-do-edit-my-Mu6ik-profile'
  }, {
    name: 'How do I edit my profile picture?',
    url: '/help/articles/How-do-I-edit-my-profile-photo'
  }, {
    name: 'How do I reset my password?',
    url: '/help/articles/How-do-I-reset-my-password'
  }, {
    name: 'How do I change my password?',
    url: '/help/articles/How-do-I-change-my-password'
  }, {
    name: 'How do I delete my account?',
    url: '/help/articles/How-do-I-delete-my-account'
  }, {
    name: 'How do I change my email notifications?',
    url: '/help/articles/How-do-I-change-my-email-notifications'
  }]
}, {
  name: 'DJs',
  url: '/help/categories/djs',
  articles: [{
    name: 'What is a DJ profile?',
    url: '/help/articles/What-is-a-DJ-profile'
  }, {
    name: 'What’s the difference between a DJ set and a User’s playlist?',
    url: '/help/articles/Whats-the-difference-between-a-DJ-set-and-a-Users-playlist'
  }, {
    name: 'How do I become a DJ on Mu6ik?',
    url: '/help/articles/How-do-I-become-a-DJ-on-Mu6ik'
  }, {
    name: 'What does following a DJ do?',
    url: '/help/articles/What-does-following-a-DJ-do'
  }, {
    name: ''
  }]
}, {
  name: 'Songs',
  url: '/help/categories/songs',
  articles: [{
    name: 'How do I view song details?',
    url: '/help/articles/How-do-I-view-song-details'
  }, {
    name: 'Why do some songs have multiple genres?',
    url: '/help/articles/Why-do-some-songs-have-multiple-genres'
  }, {
    name: 'Why are some of the songs / videos not music at all?',
    url: '/help/articles/Why-are-some-of-the-songs-or-videos-not-music-at-all'
  }, {
    name: 'How can I view song keys by the Camelot system?',
    url: '/help/articles/How-can-I-view-song-keys-by-the-Camelot-system'
  }]
}, {
  name: 'Song Feed',
  url: '/help/categories/song-feed',
  articles: [{
    name: 'What is the song feed?',
    url: '/help/articles/What-is-the-song-feed'
  }, {
    name: 'Why are there no songs in my feed?',
    url: '/help/articles/Why-are-there-no-songs-in-my-feed'
  }, {
    name: 'How do the song feed filters work?',
    url: '/help/articles/How-do-the-song-feed-filters-work'
  }, {
    name: 'How often is my song feed updated?',
    url: '/help/articles/How-often-is-my-song-feed-updated'
  }]
}, {
  name: 'Search',
  url: '/help/categories/search',
  articles: [{
    name: 'What can you search for?',
    url: '/help/articles/What-can-you-search-for'
  }, {
    name: 'How are DJs shown without a search query',
    url: '/help/articles/How-are-DJs-shown-without-a-search-query'
  }, {
    name: 'How does auto suggest work?',
    url: '/help/articles/How-does-auto-suggest-work'
  }]
}, {
  name: 'Playlists',
  url: '/help/categories/playlists',
  articles: [{
    name: 'How do I create a playlist?',
    url: '/help/articles/How-do-I-create-a-playlist'
  }, {
    name: 'How do I edit a playlist?',
    url: '/help/articles/How-do-I-edit-a-playlist'
  }, {
    name: 'How can I add my friends to a playlist?',
    url: '/help/articles/How-can-I-add-my-friends-to-a-playlist'
  }, {
    name: 'How do I delete a playlist?',
    url: '/help/articles/How-do-I-delete-a-playlist'
  }]
}, {
  name: 'Music Player',
  url: '/help/categories/music-player',
  articles: [{
    name: 'How to use the music player controls',
    url: '/help/articles/How-to-use-the-music-player-controls'
  }, {
    name: 'How to use quick keys for the music player',
    url: '/help/articles/How-to-use-quick-keys-for-the-music-players'
  }, {
    name: 'Where is the sound coming from?',
    url: '/help/articles/Where-is-the-sound-coming-from'
  }]
}, {
  name: 'General',
  url: '/help/categories/general',
  articles: [{
    name: 'What is Mu6ik?',
    url: '/help/articles/What-is-Mu6ik'
  }]
}]
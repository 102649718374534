import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import Loading from 'react-loading'
import queryString from 'query-string'

import { Link } from 'react-router-dom'

import MiniFooter from 'components/mini-footer'

import {
  verifySubscription,
  unsubscribe
} from 'modules/email-notifications'

import '../unsubscribe.scss'

class Unsubscribe extends React.Component {
  constructor (props) {
    super(props)

    this.renderForm = this.renderForm.bind(this)
    this.renderLoading = this.renderLoading.bind(this)
    this.renderSuccess = this.renderSuccess.bind(this)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    const params = queryString.parse(this.props.location.search)
    this.sid = params.sid
    this.props.verifySubscription(this.sid)
  }

  renderForm () {
    const subscriptions = this.props.subscriptions.map((s, i) =>
      <div className='subscription' key={i}>
        <input type='checkbox'
          checked
          onChange={e => e.preventDefault()} />
        <span className='subscription-label'>
          {s.email_campaign.name}
        </span>
      </div>
    )

    return (
      <div>
        <h3>Update subscription settings for</h3>
        <form className='form-horizontal' onSubmit={(e) => {
          e.preventDefault()
          this.handleSubmit()
        }}>
          <div className='subscriptions'>
            {subscriptions}
          </div>

          <button type='text' className='btn btn-default unsubscribe'>
            Unsubscribe
          </button>
        </form>

        <h6 className='info'>
          <a href='/login'>Login</a> to manage all of your email notifications.
        </h6>
      </div>
    )
  }

  renderSuccess () {
    const successLabels = this.props.subscriptions.map((s, i) =>
      <li key={i}>{s.email_campaign.name}</li>
    )

    return (
      <div className='success-notice'>
        <h3>You have succesfully unsubscribed from</h3>
        <ul>
          {successLabels}
        </ul>

        <h6 className='info'>
          <Link to='login'>Login</Link> to manage all of your email notifications.
        </h6>
      </div>
    )
  }

  handleSubmit () {
    this.props.unsubscribe(this.sid)
  }

  renderLoading () {
    return (
      <div className='loading'>
        <Loading
          type='spin'
          color='#aaa'
          delay={0}
          height={65}
          width={65}
        />
      </div>
    )
  }

  render () {
    return (
      <div className='unsubscribe-page'>
        <Helmet>
          <title>Mu6ik / Unsubscribe</title>
        </Helmet>

        <div className='unsubscribe-panel'>
          <h1>Mu6ik</h1>
          {
            this.props.isUpdating
              ? this.renderLoading()
              : this.props.didUnsubscribe
                ? this.renderSuccess()
                : this.renderForm()
          }
        </div>

        <MiniFooter />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    subscriptions: state.emailNotifications.subscriptions,
    isUpdating: state.emailNotifications.isUpdating,
    didUnsubscribe: state.emailNotifications.didUnsubscribe
  }
}

export default connect(mapStateToProps, {
  verifySubscription,
  unsubscribe
})(Unsubscribe)

import React from 'react';
import ClickHandler from 'components/click-handler'

export default class HelpNav extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <nav className="navbar navbar-default">
        <div className='container'>
          <div className='navbar-header'>
            <ClickHandler url="/" >
              <h1>MU6IK</h1>
            </ClickHandler>
            <h3>Help Center</h3>
          </div>

          <ul className="nav navbar-nav navbar-right">
            <li>
              <ClickHandler url='/help/requests/new' className='sign-in'>
                Submit a request
              </ClickHandler>
            </li>
         </ul>

        </div>
      </nav>
    );
  }
}

import axios from 'lib/axios-config'

import { user, users } from 'app/schemas/main'
import { replace, push } from 'connected-react-router'

import { normalize } from 'normalizr'
import { concatName } from 'lib/user'

import { selectCurrentUser } from 'app/modules/current-user/account'

export function fetchBlockedUsers() {
  return (dispatch, getState) => {
    const url = `/api/blocked_friends`
    const state = getState()

    if(state.blockedUsers.isFetching) {
      return Promise.reject()
    }

    dispatch({ type: "FETCH_BLOCKED_USERS" })

    return axios.get(url)
      .then((response) => {
        const normalized = normalize(response.data, users)
        dispatch({
          type: "FETCH_BLOCKED_USERS_SUCCESS",
          payload: {
            entities: normalized.entities, 
            ids: normalized.result,
          }
        })

        return Promise.resolve()
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: "FETCH_BLOCKED_USERS_FAILURE",
          payload: {
            error: error.response.data.error
          }
        })

        return Promise.reject()
      }) 
  }
}

export function selectBlockedUsers(state) {
  const users = state.entities.users
  const ids = state.blockedUsers.ids;

  return ids.map((id) => {
    const user = users[id];

    return {
      ...user,
      name: concatName(user.first_name, user.last_name, user.username)
    }
  })
}

export function blockUser(friendUUID) {
  return (dispatch, getState) => {
    const state = getState()
    const url = `/api/blocked_friends`
  
    if(state.blockedUsers.isUpdating) {
      return Promise.reject()
    }

    dispatch({
      type: 'BLOCK_USER_REQUEST'
    })

    return axios.post(url, {
      friend_id: friendUUID,
    })
      .then((response) => {
        dispatch({
          type: 'BLOCK_USER_REQUEST_SUCCESS',
          payload: {
            friendUUID,
          }
        })

        const currentUser = selectCurrentUser(getState())

        dispatch(push({
          pathname: `/${currentUser.username}` 
        }))
      })
      .catch((error) => {
        dispatch({
          type: 'BLOCK_USER_REQUEST_FAILURE',
          payload: {
            error: error.response.data.error
          }
        })
      })
  }
}

export function unblockUser(friendUUID, index) {
  return (dispatch, getState) => {
    const state = getState()
    const url = `/api/blocked_friends/${friendUUID}`
  
    if(state.blockedUsers.isUpdating) {
      return Promise.reject()
    }

    dispatch({
      type: 'UNBLOCK_USER_REQUEST'
    })

    return axios.delete(url)
      .then((response) => {
        dispatch({
          type: 'UNBLOCK_USER_REQUEST_SUCCESS',
          payload: {
            friendUUID,
            index,
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: 'UNBLOCK_USER_REQUEST_FAILURE',
          payload: {
            error: error.response.data.error
          }
        })
      })
  }
}

export function reportUser(friendUUID, flagType) {
  return (dispatch, getState) => {
    const state = getState()
    const url = `/api/reported_users`
  
    if(state.blockedUsers.isUpdating) {
      return Promise.reject()
    }

    dispatch({
      type: 'REPORT_USER_REQUEST'
    })

    return axios.post(url, {
      user_id: friendUUID,
      flag_type: flagType,
    })
      .then((response) => {
        dispatch({
          type: 'REPORT_USER_REQUEST_SUCCESS'
        })
      })
      .catch((error) => {
        dispatch({
          type: 'REPORT_USER_REQUEST_FAILURE',
          payload: {
            error: error.response.data.error
          }
        })
      })
  }
}

const defaultState = {
  isFetching: false,
  isUpdating: false,
  ids: [],
  error: null,
}

const blockedUsers = (state = defaultState, action) => {
  switch(action.type) {
    case 'FETCH_BLOCKED_USERS':
      return {
        ...state,
        isFetching: true,
        ids: []
      }
    case 'FETCH_BLOCKED_USERS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        ids: action.payload.ids
      }
    case 'FETCH_BLOCKED_USERS_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.payload.error, 
      }
    case 'BLOCK_USER_REQUEST':
      return {
        ...state,
        isUpdating: true
      }
    case 'BLOCK_USER_REQUEST_SUCCESS':
      return {
        ...state,
        isUpdating: false
      }
    case 'BLOCK_USER_REQUEST_FAILURE':
      return {
        ...state,
        isUpdating: false,
        error: action.payload.error
      }
    case 'REPORT_USER_REQUEST':
      return {
        ...state,
        isUpdating: true
      }
    case 'REPORT_USER_REQUEST_SUCCESS':
      return {
        ...state,
        isUpdating: false
      }
    case 'REPORT_USER_REQUEST_FAILURE':
      return {
        ...state,
        isUpdating: false,
        error: action.payload.error
      }
    case 'UNBLOCK_USER_REQUEST':
      return {
        ...state,
        isUpdating: true
      }
    case 'UNBLOCK_USER_REQUEST_SUCCESS':
      return {
        ...state,
        isUpdating: false,
        ids: [
          ...state.ids.slice(0, action.payload.index),
          ...state.ids.slice(action.payload.index + 1)
        ],
      }
    case 'UNBLOCK_USER_REQUEST_FAILURE':
      return {
        ...state,
        isUpdating: false
      }
    default:
      return state;
  }
}

export default blockedUsers;
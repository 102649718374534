import merge from 'lodash/merge';
import transform from 'lodash/transform';
import filter from 'lodash/filter'

const defaultState = {
  favorites: {},
  artists: {},
  genres: {},
  cities: {},
  countries: {},
  songs: {},
  users: {},
  friendRequests: {},
  playlists: {},
  userPlaylistPermissions: {},
  keys: {},
  feedTemplates: {},
}

const entities = (state = defaultState, action) => {
  switch(action.type) {
    case "TOGGLE_IS_FOLLOWED":
      var id = action.payload.id;

      return {
        ...state,
        artists: {
          ...state.artists,
          [id]: {
            ...state.artists[id],
            is_followed: action.payload.is_followed
          }
        }
      }
    case "CLEAR_ALL_FILTER_OPTIONS":
      return {
        ...state,
        [action.payload.key]: transform(state[action.payload.key], (result, value, key) => {
          result[key] = {
            ...value,
            isFilter: false
          } 
          return result;
        })
      }
    case 'TOGGLE_FILTER_OPTION':
      var id = action.payload.id
      var key = action.payload.key
      
      return {
        ...state,
        [key]: {
          ...state[key],
          [id]: {
            ...state[key][id],
            isFilter: action.payload.isFilter
          }
        }
      }
    case "REQUEST_REMOVE_FAVORITE_SONG_SUCCESS":
      var id = action.payload.song_id;
      return {
        ...state,
        songs: {
          ...state.songs,
          [id]: {
            ...state.songs[id],
            is_favorite: false
          }
        }
      }
    case "FETCH_ARTIST_GENRE_PROFILE_SUCCESS":
      var id = action.payload.id

      return {
        ...state,
        artists: {
          ...state.artists,
          [id]: {
            ...state.artists[id],
            genres: action.payload.genres
          }
        }
      }
    case "ADD_USER_GENRES_SUCCESS":
      const { genres, uuid } = action.payload;

      return {
        ...state,
        users: {
          ...state.users,
          [uuid]: {
            ...state.users[uuid],
            genres: genres,
          }
        }
      }
    case 'SEND_FRIEND_REQUEST_SUCCESS':
      var { friendUUID } = action.payload;

      return {
        ...state,
        users: {
          ...state.users,
          [friendUUID]: {
            ...state.users[friendUUID],
            did_send_friend_request: true
          }
        }
      }
    case 'ACCEPT_FRIEND_REQUEST_SUCCESS':
      var { friendUUID } = action.payload;

      return {
        ...state,
        users: {
          ...state.users,
          [friendUUID]: {
            ...state.users[friendUUID],
            did_receive_friend_request: false,
            is_friend: true,
          }
        }
      }
    case 'CANCEL_FRIEND_REQUEST_SUCCESS':
      var { friendUUID } = action.payload

      return {
        ...state,
        users: {
          ...state.users,
          [friendUUID]: {
            ...state.users[friendUUID],
            [action.payload.friendAction]: false,
          }
        }
      }
    case 'UNFRIEND_REQUEST_SUCCESS':
      var { friendUUID } = action.payload;
      
      return {
        ...state,
        users: {
          ...state.users,
          [friendUUID]: {
            ...state.users[friendUUID],
            is_friend: false
          }
        }
      }
    case 'BLOCK_USER_REQUEST_SUCCESS':
      var { friendUUID } = action.payload;

      return {
        ...state,
        users: {
          ...state.users,
          [friendUUID]: {
            ...state.users[friendUUID],
            is_friend: false,
            did_send_friend_request: false,
            did_receive_friend_request: false,
          }
        }
      }
    case 'REMOVE_SONG_FROM_PLAYLIST_SUCCESS':
      var { playlistId, psIndex } = action.payload;
      var playlist = state.playlists[playlistId]; 

      return {
        ...state,
        playlists: {
          ...state.playlists,
          [playlistId]: {
            ...playlist,
            playlistSongs: [
              ...playlist.playlistSongs.slice(0, psIndex),
              ...playlist.playlistSongs.slice(psIndex + 1)
            ]
          }
        }
      }
    case 'UPDATE_PLAYLIST_SONG':
    case 'UPDATE_PLAYLIST_SONG_FAILURE':
      var { playlistId, sourceIndex, destIndex } = action.payload;
      var playlist = state.playlists[playlistId];

      if(sourceIndex === destIndex) return state;

      var playlistSongs = playlist.playlistSongs.slice();
      const ps = playlistSongs[sourceIndex];

      // Remove Song from existing position
      playlistSongs.splice(sourceIndex, 1)

      // Place Song in new position
      playlistSongs.splice(destIndex, 0, ps)

      return {
        ...state,
        playlists: {
          ...state.playlists,
          [playlistId]: {
            ...playlist,
            playlistSongs,
          }
        }
      }
    case 'CREATE_UPP_SUCCESS':
      var { playlistId, result } = action.payload;
      var playlist = state.playlists[playlistId];

      return {
        ...state,
        playlists: {
          ...state.playlists,
          [playlistId]: {
            ...playlist,
            user_playlist_permissions: [...playlist.user_playlist_permissions, result]
          }
        },
        userPlaylistPermissions: {
          ...state.userPlaylistPermissions,
          ...action.payload.entities.userPlaylistPermissions,
        },
        users: {
          ...state.users,
          ...action.payload.entities.users,
        }
      }
    case 'UPDATE_UPP_SUCCESS':
      var { upp } = action.payload;
      var playlist = upp.playlist;

      return {
        ...state,
        userPlaylistPermissions: {
          ...state.userPlaylistPermissions,
          [upp.id]: {
            ...state.userPlaylistPermissions[upp.id],
            permission_level: upp.permission_level,
          }
        } 
      }
    case 'DELETE_UPP_SUCCESS':
      var { upp } = action.payload;
      var playlist = state.playlists[upp.playlist.id];

      const ids = filter(playlist.user_playlist_permissions, (oldUpp) => {
        return oldUpp !== upp.id
      })

      delete state.userPlaylistPermissions[upp.id];

      return {
        ...state,
        userPlaylistPermissions: {
          ...state.userPlaylistPermissions,
        },
        playlists: {
          ...state.playlists,
          [playlist.id]: {
            ...state.playlists[playlist.id],
            user_playlist_permissions: ids,
          }
        }
      }
    default:
      if(action.payload && action.payload.entities) {
        return merge({}, state, action.payload.entities)
      }

      return state;  
  }
}

export default entities;

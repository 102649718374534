import React from 'react';
import Modal from 'react-modal'

export default class ProfileImageModal extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<Modal
					isOpen={this.props.visible}
					contentLabel=''
					shouldCloseOnOverlayClick={true}
					onRequestClose={this.props.closeModal}
					className='profile-img-modal'
				  overlayClassName='profile-img-modal-overlay'
				>
					<img className='profile-img-large' src={this.props.url} />
				</Modal>
			</div>
		);
	}
}

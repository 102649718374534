import React from 'react';
import Modal from 'react-modal'
import NavLink from 'app/components/nav-link'
import debounce from 'lodash/debounce';
import SearchDropdown from './search-dropdown';

import { connect } from 'react-redux';

import queryString from 'query-string'

import './m6k-search.scss'

import { buildArtistUrl } from 'lib/artist-url'

import {
  getNameSuggestions,
  selectNameSuggestions,
  gotoUrl
} from 'app/modules/autocomplete'

class MobileSearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.renderSearchBar = this.renderSearchBar.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.setupSearch = this.setupSearch.bind(this)
    this.clearSearch = this.clearSearch.bind(this)

    this.gotoDJUrl = this.gotoDJUrl.bind(this)
    this.getNameSuggestions = debounce(this.getNameSuggestions.bind(this), 100);

    this.state = {
      isModalOpen: false,
      isFocused: false,
      isInputBlank: true,
    }
  }

  handleKeyPress (e) {
    if (!this.state.isModalOpen) return

    switch (e.key) {
      case 'Escape':
        this.closeModal()
    }
  }

  clearSearch() {
    this.props.setSearchQuery("");
  }

  closeModal () {
    this.setState({isModalOpen: false})
  }

  openModal() {
    if(this.state.isModalOpen) return;
    
    this.setState({isModalOpen: true})

    this.input.focus()
  }

  getNameSuggestions(query) {
    return this.props.getNameSuggestions(query);
  }
  
  isBlank(text) {
    return text === '' ||
      text === null || 
      text === undefined
  }

  handleChange(e) {
    e.preventDefault()

    const value = e.target.value;

    if(this.isBlank(value)) {
      this.setState({isInputBlank: true})
    } else {
      this.setState({isInputBlank: false})
      this.getNameSuggestions(value);
    }
 
    this.props.setSearchQuery(value);
  }

  handleSubmit(e) {
    e.preventDefault()

    this.setState({isModalOpen: false})

    this.props.setIndex(null);
    this.input.blur();
    this.props.updateUrl();
    this.props.submitSearch()
      .then(() => {})
      .catch(() => {})
  }

  renderSearchBar() {
    return (
      <form className="navbar-form navbar-left m6k-search" onSubmit={this.handleSubmit} action=".">
        <div className="form-group">
          <input 
            type="search" 
            aria-label="Search mu6ik.com"
            placeholder='Search for DJs, People, and Songs' 
            value={this.props.q || ""}
            onChange={this.handleChange}
            autoCorrect="off" 
            autoCapitalize="off" 
            autoComplete="off" 
            spellCheck="false"
            autoFocus={this.state.isModalOpen}
            ref={(ref) => this.input = ref}
            onClick={this.openModal}
          />

          <button type="submit">
            <span className='glyphicon glyphicon-search'></span>
          </button>
        </div>
      </form>
    )
  }

  setupSearch(mode) {
    this.props.setSearchMode(mode);

    this.setState({isModalOpen: false})
    
    this.props.updateUrl();
    this.props.submitSearch()
      .then(() => {})
      .catch(() => {})
  }

  gotoDJUrl(url) {
    this.props.gotoUrl(url)
    this.props.setIndex(null);
    this.props.updateUrl();
    this.setState({
      isFocused: false,
      isModalOpen: false,
    })
  }

  render() {
    const search = this.props.location.search;

    if (this.state.isModalOpen) {
      return (
        <Modal
          isOpen={this.state.isModalOpen}
          className='search-modal-fs'
          overlayClassName='full-modal-overlay'
          contentLabel='SearchModal'
          ref={(modal) => this.modal = modal}
          onKeyUp={this.handleKeyPress}
          tabIndex='-1'
        >
          <div className='search-controls-top'>
            <div className='option option-l'>
              <span
                className='option option-l glyphicon glyphicon-remove close-modal'
                onClick={this.closeModal}
               />
            </div>

            <div className='option option-c'>
              <h2>Search</h2>
            </div>

            <div className='option option-r'>
              <h2 onClick={this.clearSearch} >Clear</h2>
            </div>
          </div>

          { this.renderSearchBar() }

          <ul className='search-nav-list'>
            <NavLink 
              onClick={() => { this.setupSearch('djs') }} 
              to={'/search/djs' + search}
            >DJs</NavLink>
            <NavLink 
              onClick={() => { this.setupSearch('people') }} 
              to={'/search/people' + search}
            >People</NavLink>
            <NavLink 
              onClick={() => { this.setupSearch('songs') }} 
              to={'/search/songs' + search}
            >Songs</NavLink>
          </ul>

          <SearchDropdown 
            isFocusedInput={this.state.isFocused}
            isInputBlank={this.state.isInputBlank}
            suggestions={this.props.suggestions}
            cursorHoverIndex={this.state.cursorHoverIndex}
            gotoDJUrl={this.gotoDJUrl}
            isMobile={true}
          />

        </Modal>
      )
    } else {
      return this.renderSearchBar()
    }
  }
}

const mapStateToProps = (state) => {
  return {
    suggestions: selectNameSuggestions(state),
  }
}

export default connect(mapStateToProps, {
  getNameSuggestions,
  gotoUrl
})(
  MobileSearchBar
) 
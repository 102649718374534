import { injectReducer } from 'store/reducers'
import asyncComponent from 'components/async-component'

export default (store) => asyncComponent(() => new Promise((resolve) => {
  require.ensure([], (require) => {
    const Signup = require('./components/signup').default
    const reducer = require('./modules/invite-campaign').default

    injectReducer(store, { key: 'inviteCampaign', reducer })
    resolve(Signup)
  }, 'signup')
}))

import React from 'react';

import ClickHandler from 'components/click-handler'

import { trimTitle } from 'lib/string';
import { translateKey } from 'lib/translate-key'

import unescape from 'lodash/unescape'

export default class SongDetails extends React.Component {
  constructor(props) {
    super(props);

    this.renderControls = this.renderControls.bind(this)
  }

  renderControls() {
    let iconClass = 'glyphicon '

    if (this.props.isPlaying) {
      iconClass += this.props.isHover ? 'glyphicon-pause' : 'glyphicon-volume-up'
    } else if (this.props.isBuffering) {
      iconClass += 'glyphicon-transfer'
    } else {
      iconClass += 'glyphicon-play'
    }

    return (
      <div className='icon-display' onClick={this.props.handleControlAction} >
        <span className={iconClass} />
      </div>
    )
  }

  render() {
    const song = this.props.song;
    const genreItems = song.genres ? song.genres : [];
    const totalGenres = this.props.isMobile ? 2 : 3;

    const genres = genreItems.slice(0, totalGenres).map((g, gIndex) => {
      return (
        <label className='genre-label no-select' key={`song-${this.props.index}-genre-${gIndex}`}>{ g.name }</label>
      )
    })

    const key = this.props.keyMode === 'camelot' ? translateKey(song.key) : (song.key ?  song.key : null)
    const songDetail = `${song.bpm !== null ? song.bpm : ''}${ key !== null ? " " + key : ''}`

    const favoriteIcon = this.props.isFavorite
      ? 'glyphicon-heart'
      : 'glyphicon-heart-empty'

    const title = song.video ? trimTitle(song.video.name) : ''

    return (
      <div className='song-details' onDoubleClick={this.props.handleControlAction}>
        <div className='song-description'>
          <div className='title'>
            {
              this.props.isPublic ?
                <a href="#" className='no-select' onClick={e => e.preventDefault()}>
                  <span>{ unescape(title) }</span>
                </a> :
                <ClickHandler url={this.props.songUrl} className='no-select'>
                  <span>{ unescape(title) }</span>
                </ClickHandler>
            }
          </div>

          {
            this.props.hideGenres ?
              null :  
              <div className='genres'>
                { genres }
              </div>
          }

          { 
            this.props.hideDetails ?
              null :
              <div className='song-bpm-key'>
                <h6 className='no-select'>
                  { songDetail }
                </h6>
              </div>
          }
        </div>

        <div className='player-control' >
          { this.props.isMobile || this.props.isHover || this.props.isActive ? this.renderControls() : null }
        </div>

        <div className='playlist-dropdown' onClick={this.props.handleDropdownMenu}>
          <span className='glyphicon glyphicon-option-horizontal'/>
        </div>
      </div>
    );
  }
}

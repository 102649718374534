import React from 'react';
import Modal from 'react-modal'
import { connect } from 'react-redux'

import {  
  removeUserPlaylistPermission
} from 'app/modules/playlists'

const mapStateToProps = (state) => {
  return  {

  }
}

export class DeleteCollaboratorModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.removeCollaborator = this.removeCollaborator.bind(this)
  }

  removeCollaborator(e)  {
    e.preventDefault();

    this.props.removeUserPlaylistPermission({
      ...this.props.upp,
      playlist: this.props.playlist,
    })
    .then(() => {
      this.props.onCancel()
    })
    .catch(() => {})
  }

  render() {
    const { upp } = this.props;
    const user = upp.user;

    return (
      <Modal
        className='delete-collaborator-modal m6k-panel'
        isOpen={this.props.show}
        contentLabel="Confirm Modal"
        onRequestClose={this.props.onCancel}
      >
        <div className='modal-header'>
          <div className='row'>
            <div className='col-md-8'>
              <h3>Remove Collaborator: {user.first_name + " " + user.last_name }</h3>
            </div>
            <div className='col-md-4'>
              <span className='glyphicon glyphicon-remove' onClick={this.props.onCancel} />
            </div>
          </div>
        </div>

        <div className='modal-body'>
          <div className='row'>
            <div className='col-md-12'>
              <h4>Please confirm you'd like to remove { user.first_name + " " + user.last_name } from this playlist</h4> 
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <div className='btn-group'>

                <button className='btn btn-default' onClick={this.props.onCancel}>
                  Cancel
                </button>

                <button 
                  className='btn btn-danger' 
                  onClick={this.removeCollaborator}
                >
                  Remove
                </button>

              </div>
            </div>
          </div>
        </div> 
      </Modal>
    );
  }
}

export default connect(mapStateToProps, {
  removeUserPlaylistPermission,
})(DeleteCollaboratorModal)
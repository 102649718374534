import React from 'react';
import ReactDOM from 'react-dom'
import SearchDropdown from './search-dropdown';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import { buildArtistUrl } from 'lib/artist-url'

import {
  getNameSuggestions,
  selectNameSuggestions,
  gotoUrl
} from 'app/modules/autocomplete'

import './m6k-search.scss'

class SearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)

    this.gotoDJUrl = this.gotoDJUrl.bind(this)

    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);

    this.moveUp = this.moveUp.bind(this);
    this.moveDown = this.moveDown.bind(this);

    this.getNameSuggestions = debounce(this.getNameSuggestions.bind(this), 100);

    this.state = {
      isFocused: false,
      isInputBlank: true,
      cursorHoverIndex: null,
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  isBlank(text) {
    return text === '' ||
      text === null || 
      text === undefined
  }

  handleChange(e) {
    e.preventDefault()
    const value = e.target.value;

    if(this.isBlank(value)) {
      this.setState({isInputBlank: true})
    } else {
      this.getNameSuggestions(value)
    }
 
    this.props.setSearchQuery(value);
  }

  handleClickOutside (e) {
    const domNode = ReactDOM.findDOMNode(this)
    if (!domNode || !domNode.contains(e.target)) {
      this.setState({
        isFocused: false,
        cursorHoverIndex: null,
      })
    }
  }

  moveUp() {
    const currentIndex = this.state.cursorHoverIndex
    let nextIndex = null;

    if(currentIndex === 0) {
      nextIndex = null; 
    } else if (currentIndex === null) {
      nextIndex = this.props.suggestions.length - 1;
    } else {
      nextIndex = currentIndex - 1;
    }

    this.setState({cursorHoverIndex: nextIndex})
  }

  moveDown() {
    const currentIndex = this.state.cursorHoverIndex
    let nextIndex = null;

    if(currentIndex === null) {
      nextIndex = 0;
    } else if (currentIndex === (this.props.suggestions.length - 1)) {
      nextIndex = null;
    } else {
      nextIndex = currentIndex + 1;
    }

    this.setState({cursorHoverIndex: nextIndex})
  }

  onFocus(e) {
    e.preventDefault();

    this.setState({
      isFocused: true
    })

    if(this.props.q !== null && this.props.q !== '') {
      this.getNameSuggestions(this.props.q)
    }
  }

  getNameSuggestions(query) {
    this.props.getNameSuggestions(query)
      .then(() => {
        this.setState({isInputBlank: false})
      })
      .catch(() => {})
  }

  handleKeyPress(e) {
    switch(e.keyCode) {
      case 40: // Arrow Down
        e.preventDefault();
        this.moveDown();
        break;
      case 38: // Arrow Up
        e.preventDefault();
        this.moveUp();
        break;
      case 27: // Escape Key
        e.preventDefault();

        this.setState({
          isFocused: false,
          cursorHoverIndex: null,
        })
      default:
        return; // do nothing
    }
  }

  onBlur(e) {
    e.preventDefault();
  }

  gotoDJUrl(url) {
    this.props.gotoUrl(url)
    this.props.setIndex(null);
    this.input.blur();
    this.props.updateUrl();
    this.setState({
      isFocused: false,
      cursorHoverIndex: null,
    })
  }

  handleSubmit(e) {
    e.preventDefault()

    const currentIndex = this.state.cursorHoverIndex

    if(currentIndex === null) {
      this.props.setIndex(null);
      this.input.blur();
      this.props.updateUrl();

      this.setState({
        isFocused: false,
        cursorHoverIndex: null,
      })

      if(this.props.location && this.props.location.includes("search")) {
        this.props.submitSearch()
      } else {
        this.props.gotoSearchPage();
      }
    } else {
      const dj = this.props.suggestions[currentIndex];
      const urlObj = buildArtistUrl({
        name: dj.name,
        slug: dj.slug,
        id: dj.id
      }) 

      const url = urlObj.pathname + urlObj.search
      this.gotoDJUrl(url);
    }
  }

  render() {
    return (
      <form className="navbar-form navbar-left m6k-search" onSubmit={this.handleSubmit} action=".">
        <div className="form-group">
          <input 
            type="search"
            aria-label="Search mu6ik.com"
            placeholder='Search for DJs, People, and Songs'
            value={this.props.q || ""}
            onChange={this.handleChange}
            autoCorrect="off" 
            autoCapitalize="off" 
            autoComplete="off"
            spellCheck="false"
            onKeyDown={this.handleKeyPress}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            ref={(ref) => this.input = ref}
          />

          <button type="submit">
            <span className='glyphicon glyphicon-search'></span>
          </button>
        </div>

        <SearchDropdown 
          isFocusedInput={this.state.isFocused}
          isInputBlank={this.state.isInputBlank}
          suggestions={this.props.suggestions}
          cursorHoverIndex={this.state.cursorHoverIndex}
          gotoDJUrl={this.gotoDJUrl}
          isMobile={false}
        />
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suggestions: selectNameSuggestions(state),
  }
}

export default connect(mapStateToProps, {
  getNameSuggestions,
  gotoUrl
})(SearchBar);
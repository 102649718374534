import React from 'react';
import { connect } from 'react-redux'

import { getUsers } from 'app/modules/search/search-users'

import debounce from 'lodash/debounce'

import withInfiniteScroll from 'app/shared/infinite-scroll'

import FilterCityList from 'app/shared/filters/filter-city-list'
import Filters from 'app/shared/filters/filters'
import FilterSection from 'app/shared/filters/filter-section';

import SearchUsersList from './search-users-list'
import Loading from 'react-loading'

import M6kRadio from 'app/components/m6k-radio';
import M6kCheckbox from 'components/m6k-checkbox'

import { isBrowser } from "react-device-detect";

import { 
  submitSearch, 
  toggleFilterOption,
  setSearchField,
  updateUrl,
  setSearchQuery,
  clearAllFilterOptions,
  selectFilterCities,
} from 'app/modules/search'

import {
  sendFriendRequest,
  acceptFriendRequest,
  cancelFriendRequest,
  unfriend,
} from 'app/modules/friends'

import {
  searchCities,
  getCities,
  clearCitySearch
} from 'app/modules/cities'

class SearchUsers extends React.Component {
  constructor(props) {
    super(props);

    this.onPaginatedSearch = this.onPaginatedSearch.bind(this)
    this.renderEndListItem = this.renderEndListItem.bind(this)
    this.renderCityList = this.renderCityList.bind(this)
    this.handleOutsideClickCity = this.handleOutsideClickCity.bind(this);

    this.searchCities = debounce(this.searchCities.bind(this), 150)
    this.submitSearch = this.submitSearch.bind(this)

    this.renderFilters = this.renderFilters.bind(this)
    this.renderMobile = this.renderMobile.bind(this)
    this.renderBrowser = this.renderBrowser.bind(this)

    this.state = {
      mode: 'list',
      location: '',
      isPaginatedFetching: false,
    }
  }

  componentWillMount() {
    this.InfiniteSearchUsers = withInfiniteScroll(SearchUsersList)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClickCity, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClickCity, false);

    this.props.clearAllFilterOptions('people', 'cities')
  }

  submitSearch() {
    this.props.submitSearch()
      .then(() => {})
      .catch(() => {})    
  }

  onPaginatedSearch() {
    if(this.props.isFetching) return;

    this.setState({
      isPaginatedFetching: true
    })

    this.props.submitSearch(true)
      .then(() => {
        this.setState({isPaginatedFetching: false})
      })
      .catch(() => {})
  }

  renderEndListItem () {
    if(this.state.isPaginatedFetching && !this.props.reachedLimit) {
      return (
        <tr className='setlist-empty'>
          <td colSpan='8' >
            <div className='loading'>
              <Loading
                type='spin'
                color='#aaa'
                delay={0}
                height={45}
                width={45}
              />
            </div>
          </td>
        </tr>
      )
    } else {
      return (
       <tr className='setlist-empty'>
         <td colSpan='8' > 
            <h3 className='logo'>M</h3>
          </td>
        </tr>
      ) 
    }
  }

  handleOutsideClickCity(e) {
    if(!this.citySearchArea || this.citySearchArea.contains(e.target)) {
      return 
    }

    this.props.clearCitySearch()
  }

  renderCityList() {
    if(this.state.location.length > 0 && this.props.cities && this.props.cities.length > 0) {
      return (
        <FilterCityLisr
          cities={this.props.cities}
          handleClick={(city, name) => {
            this.props.toggleFilterOption('people', 'cities', city.id, true)
            this.props.clearCitySearch()
            this.props.submitSearch()
            this.props.updateUrl()
            this.cityInput.value = ""
          }}
        />
      )     
    } else {
      return null;
    }
  }

  searchCities(q) {
    this.setState({
      location: q,
      citySearch: true,
    })

    if(this.props.cityIsFetching) return;
    this.props.searchCities(q)
  }

  renderFilters() {
    return (
      <div className='m6k-panel filters'>
        <h3>Filters</h3>
        <FilterSection name="Mutual Friends">
          <div className='friends-degree-filter'>

            <M6kRadio 
              checked={this.props.degree === 0} 
              label='Anyone' 
              handleClick={() => {
                this.props.setSearchField('people', 'degree', 0)
                this.props.updateUrl()
                this.submitSearch()
              }}
            />

            <M6kRadio 
              checked={this.props.degree === 1} 
              label='Friends' 
              handleClick={() => {
                this.props.setSearchField('people', 'degree', 1)
                this.props.updateUrl()
                this.submitSearch()
              }}
            />

            <M6kRadio 
              checked={this.props.degree === 2} 
              label='Friends of Friends' 
              handleClick={() => {
                this.props.setSearchField('people', 'degree', 2)
                this.props.updateUrl()
                this.submitSearch()
              }}
            />

          </div>
        </FilterSection>
        <FilterSection name="Cities">
          <div className='city-filters-select' ref={(ref) => this.citySearchArea = ref }>
            <div className='dropdown-wrapper'>
              <input
                className='city-filter-input'
                type='text'
                placeholder='Add a location'
                ref={(ref) => this.cityInput = ref }
                onKeyUp={(e) => {
                  if(e.key === 'Escape') {
                    this.props.clearCitySearch()
                  }
                }}
                onChange={(e) => {
                  e.preventDefault()
                  this.searchCities(e.currentTarget.value)
                }}
              />
              { this.renderCityList() }
            </div>
            <div className='city-filters-results'>
            {
              this.props.cityFilters.map((c, i) => {
                return (
                  <div className='city-filter-toggles' key={`city-filter-${i}`} onClick={() => {
                    this.props.toggleFilterOption('people', 'cities', c.id)
                    this.props.updateUrl()
                    this.props.submitSearch()
                  }} >
                    <M6kCheckbox label={c.name} checked={true} />
                  </div>
                )
              })
            }
            </div>
          </div> 
        </FilterSection>
      </div>
    )
  }

  renderMobile() {
    return (
      <div className='search-results-mobile'>
        <div className='results-container m6k-panel'>
          <table className={`results-${this.state.mode}`} >
            {
              React.createElement(this.InfiniteSearchUsers, {
                users: this.props.users,
                currentUserId: this.props.currentUserId,
                onPaginatedSearch: this.onPaginatedSearch,
                endListItem: this.renderEndListItem(),
                sendFriendRequest: this.props.sendFriendRequest,
                acceptFriendRequest: this.props.acceptFriendRequest,
                cancelFriendRequest: this.props.cancelFriendRequest,
                unfriend: this.props.unfriend,
              })
            }
          </table>
        </div>
      </div>
    )
  }

  renderBrowser() {
    const { users } = this.props;
    const isFetching = this.props.isInitialFetching || 
      (this.props.isFetching && this.props.page === 1)

    return (
      <div>
        <div className='row'>
          <div className='col-md-12'>
            <Filters
              name={this.props.q} 
              cities={this.props.cityFilters}
              degree={this.props.degree}
              clearName={() => {
                this.props.setSearchQuery("")
                this.props.updateUrl()
                this.props.submitSearch()
              }}
              clearCity={(id) => {
                this.props.toggleFilterOption('people', 'cities', id)
                this.props.submitSearch()
              }}
              clearAll={() => {
                this.props.clearAllFilterOptions('people', 'cities')
                this.props.setSearchQuery("")
                this.props.updateUrl()
                this.props.submitSearch()
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3 m6k-fixed-col-3 col-md-4 col-sm-4 col-xs-4'>
            { this.renderFilters() }
          </div>
          <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>
            <div className='results-container m6k-panel'>
              <div className='row header'>
                <div className='col-md-10'>
                  <h3>{isFetching ? 'Searching...' : 'Results'}</h3>
                </div>
                <div className='col-md-2'>
                </div>
              </div>
                <table className={`results-${this.state.mode}`} >
                  {
                    React.createElement(this.InfiniteSearchUsers, {
                      users: this.props.users,
                      currentUserId: this.props.currentUserId,
                      onPaginatedSearch: this.onPaginatedSearch,
                      endListItem: this.renderEndListItem(),
                      isFetching: isFetching,
                      sendFriendRequest: this.props.sendFriendRequest,
                      acceptFriendRequest: this.props.acceptFriendRequest,
                      cancelFriendRequest: this.props.cancelFriendRequest,
                      unfriend: this.props.unfriend,
                    })
                  }
                </table>
              </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return isBrowser ? 
      this.renderBrowser() :
      this.renderMobile()
  }
}

const mapStateToProps = (state) => {
  return {
    users: getUsers(state),
    currentUserId: state.currentUser.account.uuid,
    isFetching: state.search.isFetching,
    page: state.search.pagination.page,
    degree: state.search.users.filters.degree,
    cityFilters: selectFilterCities(state),
    cities: getCities(state),
    cityIsFetching: state.cities.isFetching,
    q: state.search.q,
  }
}

export default connect(mapStateToProps, {
  submitSearch,
  setSearchField,
  setSearchQuery,
  searchCities,
  clearCitySearch,
  toggleFilterOption,
  clearAllFilterOptions,
  sendFriendRequest,
  acceptFriendRequest,
  cancelFriendRequest,
  unfriend,
  updateUrl,
})(SearchUsers)
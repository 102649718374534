import React from 'react';

import { Helmet } from 'react-helmet'
import notFoundImg from 'assets/img/broken-record.png'

import './index.scss'

export default class NotFound extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='not-found'>
        <Helmet>
          <title>Not found</title>
        </Helmet>

        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='m6k-panel not-found-panel'>
                <img src={notFoundImg} />

                {
                  this.props.children
                }
                
              </div>
            </div>
          </div>  
        </div>
      </div>
    );
  }
}

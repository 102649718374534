import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import Loading from 'react-loading'

import {
  selectFavoriteSongs
} from 'app/modules/song'

import {
 getProfileFavoriteSongs,
 updatePaginationPage,
 resetPaginationLimit
} from 'app/modules/favorites'

import {
  selectProfile
} from 'app/modules/profile'
 
import {
  filterEmptyVideos,
  filterDuplicateVideos,
  filterVideoIds
} from 'lib/video'

import {
  updatePlaylistLocation 
} from 'app/shared/music-player/modules/player'

import withInfiniteScroll from 'app/shared/infinite-scroll'
import Setlist from 'app/shared/setlist'

import '../styles/favorites.scss'

class Favorites extends Component {
  constructor (props) {
    super(props)

    this.getProfileFavoriteSongs = this.getProfileFavoriteSongs.bind(this)
    this.onPaginatedSearch = this.onPaginatedSearch.bind(this)
    this.renderEndListItem = this.renderEndListItem.bind(this)

    this.state = {
      songs: [],
      isInitialFetching: true,
      isPaginatedFetching: false,
    }
  }

  componentWillMount() {
    this.SetlistWithInfiniteScroll = withInfiniteScroll(Setlist)
  }

  componentDidMount () {
    this.props.updatePlaylistLocation('favorites')
    this.getProfileFavoriteSongs(false)
      .then(() => {
        this.setState({isInitialFetching: false})
      })
      .catch(() => {
        this.setState({isInitialFetching: false})
      })
  }

  componentWillUnmount() {
    this.props.updatePaginationPage(1)
    this.props.resetPaginationLimit()
  }

  getProfileFavoriteSongs (loadMore) {
    return this.props.getProfileFavoriteSongs(this.props.profile.uuid, loadMore)
      .then(() => {
        return Promise.resolve()
      })
      .catch(() => {
        return Promise.reject()
      })
  }

  onPaginatedSearch() {
    if(this.props.isFetching || this.props.reachedLimit) return;

    this.setState({isPaginatedFetching: true})

    this.getProfileFavoriteSongs(true) 
      .then(() => {
        this.setState({isPaginatedFetching: false})
      })
      .catch(() => {
        this.setState({isPaginatedFetching: false})
      })
  }

  renderEndListItem() {
    if(this.state.isPaginatedFetching && !this.props.reachedLimit) {
      return (
        <li className='setlist-empty'>
          <div className='loading'>
            <Loading
              type='spin'
              color='#aaa'
              delay={0}
              height={45}
              width={45}
            />
          </div>
        </li>
      )
    } else {
      return (
        <li className='setlist-empty'>
          <h3 className='logo'>M</h3>
        </li>
      ) 
    }
  }

  render () {
    return (
      <div className='favorites'>
        {
            React.createElement(this.SetlistWithInfiniteScroll, 
              {
                songs: this.props.songs,
                renderDJ: true,
                renderCreated: true,
                dj: this.props.dj,
                renderRefresh: true,
                isInitialFetching: this.state.isInitialFetching,
                isFetching: this.props.isFetching,
                onPaginatedSearch: this.onPaginatedSearch,
                endListItem:this.renderEndListItem,
                refreshPlaylist: this.refreshPlaylist,
                title: "Favorites",
              }
            )
          }
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    songs: selectFavoriteSongs(state),
    profile: selectProfile(state),
    reachedLimit: state.favorites.form.pagination.reachedLimit,
    isFetching: state.song.isFetching,
  }
}

export default connect(mapStateToProps, {
 getProfileFavoriteSongs,
 updatePaginationPage,
 resetPaginationLimit,
 updatePlaylistLocation,
})(Favorites)

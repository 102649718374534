import axios from 'lib/axios-config'
import { push, replace } from 'connected-react-router'

import { setBannerNotification } from 'modules/banner-notification'

export function updatePassword (password, password_confirmation, reset_token) {
  return (dispatch, getState) => {
    const state = getState()
    const url = `/api/users/passwords`

    if (state.password.isFetching) {
      return Promise.reject()
    }

    dispatch({
      type: 'UPDATE_PASSWORD_REQUEST'
    })

    return axios.put(url, {
      password,
      password_confirmation,
      reset_token
    })
    .then((response) => {
      dispatch({ type: 'UPDATE_PASSWORD_SUCCESS' })

      dispatch(setBannerNotification(
        "You're password has been updated! Please login with your updated password.",
        'success'
      ))

      dispatch(replace('/login'))
    })
    .catch((error) => {
      console.log(error)
      dispatch(setBannerNotification(
        'Sorry, the password reset token is invalid. Please send a new request to reset your password.',
        'error'
      ))

      dispatch({
        type: 'UPDATE_PASSWORD_FAILURE',
        payload: {
          error: error.response.data
        }
      })
    })
  }
}

export function verifyResetToken (token) {
  return (dispatch, getState) => {
    const state = getState()
    const url = `/api/users/passwords/valid?reset_token=${token}`

    if (state.password.isFetching) {
      return Promise.reject()
    }

    dispatch({type: 'VERIFY_RESET_TOKEN_REQUEST'})

    return axios.get(
      url
    ).then((response) => {
      dispatch({
        type: 'VERIFY_RESET_TOKEN_SUCCESS'
      })
    }).catch((error) => {
      dispatch({
        type: 'VERIFY_RESET_TOKEN_FAILURE',
        error: error.response.data
      })

      dispatch(setBannerNotification(
        'Sorry, the password reset token is invalid. Please send a new request to reset your password.',
        'error'
      ))

      dispatch(replace('/login'))
    })
  }
}

export function sendResetEmail (email) {
  return (dispatch, getState) => {
    const state = getState()
    const url = `/api/users/passwords/request_reset`

    if (state.password.isFetching) {
    	return Promise.reject()
    }

    dispatch({
      type: 'SEND_RESET_REQUEST'
    })

    return axios.post(url, {
      email
    })
      .then((response) => {
        dispatch({ type: 'SEND_RESET_SUCCESS' })

        dispatch(setBannerNotification(
          'Reset password instructions have been sent!',
          'notice'
        ))

        dispatch(push('/login'))
      })
      .catch((error) => {
        return dispatch({
          type: 'SEND_RESET_FAILURE',
          payload: {
            error: error.response.data
          }
        })
      })
  }
}

export function clearPasswordError () {
  return {
    type: 'CLEAR_PASSWORD_ERROR'
  }
}

const defaultState = {
  token: null,
  isFetching: false,
  error: null,
  message: null,
  uuid: null
}

const password = (state = defaultState, action) => {
  switch (action.type) {
    case 'VERIFY_RESET_TOKEN_REQUEST':
      return {
        ...state,
        isFetching: true
      }
    case 'VERIFY_RESET_TOKEN_SUCCESS':
      return {
        ...state,
        isFetching: false,
        error: null
      }
    case 'VERIFY_RESET_TOKEN_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    case 'SEND_RESET_REQUEST':
      return {
        ...state,
        isFetching: true
      }
    case 'SEND_RESET_SUCCESS':
      return {
        ...state,
        isFetching: false,
        error: null
      }
    case 'SEND_RESET_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      }
    case 'UPDATE_PASSWORD_REQUEST':
      return {
        ...state,
        isUpdating: true
      }
    case 'UPDATE_PASSWORD_SUCCESS':
      return {
        ...state,
        isUpdating: false,
        error: null
      }
    case 'UPDATE_PASSWORD_FAILURE':
      return {
        ...state,
        isUpdating: false,
        error: action.payload.error
      }
    case 'CLEAR_PASSWORD_ERROR':
      return {
        ...state,
        error: null
      }
    default:
     	return state
  }
}

export default password

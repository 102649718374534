import React from 'react';

import { connect } from 'react-redux';
import { push } from 'connected-react-router'
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';

import Notice from 'components/Notice/Notice';
import { formatErrors } from 'lib/format-message';
import M6kCheckbox from 'components/m6k-checkbox'

import MiniFooter from 'components/mini-footer'

import { 
  setRegistrationField,   
  usernameAvailable, 
  clearUsernameAvailable,
  clearEmailAvailable,
  submitRegistration,
  emailAvailable
} from 'modules/registration';

import {
  getInviteCampaign
} from '../modules/invite-campaign';

import '../invite-campaign.scss';

export class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.renderFieldAvailable = this.renderFieldAvailable.bind(this);
    this.closeNotice = this.closeNotice.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this)

    this.validatorTimeout = null;

    this.state = {
      messages: [],
      messageVisible: false,
      messageClasses: null,
      unsavedChanges: false,
      fetched: false,
    }
  }

  handleChange(e, field, validator = null) {
    const newValue = e.currentTarget.value;
    const existingValue = this.props.registration[field];

    this.props.setRegistrationField(field, newValue);
    this.setState({unsavedChanges: true});

    if(validator) {
      this.clearValidatorTimeout();

      this.validatorTimeout = setTimeout(() => {
        validator(existingValue, newValue);
        this.validatorTimeout = null;
      }, 500);
    }
  }

  toggle(field) {
    this.props.setRegistrationField(field, !this.props.registration[field])
  }

  handleSubmit() {
    const password = this.password.value;
    const confirmPassword = this.confirmPassword.value;
    const firstName = this.firstName.value;
    const lastName = this.lastName.value;

    this.props.submitRegistration(
      firstName,
      lastName,
      password, 
      confirmPassword, 
      this.props.campaign.identifier
    ).then(() => {
      ReactGA.event({
        category: "User",
        action: "Created an Account",
        label: this.props.campaign.name,
      });

      this.props.clearUsernameAvailable();
      this.props.clearEmailAvailable();
    }).catch(() => {
      const { registration } = this.props;

      this.props.clearUsernameAvailable();
      this.props.clearEmailAvailable();

      if(registration.didInvalidate) {
        const errors = formatErrors(registration.error.errors);

        this.setState({
          messages: errors,
          messageVisible: true,
          messageClasses: "red",
          unsavedChanges: false,
        });
      }
    })
  }

  clearValidatorTimeout() {
    if(this.validatorTimeout){
      clearTimeout(this.validatorTimeout);
      this.validatorTimeout = null;
    }
  }

  renderFieldAvailable(field) {
    const registration = this.props.registration;
    if(!registration) {
      return null;
    }

    const available = registration[`${field}_available`];
    if(available === null || available === undefined) {
      return null;
    }

    let classes = "info "
    if(registration.isFetching && available.valid === undefined) {
      classes += "fetching"
      return (
        <div className={classes}>
          Checking...
        </div>
      )
    }

    classes += available.valid ? "valid" : "invalid"
    return (
      <div className={classes}>
        {available.msg}
      </div>
    )
  }

  closeNotice() {
    this.setState({
      messages: [],
      messageVisible: false
    })
  }

  componentDidMount() {
    this.props.getInviteCampaign(this.props.match.params.name)
      .then(() => {
        this.setState({fetched: true})
      })
      .catch(() => {})
  }

  render() {
    if(!this.state.fetched) return null;

    let inputClass = "form-control";

    const remaining = this.props.campaign.limit 
      ? this.props.campaign.limit - this.props.campaign.sign_up_total 
      : null

    return (
      <div className='invite-campaign'>
        <Helmet>
          <title>Mu6ik / Signup</title>
        </Helmet> 
        <div className="invite-campaign-panel">
          <h2>Mu6ik</h2>
          <h3>Welcome to the Public Beta Signup.</h3> 

          <h5 className={remaining < 10 ? 'important' : ''}>
            {remaining < 10 ? "Hurry: " : ""}{remaining} Signup{remaining > 1 ? "s" : ""} remaining!
          </h5>

          <Notice 
            classes={this.state.messageClasses} 
            visible={this.state.messageVisible}
            handleClick={this.closeNotice}
          >
            {
              this.state.messages.map((message, i) =>
                <div key={i}>{message}</div>
              )
            }
          </Notice>

          <form onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit()
          }}>
            <div className="form-group">
              <input 
                type="text" 
                className={inputClass} 
                placeholder="Email"
                onChange={(e) => {
                  this.handleChange(e, "email", this.props.emailAvailable);
                }}
                value={this.props.registration.email}
                ref={(email) => this.email = email}/>
                {this.renderFieldAvailable('email')}
            </div>
            <div className="form-group">
              <input 
                type="text" 
                className={inputClass} 
                placeholder="Username"
                onChange={(e) => {
                  this.handleChange(e, "username", this.props.usernameAvailable);
                }}
                value={this.props.registration.username}
                ref={(username) => this.username = username}/>
                {this.renderFieldAvailable('username')}
            </div>
            <br/>
            <div className="form-group">
              <input 
                type="text" 
                className={inputClass} 
                placeholder="First Name"
                ref={(firstName) => this.firstName = firstName}/>
            </div>
            <div className="form-group">
              <input 
                type="text" 
                className={inputClass} 
                placeholder="Last Name"
                ref={(lastName) => this.lastName = lastName}/>
            </div>
            <br/>
            <div className="form-group">
              <input 
                type="password" 
                className={inputClass}
                placeholder="Password"
                ref={(password) => this.password = password}
              />
            </div>
            <div className="form-group">
              <input 
                type="password" 
                className={inputClass}
                placeholder="Confirm Password"
                ref={(password) => this.confirmPassword = password}
              />
            </div>

            <div className='agreement-checkbox'>
              <div className='checkbox-wrapper' onClick={() => this.toggle('didAgree')}>
                <M6kCheckbox checked={this.props.registration.didAgree}  />
              </div>

              <h6 className='message'>
                I agree to the Mu6ik&nbsp; 
                <a href='/terms' target="_blank">Terms of Service</a> and&nbsp; 
                <a href='/privacy-policy' target="_blank">Privacy Policy</a>
              </h6>
            </div>

            <div className='agreement-checkbox'>
              <div className='checkbox-wrapper' onClick={() => this.toggle('sendMarketingEmails')} >
                <M6kCheckbox checked={this.props.registration.sendMarketingEmails} />
              </div>
              <h6 className='message'>
                I'd like to receive artist and song suggestions, as well as a periodic newsletter and other promotional material 
              </h6>
            </div>

            <button type="submit" className="btn btn-default sign-up">Sign up</button>
          </form>
        </div>

        <MiniFooter />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    registration: state.registration,
    campaign: state.inviteCampaign,
  }
}

export default connect(mapStateToProps, {
  setRegistrationField,
  usernameAvailable,
  clearUsernameAvailable,
  emailAvailable,
  clearEmailAvailable,
  getInviteCampaign,
  submitRegistration,
})(withRouter(Signup));

import axios from 'lib/axios-config'
import { setBannerNotification } from 'modules/banner-notification'
import { replace } from 'connected-react-router'

export function clearUsernameAvailable () {
  return {
    type: 'CLEAR_USERNAME_AVAILABLE'
  }
}

export function clearEmailAvailable () {
  return {
    type: 'CLEAR_EMAIL_AVAILABLE'
  }
}

export function fetchEmailAvailable() {
  return {
    type: "FETCH_EMAIL_AVAILABLE"
  }
}

export function fetchEmailAvailableSuccess(payload) {
  return {
    type: "FETCH_EMAIL_AVAILABLE_SUCCESS",
    payload
  }
}

export function fetchEmailAvailableFailure(payload) {
  return {
    type: "FETCH_EMAIL_AVAILABLE_FAILURE",
    payload
  }
}

export function fetchUsernameAvailable() {
  return {
    type: 'FETCH_USERNAME_AVAILABLE'
  }
}

export function fetchUsernameAvailableSuccess(payload) {
  return {
    type: 'FETCH_USERNAME_AVAILABLE_SUCCESS',
    payload
  }
}

export function fetchUsernameAvailableFailure(payload) {
  return {
    type: 'FETCH_USERNAME_AVAILABLE_FAILURE',
    payload
  }
}

export function createUserRequest() {
  return {
    type: "CREATE_USER_REQUEST"
  }
}

export function createUserSuccess() {
  return {
    type: "CREATE_USER_SUCCESS"
  }
}
 
export function createUserFailure(payload) {
  return {
    type: "CREATE_USER_FAILURE",
    payload
  }
}

export function emailAvailable (email, value) {
  return (dispatch, getState) => {
    const token = getState().auth.token
    const registration = getState().registration;
    const url = `/api/users/${token === null ? 'new_' : ''}email_available`

    if (registration.isFetching) {
      return Promise.reject()
    }

    dispatch(fetchEmailAvailable())

    return axios.get(url, {
      params: {
        email,
        value
      }
    }).then((response) => {
      dispatch(fetchEmailAvailableSuccess(response.data))
    }).catch((error) => {
      dispatch(fetchEmailAvailableFailure({
        error: error.response.data.error
      }))
    })
  }
}

export function usernameAvailable (username, value) {
  return (dispatch, getState) => {
    const token = getState().auth.token
    const registration = getState().registration;
    const url = `/api/users/${token === null ? 'new_' : ''}username_available`

    if (registration.isFetching) {
      return Promise.reject()
    }

    dispatch(fetchUsernameAvailable())

    return axios.get(url, {
      params: {
        username,
        value
      }
    }).then((response) => {
      dispatch(fetchUsernameAvailableSuccess(response.data))
    }).catch((error) => {
      dispatch(fetchUsernameAvailableFailure({
        error: error.response.data.error
      }))
    })
  }
}

export function submitRegistration (
  first_name, last_name, password, password_confirmation, campaign_identifier) {
  return (dispatch, getState) => {
    const registration = getState().registration
    const url = `/api/users/`

    if (registration.isFetching) {
      return Promise.reject()
    }

    if(registration.didAgree !== true) {
      dispatch(createUserFailure({
        error: {
          errors: { 
            you: ['must accept the terms of service and privacy policy agreement.']
          }
        }
      }))

      return Promise.reject()
    }

    dispatch(createUserRequest())

    const params = {
      registration: {
        username: registration.username,
        email: registration.email,
        send_marketing_emails: registration.sendMarketingEmails,
        first_name,
        last_name,
        password,
        password_confirmation
      },
      campaign_identifier
    }

    return axios.post(url, params)
      .then((response) => {
        dispatch(createUserSuccess())

        dispatch(setBannerNotification(
          'Yout account has been created. Please confirm your email to login.',
          'notice'
        ))

        dispatch(replace('/login'))
        return Promise.resolve()
      }).catch((error) => {
        dispatch(createUserFailure({
          error: error.response.data
        }))

        return Promise.reject()
      })
  }
}

export function setRegistrationField (field, value) {
  return {
    type: 'SET_REGISTRATION_FIELD',
    field,
    value
  }
}

const defaultState = {
  username_available: null,
  email_available: null,
  isFetching: false,
  didAgree: false,
  didInvalidate: false,
  sendMarketingEmails: false,
  username: '',
  email: '',
}

const registration = (state = defaultState, action) => {
  switch(action.type) {
    case 'VERIFY_INVITE_TOKEN_SUCCESS':
      return {
        ...state,
        email: action.payload.email
      }
    case 'SET_REGISTRATION_FIELD':
      return {
        ...state,
        [action.field]: action.value
      }
    case 'FETCH_USERNAME_AVAILABLE':
      return {
        ...state,
        isFetching: true
      }
    case 'FETCH_USERNAME_AVAILABLE_SUCCESS':
      return {
        ...state,
        isFetching: false,
        username_available: {
          ...action.payload
        }
      }
    case 'FETCH_USERNAME_AVAILABLE_FAILURE':
      return {
        ...state,
        isFetching: false,
        username_available: null,
        error: action.payload.error
      }
    case 'CLEAR_USERNAME_AVAILABLE':
      return {
        ...state,
        username_available: null
      }
    case 'FETCH_EMAIL_AVAILABLE':
      return {
        ...state,
        isFetching: true
      }
    case 'FETCH_EMAIL_AVAILABLE_SUCCESS':
      return {
        ...state,
        isFetching: false,
        email_available: {
          ...action.payload
        }
      }
    case 'FETCH_EMAIL_AVAILABLE_FAILURE':
      return {
        ...state,
        isFetching: false,
        email_available: null,
        error: action.payload.error
      }
    case 'CLEAR_EMAIL_AVAILABLE':
      return {
        ...state,
        email_available: null
      }
    case 'CREATE_USER_REQUEST':
      return {
        ...state,
        isFetching: true
      }
    case 'CREATE_USER_SUCCESS':
      return {
        ...state,
        isFetching: false,
        didInvalidate: false
      }
    case 'CREATE_USER_FAILURE':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.payload.error
      }
    default:
      return state;
  }
}

export default registration;
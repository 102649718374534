import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import { history }  from 'store/reducers'
import configureStore, { StoreContext } from 'store/configure-store'

import Routes from 'routes'
import { verifyAuthToken } from 'modules/auth'

const store = configureStore(history)

class Root extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticating: true
    }
  } 

  componentDidMount() {
    store.dispatch(verifyAuthToken())
      .then(() => {
        this.setState({isAuthenticating: false})
      })
      .catch((e) => {
        console.error(e)
        this.setState({isAuthenticating: false})
      })
  }

  render () {
    return (
      <Provider store={store}>
        <StoreContext.Provider value={store}>
          <ConnectedRouter history={history}>
            { this.state.isAuthenticating ? null : <Routes store={store} /> }
          </ConnectedRouter>
        </StoreContext.Provider>
      </Provider>
    )
  }
}

export default Root

// ReactGA.initialize('UA-93694935-1', {
//   debug: false,
//   gaOptions: {
//     userId: uuid,
//   }
// });

// history.listen((location) => {
//   ReactGA.pageview(location.pathname + location.search);
// });

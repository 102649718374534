import axios from 'lib/axios-config'
import { concatName } from 'lib/user'

import compact from  'lodash/compact'

import { user, users } from 'app/schemas/main'
import { normalize } from 'normalizr'

export function fetchFriends(userId, filterUserIds = null) {
  return (dispatch, getState) => {
    const url = `/api/users/${userId}/friends`
    const state = getState()

    if(state.friends.isFetching) {
      return Promise.reject()
    }

    dispatch({ type: "FETCH_FRIENDS" })

    return axios.get(url, {
      params: {
        filter_ids: filterUserIds
      }
    })
      .then((response) => {
        const normalized = normalize(response.data, users)

        dispatch({
          type: "FETCH_FRIENDS_SUCCESS",
          payload: {
            ids: normalized.result,
            entities: normalized.entities
          }
        })

        return Promise.resolve()
      })
      .catch((error) => {
        console.error(error);
        
        dispatch({
          type: "FETCH_FRIENDS_FAILURE",
          payload: {
            error: error.response.data.error
          }
        })

        return Promise.reject()
      }) 
  }
}

export function sendFriendRequest(friendUUID) {
  return (dispatch, getState) => {
    const state = getState()
    const url = `/api/friend_requests`

    if(state.friends.isUpdating) {
      return Promise.reject()
    }
  
    dispatch({
      type: 'SEND_FRIEND_REQUEST'
    })

    return axios.post(url, {
      friend_id: friendUUID
    })
      .then((response) => {

        dispatch({
          type: 'SEND_FRIEND_REQUEST_SUCCESS',
          payload: {
            friendUUID
          }
        })
      })
      .catch((error) => {

        dispatch({
          type: 'SEND_FRIEND_REQUEST_FAILURE',
          payload: {
            error: error.response.data.error
          }
        })
      })
  }
}

export function acceptFriendRequest(friendUUID) {
  return (dispatch, getState) => {
    const state = getState()
    const url = `/api/friend_requests/${friendUUID}`

  
    if(state.friends.isUpdating) {
      return Promise.reject()
    }

    dispatch({
      type: 'ACCEPT_FRIEND_REQUEST'
    })

    return axios.put(url)
      .then((response) => {
        dispatch({
          type: 'ACCEPT_FRIEND_REQUEST_SUCCESS',
          payload: {
            friendUUID,
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: 'ACCEPT_FRIEND_REQUEST_FAILURE',
          payload: {
            error: error.response.data.error
          }
        })
      })
  }
}

export function cancelFriendRequest(friendUUID, friendAction) {
  return (dispatch, getState) => {
    const state = getState()
    const url = `/api/friend_requests/${friendUUID}`

  
    if(state.friends.isUpdating) {
      return Promise.reject()
    }

    dispatch({
      type: 'CANCEL_FRIEND_REQUEST'
    })

    return axios.delete(url)
      .then((response) => {
        dispatch({
          type: 'CANCEL_FRIEND_REQUEST_SUCCESS',
          payload: {
            friendUUID,
            friendAction,
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: 'CANCEL_FRIEND_REQUEST_FAILURE',
          payload: {
            error: error.response.data.error
          }
        })
      })
  }
}

export function unfriend(friendUUID) {
  return (dispatch, getState) => {
    const state = getState()
    const url = `/api/friends/${friendUUID}`
  
    if(state.friends.isUpdating) {
      return Promise.reject()
    }

    dispatch({
      type: 'UNFRIEND_REQUEST'
    })

    return axios.delete(url)
      .then((response) => {
        dispatch({
          type: 'UNFRIEND_REQUEST_SUCCESS',
          payload: {
            friendUUID,
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: 'UNFRIEND_REQUEST_FAILURE',
          payload: {
            error: error.response.data.error
          }
        })
      })
  }
}

export function filterFriends(filter) {
  return {
    type: 'FILTER_FRIENDS',
    payload: {
      filter,
    }
  }
}

export function selectFriends(state) {
  const ids = state.friends.ids
  const users = state.entities.users;

  return ids.map((id) => {
    const user = users[id]
    return {
      ...user,
      name: concatName(user.first_name, user.last_name, user.username)
    }
  })
}

export function selectFilteredFriends(state) {
  const ids = state.friends.ids
  const users = state.entities.users;
  const filter = state.friends.filter;

  const filteredUsers = ids.map((id) => {
    const user = users[id];
    const name = user.first_name + ' ' + user.last_name;

    if (name.toLowerCase().startsWith(filter.toLowerCase())) {
      return {
        ...user,
        name,
      }
    }
  })

  return compact(filteredUsers)
}

export function selectFriendsFilter(state) {
  return state.friends.filter;
}

const defaultState = {
  isFetching: false,
  filter: '',
  ids: [],
  error: null,
}

const friends = (state = defaultState, action) => {
  switch(action.type) {
    case 'FILTER_FRIENDS':
      return {
        ...state,
        filter: action.payload.filter,
      }
    case "ACCEPT_FRIEND_REQUEST":
      return {
        ...state,
        isUpdating: true,
      }
    case "ACCEPT_FRIEND_REQUEST_SUCCESS":
      return {
        ...state,
        isUpdating: false,
      }
    case "ACCEPT_FRIEND_REQUEST_FAILURE":
      return {
        ...state,
        isUpdating: false,
        error: action.payload.error
      }
    case "SEND_FRIEND_REQUEST":
      return {
        ...state,
        isUpdating: true,
      }
    case "SEND_FRIEND_REQUEST_SUCCESS":
      return {
        ...state,
        isUpdating: false,
      }
    case "SEND_FRIEND_REQUEST_FAILURE":
      return {
        ...state,
        isUpdating: false,
        error: action.payload.error
      }
    case 'CANCEL_FRIEND_REQUEST':
      return {
        ...state,
        isUpdating: true,
      }
    case 'CANCEL_FRIEND_REQUEST_SUCCESS':
      return {
        ...state,
        isUpdating: false,
        error: action.payload.error,
      }
    case 'CANCEL_FRIEND_REQUEST_FAILURE':
      return {
        ...state,
        isUpdating: false,
      }
    case 'UNFRIEND_REQUEST':
      return {
        ...state,
        isUpdating: true
      }
    case 'UNFRIEND_REQUEST_SUCCESS':
      return {
        ...state,
        isUpdating: false
      }
    case 'UNFRIEND_REQUEST_FAILURE':
      return {
        ...state,
        isUpdating: false
      }
    case "FETCH_FRIENDS":
      return {
        ...state,
        isFetching: true
      }
    case "FETCH_FRIENDS_SUCCESS":
      return {
        ...state,
        isFetching: false,
        ids: action.payload.ids
      }
    case "FETCH_FRIENDS_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      }
    default:
      return state;
  }
}

export default friends;
import React from 'react';
import { NavLink } from 'react-router-dom'
import ProfileNavItem from 'app/shared/profile/profile-nav-item';

export default class SkeletonSongHeader extends React.Component {
  constructor(props) {
    super(props);

    this.renderNav = this.renderNav.bind(this)
  }

  renderNav() {
    const { song, url } = this.props;

    return (
      <ul className='song-nav'>
        <ProfileNavItem
          name='Transitions'
          url={`${url}/transitions`}
        />
        <ProfileNavItem
          name='Similar Songs'
          url={`${url}/similar-songs`}
        />
        <ProfileNavItem
          name='Marketplace'
          url={`${url}/marketplace`}
          count={song.marketplace.beatport_tracks.length}
        />
      </ul>
    )
  }
  render() {
    return (
      <header className='m6k-panel song-header'>
        <div className='song-album-art'>
          <div className='empty-image'>
          </div>
        </div>

        <div className='song-detail-panel'>
          <div className='title'>
            <div className='empty-title'></div>
          </div>

          {
            !this.props.isMobile ? 
              this.renderNav() : null
          }
        </div>
      </header>
    );
  }
}

import React from 'react';

const EditButton = (props) => {
	return (
		<span 
			className='glyphicon glyphicon-pencil edit'
			onClick={props.handleClick}
		>
		</span>
	);
}

export default EditButton;
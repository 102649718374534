import React from 'react'
import addEventListener from 'rc-util/lib/Dom/addEventListener'

// https://github.com/schrodinger/rc-slider/blob/master/src/Slider.jsx

function pauseEvent (e) {
  if (e.stopPropagation) e.stopPropagation()
  if (e.preventDefault) e.preventDefault()
}

function getMousePosition (e) {
  return e.pageX
}

function isNotTouchEvent(e) {
  return e.touches.length > 1 || (e.type.toLowerCase() === 'touchend' && e.touches.length > 0);
}

function getTouchPosition(e) {
  return e.touches.length > 0 ? e.touches[0].pageX : e.changedTouches[0].pageX
}

export default class ProgressBar extends React.Component {
  constructor (props) {
    super(props)
    this.bar = {}
    this.calculateHoverPosition = this.calculateHoverPosition.bind(this)

    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.onTouchEnd = this.onTouchEnd.bind(this)
    this.onTouchStart = this.onTouchStart.bind(this)

    this.getSliderLength = this.getSliderLength.bind(this)

    this.state = {
      hoverPosition: 0,
      seekPosition: 0,
      isSeeking: false,
      innerWidth: 0
    }
  }

  calculateHoverPosition (event) {
    const bounds = event.target.getBoundingClientRect()
    const x = event.clientX - bounds.left
    const scaled = Math.min(Math.max(x / event.target.offsetWidth, 0), 1)

    this.setState({
      hoverPosition: scaled
    })

    return scaled
  }

  addDocumentEvents (type) {
    if(type === 'touch') {
      this.onTouchMoveListener = addEventListener(document, 'touchmove', this.onTouchMove.bind(this));
      this.onTouchUpListener = addEventListener(document, 'touchend', this.onTouchEnd.bind(this));
    } else if(type === 'mouse') {
      this.onMouseMoveListener = addEventListener(document, 'mousemove', this.onMouseMove.bind(this))
      this.onMouseUpListener = addEventListener(document, 'mouseup', this.onMouseUp.bind(this))
    }
  }

  removeDocumentEvents (type) {
    if(type === 'touch') {
      if (this.onTouchMoveListener) {
        this.onTouchMoveListener.remove();
      }

      if(this.onTouchUpListener) {
        this.onTouchUpListener.remove();
      }
    } else if (type === 'mouse') {
      if (this.onMouseMoveListener) {
        this.onMouseMoveListener.remove()
      }

      if (this.onMouseUpListener) {
        this.onMouseUpListener.remove()
      }
    }
  }

  componentWillUnmount () {
    this.removeDocumentEvents('mouse')
    this.removeDocumentEvents('touch')
  }

  getSliderLength () {
    const slider = this.refs.slider

    return slider.clientWidth
  }

  getSliderStart () {
    const slider = this.refs.slider
    const rect = slider.getBoundingClientRect()

    return rect.left
  }

  onTouchMove(e) {
    if (isNotTouchEvent(e)) {
      this.removeDocumentEvents('touch')
      return;
    }

    const position = getTouchPosition(e);
    pauseEvent(e)

    const value = this.onMove(position);

    this.props.seekTo(value, false)

    this.setState({
      seekPosition: value
    })
  }

  onMouseMove (e) {
    const position = getMousePosition(e)
    pauseEvent(e)

    const value = this.onMove(position)

    this.props.seekTo(value, false)

    this.setState({
      seekPosition: value
    })
  }

  onTouchStart(e) {
    if (isNotTouchEvent(e)) return;

    const position = getTouchPosition(e);
    const value = this.onStart(position);

    this.addDocumentEvents('touch');

    this.setState({
      isSeeking: true,
      hoverPosition: 0,
      seekPosition: value
    })

    pauseEvent(e);
  }

  onMouseDown (e) {
    const position = getMousePosition(e)
    const value = this.onStart(position)

    this.props.seekTo(value, false)
    this.addDocumentEvents('mouse')

    this.setState({
      isSeeking: true,
      hoverPosition: 0,
      seekPosition: value
    })

    pauseEvent(e)
  }

  onMouseUp (e) {
    pauseEvent(e)
    this.removeDocumentEvents('mouse')

    const position = getMousePosition(e)
    const value = this.onStart(position)

    this.props.seekTo(value, true)

    this.setState({
      isSeeking: false
    })
  }

  onTouchEnd(e) {
    pauseEvent(e)
    this.removeDocumentEvents('touch')

    const position = getTouchPosition(e)
    const value = this.onMove(position)

    this.props.seekTo(value, true)

    this.setState({
      isSeeking: false
    })
  }

  onMove (position) {
    const diffPosition = position - this.startPosition
    const diffValue = diffPosition / this.getSliderLength()
    const value = this.startValue + diffValue

    return value > 1 ? 1 : (value < 0 ? 0 : value)
  }

  onStart (position) {
    const value = this.calcValueByPos(position)
    this.startValue = value
    this.startPosition = position

    this.setState({
      sliderValue: value
    })

    return value
  }

  calcValue (offset) {
    const ratio = Math.abs(offset / this.getSliderLength())
    const value = ratio * (1 - 0) + 0

    return value
  }

  calcValueByPos (position) {
    const pixelOffset = position - this.getSliderStart()
    return this.calcValue(pixelOffset)
  }

  render () {
    const progress = this.state.isSeeking
      ? this.state.seekPosition
      : Math.min(this.props.progress || 0, 1)

    const buffered = Math.min(this.props.buffered || 0, 1)

    return (
      <div className='player-progress-bar-wrapper'
        onMouseDown={this.onMouseDown}
        onTouchStart={this.onTouchStart}
        onMouseMove={(e) => {
          if (!this.state.isSeeking) {
            this.calculateHoverPosition(e)
          }
        }}
        ref='slider'
      >
        <div className='player-progress-bar'>
          <div
            className='progress-bar-fill progress-bar-played'
            style={{
              transform: `scaleX(${progress})`,
              transitionTime: `${this.state.isSeeking ? '2ms' : '75ms'} !important`
            }}
           />

          <div
            className='progress-bar-fill progress-bar-buffered'
            style={{transform: `scaleX(${buffered})`}}
           />

          <div
            className='progress-bar-fill progress-bar-hover'
            style={{transform: `scaleX(${this.state.hoverPosition})`}}
           />
        </div>
      </div>
    )
  }
}

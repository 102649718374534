import React from 'react';

import ClickHandler from 'components/click-handler'

import SquareImage from 'app/shared/dj-wall/square-image';
import FriendButton from 'app/shared/friend-button'
import MobileFriendButton from 'app/shared/friend-button/mobile-friend-button'

import { Glyphicon, Dropdown, DropdownButton, MenuItem} from 'react-bootstrap'

import { isBrowser } from 'react-device-detect'

class SearchUsersList extends React.Component { 
  constructor(props) {
    super(props);

    this.renderFriendButton = this.renderFriendButton.bind(this)
    this.renderItem = this.renderItem.bind(this)
    this.renderSkeleton = this.renderSkeleton.bind(this)
  }

  renderFriendButton(user, i) {
    if(user.uuid === this.props.currentUserId) {
      return (
        <div className='follow-button'>That's you</div>
      )
    }

    if(user.did_receive_friend_request) {
      return (
        <DropdownButton
          pullRight
          title={'Respond To Friend Request'}
          id={`receive-friend-request-button-${i}`}
        >
          <MenuItem eventKey="1" onClick={(e) => {
            e.preventDefault()
            this.props.acceptFriendRequest(user.uuid)
          }}>Confirm</MenuItem>
          <MenuItem eventKey="2" onClick={(e) => {
            e.preventDefault()
            this.props.cancelFriendRequest(user.uuid, 'did_receive_friend_request')
          }}>Delete Request</MenuItem>
        </DropdownButton>
      ) 
    }

    if(user.did_send_friend_request) {
      return (
        <DropdownButton
          pullRight
          title={'Friend Request Sent'}
          id='friend-request-button'
          id={`sent-friend-request-button-${i}`}
        >
          <MenuItem eventKey="1" onClick={(e) => {
            e.preventDefault()
            this.props.cancelFriendRequest(user.uuid, 'did_send_friend_request')
          }} >Cancel Request</MenuItem>
        </DropdownButton>
      )
    }

    if(user.is_friend) {
      return (
        <Dropdown id={`friend-button`} pullRight>
          <Dropdown.Toggle
            id='friends-dropdown-btn'
            title={'Friends'}
          >
            <Glyphicon glyph="user" />
            Friends
          </Dropdown.Toggle>
          <Dropdown.Menu className="super-colors">
            <MenuItem eventKey="1" onClick={(e) => {
              e.preventDefault()
              this.props.unfriend(user.uuid)
            }}>Unfriend</MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      )
    }

    return (
      <button className='btn default-action-btn' onClick={(e) => {
        e.preventDefault()
        this.props.sendFriendRequest(user.uuid)
      }}>
        <span className='glyphicon glyphicon-user'></span>
        Add Friend
      </button>
    )
  }

  renderItem(user, index) {
    const images = user.profile_photo ? user.profile_photo.images : [];
    const urlObj = {
      url: `/${user.username}`,
      imgURL: images.length > 0 ? images[0].url : null
    }

    const limitedGenres = user.genres.slice(0, 3)

    const genres = limitedGenres.map((g, gIndex) => {
      return (
        <label className='genre-label' key={`user-${index}-genre-${gIndex}`}>{ g.name }</label>
      )
    })

    return (
      <tr className='result-row' key={`result-${index}`} >
        <td className='preview-image'>
          <ClickHandler url={urlObj.url} >
            <SquareImage urlPath={urlObj.imgURL} />
          </ClickHandler>
        </td>
        <td className='info'>
          <ClickHandler url={urlObj.url} >
            <h3>{ user.display_name }</h3>
          </ClickHandler>
          <h5>{ user.location || "" }</h5>
          <h5>
            { user.mutual_friends > 0 ? `${user.mutual_friends} Mutual Friend${user.mutual_friends > 1 ? 's' : ''}` : null}
          </h5>
          <div className='genres'>
            { genres }
          </div>
        </td>
        <td className='action-row'>
          {
            isBrowser ?
              <FriendButton 
                user={user} 
                currentUserId={this.props.currentUserId}
                acceptFriendRequest={this.props.acceptFriendRequest}
                cancelFriendRequest={this.props.cancelFriendRequest}
                sendFriendRequest={this.props.sendFriendRequest}
                unfriend={this.props.unfriend}
              /> :
              <MobileFriendButton 
                user={user} 
                currentUserId={this.props.currentUserId}
                acceptFriendRequest={this.props.acceptFriendRequest}
                cancelFriendRequest={this.props.cancelFriendRequest}
                sendFriendRequest={this.props.sendFriendRequest}
                unfriend={this.props.unfriend}
              /> 
          }
        </td>
      </tr>
    )
  }

  renderSkeleton(index) {
    return (
      <tr className='skeleton-row' key={`result-${index}`}>
        <td className='square'><div/></td>
        <td className='long-rectangle'>
          <div className='r1'/>
          <div className='r2'/>
        </td>
        <td></td>
      </tr>
    )  
  }

  render() {
    const { endListItem, users } = this.props;
    let items = []

    if(this.props.isFetching) {
      const count = 18
      for(var i = 0; i < count; ++i) {
        items.push(this.renderSkeleton(i))
      }
    } else {
      items = users.map((user, index) =>
        this.renderItem(user, index)
      )
    }
   

    return (
      <tbody>
        { items }
        { endListItem }
      </tbody>
    )

  }
}

export default SearchUsersList; 

import { combineReducers } from 'redux'

// export { default as selectCurrentUser } from './account' 

import account from './account'
import ui from './ui'

const user = combineReducers({
  account,
  ui
})

export default user

import React from 'react';
import { Glyphicon, Dropdown, DropdownButton, MenuItem} from 'react-bootstrap'

import './index.scss'

class FriendButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { user, currentUserId } = this.props;

    if(user.uuid === currentUserId) {
      return (
        <div className='friend-button-wrapper'>
          <div className='follow-button'>That's you</div>
        </div>
      )
    }

    if(user.did_receive_friend_request) {
      return (
        <div className='friend-button-wrapper btn-group'>
          <DropdownButton
            pullRight
            title={'Respond To Friend Request'}
            id={`receive-friend-request-button`}
          >
            <MenuItem eventKey="1" onClick={(e) => {
              e.preventDefault()
              this.props.acceptFriendRequest(user.uuid)
            }}>Confirm</MenuItem>
            <MenuItem eventKey="2" onClick={(e) => {
              e.preventDefault()
              this.props.cancelFriendRequest(user.uuid, 'did_receive_friend_request')
            }}>Delete Request</MenuItem>
          </DropdownButton>
        </div>
      ) 
    }

    if(user.did_send_friend_request) {
      return (
        <div className='friend-button-wrapper btn-group'>
          <DropdownButton
            pullRight
            title={'Friend Request Sent'}
            id='friend-request-button'
            id={`sent-friend-request-button`}
          >
            <MenuItem eventKey="1" onClick={(e) => {
              e.preventDefault()
              this.props.cancelFriendRequest(user.uuid, 'did_send_friend_request')
            }} >
              Cancel Request
            </MenuItem>
          </DropdownButton>
        </div>
      )
    }

    if(user.is_friend) {
      return (
        <div className='friend-button-wrapper btn-group'>
          <Dropdown id={`friend-button`} pullRight>
            <Dropdown.Toggle
              id='friends-dropdown-btn'
              title={'Friends'}
            >
              <Glyphicon glyph="user" />
              Friends
            </Dropdown.Toggle>
            <Dropdown.Menu className="super-colors">
              <MenuItem eventKey="1" onClick={(e) => {
                e.preventDefault()
                this.props.unfriend(user.uuid)
              }}>Unfriend</MenuItem>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )
    }

    return (
      <div className='friend-button-wrapper btn-group'>
        <button className='btn default-action-btn' onClick={(e) => {
          e.preventDefault()
          this.props.sendFriendRequest(user.uuid)
        }}>
          <span className='glyphicon glyphicon-user'></span>
          Add Friend
        </button>
      </div>
    )
  }
}

export default FriendButton;
import React from 'react';

import Img from './img'
import ClickHandler from 'components/click-handler'

export default class M6kThumbnail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      error: false,
    }

    this.handleLoad = this.handleLoad.bind(this)
    this.renderWithUrl =  this.renderWithUrl.bind(this)
    this.renderWithoutUrl = this.renderWithoutUrl.bind(this)
  }

  renderWithoutUrl() {
    return (
      <Img
        src={this.props.imageUrl}
        onError={() => {
          this.setState({error: true})
          this.props.handleError(this.props.index)
        }}
        onLoad={() => this.handleLoad()}
      />
    )
  }

  renderWithUrl() {
    if(this.props.isPublic) {
      return (
        <a href="#" onClick={e => e.preventDefault()} className='thumbnail-link'>
          <Img
            src={this.props.imageUrl}
            onError={() => {
              this.setState({error: true})
              this.props.handleError(this.props.index)
            }}
            onLoad={() => this.handleLoad()}
          />
        </a>
      )
    }

    return (
      <ClickHandler url={this.props.songUrl} className='thumbnail-link'>
        <Img
          src={this.props.imageUrl}
          onError={() => {
            this.setState({error: true})
            this.props.handleError(this.props.index)
          }}
          onLoad={() => this.handleLoad()}
        />
      </ClickHandler>
    )
  }

  handleLoad () {
    this.setState({loaded: true})
    this.props.handleLoad(this.props.index)
  }
  
  render() {
    return (
      <div className='m6k-thumbnail no-select'>

        { this.props.songUrl ? this.renderWithUrl() : this.renderWithoutUrl() }

        <div className='duration-overlay'>
          <h6>{ this.props.duration }</h6> 
        </div>
      </div>
    );
  }
}

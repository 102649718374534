import React, { Component } from 'react'

import './notice.scss'

class Notice extends Component {
  render () {
    if (this.props.visible) {
      let classes = 'notice ' + this.props.classes

      return (
        <div className={classes}>
          <div className='message'>
            {this.props.children}
          </div>
          <div className='icon' onClick={this.props.handleClick}>
            <span className='remove'>
              X
            </span>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default Notice

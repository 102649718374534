import axios from 'lib/axios-config'

import { user, users } from 'app/schemas/main'
import { friendRequest, friendRequests } from 'app/schemas/main'

import { normalize } from 'normalizr'
import { concatName } from 'lib/user'

import merge from 'lodash/merge'

export function fetchFriendRequests() {
  return (dispatch, getState) => {
    const url = `/api/friend_requests`
    const state = getState()

    if(state.friendRequests.isFetching) {
      return Promise.reject()
    }

    dispatch({ type: "FETCH_FRIEND_REQUESTS" })

    return axios.get(url)
      .then((response) => {
        const incoming = response.data.incoming.map((fr) => {
          return {
            id: fr.id,
            created_at: fr.created_at,
            user: {
              ...fr.user,
              is_friend: false,
              did_receive_friend_request: true,
              did_send_friend_request: false
            }
          }
        })

        const outgoing = response.data.outgoing.map((fr) => {
          return {
            id: fr.id,
            created_at: fr.created_at,
            user: {
              ...fr.friend,
              is_friend: false,
              did_receive_friend_request: false,
              did_send_friend_request: true
            }
          }
        })

        const normalizedIncoming = normalize(incoming, friendRequests)
        const normalizedOutgoing = normalize(outgoing, friendRequests)

        dispatch({
          type: "FETCH_FRIEND_REQUESTS_SUCCESS",
          payload: {
            incoming: normalizedIncoming.result,
            outgoing: normalizedOutgoing.result,
            entities: merge(normalizedOutgoing.entities, normalizedIncoming.entities),
          }
        })

        return Promise.resolve()
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_FRIEND_REQUESTS_FAILURE",
          payload: {
            error: error.response.data.error
          }
        })

        return Promise.reject()
      }) 
  }
}

export function selectFriendRequests(state) {
  const incoming = state.friendRequests.incoming
  const outgoing = state.friendRequests.outgoing

  const requests = state.entities.friendRequests;
  const users = state.entities.users

  return {
    incoming: incoming.map((id) => {
      const user = users[requests[id].user]
      return {
        ...user,
        name: concatName(user.first_name, user.last_name, user.username)
      }
    }),
    outgoing: outgoing.map((id) => {
      const user = users[requests[id].user]
      return {
        ...user,
        name: concatName(user.first_name, user.last_name, user.username)
      }
    })
  } 
}

const defaultState = {
  isFetching: false,
  incoming: [],
  outgoing: [],
  error: null,
}

const friendRequestsReducer = (state = defaultState, action) => {
  switch(action.type) {
    case 'FETCH_FRIEND_REQUESTS':
      return {
        ...state,
        isFetching: true,
        ids: []
      }
    case 'FETCH_FRIEND_REQUESTS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        incoming: action.payload.incoming,
        outgoing: action.payload.outgoing,
      }
    case 'FETCH_FRIEND_REQUESTS_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: error.response.data.error
      }
    default:
      return state;
  }
}

export default friendRequestsReducer;
import { injectReducer } from 'store/reducers'
import asyncComponent from 'components/async-component'

export default (store) => asyncComponent(() => new Promise((resolve) => {
  require.ensure([], (require) => {
    const Confirmation = require('./components/Confirmation').default
    const reducer = require('./modules/confirmation').default

    injectReducer(store, { key: 'confirmation', reducer })

    resolve(Confirmation)
  }, 'confirmation')
}))

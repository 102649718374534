import React from 'react';
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import {
  fetchCharts,
  selectCharts,
} from 'app/modules/charts'

import ClickHandler from 'components/click-handler'
import '../styles/home.scss'

import Setlist from  'app/shared/setlist'
import Loading from 'react-loading'
import ListGallery from './list-gallery'

import {
  buildResourceUrl
} from 'lib/string'

import { 
  formatYoutubeUrl,
} from 'lib/video'

import {
  buildImgUrl
} from 'artist-url'

const mapStateToProps = (state) => {
  return {
    lists: selectCharts(state),
    isFetching: state.charts.isFetching,
  }
}

class Charts extends React.PureComponent {
  constructor(props) {
    super(props);

    this.renderEndListItem = this.renderEndListItem.bind(this)
  }

  componentDidMount() {
    this.props.fetchCharts()
  }

  renderEndListItem() {
    if(this.props.isFetching) {
      return (
        <li className='setlist-empty'>
          <div className='loading'>
            <Loading
              type='spin'
              color='#aaa'
              delay={0}
              height={45}
              width={45}
            />
          </div>
        </li>
      )
    } else {
      return (
        <li className='setlist-empty'>
          <h3 className='logo'>M</h3>
        </li>
      ) 
    }
  }

  render() {
    const { lists } = this.props;
    const {
      new_music,
      top_artists,
      mu6ik_charts,
      female_artists,
      overlooked,
      recommended
    } = lists;

    const songs = new_music.playlistSongs.map((ps) => {
      return ps.song
    })

    return (
      <div>
        <Helmet>
          <title>
            Mu6ik - Charts
          </title>
        </Helmet>

        <div className='container-fluid'>
          <div className='home-lists'>

            <div className='row'>
              <div className='col-md-12'>

                <div className='home-lists-section'>
                  <div className='m6k-float-left'>
                    <h3>New Music This Week</h3>
                    <p>Listen to top songs played DJs worldwide</p>
                  </div>
                  <Setlist
                    playlist={new_music}
                    compactPlaylist={true}
                    songs={songs}
                  />
                </div>

                { mu6ik_charts && mu6ik_charts.length > 0 ?
                    <div className='home-lists-section'>
                      <h3>MU6IK Charts</h3>
                      <p>Top played songs by DJs worldwide by genre</p>

                      <ListGallery>
                        { mu6ik_charts.map((playlist, index) => {
                          return (
                            <div className='gallery-item' key={index}>
                              <ClickHandler url={buildResourceUrl('playlists', playlist.name, playlist.id)}>
                                <img className='youtube-cover' src={formatYoutubeUrl(playlist.youtube_id)} />
                                <h6>{playlist.name}</h6>
                              </ClickHandler>
                            </div>
                          )
                        }) }
                      </ListGallery>
                    </div>
                  : null
                }

                { top_artists && top_artists.length > 0 ?
                    <div className='home-lists-section'>
                      <h3>Around the World</h3>
                      <p>Follow, listen, and explore DJs by country</p>

                      <ListGallery>
                        { top_artists.map((artist, index) => {
                          return (
                            <div className='gallery-item' key={index}>
                              <ClickHandler url={`/search/djs?countries=${artist.country.id}`}>
                                <img src={buildImgUrl({urls: artist.discogs})} />
                                <h6>{artist.country.name}</h6>
                              </ClickHandler>
                            </div>
                          )
                        }) }
                      </ListGallery>
                    </div>
                  : null
                }

                { recommended && recommended.length > 0 ? 
                    <div className='home-lists-section'>
                      <h3>Trainspotter</h3>
                      <p>Listen to top played songs from the DJs you follow by genre</p>

                      <ListGallery>
                        { recommended.map((playlist, index) => {
                          return (
                            <div className='gallery-item' key={index}>
                              <ClickHandler url={buildResourceUrl('playlists', playlist.name, playlist.id)}>
                                <img className='youtube-cover' src={formatYoutubeUrl(playlist.youtube_id)} />
                                <h6>{playlist.name}</h6>
                              </ClickHandler>
                            </div>
                          )
                        }) }
                      </ListGallery>
                    </div>
                  : null
                }

                { female_artists && female_artists.length > 0 ?
                    <div className='home-lists-section'>
                      <h3>Featured Female DJs</h3>
                      <p>Listen, follow and explore songs played by this select group of DJs</p>

                      <ListGallery>
                        { female_artists.map((artist, index) => {
                          return (
                            <div className='gallery-item' key={index}>
                              <ClickHandler url={buildResourceUrl('dj', artist.name, artist.id)}>
                                <img src={buildImgUrl({urls: artist.discogs})} />
                                <h6>{artist.name}</h6>
                              </ClickHandler>
                            </div>
                          )
                        }) }
                      </ListGallery>
                    </div>
                  : null
                }

                { overlooked && overlooked.length > 0 ?
                    <div className='home-lists-section'>
                      <h3>Overlooked</h3>
                      <p>The least played songs by DJs you follow</p>

                      <ListGallery>
                        { overlooked.map((playlist, index) => {
                          return (
                            <div className='gallery-item' key={index}>
                              <ClickHandler url={buildResourceUrl('playlists', playlist.name, playlist.id)}>
                                <img className='youtube-cover' src={formatYoutubeUrl(playlist.youtube_id)} />
                                <h6>{playlist.name}</h6>
                              </ClickHandler>
                            </div>
                          )
                        }) }
                      </ListGallery>
                    </div>
                  : null
                }
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  fetchCharts 
})(Charts); 